import React from 'react'
import { Link } from 'react-router-dom'
import { Media } from 'reactstrap'

const SidebarHeader = ({ hidden, logo, marginLogo }) => {
    return (
        <div className="app-sidebar--header align-self-center">
            <div className="nav-logo text-white">
                <Link to="/dashboard/" title="Meili Dashboard" className="text-white">
                    <Media className="text-center">
                        <Media top>
                            {hidden ? (
                                <img
                                    width="52px"
                                    height="52px"
                                    className="small-logo text-white animated-text"
                                    src={logo}
                                    alt="logo"
                                />
                            ) : (
                                <img
                                    width="176px"
                                    height="64px"
                                    style={{ marginTop: marginLogo }}
                                    className="text-logo text-white animated-text"
                                    src={logo}
                                    alt="logo"
                                />
                            )}
                        </Media>
                    </Media>
                </Link>
            </div>
        </div>
    )
}

export default SidebarHeader
