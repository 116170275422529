import React, { useState } from 'react'
import useImage from 'use-image'
import { Image as KonvaImage, Text } from 'react-konva'
import { stationIconSize } from './MapLayerHelpers'

const MapDevice = ({
    device,
    aoi,
    handleClickDevice,
    editPoint,
    handleDragStart,
    handleDragEnd,
    handleMouse,
    size,
    icon,
}) => {
    const [deviceDefault] = useImage('/svgs/map-layer/device.svg')
    const [deviceHover] = useImage('/svgs/map-layer/device-hover.svg')
    const [deviceSelect] = useImage('/svgs/map-layer/device-select.svg')
    const [closedDoorDefault] = useImage('/svgs/map-layer/closed-door.svg')
    const [closedDoorHover] = useImage('/svgs/map-layer/closed-door-hover.svg')
    const [closedDoorSelect] = useImage('/svgs/map-layer/closed-door-select.svg')
    const [switchOnDefault] = useImage('/svgs/map-layer/switch.svg')
    const [switchOnHover] = useImage('/svgs/map-layer/switch-hover.svg')
    const [switchOnSelect] = useImage('/svgs/map-layer/switch-select.svg')
    const [elevatorDefault] = useImage('/svgs/map-layer/elevator.svg')
    const [elevatorHover] = useImage('/svgs/map-layer/elevator-hover.svg')
    const [elevatorSelect] = useImage('/svgs/map-layer/elevator-select.svg')
    const [trafficLightRedDefault] = useImage('/svgs/map-layer/traffic-light-red.svg')
    const [trafficLightRedHover] = useImage('/svgs/map-layer/traffic-light-red-hover.svg')
    const [trafficLightRedSelect] = useImage('/svgs/map-layer/traffic-light-red-select.svg')
    // const [lightBulbOffDefault] = useImage('/svgs/map-layer/lightbulb-off.svg')
    // const [lightBulbOffHover] = useImage('/svgs/map-layer/lightbulb-off-hover.svg')
    // const [lightBulbOffSelect] = useImage('/svgs/map-layer/lightbulb-off-select.svg')

    const [deviceHovered, setDeviceHovered] = useState(false)
    const [deviceClicked, setDeviceClicked] = useState(false)
    const [isMoving, setIsMoving] = useState(false)
    const [hov, setHov] = useState(null)
    const { width, height } = aoi
    const biggerMapSize = 1000000

    const showImage = () => {
        switch (device.model_type) {
            case 'door':
                if (deviceClicked) return closedDoorSelect
                if (deviceHovered) return closedDoorHover
                return closedDoorDefault
            case 'switch':
                if (deviceClicked) return switchOnSelect
                if (deviceHovered) return switchOnHover
                return switchOnDefault
            case 'elevator':
                if (deviceClicked) return elevatorSelect
                if (deviceHovered) return elevatorHover
                return elevatorDefault
            case 'traffic_light':
                if (deviceClicked) return trafficLightRedSelect
                if (deviceHovered) return trafficLightRedHover
                return trafficLightRedDefault
            default:
                if (deviceClicked) return deviceSelect
                if (deviceHovered) return deviceHover
                return deviceDefault
        }
    }

    if (!device) return
    if (!device.points) return

    return (
        <>
            <KonvaImage
                draggable={editPoint}
                image={showImage()}
                width={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                height={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                offsetX={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                offsetY={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                x={device.points[0]}
                y={device.points[1]}
                onDragMove={() => {
                    handleMouse()
                    setIsMoving(true)
                }}
                onDragStart={(e) => {
                    handleDragStart(e)
                    setIsMoving(true)
                }}
                onDragEnd={(e) => {
                    handleDragEnd(e, device)
                    setIsMoving(false)
                }}
                onMouseEnter={(e) => {
                    setDeviceHovered(true)
                    const container = e.target.getStage().container()
                    container.style.cursor = 'pointer'
                    setHov(device.title)
                    if (editPoint) {
                        setHov('Drag to move')
                    }
                }}
                onMouseLeave={(e) => {
                    setDeviceHovered(false)
                    const container = e.target.getStage().container()
                    container.style.cursor = 'default'
                    setHov(null)
                }}
                onMouseDown={() => setDeviceClicked(true)}
                onMouseUp={() => setDeviceClicked(false)}
                onClick={() => {
                    if (!device.active) return
                    handleClickDevice(device)
                }}
            />
            {!isMoving && (
                <Text
                    x={device.points[0] - 10}
                    y={device.points[1] / 1.2}
                    align="center"
                    stroke="#670d95"
                    strokeWidth={0.1}
                    text={icon ? device.title : hov}
                    fontStyle="bold"
                    fontSize={size}
                />
            )}
        </>
    )
}

export default MapDevice
