import React, { Fragment } from 'react'
import Chart from 'react-apexcharts'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Card, CardBody, Row, Col, CardFooter, CardHeader, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronCircleUp,
    faAngleRight,
    faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'

import { getMembers } from 'features/dashboard/selectors'
import { Loading } from 'ui/common'
import { secondsToString } from 'utils'

export const TotalUVChours = ({ whitedevDashboard }) => {
    let UVChours
    let UVValue
    whitedevDashboard.influx_gauges?.forEach((mapitem) => {
        if (mapitem.name === 'uvc_working_time') {
            UVChours = mapitem.name
            mapitem.data.forEach((childItem) => {
                UVValue = childItem.value
            })
        }
    })

    return (
        <Fragment>
            <Card className="mb-4 h-100">
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={12} className="text-center">
                            <span className="text-dark-50 font-size-xl">
                                Total {UVChours?.replaceAll('_', ' ')}
                            </span>
                        </Col>
                        <Col md={12} className="text-center m-0">
                            <span className="font-size-xl font-weight-bold mt-1 text-success">
                                {UVValue} Minutes
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export const TotalAFhours = ({ whitedevDashboard }) => {
    let AFhours
    let AFValue

    whitedevDashboard.influx_gauges?.forEach((mapitem) => {
        if (mapitem.name === 'AF_working_time') {
            AFhours = mapitem.name
            mapitem.data.forEach((childItem) => {
                AFValue = childItem.value
            })
        }
    })

    return (
        <Fragment>
            <Card className="mb-4 h-100">
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={12} className="text-center">
                            <span className="text-dark-50 font-size-xl">
                                Total {AFhours?.replaceAll('_', ' ')}
                            </span>
                        </Col>
                        <Col md={12} className="text-center m-0">
                            <span className="font-size-xl font-weight-bold mt-1 text-success">
                                {' '}
                                {AFValue} Minutes
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export const TotalSShours = ({ whitedevDashboard }) => {
    let SShours
    let SSValue

    whitedevDashboard.influx_gauges?.forEach((mapitem) => {
        if (mapitem.name === 'total_ss_working_time') {
            SShours = mapitem.name
            mapitem.data.forEach((childItem) => {
                SSValue = childItem.value
            })
        }
    })

    return (
        <Fragment>
            <Card className=" mb-4, h-100">
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={12} className="text-center">
                            <span className="text-dark-50 font-size-xl">
                                {SShours?.charAt(0).toUpperCase() +
                                    SShours?.substring(1)?.replaceAll('_', ' ')}
                            </span>
                        </Col>
                        <Col md={12} className="text-center m-0">
                            <span className="font-size-xl font-weight-bold mt-1 text-success">
                                {' '}
                                {SSValue} Minutes
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export const Totaldistancetravel = ({ whitedevDashboard }) => {
    let DistanceTravel
    let DistanceValue

    whitedevDashboard.influx_gauges?.forEach((mapitem) => {
        if (mapitem.name === 'total_distance_travelled') {
            DistanceTravel = mapitem.name
            mapitem.data.forEach((childItem) => {
                DistanceValue = childItem.value
            })
        }
    })
    return (
        <Fragment>
            <Card className="mb-4, h-100">
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={12} className="text-center">
                            <span className="text-dark-50 font-size-xl">
                                {DistanceTravel?.charAt(0).toUpperCase() +
                                    DistanceTravel?.substring(1)?.replaceAll('_', ' ')}
                            </span>
                        </Col>
                        <Col md={12} className="text-center m-0">
                            <span className="font-size-xl font-weight-bold mt-1 text-success">
                                {DistanceValue} Meters
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export const TotalHOCLValue = ({ whitedevDashboard }) => {
    let HOCLName
    let HOCLValue

    whitedevDashboard.influx_gauges?.forEach((mapitem) => {
        if (mapitem.name === 'HOCL %') {
            HOCLName = mapitem.name
            mapitem.data.forEach((childItem) => {
                HOCLValue = childItem.value
            })
        }
    })

    return (
        <Fragment>
            <Card className="mb-4 h-100">
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={12} className="text-center">
                            <span className="text-dark-50 font-size-xl">{HOCLName}</span>
                        </Col>
                        <Col md={12} className="text-center m-0">
                            <span className="font-size-xl font-weight-bold mt-1 text-success">
                                {' '}
                                {HOCLValue} %
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export const OperationValue = ({ whitedevDashboard }) => {
    let OperationName
    let OperationValue

    whitedevDashboard.influx_gauges?.forEach((mapitem) => {
        if (mapitem.name === 'operation') {
            OperationName = mapitem.name
            mapitem.data.forEach((childItem) => {
                OperationValue = childItem.value
            })
        }
    })

    return (
        <Fragment>
            <Card className="mb-4 h-100">
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={12} className="text-center">
                            <span className="text-dark-50 font-size-xl">
                                {OperationName?.charAt(0).toUpperCase() +
                                    OperationName?.substring(1)}
                            </span>
                        </Col>
                        <Col md={12} className="text-center m-0">
                            <span className="font-size-xl font-weight-bold mt-1 text-success">
                                {' '}
                                {OperationValue}
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export const TotalTasksCard = ({ analytics, showAnalyticsLink }) => {
    const totalTasks =
        analytics.stat?.completed_tasks +
            analytics.stat?.failed_tasks +
            analytics.stat?.terminated_tasks +
            analytics.stat?.aborted_tasks || 0

    const totalTasksComp =
        (analytics.comparison?.completed_tasks +
            analytics.comparison?.failed_tasks +
            analytics.comparison?.terminated_tasks +
            analytics.comparison?.aborted_tasks) /
            4 || 0

    return (
        <Fragment>
            <Card
                className={cx('card-box shadow-none border-0 text-dark mb-4', {
                    'card-border-left-success': totalTasksComp >= 0,
                    'card-border-left-warning': totalTasksComp < 0,
                })}
            >
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={8} className="text-center py-3">
                            <span className="text-dark-50 font-size-xl">
                                Total <br />
                                Tasks
                            </span>
                        </Col>
                        <Col md={4} className="text-center v-divider m-0">
                            <span className="font-size-xxl font-weight-bold mt-1">
                                {totalTasks}
                            </span>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="m-0 p-1 text-right">
                    <small>
                        {showAnalyticsLink && (
                            <Link to="" className="text-meili text-uppercase">
                                Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        )}
                    </small>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export const DashboardDeliveries = ({ analytics, showAnalyticsLink }) => {
    const totalCompleted = Number(analytics?.stat?.completed_tasks) || 0

    return (
        <Fragment>
            <Card
                className={cx('card-box shadow-none border-0 text-dark mb-4', {
                    'card-border-left-success': totalCompleted >= 0,
                    'card-border-left-warning': totalCompleted < 0,
                })}
            >
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={8} className="text-center py-3">
                            <span className="text-dark-50 font-size-xl">
                                Total
                                <br />
                                Deliveries
                            </span>
                        </Col>
                        <Col md={4} className="text-center v-divider m-0">
                            <span className="font-size-xxl font-weight-bold mt-1">
                                {analytics?.stat?.completed_tasks || 0}
                            </span>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="m-0 p-1 text-right">
                    <small>
                        {showAnalyticsLink && (
                            <Link to="./analytics/" className="text-meili text-uppercase">
                                Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        )}
                    </small>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export const TotalRobots = ({ analytics, showAnalyticsLink }) => {
    const totalRobots = analytics.comparison?.total_vehicles || 100

    return (
        <Fragment>
            <Card
                className={cx('card-box shadow-none border-0 text-dark mb-4', {
                    'card-border-left-success': totalRobots >= 0,
                    'card-border-left-warning': totalRobots < 0,
                })}
            >
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={8} className="text-center py-3">
                            <span className="text-dark-50 font-size-xl">
                                Connected
                                <br />
                                Robots
                            </span>
                        </Col>
                        <Col md={4} className="text-center v-divider m-0">
                            <span className="font-size-xxl font-weight-bold mt-1">
                                {analytics.stat?.total_vehicles}
                            </span>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="m-0 p-1 text-right">
                    <small>
                        {showAnalyticsLink && (
                            <Link to="./analytics/" className="text-meili text-uppercase">
                                Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        )}
                    </small>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export const TotalOperator = ({ showAnalyticsLink }) => {
    const members = useSelector(getMembers)
    const totalOperators = members.results.results?.length
    return (
        <Fragment>
            <Card
                className={cx('card-box shadow-none border-0 text-dark mb-4', {
                    'card-border-left-success': totalOperators >= 0,
                    'card-border-left-warning': totalOperators < 0,
                })}
            >
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={8} className="text-center py-3">
                            <span className="text-dark-50 font-size-xl">
                                Total
                                <br />
                                Operators
                            </span>
                        </Col>
                        <Col md={4} className="text-center v-divider m-0">
                            <span className="font-size-xxl font-weight-bold mt-1">
                                {totalOperators}
                            </span>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="m-0 p-1 text-right">
                    <small>
                        {showAnalyticsLink && (
                            <Link to="./analytics/" className="text-meili text-uppercase">
                                Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        )}
                    </small>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export const TotalDistance = ({ analytics, showAnalyticsLink }) => {
    if (!analytics.status || analytics.status === 'loading') return <Loading />
    const totalDistance = analytics.data.stat.real_distance || 0

    return (
        <Fragment>
            <Card className="card-box card-border-left-success shadow-none border-0 text-dark mb-4">
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={12} className="text-right">
                            {totalDistance >= 0 && (
                                <p className="text-success">
                                    {totalDistance > 0 && (
                                        <>
                                            <FontAwesomeIcon icon={faChevronCircleUp} />{' '}
                                        </>
                                    )}
                                    {totalDistance}%
                                </p>
                            )}
                            {totalDistance < 0 && (
                                <p className="text-warning">
                                    <FontAwesomeIcon icon={faChevronCircleDown} /> {totalDistance}%
                                </p>
                            )}
                        </Col>
                        <Col md={6} className="text-center">
                            <span className="text-dark-50 font-size-xl">Total Distance</span>
                        </Col>
                        <Col md={6} className="text-center v-divider m-0">
                            <span className="font-size-xxl font-weight-bold mt-1">340 Meter</span>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="m-0 p-1 text-right">
                    <small>
                        {showAnalyticsLink && (
                            <Link to="./analytics" className="text-meili text-uppercase">
                                Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        )}
                    </small>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export const TaskStatus = ({ analytics, showAnalyticsLink }) => {
    const totalTasks =
        analytics.stat?.completed_tasks +
            analytics.stat?.failed_tasks +
            analytics.stat?.terminated_tasks +
            analytics.stat?.aborted_tasks || 0

    const { stat } = analytics
    const compTasks = stat?.completed_tasks || 0
    const failedTasks = stat?.failed_tasks || 0
    const terminateTasks = stat?.terminated_tasks || 0

    return (
        <Fragment>
            <Card className="card-box shadow-none border-0 text-dark h-100">
                <CardHeader>Task Status</CardHeader>
                <CardBody className="p-2">
                    <Row className="align-items-center">
                        <Col md={12} className="text-center">
                            <p className="text-black">COMPLETED</p>
                            <Button color="circle" className=" remove-pointer bg-sunny-morning">
                                {stat?.completed_tasks}
                            </Button>
                            <p
                                className={cx('font-size-xs mt-sm-1', {
                                    'text-success': compTasks >= 0,
                                    'text-danger': compTasks < 0,
                                })}
                            >
                                {((compTasks / totalTasks) * 100).toFixed(1)}%
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-center">
                            <p className="text-black">TERMINATED</p>
                            <Button
                                color="circle"
                                className=" remove-pointer bg-neutral-warning text-black"
                            >
                                {stat?.terminated_tasks}
                            </Button>
                            <p
                                className={cx('font-size-xs mt-sm-1', {
                                    'text-success': terminateTasks >= 0,
                                    'text-danger': terminateTasks < 0,
                                })}
                            >
                                {((terminateTasks / totalTasks) * 100 || 0).toFixed(1)}%
                            </p>
                        </Col>
                        <Col md={6} className="text-center v-divider m-0">
                            <p className="text-black">CANCELLED</p>
                            <Button
                                color="circle"
                                className=" remove-pointer bg-neutral-danger text-black"
                            >
                                {stat?.failed_tasks}
                            </Button>
                            <p
                                className={cx('font-size-xs mt-sm-1', {
                                    'text-success': failedTasks >= 0,
                                    'text-danger': failedTasks < 0,
                                })}
                            >
                                {((failedTasks / totalTasks) * 100 || 0).toFixed(1)}%
                            </p>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="m-0 p-1 text-right">
                    <small>
                        {showAnalyticsLink && (
                            <Link to="./analytics/" className="text-meili  text-uppercase">
                                Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        )}
                    </small>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export const DeliveryStatus = ({ analytics, showAnalyticsLink }) => {
    const totalTasks =
        analytics.stat?.completed_tasks +
            analytics.stat?.failed_tasks +
            analytics.stat?.terminated_tasks +
            analytics.stat?.aborted_tasks || 0
    const compTasks = Number(analytics.stat?.completed_tasks || 0)
    const failedTasks = Number(analytics.stat?.failed_tasks + analytics.stat?.terminated_tasks || 0)

    return (
        <Card className="card-box shadow-none border-0 text-dark h-100">
            <CardHeader>Delivery Status</CardHeader>
            <CardBody className="mt-5">
                <Row className="text-center align-items-center">
                    <Col md={6} className="text-center">
                        <p className="text-black text-uppercase">Failed</p>
                        <Button
                            color="circle"
                            className="remove-pointer bg-neutral-meili text-white"
                        >
                            {failedTasks}
                        </Button>
                        <p
                            className={cx({
                                'text-success': failedTasks >= 0,
                                'text-danger': failedTasks < 0,
                                'font-size-xs': true,
                                'mt-sm-1': true,
                            })}
                        >
                            {((failedTasks / totalTasks) * 100 || 0).toFixed(1)}%
                        </p>
                    </Col>
                    <Col md={6} className="text-center m-0">
                        <p className="text-black text-uppercase">Delivered</p>
                        <Button color="circle btn-meili remove-pointer ">
                            <span>{analytics.stat?.completed_tasks}</span>
                        </Button>
                        <p
                            className={cx({
                                'text-success': compTasks >= 0,
                                'text-danger': compTasks < 0,
                                'font-size-xs': true,
                                'mt-sm-1': true,
                            })}
                        >
                            {((compTasks / totalTasks) * 100 || 0).toFixed(1)}%
                        </p>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className="m-0 p-1 text-right">
                <small>
                    {showAnalyticsLink && (
                        <Link to="./analytics/" className="text-meili text-uppercase">
                            Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                        </Link>
                    )}
                </small>
            </CardFooter>
        </Card>
    )
}

export const RobotsSpeed = ({ analytics, showAnalyticsLink }) => {
    const realAvgSpeed = analytics.stat?.avg_speed || 0

    const data = {
        series: [
            {
                name: 'Speeds',
                data: [realAvgSpeed.toFixed(1)],
            },
        ],
        options: {
            chart: {
                type: 'bar',
                width: '10px',
            },
            plotOptions: {
                bar: {
                    columnWidth: '20%',
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                formatter: function (val) {
                    return val + ' m/s'
                },
                style: {
                    fontSize: '12px',
                    colors: ['#304758'],
                },
            },

            xaxis: {
                categories: ['Average Speed'],
                position: 'top',
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        },
                    },
                },
            },
            yaxis: {
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                },
            },
        },
    }

    return (
        <Card className="card-box shadow-none border-0 text-dark h-100">
            <CardHeader>Speeds</CardHeader>
            <CardBody className="p-0">
                <Chart options={data.options} series={data.series} type="bar" />
            </CardBody>
            <CardFooter className="m-0 p-1 text-right">
                <small>
                    {showAnalyticsLink && (
                        <Link to="./analytics/" className="text-meili text-uppercase">
                            Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                        </Link>
                    )}
                </small>
            </CardFooter>
        </Card>
    )
}

export const DistancesByRobots = ({ analytics, showAnalyticsLink }) => {
    const realDistance = analytics.stat?.distance || 0
    const avgRealDistance = analytics.stat?.avg_distance || 0

    const data = {
        series: [
            {
                name: 'Distance',
                data: [realDistance.toFixed(1), avgRealDistance.toFixed(1)],
            },
        ],
        options: {
            chart: {
                height: 350,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    columnWidth: '30%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                },
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                formatter: function (val) {
                    return val + ' m'
                },
                style: {
                    fontSize: '9px',
                    colors: ['#304758'],
                },
            },

            xaxis: {
                categories: ['Total Distance', 'Average Distance'],
                position: 'top',
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        },
                    },
                },
            },
            yaxis: {
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                },
            },
        },
    }

    return (
        <Fragment>
            <Card className="card-box shadow-none border-0 text-dark mb-4 h-100">
                <CardHeader>Task's Distance</CardHeader>
                <CardBody className="p-0">
                    <Chart options={data.options} series={data.series} type="bar" />
                </CardBody>
                <CardFooter className="m-0 p-1 text-right">
                    <small>
                        {showAnalyticsLink && (
                            <Link to="./analytics/" className="text-meili text-uppercase">
                                Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        )}
                    </small>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export const RobotStatus = ({ analytics, showAnalyticsLink }) => {
    const { stat } = analytics
    const unsedVehicles = stat?.total_vehicles - stat?.vehicles_used

    const data = {
        series: [stat?.vehicles_used || 0, unsedVehicles || 0],
        options: {
            chart: {
                width: 'auto',
                height: 350,
                type: 'donut',
            },
            labels: ['Vehicle Used', 'Unused Vehicle'],
            responsive: [
                {
                    breakpoint: 3000,
                    options: {
                        chart: {
                            width: 350,
                        },
                    },
                },
                {
                    breakpoint: 1700,
                    options: {
                        chart: {
                            width: 275,
                        },
                    },
                },
                {
                    breakpoint: 1600,
                    options: {
                        chart: {
                            width: 270,
                        },
                    },
                },
                {
                    breakpoint: 1100,
                    options: {
                        chart: {
                            width: 300,
                        },
                    },
                },
                {
                    breakpoint: 1200,
                    options: {
                        chart: {
                            width: 300,
                        },
                    },
                },
            ],
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val.toFixed(1) + ' %'
                },
            },
        },
    }

    return (
        <Fragment>
            <Card className="card-box shadow-none border-0 text-dark h-100">
                <CardHeader>Robot Status</CardHeader>
                <CardBody className="mt-5">
                    <div>
                        <Chart
                            options={data.options}
                            series={data.series}
                            type="donut"
                            height={350}
                        />
                    </div>
                </CardBody>
                <CardFooter className="m-0 p-1 text-right">
                    <small>
                        {showAnalyticsLink && (
                            <Link to="./analytics/" className="text-meili text-uppercase">
                                Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                        )}
                    </small>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export const AverageIdleTime = ({ analytics, showAnalyticsLink }) => {
    const onTaskTime = secondsToString(analytics.stat?.total_on_task_time)
    const offTaskTime = secondsToString(analytics.stat?.idle_time)
    return (
        <Card className="card-box shadow-none border-0 text-dark h-100">
            <CardHeader>Average Idle Time</CardHeader>
            <CardBody>
                <Row className="text-center align-items-center" style={{ height: '220px' }}>
                    <Col md={6}>
                        <h5 className="text-uppercase text-warning">On Task</h5>
                        <p className="font-size-sm">{`${onTaskTime.hours} H, ${onTaskTime.minutes} M ${onTaskTime.seconds} S`}</p>
                        <p>{analytics.stat?.total_on_task_time?.toFixed(1)}</p>
                    </Col>
                    <Col md={6}>
                        <h5 className="text-uppercase text-warning">Idle</h5>
                        <p className="font-size-sm">{`${offTaskTime.hours} H, ${offTaskTime.minutes} M ${offTaskTime.seconds} S`}</p>
                        <p>{analytics.stat?.idle_time?.toFixed(1)}</p>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className="m-0 p-1 text-right">
                <small>
                    {showAnalyticsLink && (
                        <Link to="./analytics/" className="text-meili text-uppercase">
                            Analytics overview <FontAwesomeIcon icon={faAngleRight} />
                        </Link>
                    )}
                </small>
            </CardFooter>
        </Card>
    )
}

export const BorderedCard = ({ title, titleValue, secondaryValue, valueType, secondaryText }) => (
    <Fragment>
        <Card className="card-box card-border-left-success shadow-none border-0 text-dark mb-4">
            <CardBody>
                <div className="d-flex align-items-start">
                    <div className="font-weight-bold">
                        <small className="text-dark-50 d-block mb-1 text-uppercase">{title}</small>
                        <span className="font-size-xxl mt-1">{titleValue}</span>
                    </div>
                </div>
                <div className="mt-3">
                    <span className={`text-${valueType} pr-1`}>{secondaryValue}</span>
                    <span className="text-dark-50">{secondaryText}</span>
                </div>
            </CardBody>
        </Card>
    </Fragment>
)
