import { actionTypes } from './Message.actionTypes'

const initialState = {
    list: [],
}

export const MessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MESSAGES:
            return {
                ...state,
                list: [...state.list, ...action.messages],
            }
        case actionTypes.CLEAR_MESSAGES:
            return {
                ...state,
                list: [],
            }
        default:
            return state
    }
}
