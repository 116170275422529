export const actionTypes = {
    GET_VERSION: 'GET_VERSION',
    GET_VERSION_SUCCESS: 'GET_VERSION_SUCCESS',
    GET_VERSION_FAIL: 'GET_VERSION_FAIL',
}

export function getVersion() {
    return {
        type: actionTypes.GET_VERSION,
        payload: {
            request: {
                url: '/version/',
                method: 'GET',
                responseType: 'text',
            },
        },
    }
}
