import { actionTypes as mapActionTypes } from './Map.actionTypes'
import { normalize, schema } from 'normalizr'
import { produce } from 'immer'

export const mapState = {
    status: 'loading',
    errors: {},
    safeZones: [],
    restrictedZones: [],
    zones: {
        action: [],
        forbidden: [],
        charging: [],
        resting: [],
        tag: [],
    },
    areaSettings: {},
    paths: {},
    zonesPaths: [],
    zonesPath: {},
    points: {},
    stations: [],
    outdoorPoints: {},
    area: {},
    areaDetails: {},
    imagesData: {},
    aoi: {},
    MapAnalysisStatus: {},
    finalizedRecording: {},
    canUndo: null,
    canRedo: null,
}
const outdoorPoint = new schema.Entity(
    'points',
    {},
    { idAttribute: (value) => value.properties.uuid }
)
const point = new schema.Entity('points', {}, { idAttribute: 'uuid' })
const points = [point]
const path = new schema.Entity(
    'paths',
    {
        points: points,
    },
    { idAttribute: 'uuid' }
)
const paths = new schema.Array(path)

export default produce((draft, { type, payload, error }) => {
    switch (type) {
        case mapActionTypes.RESET_PATHS_AND_POINTS:
        case mapActionTypes.RESET_ZONES:
            draft.status = 'resetting'
            return draft
        case mapActionTypes.RESET_PATHS_AND_POINTS_SUCCESS:
            draft.status = 'reset'
            draft.zonesPaths = []
            draft.zonesPath = {}
            draft.stations = []
            return draft
        case mapActionTypes.RESET_PATHS_AND_POINTS_FAIL:
            draft.status = 'reset'
            return draft
        case mapActionTypes.RESET_ZONES_SUCCESS:
            draft.status = 'reset'
            draft.zones = {
                action: [],
                forbidden: [],
                charging: [],
                resting: [],
            }
            return draft
        case mapActionTypes.RESET_ZONES_FAIL:
            draft.status = 'reset'
            return draft
        case mapActionTypes.UPDATE_MAP_ROBOT:
            draft.status = 'updating'
            return draft
        case mapActionTypes.UPDATE_MAP_ROBOT_SUCCESS:
            draft.status = 'updated'
            return draft
        case mapActionTypes.UPDATE_MAP_ROBOT_FAIL:
            draft.status = 'error'
            return draft
        case mapActionTypes.GET_MAP_AREA_DETAILS:
            draft.status = 'loading'
            return draft
        case mapActionTypes.GET_MAP_IMAGE:
            draft.status = 'loading'
            return draft
        case mapActionTypes.GET_MAP_POINTS:
            draft.status = 'loading-points'
            draft.points = {}
            return draft
        case mapActionTypes.GET_MAP_STATIONS:
            draft.status = 'loading-stations'
            return draft
        case mapActionTypes.GET_MAP_ANALYSIS_STATUS:
            draft.status = 'loading'
            return draft
        case mapActionTypes.GET_MAP_ANALYSIS_STATUS_SUCCESS:
            draft.status = 'loaded'
            draft.MapAnalysisStatus = payload.data
            return draft
        case mapActionTypes.GET_AREA_SETTINGS:
            draft.status = 'loading'
            return draft
        case mapActionTypes.GET_AREA_SETTINGS_SUCCESS:
            draft.status = 'loaded'
            draft.areaSettings = payload.data
            return draft
        case mapActionTypes.UPDATE_AREA_SETTINGS:
            draft.status = 'updating-map-settings'
            return draft
        case mapActionTypes.UPDATE_AREA_SETTINGS_SUCCESS:
            draft.status = 'updated-map-settings'
            draft.areaSettings = { ...draft.areaSettings, ...payload.data }
            return draft
        case mapActionTypes.UPDATE_AREA_SETTINGS_FAIL:
            draft.status = 'error-map-settings'
            return draft

        case mapActionTypes.CAN_UNDO_SUCCESS:
            draft.status = 'can-undo-loaded'
            draft.canUndo = payload.data.can_undo
            return draft
        case mapActionTypes.CAN_UNDO_FAIL:
            draft.status = 'can-undo-error'
            draft.canUndo = false
            return draft
        case mapActionTypes.CAN_REDO_SUCCESS:
            draft.status = 'loaded'
            draft.canRedo = payload.data.can_redo
            return draft
        case mapActionTypes.CAN_REDO_FAIL:
            draft.status = 'can-redo-error'
            draft.canRedo = false
            return draft

        case mapActionTypes.GET_AREA_SETTINGS_FAIL:
        case mapActionTypes.GET_MAP_AREA_DETAILS_FAIL:
        case mapActionTypes.GET_AREA_OF_INTEREST_FAIL:
        case mapActionTypes.GET_MAP_POINTS_FAIL:
        case mapActionTypes.GET_MAP_STATIONS_FAIL:
            draft.status = 'error'
            return draft
        case mapActionTypes.GET_OUTDOOR_AREA_SUCCESS:
            draft.area = payload.data
            draft.status = 'updated'
            return draft
        case mapActionTypes.UPDATE_POINT_SUCCESS:
            draft.points[payload.data?.uuid] = payload.data
            draft.status = 'updated'
            return draft
        case mapActionTypes.UPDATE_POINT_FAIL:
            draft.status = 'error'
            return draft
        case mapActionTypes.UPDATE_ORIGIN_POINT_SUCCESS:
            draft.areaDetails = {
                ...draft.areaDetails,
                origin_x: payload.data.origin_x,
                origin_y: payload.data.origin_y,
            }
            draft.status = 'updated'
            return draft
        case mapActionTypes.UPDATE_ORIGIN_POINT_FAIL:
            draft.status = 'error'
            return draft
        case mapActionTypes.UPDATE_OUTDOOR_POINT_SUCCESS:
            draft.outdoorPoints[payload.data.properties.uuid] = payload.data
            draft.status = 'loaded'
            return draft
        case mapActionTypes.GET_OUTDOOR_MAP_POINTS_SUCCESS:
            draft.outdoorPoints =
                normalize(payload.data.features, [outdoorPoint]).entities.points || {}
            draft.status = 'loaded'
            return draft
        case mapActionTypes.GET_MAP_POINTS_SUCCESS:
            payload.data.forEach((point) => {
                point.rotation = 90 - point.rotation
                if (point.rotation < 0) point.rotation = 360 + point.rotation
                return payload.data
            })
            draft.points = normalize(payload.data, points).entities.points || {}
            draft.status = 'loaded'
            return draft
        case mapActionTypes.GET_MAP_STATIONS_SUCCESS:
            payload.data.forEach((station) => {
                station.rotation = 90 - station.rotation
                if (station.rotation < 0) station.rotation = 360 + station.rotation
                return payload.data
            })
            draft.stations = payload.data
            draft.status = 'loaded'
            return draft
        case mapActionTypes.GET_MAP_PATHS:
            draft.status = 'loading-paths'
            draft.paths = {}
            return draft
        case mapActionTypes.GET_PATHS:
            draft.status = 'loading-paths'
            draft.zonesPaths = []
            return draft
        case mapActionTypes.GET_ZONES_PATH:
            draft.status = 'loading-paths'
            draft.zonesPath = {}
            return draft
        case mapActionTypes.GET_OUTDOOR_MAP_PATHS_SUCCESS:
            draft.paths = payload.data
            draft.status = 'loaded-paths'
            return draft
        case mapActionTypes.GET_MAP_PATHS_SUCCESS:
            draft.paths = normalize(payload.data, paths).entities.paths || {}
            draft.status = 'loaded-paths'
            return draft
        case mapActionTypes.GET_PATHS_SUCCESS:
            draft.zonesPaths = payload.data
            draft.status = 'loaded-paths'
            return draft
        case mapActionTypes.GET_PATHS_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.GET_ZONES_PATH_SUCCESS:
            draft.zonesPath = payload.data
            draft.status = 'loaded-path'
            return draft
        case mapActionTypes.GET_ZONES_PATH_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.GET_SAFE_ZONES:
            draft.status = 'loading-safeZones'
            return draft
        case mapActionTypes.GET_RESTRICTED_ZONES:
            draft.status = 'loading-restrictedZones'
            return draft
        case mapActionTypes.GET_AREA_ZONES:
            draft.status = 'loading-zones'
            return draft
        case mapActionTypes.GET_MAP_AREA_DETAILS_SUCCESS:
            draft.areaDetails = payload.data
            draft.status = 'loaded'
            return draft
        case mapActionTypes.GET_MAP_IMAGE_SUCCESS:
            draft.imagesData = payload.data
            draft.status = 'loaded'
            return draft
        case mapActionTypes.GET_AREA_OF_INTEREST:
            draft.status = 'loading'
            return draft
        case mapActionTypes.GET_AREA_OF_INTEREST_SUCCESS:
            draft.aoi = payload.data
            draft.status = 'loaded'
            return draft
        case mapActionTypes.GET_SAFE_ZONES_SUCCESS:
            draft.status = 'loaded'
            draft.safeZones = payload.data
            return draft
        case mapActionTypes.GET_AREA_ZONES_SUCCESS:
            draft.status = 'loaded'
            payload.config.type === 'action' && (draft.zones.action = payload.data)
            payload.config.type === 'forbidden' && (draft.zones.forbidden = payload.data)
            payload.config.type === 'charging' && (draft.zones.charging = payload.data)
            payload.config.type === 'resting' && (draft.zones.resting = payload.data)
            payload.config.type === 'tag' && (draft.zones.tag = payload.data)
            return draft
        case mapActionTypes.GET_RESTRICTED_ZONES_SUCCESS:
            draft.status = 'loaded'
            draft.restrictedZones = payload.data
            return draft
        case mapActionTypes.CREATE_PATH_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.CREATE_ZONE_PATH_FAIL:
            draft.status = 'error'
            return draft
        case mapActionTypes.UPDATE_PATH_SUCCESS:
            draft.paths[payload.data.uuid] = payload.data
            draft.status = 'updated'
            return draft
        case mapActionTypes.UPDATE_ZONE_PATH_SUCCESS: {
            draft.status = 'saved'
            const index = draft.zonesPaths.findIndex((path) => path.uuid === payload.data.uuid)
            draft.zonesPaths[index] = payload.data
            return draft
        }
        case mapActionTypes.UPDATE_PATH_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.UPDATE_ZONE_PATH_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.CREATE_OUTDOOR_PATH:
        case mapActionTypes.CREATE_PATH:
        case mapActionTypes.CREATE_ZONE_PATH:
            draft.status = 'saving-path'
            return draft
        case mapActionTypes.CREATE_OUTDOOR_PATH_SUCCESS:
            draft.status = 'saved'
            draft.paths.features.push(payload.data)
            return draft
        case mapActionTypes.CREATE_PATH_SUCCESS:
            draft.status = 'saved'
            draft.paths =
                {
                    ...draft.paths,
                    ...normalize([payload.data], paths).entities.paths,
                } || {}
            return draft
        case mapActionTypes.CREATE_ZONE_PATH_SUCCESS:
            draft.status = 'saved'
            draft.zonesPaths = payload.data.length === 0 ? [] : [...draft.zonesPaths, payload.data]
            return draft
        case mapActionTypes.UPDATE_POINT:
        case mapActionTypes.UPDATE_ORIGIN_POINT:
        case mapActionTypes.UPDATE_PATH:
        case mapActionTypes.CREATE_POINT:
        case mapActionTypes.UPDATE_ZONE_PATH:
        case mapActionTypes.CREATE_MAP_STATIONS:
        case mapActionTypes.CREATE_TRAVERSAL_STATION:
            draft.status = 'saving'
            return draft
        case mapActionTypes.CREATE_POINT_FAIL:
            draft.errors = error.response.data
            return draft

        case mapActionTypes.CREATE_OUTDOOR_POINT_SUCCESS:
            draft.outdoorPoints[payload.data.properties.uuid] = payload.data
            return draft
        case mapActionTypes.CREATE_POINT_SUCCESS:
            draft.points = {
                ...draft.points,
                [payload.data.uuid]: payload.data,
            }
            draft.status = 'saved'
            return draft
        case mapActionTypes.DELETE_OUTDOOR_POINT_SUCCESS:
            if (draft.paths) {
                const updatedPaths = draft.paths.features.filter(
                    (path) =>
                        !path.properties.points.find(
                            (pathPoint) => pathPoint.uuid === payload.data.uuid
                        )
                )
                draft.paths.features = updatedPaths
            }
            delete draft.outdoorPoints[payload.data.uuid]
            return draft

        case mapActionTypes.DELETE_POINT_FAIL:
            draft.errors = error.response.data
            return draft
        case mapActionTypes.DELETE_POINT_SUCCESS:
            draft.status = 'deleted'
            if (draft.paths) {
                const updatedPaths = Object.values(draft.paths).filter(
                    (path) => !path.points.find((pointID) => pointID === payload.data.uuid)
                )
                draft.paths = normalize(updatedPaths, paths).entities.paths || {}
            }
            delete draft.points[payload.data.uuid]
            return draft
        case mapActionTypes.DELETE_OUTDOOR_PATH:
        case mapActionTypes.DELETE_PATH:
        case mapActionTypes.DELETE_ZONE_PATH:
            draft.status = 'deleting'
            return draft
        case mapActionTypes.DELETE_OUTDOOR_PATH_SUCCESS:
            draft.status = 'deleted'
            draft.paths.features = draft.paths.features.filter(
                (path) => path.properties.uuid !== payload.data.uuid
            )
            return draft
        case mapActionTypes.DELETE_PATH_SUCCESS:
            draft.status = 'deleted'
            delete draft.paths[payload.data.uuid]
            return draft
        case mapActionTypes.DELETE_ZONE_PATH_SUCCESS:
            draft.status = 'deleted'
            // eslint-disable-next-line no-case-declarations
            const indexDelete = draft.zonesPaths.findIndex(
                (path) => path.uuid === payload.data.uuid
            )
            delete draft.zonesPaths[indexDelete]
            return draft
        case mapActionTypes.DELETE_ZONE_PATH_FAIL:
            draft.errors = error.response.data
                ? error.response.data.detail
                : error.response.data.non_field_errors
                ? error.response.data.non_field_errors[0]
                : 'There was a problem deleting the path'
            return draft
        case mapActionTypes.CREATE_AREA_ZONES:
        case mapActionTypes.UPDATE_AREA_ZONES:
        case mapActionTypes.UPDATE_MAP_STATIONS:
        case mapActionTypes.CREATE_MAP_FIGURE:
            draft.status = 'saving'
            return draft
        case mapActionTypes.CREATE_MAP_FIGURE_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.CREATE_SAFE_ZONES_SUCCESS:
            draft.status = 'saved'
            draft.safeZones = payload.data
            return draft
        case mapActionTypes.CREATE_AREA_ZONES_SUCCESS:
            draft.status = 'saved'
            switch (payload.config.type) {
                case 'forbidden':
                    draft.zones.forbidden = payload.data
                    break
                case 'action':
                    draft.zones.action = payload.data
                    break
                case 'charging':
                    draft.zones.charging = payload.data
                    break
                case 'resting':
                    draft.zones.resting = payload.data
                    break
                default:
                    return
            }
            return draft
        case mapActionTypes.CREATE_AREA_ZONES_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.UPDATE_AREA_ZONES_SUCCESS:
            draft.status = 'saved'
            switch (payload.data.zone_type) {
                case 'charging':
                    // eslint-disable-next-line no-case-declarations
                    const foundIndex = draft.zones.charging.findIndex(
                        (zone) => zone.uuid === payload.data.uuid
                    )
                    draft.zones.charging[foundIndex] = payload.data
                    break
                case 'resting': {
                    const index = draft.zones.resting.findIndex(
                        (zone) => zone.uuid === payload.data.uuid
                    )
                    draft.zones.resting[index] = payload.data
                    break
                }
                default:
                    return
            }
            return draft
        case mapActionTypes.CREATE_MAP_STATIONS_SUCCESS:
            draft.status = 'saved'
            draft.stations = payload.data.length
                ? payload.data
                : payload.data.length === 0
                ? []
                : [...draft.stations, payload.data]
            return draft
        case mapActionTypes.CREATE_TRAVERSAL_STATION_SUCCESS:
            draft.status = 'saved'
            draft.stations = [...draft.stations, payload.data]
            return draft
        case mapActionTypes.UPDATE_MAP_STATIONS_SUCCESS: {
            draft.status = 'saved'
            const foundIndex = draft.stations.findIndex(
                (station) => station.uuid === payload.data.uuid
            )
            draft.stations[foundIndex] = payload.data
            return draft
        }
        case mapActionTypes.UPDATE_AREA_ZONES_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.CREATE_MAP_STATIONS_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.CREATE_TRAVERSAL_STATION_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.UPDATE_MAP_STATIONS_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.DELETE_AREA_ZONES:
        case mapActionTypes.DELETE_MAP_STATIONS:
            draft.status = 'deleting'
            return draft
        case mapActionTypes.DELETE_AREA_ZONES_SUCCESS:
            draft.status = 'deleted'
            switch (payload.config.type) {
                case 'charging': {
                    const foundIndex = draft.zones.charging.findIndex(
                        (zone) => zone.uuid === payload.config.uuid
                    )
                    delete draft.zones.charging[foundIndex]
                    break
                }
                case 'resting': {
                    const index = draft.zones.resting.findIndex(
                        (zone) => zone.uuid === payload.config.uuid
                    )
                    delete draft.zones.resting[index]
                    break
                }
                default:
                    return
            }
            return draft
        case mapActionTypes.DELETE_MAP_STATIONS_SUCCESS: {
            draft.status = 'deleted'
            const stationIndex = draft.stations.findIndex(
                (station) => station.uuid === payload.data.uuid
            )
            delete draft.stations[stationIndex]
            return draft
        }
        case mapActionTypes.DELETE_AREA_ZONES_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.DELETE_MAP_STATIONS_FAIL:
            draft.errors = error.response.data
            draft.status = 'error'
            return draft
        case mapActionTypes.FINALIZE:
            draft.status = 'finalizing'
            return draft
        case mapActionTypes.FINALIZE_FAIL:
            draft.errors = error.response?.data
            draft.status = 'finalize-error'
            return draft
        case mapActionTypes.FINALIZE_SUCCESS:
            draft.status = 'finalize-saved'
            return draft
        case mapActionTypes.START_RECORDING_SUCCESS:
            draft.status = 'recording'
            return draft
        case mapActionTypes.STOP_RECORDING_SUCCESS:
            draft.status = 'finalizing'
            return draft
        case mapActionTypes.GET_RECORDING_SUCCESS:
            draft.status = 'ok'
            draft.finalizedRecording = payload.data
            return draft
        default:
            return draft
    }
}, mapState)
