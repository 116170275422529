import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { Button, FormGroup, Label, Container, Spinner, Alert } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { customInputForm, CommonErrors } from 'ui/common'
import { requestResetPassword } from 'features/user/actionTypes'
import { ResetPasswordSchema } from 'schemas/user'

const ResetPassword = () => {
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()
    const [emailId, setEmailId] = useState('')
    const [resend, setResend] = useState(false)
    let history = useHistory()

    const handleSubmit = (data, { setErrors, setSubmitting }) => {
        setEmailId(data)
        dispatch(requestResetPassword(data)).then(({ error }) => {
            setSubmitting(false)
            if (!error) return setSuccess(true)
            const { data } = error.response
            setErrors(data)
        })
    }
    useEffect(() => {
        document.title = 'Reset Password'
    }, [dispatch])

    useEffect(() => {
        setTimeout(() => {
            resend && setResend(false)
        }, 2000)
    }, [resend])

    return (
        <Container
            className="d-flex flex-column justify-content-center mx-auto"
            style={{ height: '450px', width: '340px' }}
        >
            {resend && <Alert color="success">An email has been resent</Alert>}
            {success ? (
                <div className="pb-32 pt-32 d-flex flex-column justify-content-center align-items-center">
                    <img className="mt-2 pr-2" alt="key" src="/svgs/user-icon/email.svg" />
                    <h2 className="size-32 text-black pt-32 pb-2">Check your email</h2>
                    <span className="size-16 grey-600 pt-2 text-center">
                        {' '}
                        We have sent a password reset link to {emailId.email}
                    </span>

                    <small className="size-14 grey-600 mt-3 text-center weight-500">
                        Didn’t receive the email?{' '}
                        <span
                            className="text-meili"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                dispatch(requestResetPassword(emailId))
                                setResend(true)
                            }}
                        >
                            Click to resend
                        </span>
                    </small>

                    <div
                        style={{ cursor: 'pointer', marginTop: '24px' }}
                        className="p-2 d-flex justify-content-center align-items-center"
                        onClick={() => setSuccess(false)}
                    >
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            style={{ cursor: 'pointer', paddingRight: '8px' }}
                            alt="Logout"
                            width={28}
                            height={28}
                        ></img>
                        <span className="grey-600 size-14 weight-500">Go back</span>
                    </div>
                </div>
            ) : (
                <div style={{ height: '450px', width: '340px' }}>
                    <div className="pb-32 pt-32 d-flex flex-column justify-centent-center align-items-center">
                        <img className="mt-2 pr-2" alt="key" src="/svgs/user-icon/forgetKey.svg" />
                        <h2 className="size-32 text-black pt-32 pb-2">Forgot password?</h2>
                        <span className="size-16 grey-600 text-center">
                            {' '}
                            No worries, we will send you instructions on how to reset your password.
                        </span>
                    </div>
                    <Formik
                        initialValues={{}}
                        validationSchema={ResetPasswordSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, isSubmitting }) => (
                            <Form>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Field
                                        component={customInputForm}
                                        style={{
                                            width: '340px',
                                            height: '40px',
                                            paddingLeft: '12px',
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                        }}
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={values.email || ''}
                                        className="inputbox-border "
                                    />
                                </FormGroup>
                                <Button
                                    style={{
                                        width: '340px',
                                        height: '40px',
                                        marginTop: '24px',
                                        marginBottom: '24px',
                                    }}
                                    disabled={isSubmitting}
                                    type="submit"
                                    block={true}
                                    color="meili"
                                    className="login-border"
                                >
                                    Reset password
                                    {isSubmitting && <Spinner size="sm" color="white ml-4" />}
                                </Button>

                                <div
                                    className="grey-600 text-center mr-2 size-14 weight-500"
                                    onClick={() => history.push('/login/')}
                                    style={{ width: '340px', marginTop: '24px', cursor: 'pointer' }}
                                >
                                    <img
                                        src="/svgs/user-icon/arrow.svg"
                                        style={{ cursor: 'pointer', paddingRight: '8px' }}
                                        alt="Logout"
                                        width={28}
                                        height={28}
                                    />
                                    <span style={{ cursor: 'pointer', lineHeight: '28px' }}>
                                        Back to log in
                                    </span>
                                </div>

                                <CommonErrors />
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </Container>
    )
}

export default ResetPassword
