import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { thunk } from 'redux-thunk'
import { createBrowserHistory } from 'history'

import { UserReducer } from './features/user'
import { OrganizationReducer, TeamReducer, TaskReducer, VersionReducer } from './features/dashboard'
import { apiMiddleware, loggerMiddleware, messageUpdaterMiddleware } from './middleware'
import { MessageReducer } from 'features/generic/MessageReducer'
import { SimulationReducer } from 'features/generic/SimulationReducer'
import { NotificationReducer } from 'features/dashboard/NotificationReducer'
import BillingReducer from 'features/dashboard/BillingReducer'

export const history = createBrowserHistory()
let middlewares = [thunk, apiMiddleware, messageUpdaterMiddleware]
if (process.env.NODE_ENV !== 'production') {
    middlewares = [...middlewares, loggerMiddleware]
}

const middlewareEnhancer = applyMiddleware(...middlewares)

/* Create root reducer, containing all features of the application */
export const rootReducer = () =>
    combineReducers({
        user: UserReducer,
        generic: combineReducers({
            messages: MessageReducer,
            billing: BillingReducer,
            simulation: SimulationReducer,
        }),
        dashboard: combineReducers({
            org: OrganizationReducer,
            team: TeamReducer,
            task: TaskReducer,
            version: VersionReducer,
            notification: NotificationReducer,
        }),
    })

export default function configureStore(preloadedState) {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const store = createStore(
        rootReducer(history),
        preloadedState,
        composeEnhancer(middlewareEnhancer)
    )
    return store
}
