import React from 'react'
import { Image } from 'react-konva'
import useImage from 'use-image'

const MapEditorEraser = ({ cursor, eraserSize, handleErasing, handleMouseMove }) => {
    const [eraser] = useImage('/svgs/map-editor/eraser-cursor.svg')
    return (
        <Image
            image={eraser}
            x={cursor.x - eraserSize / 4}
            y={cursor.y}
            width={eraserSize}
            height={eraserSize}
            onMouseDown={handleErasing}
            onMouseMove={handleMouseMove}
        />
    )
}

export default MapEditorEraser
