import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Spinner } from 'reactstrap'
import '@geoman-io/leaflet-geoman-free'
import { MapContainer as Map, TileLayer, GeoJSON } from 'react-leaflet'

import { selectors } from 'features/dashboard'
import OutsideMapCreateSideBar from './OutsideMapCreateSideBar'
import {
    createOutdoorArea,
    getOutdoorArea,
} from 'features/dashboard/Map.actionTypes'
import MapSaveForm from './MapSaveForm'

const OutsideMapCreate = () => {
    let history = useHistory()
    const team = useSelector(selectors.getTeam)
    const map = useSelector(selectors.getTeamMap)
    const dispatch = useDispatch()
    const mapRef = useRef()
    const [status, setStatus] = useState('loading')
    const COPENHAGEN = {
        lat: 55.686724,
        lng: 12.5700724,
    }
    const [mapState, setMap] = useState(null)
    const { slug } = useParams()

    const getAreaLayer = () => {
        if (!mapRef.current) return
        const createdLayer = mapRef.current._map.pm.getGeomanDrawLayers()
        if (createdLayer.length > 0) return createdLayer[0]
        let result = null
        mapRef.current._map.eachLayer((layer) => {
            if (layer?.feature?.type === 'Feature') {
                result = layer
            }
        })
        return result
    }

    const toggleToolBar = (value = false, map) => {
        map.addControls({
            drawPolygon: value,
        })
    }

    const redirectToMap = () => {
        if (
            slug === team.details.slug &&
            team.details?.operation_type !== 'outside'
        )
            return history.push('../modify/')
    }

    const addControls = (map) => {
        map.pm.addControls({
            position: 'topleft',
            drawMarker: false,
            drawCircleMarker: false,
            drawPolyline: false,
            drawCircle: false,
            drawRectangle: false,
            cutPolygon: false,
        })
    }

    const addControlEvents = (map) => {
        map.on('pm:create', (e) => {
            toggleToolBar(false, e.target.pm)
        })
        map.on('pm:remove', (e) => {
            toggleToolBar(true, e.target.pm)
        })
    }

    useEffect(() => {
        redirectToMap()
        dispatch(getOutdoorArea({ slug })).then(() => setStatus('loaded'))
    }, [slug]) // eslint-disable-line

    return (
        <>
            <Row className="pr-3 mb-3">
                <Col className="text-right" md={12}>
                    <MapSaveForm
                        saveAction={createOutdoorArea}
                        getMapData={() => ({
                            coordinates:
                                getAreaLayer()
                                    ?.toGeoJSON()
                                    ?.geometry.coordinates.flat() || [],
                        })}
                    />
                </Col>
            </Row>
            <Row className="pr-5">
                <Col md={11}>
                    {status === 'loading' && <Spinner />}
                    {status === 'loaded' && (
                        <Map
                            center={COPENHAGEN}
                            zoom={13}
                            whenReady={(e) => {
                                addControls(e.target)
                                addControlEvents(e.target)
                                setMap(e.target)
                                if (!map.area.geometry) return
                                const bounds = map.area.geometry.coordinates[0].map(
                                    (coo) => [coo[1], coo[0]]
                                )
                                e.target.fitBounds(bounds)
                                toggleToolBar(false, e.target.pm)
                            }}
                        >
                            <TileLayer
                                ref={mapRef}
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {map.area?.geometry && <GeoJSON data={map.area} />}
                        </Map>
                    )}
                </Col>
                <Col md={1}>
                    <OutsideMapCreateSideBar
                        onSelect={(loc) => {
                            mapState.flyTo([loc.lat, loc.lon], mapState.getZoom())
                        }}
                        onArea={() => {}}
                    />
                </Col>
            </Row>
        </>
    )
}

export default OutsideMapCreate
