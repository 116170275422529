export const actionTypes = {
CREATE_TRIGGER: 'CREATE_TRIGGER',
CREATE_TRIGGER_SUCCESS: 'CREATE_TRIGGER_SUCCESS',
CREATE_TRIGGER_FAIL: 'CREATE_TRIGGER_FAIL',
GET_TRIGGER: 'GET_TRIGGER',
GET_TRIGGER_SUCCESS: 'GET_TRIGGER_SUCCESS',
GET_TRIGGER_FAIL: 'GET_TRIGGER_FAIL',
UPDATE_TRIGGER: 'UPDATE_TRIGGER',
UPDATE_TRIGGER_SUCCESS: 'UPDATE_TRIGGER_SUCCESS',
UPDATE_TRIGGER_FAIL: 'UPDATE_TRIGGER_FAIL',
DELETE_TRIGGER: 'DELETE_TRIGGER',
DELETE_TRIGGER_SUCCESS:'DELETE_TRIGGER_SUCCESS',
DELETE_TRIGGER_FAIL: 'DELETE_TRIGGER_FAIL'

}

export function createTrigger({ slug, data }) {
    return {
        type: actionTypes.CREATE_TRIGGER,
        payload: {
            request: {
                url: `/api/teams/${slug}/triggers/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getTriggers({ slug }) {
    return {
        type: actionTypes.GET_TRIGGER,
        payload: {
            request: {
                url: `/api/teams/${slug}/triggers/`,
                method: 'GET',
            },
        },
    }
}

export function updateTrigger({ uuid ,...data }) {
    return {
        type: actionTypes.UPDATE_TRIGGER,
        payload: {
            request: {
                url: `/api/triggers/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}
export function deleteTrigger({ uuid}) {
    return {
        type: actionTypes.DELETE_TRIGGER,
        payload: {
            request: {
                url: `/api/triggers/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}