import { logout } from 'features/user/actionTypes'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Card, CardBody, CardTitle, Container, Col, Spinner, Alert } from 'reactstrap'

const Logout = () => {
    const dispatch = useDispatch()
    const [status, setSatus] = useState('loading')
    useEffect(() => {
        dispatch(logout()).then(({ error }) => {
            if (error) {
                window.localStorage.removeItem('token')
                setSatus('error')
                return
            }
            setSatus('redirect')
            window.localStorage.removeItem('token')
        })
        document.title = 'Logging out'
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (status === 'redirect') return <Redirect to="/login/" />

    return (
        <Container
            style={{ minHeight: '450px' }}
            className="d-flex flex-row justify-content-center"
        >
            <Col md={6} className="m-4 p-4">
                <Card className="card-box">
                    <CardBody>
                        <CardTitle>
                            <h4>Logging out</h4>
                        </CardTitle>
                        <hr />
                        {status === 'loading' && <Spinner />}
                        {status === 'error' && (
                            <Alert color="danger">Something went wrong, please try again.</Alert>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Container>
    )
}

export default Logout
