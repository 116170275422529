import React from 'react'
import { FaRegTimesCircle, FaFileImage } from 'react-icons/fa'
import { Progress } from 'reactstrap'

const ProgressUploading = ({ image, yaml, progress, cancelSource }) => {
    const formatBytes = (a, b = 2) => {
        if (!+a) return '0 Bytes'
        const c = 0 > b ? 0 : b,
            d = Math.floor(Math.log(a) / Math.log(1024))
        return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
            ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
        }`
    }
    return (
        <div className="d-flex flex-row w-100 align-items-center justify-content-center my-5">
            <FaFileImage style={{ width: '5rem', height: '5rem' }} className="text-gray mb-4" />

            <div
                className={`d-flex flex-column w-50 jusitfy-content-center 
                  mx-3`}
            >
                <span>
                    {image?.name} & {yaml?.name}
                </span>
                <Progress color="success" className="w-100" value={progress} />
                <span>{formatBytes(image?.size + yaml?.size)}</span>
            </div>
            <FaRegTimesCircle
                style={{ width: '1.4rem', height: '1.4rem', cursor: 'pointer' }}
                className="text-danger mb-1"
                onClick={() => cancelSource.cancel()}
                    
            />
        </div>
    )
}

export default ProgressUploading
