import { actionTypes } from './Membership.actionTypes'

export const memberState = {
    status: 'loading',
    results: [],
}

// import/no-anonymous-default-export
export const memberReducer = (state = memberState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_MEMBERS:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.GET_ALL_MEMBERS_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                results: action.payload.data,
            }
        case actionTypes.GET_ALL_MEMBERS_FAIL:
            return {
                ...state,
                status: 'error',
            }

        case actionTypes.UPDATE_MEMBER_SUCCESS:
            return {
                ...state,
                results: state.results.map((member) =>
                    member.uuid === action.payload.data.uuid ? action.payload.data : member
                ),
            }
        case actionTypes.CREATE_MEMBER_FAIL:
            return {
                ...state,
                status: 'loaded',
            }
        case actionTypes.REMOVE_MEMBER:
            return {
                ...state,
                status: 'removing',
            }
        case actionTypes.REMOVE_MEMBER_SUCCESS:
            return {
                ...state,
                status: 'removed',
                results: state.list.filter((member) => member.uuid !== action.payload.data.uuid),
            }
        case actionTypes.REMOVE_MEMBER_FAIL:
            return {
                ...state,
                status: 'error',
            }
        default:
            return state
    }
}
