import { actionTypes } from './TeamDashboard.actionTypes'

export const teamDashboardState = {
    status: 'loading',
    data: {},
}

export const teamDashboardReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.GET_TEAM_DASHBOARD:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.GET_TEAM_DASHBOARD_FAIL:
            return {
                ...state,
                status: 'error',
            }

        case actionTypes.GET_TEAM_DASHBOARD_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
            }
        default:
            return state
    }
}
