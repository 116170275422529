import React from 'react'
import { Arrow } from 'react-konva'
import { calculateArrowPositions } from './MapLayerHelpers'

const MapEditorPaths = ({ path, editPoint, scale, aoi }) => {
    if (!path) return
    const arrowPoints = calculateArrowPositions(path.points, 10)
    if (
        (path.destination_point_type === 'resting' || path.source_point_type === 'resting') &&
        editPoint
    )
        return null
    if (
        (path.destination_point_type === 'charging' || path.source_point_type === 'charging') &&
        editPoint
    )
        return null
    return (
        <>
            <Arrow
                className="arrow"
                points={path.points?.flat()}
                strokeWidth={1}
                pointerWidth={1}
                pointerLength={1}
                pointerAtBeginning={path.bidirectional}
                stroke={path.is_active ? '#BA4FFA' : '#E7E9EC'}
            />

            {!path.bidirectional &&
                arrowPoints.map(({ x, y, angle }, i) => (
                    <Arrow
                        key={i}
                        x={x}
                        y={y}
                        tension={0.4}
                        points={[0, 0]}
                        className="arrow"
                        strokeWidth={1}
                        pointerWidth={1}
                        pointerLength={1}
                        rotation={angle * (180 / Math.PI)}
                        stroke={path.is_active ? '#BA4FFA' : '#E7E9EC'}
                    />
                ))}
        </>
    )
}

export default MapEditorPaths
