export const schedulesList = [
    {
        dataField: 'title',
        text: 'Name',
        sort: true,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'execute_at',
        text: 'Execute',
        sort: false,
        style: { textAlign: 'center' },
        headerStyle: {
            backgroundColor: '#F9FAFB',
            color: 'gray',
            textAlign: 'center',
            width: '10rem',
        },
    },
    {
        dataField: 'details',
        text: 'Details',
        sort: false,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'enabled',
        text: 'Enabled',
        sort: false,
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
    },
    {
        dataField: 'offline',
        text: 'Offline',
        sort: false,
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
    },
    {
        dataField: 'update',
        sort: false,
        text: '',
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', width: '8rem' },
    },
]

export const presetsColumns = [
    {
        dataField: 'title',
        text: 'Name',
        sort: true,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'created_at',
        text: 'Created',
        sort: false,
        style: { textAlign: 'center' },
        headerStyle: {
            backgroundColor: '#F9FAFB',
            color: 'gray',
            textAlign: 'center',
            width: '10rem',
        },
    },
    {
        dataField: 'details',
        text: 'Details',
        sort: false,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'update',
        sort: false,
        text: '',
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', width: '8rem' },
    },
]

export const actionsPresetsColumns = [
    {
        dataField: 'title',
        text: 'Name',
        sort: true,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'type',
        text: 'Type',
        sort: false,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', width: '15rem' },
    },
    {
        dataField: 'details',
        text: 'Details',
        sort: false,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },

    {
        dataField: 'update',
        sort: false,
        text: '',
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', width: '8rem' },
    },
]

export const definitionList = [
    {
        dataField: 'title',
        text: 'Name',
        sort: true,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'type',
        text: 'Type',
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
    },
    {
        dataField: 'description',
        text: 'Description',
        sort: false,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'wait_confirmation',
        text: 'Wait for confirmation',
        sort: false,
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
    },
    {
        dataField: 'user_input_needed',
        text: 'User input needed',
        sort: false,
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
    },
    {
        dataField: 'variables',
        text: 'Variables',
        sort: false,
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
    },
    {
        dataField: 'update',
        sort: false,
        text: '',
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', width: '8rem' },
    },
]

export const triggersColumns = [
    {
        dataField: 'title',
        text: 'Name',
        sort: true,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'action',
        text: 'Action',
        sort: false,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', width: '15rem' },
    },
    {
        dataField: 'ip_whitelist',
        text: 'ip_whitelist',
        sort: false,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },
    {
        dataField: 'initialized',
        text: 'Initialized',
        sort: false,
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray' },
    },

    {
        dataField: 'update',
        sort: false,
        text: '',
        style: { textAlign: 'center' },
        headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', width: '8rem' },
    },
]
