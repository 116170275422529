import React, { Fragment } from 'react'
import Chart from 'react-apexcharts'

import { Card, CardBody, Row, Col, CardHeader } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
// import redcomp from './../../assets/images/illustrations/redcomp.svg'
// import greencomp from './../../assets/images/illustrations/greencomp.svg'
// import lincomp from './../../assets/images/illustrations/lincomp.svg'

import { BsArrowDownShort, BsArrowRightShort, BsArrowUpShort } from 'react-icons/bs'

export const TotalMission = ({ filteredData, selectedDays }) => {
    let totalMission
    let compValue

    if (Object.values(filteredData).length !== 0) {
        Object.values(filteredData)?.forEach((mapitem) => {
            if (mapitem.title === 'Total missions') {
                mapitem.data.forEach((childItem) => {
                    totalMission = childItem.value
                    compValue = childItem.comparison?.value
                })
            }
        })
    }

    return (
        <Card className="card-container mr-3">
            <CardBody className="p-0">
                <div className="d-flex justify-content-between position-relative">
                    <div>
                        <h5 className="title-value">Total missions</h5>

                        <h2 className="text">
                            {isNaN(totalMission) ? 0 : Math.round(totalMission)}
                        </h2>
                        <small className="small-text">
                            {compValue > totalMission ? (
                                <BsArrowDownShort size={20} className="text-danger" />
                            ) : compValue === totalMission ? (
                                <span size={20} className="equal-data mr-1">
                                    <BsArrowRightShort />
                                </span>
                            ) : (
                                <BsArrowUpShort size={20} className="text-success" />
                            )}
                            {Math.round(totalMission - compValue) ? (
                                Math.round(totalMission - compValue)
                            ) : (
                                <span size={20} className="equal-data mr-1">
                                    no change
                                </span>
                            )}
                            <span> vs last {selectedDays} days</span>
                        </small>
                    </div>
                    {/* <div className="graph-position">
                        {compValue > totalMission ? (
                            <img alt="redcamp" src={redcomp} />
                        ) : compValue === totalMission ? (
                            <img alt="linecomp" src={lincomp} className="mb-3"></img>
                        ) : (
                            <img alt="green" src={greencomp} />
                        )}
                    </div> */}
                </div>
            </CardBody>
        </Card>
    )
}
export const TotalDeliveries = ({ filteredData, selectedDays }) => {
    let totalDeliveries
    let compValue

    if (Object.values(filteredData).length !== 0) {
        Object.values(filteredData)?.forEach((mapitem) => {
            if (mapitem.title === 'Total deliveries') {
                mapitem.data.forEach((childItem) => {
                    totalDeliveries = childItem.value
                    compValue = childItem.comparison?.value
                })
            }
        })
    }
    return (
        <Card className="card-container mr-3">
            <CardBody className="p-0">
                <div className="d-flex justify-content-between position-relative">
                    <div>
                        <h5 className="title-value"> Total deliveries</h5>

                        <h2 className="text">
                            {isNaN(totalDeliveries) ? 0 : Math.round(totalDeliveries)}
                        </h2>
                        <small className="small-text">
                            {compValue > totalDeliveries ? (
                                <BsArrowDownShort size={20} className="text-danger" />
                            ) : compValue === totalDeliveries ? (
                                <span size={20} className="equal-data mr-1">
                                    <BsArrowRightShort />
                                </span>
                            ) : (
                                <BsArrowUpShort size={20} className="text-success" />
                            )}
                            {Math.round(totalDeliveries - compValue) ? (
                                Math.round(totalDeliveries - compValue)
                            ) : (
                                <span size={20} className="equal-data mr-1">
                                    no change
                                </span>
                            )}{' '}
                            <span> vs last {selectedDays} days</span>
                        </small>
                    </div>
                    {/* <div className="graph-position">
                        {compValue < totalDeliveries ? (
                            <img alt="redcamp" src={redcomp} />
                        ) : compValue === totalDeliveries ? (
                            <img alt="linecomp" src={lincomp} className="mb-3"></img>
                        ) : (
                            <img alt="green" src={greencomp} />
                        )}
                    </div> */}
                </div>
            </CardBody>
        </Card>
    )
}
export const TotalErrors = ({ filteredData, selectedDays }) => {
    let totalErrors
    let compValue

    if (Object.values(filteredData).length !== 0) {
        Object.values(filteredData)?.forEach((mapitem) => {
            if (mapitem.title === 'Total errors') {
                mapitem.data.forEach((childItem) => {
                    totalErrors = Math.round(childItem.value)
                    compValue = isNaN(childItem.comparison?.value)
                        ? 0
                        : Math.round(childItem.comparison?.value)
                })
            }
        })
    }

    return (
        <Card className="card-container mr-3">
            <CardBody className="p-0">
                <div className="d-flex justify-content-between position-relative">
                    <div>
                        <h5 className="title-value"> Total errors</h5>
                        <h2 className="text">
                            {isNaN(totalErrors) ? 0 : Math.round(totalErrors)}%
                        </h2>
                        <small className="small-text">
                            {compValue > totalErrors ? (
                                <BsArrowDownShort size={20} className="text-danger" />
                            ) : compValue === totalErrors ? (
                                <span size={20} className="equal-data mr-1">
                                    <BsArrowRightShort /> no change
                                </span>
                            ) : (
                                <BsArrowUpShort size={20} className="text-success" />
                            )}
                            {isNaN(totalErrors - compValue)
                                ? ''
                                : Math.round(totalErrors - compValue)}
                            % <span> vs last {selectedDays} days</span>
                        </small>
                    </div>
                    {/* <div className="graph-position">
                        {compValue > totalErrors ? (
                            <img alt="redcamp" src={redcomp} />
                        ) : compValue === totalErrors ? (
                            <img alt="linecomp" src={lincomp} className="mb-3"></img>
                        ) : (
                            <img alt="green" src={greencomp} />
                        )}
                    </div> */}
                </div>
            </CardBody>
        </Card>
    )
}

export const MissionStatus = ({ filteredData }) => {
    let compLabels = []
    let compValues = []
    let emptyData

    if (Object.values(filteredData).length !== 0) {
        Object.values(filteredData)?.forEach((mapitem) => {
            if (mapitem.title === 'Mission status') {
                mapitem.data.forEach((childItem) => {
                    compLabels.push(childItem.label)
                    compValues.push(childItem.value)
                })
            }
        })
    }

    emptyData = compValues.filter((x) => x !== 0)

    const data = {
        series: compValues,
        options: {
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            chart: {
                background: '#fff',
                redrawOnWindowResize: true,
            },

            tooltip: {
                enabled: true,
                fillSeriesColor: false,
                marker: {
                    show: false,
                },

                style: {
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    backgroundColor: 'white',
                },
                y: {
                    formatter: function (val) {
                        return val
                    },
                    title: {
                        formatter: function (seriesName) {
                            return seriesName + ':'
                        },
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },

            plotOptions: {
                pie: {
                    offsetY: 15,
                    offsetX: -60,
                    donut: {
                        size: '47%',
                        background: 'transparent',
                    },
                },
            },
            labels: compLabels,
            colors: ['#670D95', '#BA4FFA', '#D99EF7', '#EACAFA', '#F4E2FC'],
            legend: {
                floating: true,
                show: true,
                offsetX: -144,
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                markers: {
                    width: 8,
                    height: 8,
                    offsetX: -5,
                },
                labels: {
                    colors: '#656D7E',
                },
            },
            fill: {
                opacity: 0.9,
                border: 'none',
                colors: ['#670D95', '#BA4FFA', '#D99EF7', '#EACAFA', '#F4E2FC', '#F4E2FC'],
            },
        },
    }

    const noData = {
        series: [0],
        options: {
            chart: {
                height: 320,
                type: 'radialBar',
            },
            fill: {
                colors: ['#7F56D9'],
            },
            labels: ['No missions'],

            plotOptions: {
                radialBar: {
                    offsetY: 10,
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: 10,
                            show: true,
                            color: '#3D414C',
                            fontSize: '14px !important',
                            fontFamily: 'Poppins',
                            fontWeight: 'normal',
                        },
                        value: {
                            color: '#24272D',
                            fontSize: '32px',
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            show: false,
                        },
                    },
                },
            },

            stroke: {
                lineCap: 'round',
            },

            responsive: [
                {
                    breakpoint: 1700,
                    options: {
                        chart: {
                            height: 270,
                        },
                    },
                },

                {
                    breakpoint: 1100,
                    options: {
                        chart: {
                            height: 320,
                        },
                    },
                },
            ],
        },
    }

    return (
        <Card className="mission-status-card mr-3">
            <CardBody className="p-0">
                <h5 className="title-value ">Mission status</h5>

                {emptyData.length !== 0 ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '230px' }}
                    >
                        <Chart
                            options={data.options}
                            series={data.series}
                            height={180}
                            type="donut"
                        />
                    </div>
                ) : (
                    <div
                        className="d-flex justify-content-center align-items-center "
                        style={{ height: '230px' }}
                    >
                        <Chart
                            options={noData.options}
                            series={noData.series}
                            type="radialBar"
                            height={320}
                        />
                    </div>
                )}
            </CardBody>
        </Card>
    )
}

export const MissionDeliveryStatus = ({ filteredData }) => {
    let percentage
    let compDeliveredTasks
    let compFailedTasks

    if (Object.values(filteredData).length !== 0) {
        Object.values(filteredData)?.forEach((mapitem) => {
            if (mapitem.title === 'Mission delivery status') {
                mapitem.data.forEach((childItem) => {
                    if (childItem.label === 'Delivered Tasks') {
                        compDeliveredTasks = childItem.value
                    } else {
                        compFailedTasks = childItem.value
                    }
                })
            }
        })
    }

    percentage = (compDeliveredTasks / (compDeliveredTasks + compFailedTasks)) * 100

    const data = {
        series: isNaN(percentage) ? [0] : [Math.round(percentage)],
        options: {
            chart: {
                height: 320,
                type: 'radialBar',
            },
            fill: {
                colors: ['#7F56D9'],
            },
            labels: ['Delivered'],

            plotOptions: {
                radialBar: {
                    offsetY: 10,
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#3D414C',
                            fontSize: '14px !important',
                            fontFamily: 'Poppins',
                            fontWeight: 'normal',
                        },
                        value: {
                            color: '#24272D',
                            fontSize: '32px',
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            show: true,
                        },
                    },
                },
            },
            stroke: {
                lineCap: 'round',
            },
            responsive: [
                {
                    breakpoint: 1700,
                    options: {
                        chart: {
                            height: 270,
                        },
                    },
                },

                {
                    breakpoint: 1100,
                    options: {
                        chart: {
                            height: 320,
                        },
                    },
                },
            ],
        },
    }

    return (
        <Card className="delivery-card mr-3">
            <CardBody className="p-0">
                <h5 className="title-value"> Mission delivery status </h5>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '230px' }}
                >
                    <Chart
                        options={data.options}
                        series={data.series}
                        type="radialBar"
                        height={320}
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export const RobotStatus = ({ filteredData }) => {
    let usedRobot
    let unUsedRobot
    let percentage

    if (Object.values(filteredData).length !== 0) {
        Object.values(filteredData)?.forEach((mapitem) => {
            if (mapitem.title === 'Robot status') {
                mapitem.data.forEach((childItem) => {
                    if (childItem.label === 'Used Vehicles') {
                        usedRobot = childItem.value
                    } else {
                        unUsedRobot = childItem.value
                    }
                })
            }
        })
    }

    percentage = (usedRobot / (usedRobot + unUsedRobot)) * 100

    const data = {
        series: isNaN(percentage) ? [0] : [Math.round(percentage)],

        options: {
            chart: {
                height: 320,
                type: 'radialBar',
            },
            fill: {
                colors: ['#7F56D9'],
            },
            labels: ['Robots used'],

            plotOptions: {
                radialBar: {
                    offsetY: 10,
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#3D414C',
                            fontSize: '14px !important',
                            fontFamily: 'Poppins',
                            fontWeight: 'normal',
                        },
                        value: {
                            color: '#24272D',
                            fontSize: '32px',
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            show: true,
                        },
                    },
                },
            },
            stroke: {
                lineCap: 'round',
            },
            responsive: [
                {
                    breakpoint: 1700,
                    options: {
                        chart: {
                            height: 270,
                        },
                    },
                },

                {
                    breakpoint: 1100,
                    options: {
                        chart: {
                            height: 320,
                        },
                    },
                },
            ],
        },
    }
    return (
        <Card className="delivery-card mr-3">
            <CardBody className="p-0">
                <h5 className="title-value"> Robot status </h5>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '230px' }}
                >
                    <Chart
                        options={data.options}
                        series={data.series}
                        type="radialBar"
                        height={320}
                    />
                </div>
            </CardBody>
        </Card>
    )
}
export const MissionSuccess = ({ filteredData }) => {
    let missionLabel = []
    let missionCompleted = []
    let missionTotal = []
    let months = []

    filteredData?.forEach((mapitem) => {
        if (mapitem.title === 'Mission success') {
            mapitem?.data?.forEach((childItem) => {
                missionLabel.push(childItem?.label)
                missionCompleted.push(childItem?.Completed)
                missionTotal.push(childItem?.Total)
            })
        }
    })
    function monthName(mon) {
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][
            mon - 1
        ]
    }

    for (let i = 1; i <= missionLabel.length; i++) {
        months.push(monthName(i))
    }

    const data = {
        series: [
            {
                name: 'Completed',
                data: missionCompleted,
            },
            {
                name: 'Total',
                data: missionTotal,
            },
        ],
        options: {
            chart: {
                type: 'bar',
                stacked: true,
                width: '100%',
                toolbar: {
                    show: false,
                },
            },
            colors: ['#BA4FFA', '#D99EF7'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 4,
                    columnWidth: '33%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
                //   width: 2,
                colors: ['transparent'],
            },

            xaxis: {
                categories: months,
            },
            yaxis: {
                title: {
                    text: 'Missions',
                },
            },
            legend: {
                position: 'top',
                offsetX: 200,
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    },
                },
            },
            
        },
    }

    return (
        <Card className="delivery-card  mr-3">
            <CardBody className="p-0 ">
                <h5 className="title-value"> Mission success </h5>
                <Chart
                    
                    options={data.options}
                    series={data.series}
                    type="bar"
                    height={250}
                    width='100%'

                />
            </CardBody>
        </Card>
    )
}

export const MissionOverview = () => {
    const dataList = [{}]

    const columnsList = [
        {
            dataField: 'robotName',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'active',
            text: 'Status',
            sort: true,
        },
        {
            dataField: 'assignedVehicle',
            text: 'Assigned Vehicle',
            sort: true,
        },
        {
            dataField: 'battery',
            text: 'Battery %',
            sort: false,
        },
    ]
    return (
        <Fragment>
            <Card className="text-black mb-4">
                <CardBody className="p-0">
                    <Row className="mb-4 mt-4 mr-1">
                        <Col md={12} className="pb-5">
                            <Card className="mb-3 p-2 card-box border-0">
                                <CardHeader>
                                    <h4>Mission overview</h4>
                                </CardHeader>
                                <div className="rounded bg-white shadow-overflow">
                                    <BootstrapTable
                                        bootstrap4={true}
                                        keyField="uuid"
                                        data={dataList}
                                        columns={columnsList}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}
