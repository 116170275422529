import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { TITLE } from 'config'
import BootstrapTable from 'react-bootstrap-table-next'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ManageOrganizationModals from './ManageOrganizationModals'
import { getOrgSetups, removeSetup, resetSetups } from 'features/dashboard/RosSetup.actionTypes'
import dayjs from 'dayjs'
import { selectors } from 'features/dashboard'
import { getOrgTeams } from 'features/dashboard/Organization.actionTypes'
import Filter from 'layout-components/Filter'
import { HiOutlineX } from 'react-icons/hi'

const FleetSetupsManage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const setupStore = useSelector(selectors.getSetup)
    const teams = useSelector(selectors.getOrganizationTeams)
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState(null)
    const [dataOnClick, setDataOnClick] = useState({})
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPage, setLastClickedPage] = useState(1)
    const [sort, setSort] = useState('asc')
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [initialFilterValues, setInitialFilterValues] = useState({ team: [], type: [] })
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggle = () => setModal(!modal)

    useEffect(() => {
        document.title = `${TITLE} - Fleet setups manage`
    }, [])

    useEffect(() => {
        dispatch(getOrgSetups({ slug: currentOrg.slug, page: currentPageNumber }))
        dispatch(getOrgTeams(currentOrg.slug))
    }, [currentOrg.slug, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentPageNumber > 1)
            dispatch(
                getOrgSetups({
                    slug: currentOrg.slug,
                    page: currentPageNumber,
                    filters: initialFilterValues,
                })
            )
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modal) {
            setDataOnClick({})
            setForm(null)
        }
    }, [modal])

    const handleRemoveSetup = (data) => {
        dispatch(removeSetup(data)).then(({ error }) => {
            if (!error) {
                dispatch(
                    getOrgSetups({
                        slug: currentOrg.slug,
                        page: currentPageNumber,
                        filters: initialFilterValues,
                    })
                )
            }
            toggle()
        })
    }

    const validateDate = (date, defaultMessage) => {
        if (date.isValid()) return date.format('YYYY/MM/DD, HH:mm:ss')
        return defaultMessage || '-'
    }

    const RenderSetupManage = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/map-editor/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )

    const setupData = useMemo(() => {
        return setupStore?.setups?.results?.map((setup) => {
            return {
                uuid: setup.uuid,
                name: setup.verbose_name || '-',
                team: setup.team.title || '-',
                type: setup.device_model ? 'Device' : 'Vehicle',
                teamObj: setup.team,
                pin: setup.pin || '-',
                used: (
                    <div className="text-dark">
                        {validateDate(dayjs(setup.used), 'Never Used')}{' '}
                    </div>
                ),
                created_at: (
                    <div className="text-dark">{validateDate(dayjs(setup.created_at))} </div>
                ),
                action: (
                    <RenderSetupManage
                        item={setup}
                        form={{ edit: 'edit-setup', remove: 'remove-setup' }}
                    />
                ),
            }
        })
    }, [setupStore.setups.results])

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            headerClasses: 'custom-header pl-4',
            classes: 'custom-cell',
            style: { paddingLeft: '24px' },
            sort: true,
            onSort: (field, order) => {
                setSort(order)
            },
        },
        {
            dataField: 'team',
            text: 'team',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
            formatter: (cell, row) => {
                return (
                    <div
                        className="team-div-cell"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push({
                                pathname: `/dashboard/organization/teams/${row.teamObj.slug}/`,
                                state: { slug: row.teamObj.slug, team: row.teamObj },
                            })
                        }
                    >
                        {cell}
                    </div>
                )
            },
        },
        {
            dataField: 'type',
            text: 'Type',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
        },
        {
            dataField: 'pin',
            text: 'PIN',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
        },
        {
            dataField: 'used',
            text: 'Used at',
            headerClasses: 'custom-header',
        },
        {
            dataField: 'created_at',
            text: 'Created at',
            headerClasses: 'custom-header',
        },
        {
            dataField: 'action',
            text: '',
            headerClasses: 'custom-header',
            headerStyle: { width: '128px' },
            style: { paddingRight: '24px' },
        },
    ]

    return (
        <div className="big-org-container">
            <div className="heading-container-org" style={{ marginTop: '32px' }}>
                <div>
                    <div className="mb-2 d-flex align-items-center">
                        <span className="header-text-org">Fleet setups</span>
                        {setupStore.status === 'loaded' && (
                            <span
                                className="text-meili rounded px-2"
                                style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                            >
                                {setupStore?.setups?.count} setups
                            </span>
                        )}
                    </div>
                    <span className="small-text-org">
                        An overview of all fleet setups in your organisation.
                    </span>
                </div>
                <div className="d-flex">
                    <Filter
                        table="setups"
                        slug={currentOrg.slug}
                        initValues={initialFilterValues}
                        setInitValues={setInitialFilterValues}
                        setLastClickedPage={setLastClickedPage}
                        dropdownOpen={dropdownOpen}
                        toggle={dropdownToggle}
                        teams={teams}
                    />
                    <Button
                        className="new-buttons-desing-org-purple org-page-btns text-white"
                        onClick={() => {
                            setForm('add-setup')
                            toggle()
                        }}
                        style={{ height: '40px' }}
                    >
                        <img src="/svgs/trigger-actions/add.svg" alt="plus" />
                        Add fleet setup
                    </Button>
                </div>
            </div>
            {setupStore.status === 'loading' ? (
                <LoadingSpinner />
            ) : (
                <>
                    {[...initialFilterValues.team, ...initialFilterValues.type].length > 0 && (
                        <div className="d-flex align-items-start">
                            {[...initialFilterValues.team, ...initialFilterValues.type].map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        className="team-div-cell d-flex align-items-center text-meili"
                                        style={{
                                            backgroundColor: '#F9EFFE',
                                            marginRight: '8px',
                                        }}
                                    >
                                        <HiOutlineX
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setInitialFilterValues((prevValues) => {
                                                    const newTeam = prevValues.team.filter(
                                                        (t) => t !== item
                                                    )
                                                    const newType = prevValues.type.filter(
                                                        (t) => t !== item
                                                    )

                                                    const updatedValues = {
                                                        team: newTeam,
                                                        type: newType,
                                                    }

                                                    dispatch(resetSetups())
                                                    setLastClickedPage(1)
                                                    dispatch(
                                                        getOrgSetups({
                                                            slug: currentOrg.slug,
                                                            filters: updatedValues,
                                                        })
                                                    )

                                                    return updatedValues
                                                })
                                            }}
                                        />
                                        {item}
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    <div className="rounded-table-container">
                        {setupData?.length > 0 ? (
                            <BootstrapTable
                                bootstrap4={true}
                                classes="my-custom-table"
                                keyField="uuid"
                                data={setupData}
                                columns={columns}
                                pagination={paginationFactory({
                                    page: lastClickedPage,
                                    sizePerPage: 7,
                                    sizePerPageList: [],
                                    onPageChange: (page, sizePerPage) => setLastClickedPage(page),
                                })}
                                defaultSorted={[
                                    {
                                        dataField: 'name',
                                        order: sort,
                                    },
                                ]}
                            />
                        ) : (
                            <h5 className="p-4">There are currently no fleet setups.</h5>
                        )}

                        {setupStore?.setups?.next &&
                            lastClickedPage === Math.ceil(setupData.length / 7) && (
                                <Button
                                    style={{
                                        position: 'absolute',
                                        bottom: '0.8rem',
                                        left: '24px',
                                        borderRadius: '8px',
                                        background: '#f9fafb',
                                        color: '#4e5462',
                                    }}
                                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                                >
                                    Load more (
                                    {setupStore?.setups?.count - setupStore?.setups?.results.length}
                                    )
                                </Button>
                            )}
                    </div>
                </>
            )}
            <ManageOrganizationModals
                slug={currentOrg?.slug}
                form={form}
                modal={modal}
                toggle={toggle}
                filters={initialFilterValues}
                setForm={setForm}
                dataOnClick={dataOnClick}
                submitDelete={handleRemoveSetup}
                currentPageNumber={currentPageNumber}
            />
        </div>
    )
}

export default FleetSetupsManage
