import React, { useEffect, useRef, useState } from 'react'
import { Line, Text, Transformer, Tag, Label } from 'react-konva'
import icon from './icon.png'
// import useImage from 'use-image'

const MapObstacle = ({
    stage,
    getCurrentMousePointer,
    zone,
    width,
    map,
    height,
    biggerMapSize,
    smallerMapSize,
    isSelected,
    onSelect,
    onObstacleChange,
    cursor,
    isdraggable,
    mouseMove,
    handleClick,
    editPoint,
    toggle,
    size,
    showTagZonesNames,
}) => {
    const shapeRef = useRef()
    const transformRef = useRef()
    const textRef = useRef()
    const patternRef = useRef(document.createElement('canvas'))
    const [text, setText] = useState('')
    const [hover, setHover] = useState()
    const [textWidth, setTextWidth] = useState(0)
    const [textHeight, setTextHeight] = useState(0)
    const [initZone, setInitZone] = useState({})

    useEffect(() => {
        setInitZone({
            ...zone,
            width: zone.points[2][0] - zone.points[0][0],
            height: zone.points[2][1] - zone.points[0][1],
            x: zone.points[0][0],
            y: zone.points[0][1],
            rotation: 0,
        })
    }, [zone.safety_parameter, zone.allowed_vehicle_categories]) // eslint-disable-line

    useEffect(() => {
        if (isSelected && editPoint) {
            transformRef.current.nodes([shapeRef.current])
            transformRef.current.getLayer().batchDraw()
            stage?.findOne('.rotater').on('mouseenter', () => {
                stage.content.style.cursor = 'url(' + icon + ') 10 20, default'
                setHover('Drag to rotate')
            })
            stage?.findOne('.rotater').on('mouseleave', () => {
                setHover('Drag to rotate')
            })
        }
        setHover('')
    }, [isSelected, stage, zone, editPoint])

    useEffect(() => {
        const patternCanvas = patternRef.current
        patternCanvas.width = 15
        patternCanvas.height = 15
        const ctx = patternCanvas.getContext('2d')

        ctx.beginPath()
        ctx.strokeStyle = '#E7E9EC'
        ctx.moveTo(0, 15)
        ctx.lineTo(15, 0)
        ctx.stroke()

        const shape = shapeRef.current
        if (shape) {
            shape.fillPatternImage(patternCanvas)
            shape.fillPatternScaleX(1)
            shape.fillPatternScaleY(1)
            shape.getLayer().batchDraw()
        }
    }, [])

    useEffect(() => {
        setTextWidth(getTextWidthPixels())
        setTextHeight(getTextHeightPixels())
    }, [showTagZonesNames])

    const onMouseEnter = (event) => {
        const stage = event.target.getStage()
        if (stage && map === 'editor' && zone.zone_type !== 'forbidden') {
            stage.container().style.cursor = 'pointer'
        }
    }

    const onMouseLeave = (event) => {
        const stage = event.target.getStage()
        if (stage && map === 'editor') {
            stage.container().style.cursor = 'default'
        }
    }

    const handleTransformer = () => {
        const node = shapeRef.current.rotation()
        const minusValue = Math.sign(node) === -1
        if (minusValue) {
            const rotate = Math.round(node) + 363
            setText(rotate)
        } else {
            const rotate = Math.round(node)
            setText(rotate)
        }
    }

    const zoneTypeColor = () => {
        switch (true) {
            case zone.zone_type === 'forbidden':
                return '#FEE4E2'
            case zone.zone_type === 'action':
                return '#F9F5FF'
            case zone.zone_type === 'charging':
                return '#FFF5DF'
            case zone.zone_type === 'resting':
                return '#EFF8FF'
            case zone.newZone === 'safe_zone' || zone.figure_type === 'safezone':
                return 'lightgreen'
            case zone.zone_type === 'tag':
                return null
            default:
                return 'lightgray'
        }
    }

    const zoneBorderColor = () => {
        switch (zone.zone_type) {
            case 'forbidden':
                return '#F04438'
            case 'action':
                return '#B692F6'
            case 'charging':
                return '#FFD67C'
            case 'resting':
                return '#53B1FD'
            case 'tag':
                return '#E7E9EC'
            default:
                return 'black'
        }
    }

    const getTextWidthPixels = () => textRef.current?.getTextWidth()
    const getTextHeightPixels = () => textRef.current?.getTextHeight()

    if (!zone) return null
    if (zone.zone_type === 'obstacle' || zone.figure_type === 'obstacle') return

    return (
        <>
            <Line
                draggable={isdraggable}
                closed={true}
                ref={shapeRef}
                points={initZone?.points?.flat()}
                stroke={zoneBorderColor()}
                strokeWidth={
                    width * height < smallerMapSize
                        ? 0.4
                        : width * height > biggerMapSize
                        ? 7
                        : width < 200
                        ? 0.4
                        : 1.2
                }
                fill={zoneTypeColor()}
                opacity={
                    isSelected &&
                    zone.zone_type !== 'forbidden' &&
                    zone.zone_type !== 'tag' &&
                    zone.zone_type !== 'action' &&
                    zone.figure_type !== 'obstacle'
                        ? null
                        : 0.6
                }
                onMouseDown={onSelect}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onDragMove={mouseMove}
                onDragStart={(event) => {
                    const { x, y } = getCurrentMousePointer(stage)
                    let sx = x - zone.points[0][0]
                    let sy = y - zone.points[0][1]
                    setInitZone({
                        ...initZone,
                        x,
                        y,
                        sx,
                        sy,
                    })
                }}
                onDragEnd={() => {
                    const { x, y } = getCurrentMousePointer(stage)
                    const node = shapeRef.current
                    const scaleX = node.scaleX()
                    const scaleY = node.scaleY()

                    let corners = [...initZone.points]

                    for (let i = 0; i < corners.length; i++) {
                        corners[i] = {
                            x: initZone.points[i][0],
                            y: initZone.points[i][1],
                        }
                    }

                    for (let i = 0; i < corners.length; i++) {
                        // Here be the magic
                        corners[i] = node.getTransform().point(corners[i])
                    }

                    let transformedArr = corners.map((cor, i) => [
                        Math.round(cor?.x),
                        Math.round(cor?.y),
                    ])

                    onObstacleChange({
                        ...initZone,
                        x: Math.round(x),
                        y: Math.round(y),
                        // set minimal value
                        width: Math.round(Math.max(5, node.width() * scaleX)),
                        height: Math.round(Math.max(node.height() * scaleY)),
                        points: transformedArr,
                    })
                }}
                onClick={(e) => {
                    if (
                        !editPoint &&
                        map === 'editor' &&
                        zone.zone_type !== 'forbidden' &&
                        zone.figure_type !== 'obstacle' &&
                        zone.newZone !== 'obstacle'
                    ) {
                        handleClick(zone, toggle)
                    }
                }}
                onTransform={handleTransformer}
                onTransformEnd={() => {
                    const node = shapeRef.current
                    const scaleX = node.scaleX()
                    const scaleY = node.scaleY()
                    let corners = [...initZone.points]

                    for (let i = 0; i < corners.length; i++) {
                        corners[i] = {
                            x: initZone.points[i][0],
                            y: initZone.points[i][1],
                        }
                    }

                    for (let i = 0; i < corners.length; i++) {
                        // Here be the magic
                        corners[i] = node.getTransform().point(corners[i])
                    }

                    let transformedArr = corners.map((cor, i) => [
                        Math.round(cor?.x),
                        Math.round(cor?.y),
                    ])

                    onObstacleChange({
                        ...zone,
                        x: Math.round(node.x()),
                        y: Math.round(node.y()),
                        // set minimal value
                        width: Math.round(Math.max(5, node.width() * scaleX)),
                        height: Math.round(Math.max(node.height() * scaleY)),
                        points: transformedArr,
                    })
                }}
            />
            {isSelected && (
                <Transformer
                    ref={transformRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox
                        }
                        return newBox
                    }}
                />
            )}
            {isSelected && hover && (
                <>
                    <Label x={cursor.x + 5} y={cursor.y - size}>
                        <Tag fill="#f0f1f3" cornerRadius={50}></Tag>
                        <Text
                            width={size * 6}
                            height={size * 6}
                            align="center"
                            lineHeight={3}
                            text={text + '°'}
                            fontSize={size * 2}
                            fontStyle="bold"
                        ></Text>
                    </Label>
                    <Text
                        x={cursor.x + 5}
                        y={cursor.y - size * 2}
                        stroke="#670d95"
                        strokeWidth={0.1}
                        text={hover}
                        fontStyle="bold"
                        fontSize={size}
                    />
                </>
            )}
            {zone.zone_type === 'tag' && showTagZonesNames && (
                <Text
                    x={
                        zone.points[0][0] +
                        (zone.points[2][0] - zone.points[0][0]) / 2 -
                        textWidth / 2
                    }
                    y={
                        zone.points[0][1] +
                        (zone.points[2][1] - zone.points[0][1]) / 2 -
                        textHeight / 2
                    }
                    stroke="#670d95"
                    ref={textRef}
                    strokeWidth={0.1}
                    text={zone?.title}
                    fontStyle="bold"
                    fontSize={size}
                />
            )}
        </>
    )
}

export default MapObstacle
