import React, { useEffect } from 'react'
import { Button, Modal, ModalHeader } from 'reactstrap'
import saveSvg from '../../../assets/images/illustrations/save-icon.svg'
import warningSvg from '../../../assets/images/illustrations/warning-triangle.svg'
import { stopRecording } from 'features/dashboard/Map.actionTypes'
import { useDispatch } from 'react-redux'

const LeavingPrompt = ({ modal, toggle, ...props }) => {
    const {
        changes,
        setChanges,
        recording,
        setRecording,
        setTriggerExit,
        setRecordingData,
        checkForChanges,
        setCheckForChanges,
        saveMap,
        revertMap,
        handleSaveMap,
        recordingData,
        preventStuckOnNavigation,
        setPreventStuckOnNavigation,
        isOriginalImage
    } = props
    const dispatch = useDispatch()

    const text = {
        docking: {
            decline: 'Resume',
            accept: 'Cancel',
            title: 'Cancel recording',
            information: 'Do you want to resume or cancel your docking routine recording?',
        },
        unsaved: {
            decline: 'Discard',
            accept: 'Save',
            title: 'Unsaved changes',
            information: 'Do you want to discard or save changes?',
        },
    }

    useEffect(() => {
        if(isOriginalImage) {
            revertMap()
        }
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggle}
                onClosed={() => {
                    if (changes) {
                        setPreventStuckOnNavigation(!preventStuckOnNavigation)
                    }
                }}
                className="model-position prompt-modal"
            >
                <ModalHeader toggle={toggle} className="border-0 m-4 p-0">
                    <img
                        src={
                            recording
                                ? warningSvg
                                : changes || checkForChanges
                                ? saveSvg
                                : warningSvg
                        }
                        alt="save"
                    ></img>
                    <div id="text" className="mt-3">
                        <div className="prompt-title">
                            {recording
                                ? text.docking.title
                                : changes || checkForChanges
                                ? text.unsaved.title
                                : ''}
                        </div>
                        <div className="prompt-information mt-1">
                            {recording
                                ? text.docking.information
                                : changes || checkForChanges
                                ? text.unsaved.information
                                : ''}
                        </div>
                    </div>
                </ModalHeader>

                <div className="d-flex  justify-content-between prompt-modal-footer">
                    <Button
                        className="prompt-modal-footer-decline-button"
                        onClick={() => {
                            if (checkForChanges) {
                                setCheckForChanges()
                            }
                            if (recording && changes) {
                                toggle()
                                return
                            }
                            if (changes) {
                                setTriggerExit((obj) => ({
                                    ...obj,
                                    onOk: true,
                                }))
                                setChanges(false)
                            }
                            toggle()
                        }}
                    >
                        {recording
                            ? text.docking.decline
                            : changes || checkForChanges
                            ? text.unsaved.decline
                            : ''}
                    </Button>
                    <Button
                        className={
                            recording
                                ? 'prompt-modal-footer-accept-button-danger'
                                : 'prompt-modal-footer-accept-button'
                        }
                        onClick={() => {
                            if (changes || recording) {
                                if (recording) {
                                    dispatch(stopRecording(recordingData))
                                    setRecordingData(null)
                                    setRecording(false)
                                    if (changes) {
                                        setChanges(true)
                                        return
                                    }
                                    setTriggerExit((obj) => ({
                                        ...obj,
                                        onOk: true,
                                    }))
                                }
                                if (changes) {
                                    handleSaveMap()
                                    setTriggerExit((obj) => ({
                                        ...obj,
                                        onOk: true,
                                    }))
                                }
                            }
                            if (checkForChanges) {
                                saveMap()
                                setCheckForChanges()
                            }
                            toggle()
                        }}
                    >
                        {recording
                            ? text.docking.accept
                            : changes || checkForChanges
                            ? text.unsaved.accept
                            : ''}
                    </Button>
                </div>
            </Modal>
        </>
    )
}
export default LeavingPrompt
