import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { nukeFunc, nukeReset } from 'features/dashboard/Team.actionTypes'
import { useDispatch } from 'react-redux'

const NukeModal = ({ nukeModal, toggleNuke, slug }) => {
    const dispatch = useDispatch()
    return (
        <Modal isOpen={nukeModal} toggle={toggleNuke} centered className="new-modals">
            <ModalBody
                style={{
                    padding: '24px 24px 0px 24px',
                    position: 'relative',
                    overflowY: 'auto',
                }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src={`/svgs/page-title-icons/${
                            localStorage.getItem('nuke') === 'stopped' ? 'play' : 'nuke'
                        }.svg`}
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggleNuke}
                    />
                </div>
                <div className="d-flex flex-column pb-4">
                    <span className="org-modal-header">
                        {localStorage.getItem('nuke') === 'stopped'
                            ? 'Resume fleet'
                            : 'Emergency stop'}
                    </span>
                    <span>
                        {localStorage.getItem('nuke') === 'stopped'
                            ? 'Is the emergency rectified?'
                            : 'Do you really want to trigger emergency stop? You can always resume your fleet again by pressing the play button.'}
                    </span>
                </div>
                <div className="d-flex pb-3 pull-right">
                    <Button
                        className="cancel-btn-modal modals-new-btns"
                        onClick={toggleNuke}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="save-btn-modal modals-new-btns"
                        style={{ marginLeft: '12px' }}
                        onClick={() => {
                            if (localStorage.getItem('nuke') === 'stopped') {
                                dispatch(nukeReset(slug)).then(({ error }) => {
                                    if (!error) {
                                        localStorage.removeItem('nuke')
                                    }
                                })
                            } else {
                                dispatch(nukeFunc(slug)).then(({ error }) => {
                                    if (!error) {
                                        localStorage.setItem('nuke', 'stopped')
                                    }
                                })
                            }

                            toggleNuke()
                        }}
                    >
                        {localStorage.getItem('nuke') === 'stopped' ? 'Yes' : 'Stop'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default NukeModal
