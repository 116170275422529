import React, { useState, forwardRef, useEffect } from 'react'
import ScheduleTaskDetailV2 from './Tasks/ScheduleTaskDetailV2'

const Wrapper = forwardRef((props, ref) => (
    <div className="row" ref={ref}>
        {props.children}
    </div>
))
const ScheduleTasksGridView = ({ tasks, filterLabel, slug, upcomingTask, setTaskHovered }) => {
    const [upcominglist, setUpcominglist] = useState(upcomingTask)

    useEffect(() => {
        if (JSON.stringify(tasks) !== JSON.stringify(upcominglist))
            setUpcominglist(
                tasks?.map((task) => ({
                    id: task.uuid,
                    ...task,
                }))
            )
    }, [tasks]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="pt-1 pl-0 bg-white shadow-none cards-wrap">
            <div
                tag={Wrapper}
                handle=".handle"
                list={upcominglist}
                className="d-flex w-100"
                style={Object.assign(
                    upcominglist.length && { overflowY: 'hidden' },
                    {
                        overflowX: 'auto',
                    },
                    { height: '180px' }
                )}
            >
                {upcominglist?.length === 0 && (
                    <h5 className="pl-3">There are currently no upcoming missions.</h5>
                )}
                {upcominglist?.map((task) => (
                    <div key={task.uuid} className="mb-3 mx-3">
                        {
                            <ScheduleTaskDetailV2
                                task={task}
                                slug={slug}
                                setTaskHovered={setTaskHovered}
                            />
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ScheduleTasksGridView
