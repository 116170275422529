import React, { useState } from 'react'
import cx from 'classnames'
import {
    Col,
    NavLink,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    Button,
    FormGroup,
    Label,
    Alert,
    Spinner,
} from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'

import { CommonErrors, customInputForm } from 'ui/common'
import { outdoorPointSchema } from 'schemas/dashboard'
import {
    updateOutdoorPoint,
    deleteOutdoorPoint,
} from 'features/dashboard/Map.actionTypes'

const OutdoorPointForm = ({ point, handleNewPoint, toggle }) => {
    const [activeTab, setActiveTab] = useState('Details')
    const dispatch = useDispatch()

    const handleSubmit = (data, action) => {
        if (!data.uuid) {
            handleNewPoint(data)
            toggle()
            return
        }
        dispatch(updateOutdoorPoint(data)).then(({ error }) => {
            action.setSubmitting(false)
            if (error) {
                action.setErrors(error.response.data)
                return
            }
            toggle()
        })
    }

    const handleDelete = (data, action) => {
        dispatch(deleteOutdoorPoint(data)).then(({ error }) => {
            action.setSubmitting(false)
            if (error) {
                action.setErrors(error.response.data)
                return
            }
            toggle()
        })
    }

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={cx({ active: activeTab === 'Details' })}
                        onClick={() => setActiveTab('Details')}
                    >
                        Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={cx({
                            active: activeTab === 'Setting',
                        })}
                        disabled={!point.uuid}
                        onClick={() => setActiveTab('Setting')}
                    >
                        Setting
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="Details">
                    <Formik
                        initialValues={{ location_data: ['', ''], ...point }}
                        validationSchema={outdoorPointSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, isSubmitting }) => (
                            <Form>
                                <Col className="my-3" md={12}>
                                    <FormGroup>
                                        <Label for="name">Name</Label>
                                        <Field
                                            component={customInputForm}
                                            bsSize="md"
                                            type="text"
                                            name="name"
                                            value={values.name || ''}
                                            placeholder="Name"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="point_type">
                                            Point Type
                                        </Label>
                                        <Field
                                            component={customInputForm}
                                            bsSize="md"
                                            type="select"
                                            name="point_type"
                                            value={values.point_type || ''}
                                        >
                                            <option value=""></option>
                                            <option value="drop_off">
                                                Drop off
                                            </option>
                                        </Field>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="lat">Lat</Label>
                                        <Field
                                            component={customInputForm}
                                            bsSize="md"
                                            type="number"
                                            step="any"
                                            name="location_data[1]"
                                            values={
                                                values.location_data[1] || ''
                                            }
                                            placeholder="Lat"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="long">Long</Label>
                                        <Field
                                            component={customInputForm}
                                            bsSize="md"
                                            type="number"
                                            step="any"
                                            name="location_data[0]"
                                            values={
                                                values.location_data[0] || ''
                                            }
                                            placeholder="Long"
                                        />
                                    </FormGroup>
                                    <CommonErrors />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        {values.uuid ? 'Save' : 'Add'}
                                        {isSubmitting && (
                                            <Spinner
                                                size="sm"
                                                color="white ml-2"
                                            />
                                        )}
                                    </Button>
                                </Col>
                            </Form>
                        )}
                    </Formik>
                </TabPane>
                {point.uuid && (
                    <TabPane tabId="Setting">
                        <Col className="my-3" md={12}>
                            <Formik
                                initialValues={{ ...point }}
                                onSubmit={handleDelete}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <Alert color="danger">
                                            Are you sure to remove the point?
                                        </Alert>
                                        <Field
                                            component={customInputForm}
                                            type="hidden"
                                            name="uuid"
                                        />
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            color="danger"
                                        >
                                            Remove
                                            {isSubmitting && (
                                                <Spinner
                                                    size="sm"
                                                    color="white ml-2"
                                                />
                                            )}
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </TabPane>
                )}
            </TabContent>
        </>
    )
}

export default OutdoorPointForm
