export const actionTypes = {
    GET_VARIABLE: 'GET_VARIABLE',
    GET_VARIABLE_FAIL: 'GET_VARIABLE_FAIL',
    GET_VARIABLE_SUCCESS: 'GET_VARIABLE_SUCCESS',
    CREATE_VARIABLE: 'CREATE_VARIABLE',
    CREATE_VARIABLE_FAIL: 'CREATE_VARIABLE_FAIL',
    CREATE_VARIABLE_SUCCESS: 'CREATE_VARIABLE_SUCCESS',
    REMOVE_VARIABLE: 'REMOVE_VARIABLE',
    REMOVE_VARIABLE_FAIL: 'REMOVE_VARIABLE_FAIL',
    REMOVE_VARIABLE_SUCCESS: 'REMOVE_VARIABLE_SUCCESS',
    GET_FORM: 'GET_FORM',
    GET_FORM_SUCCESS: 'GET_FORM_SUCCESS',
    GET_FORM_FAIL: 'GET_FORM_FAIL',
    GET_VARIABLE_VALUE: 'GET_VARIABLE_VALUE',
    GET_VARIABLE_VALUE_SUCCESS: 'GET_VARIABLE_VALUE_SUCCESS',
    GET_VARIABLE_VALUE_FAIL: 'GET_VARIABLE_VALUE_FAIL',
    ADD_VARIABLE_VALUE: 'ADD_VARIABLE_VALUE',
    ADD_VARIABLE_VALUE_SUCCESS: 'ADD_VARIABLE_VALUE_SUCCESS',
    ADD_VARIABLE_VALUE_FAIL: 'ADD_VARIABLE_VALUE_FAIL',
    GET_GENERICFORM: 'GET_GENERICFORM',
    GET_GENERICFORM_SUCCESS: 'GET_GENERICFORM_SUCCESS',
    GET_GENERICFORM_FAIL: 'GET_GENERICFORM_FAIL',
}

export function getVariables({ uuid }) {
    return {
        type: actionTypes.GET_VARIABLE,
        payload: {
            request: {
                url: `api/organizations/${uuid}/forms/variables/`,
                method: 'GET',
            },
        },
    }
}
export function getVariableValue({ object_type, uuid }) {
    return {
        type: actionTypes.GET_VARIABLE_VALUE,
        payload: {
            request: {
                url: `api/forms/${object_type}/${uuid}/`,
                method: 'GET',
            },
        },
    }
}
export function getSpecificForm({ object_type, uuid }) {
    return {
        type: actionTypes.GET_FORM,
        payload: {
            request: {
                url: `api/forms/${object_type}/${uuid}/form`,
                method: 'GET',
            },
        },
    }
}
export function addVariableValue({ uuid, object_type, data }) {
    return {
        type: actionTypes.ADD_VARIABLE_VALUE,
        payload: {
            request: {
                url: `api/forms/${object_type}/${uuid}/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createVariable({ uuid, data }) {
    return {
        type: actionTypes.CREATE_VARIABLE,
        payload: {
            request: {
                url: `api/organizations/${uuid}/forms/variables/`,
                method: 'POST',
                data,
            },
        },
    }
}
export function removeVariable({ uuid }) {
    return {
        type: actionTypes.REMOVE_VARIABLE,
        payload: {
            request: {
                url: `api/forms/variables/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}
export function updateVariable({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_VARIABLE,
        payload: {
            request: {
                url: `api/forms/variables/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}
export function getGenericForm({ uuid }) {
    return {
        type: actionTypes.GET_GENERICFORM,
        payload: {
            request: {
                url: `api/organizations/${uuid}/forms/`,
                method: 'GET',
            },
        },
    }
}
