import { actionTypes } from './Version.actionTypes'

const initialState = {
    version: '',
}

export const VersionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_VERSION:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.GET_VERSION_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                version: action.payload,
            }
        case actionTypes.GET_VERSION_FAIL:
            return {
                ...state,
                status: 'error',
            }
        default:
            return state
    }
}
