import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Container } from 'reactstrap'
import { validateAccountToken } from 'features/user/actionTypes'

const ValidateAccount = () => {
    const { token } = useParams()
    const dispatch = useDispatch()
    let history = useHistory()

    const orgtoken = localStorage.getItem('orgtoken')
    const invitationType = localStorage.getItem('invitationtype')

    useEffect(() => {
        document.title = 'Validation'
        dispatch(validateAccountToken({ token }))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getNextUrl = () => {
        let url
        invitationType === 'invitation_token'
            ? (url = `/login/?next=/dashboard/organization/selfboarding/${orgtoken}/`)
            : (url = '/login/')

        return url
    }

    return (
        <div className="d-flex flex-column">
            <Container
                className="d-flex flex-column"
                style={{ width: '390px', marginBottom: '24px' }}
            >
                <div className="pb-32 pt-32 d-flex flex-column justify-content-center align-items-center">
                    <img className="mt-2 pr-2" alt="key" src="/svgs/user-icon/email.svg" />
                    <h2 className="size-32 text-black pt-32 pb-2">Account validated</h2>
                    <span className="size-16 grey-600 pt-2 text-center">
                        {' '}
                        Your email account has been successfully validated.
                    </span>
                    <Button
                        style={{
                            width: '340px',
                            height: '40px',
                            marginTop: '24px',
                            marginBottom: '24px',
                        }}
                        block={true}
                        color="meili"
                        className="login-border"
                        onClick={() => history.push(getNextUrl())}
                    >
                        {' '}
                        Continue log in
                    </Button>
                </div>
            </Container>
        </div>
    )
}

export default ValidateAccount
