import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Nav,
    NavItem,
    Button,
    UncontrolledPopover,
    PopoverBody,
    InputGroup,
    Col,
    FormGroup,
    ListGroup,
    ListGroupItem,
    Spinner,
} from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'

import { customInputForm } from 'ui/common'
import { getLocation } from 'features/dashboard/Map.actionTypes'

const OutsideMapCreateSideBar = ({ onSelect }) => {
    const [result, setResult] = useState(null)
    const dispatch = useDispatch()

    const SearchResult = ({ data }) => {
        if (data.length === 0) return <p className="text-warning mt-2">Can not find the location</p>
        return (
            <div className="scroll-area rounded bg-white shadow-overflow">
                <PerfectScrollbar options={{ wheelPropagation: false }}>
                    <ListGroup className="mt-2" style={{ overflow: 'hidden' }}>
                        {result.map((loc) => (
                            <ListGroupItem
                                key={loc.place_id}
                                tag="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    onSelect(loc)
                                }}
                            >
                                {loc.display_name}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </PerfectScrollbar>
            </div>
        )
    }

    return (
        <Nav vertical>
            <NavItem className="border-bottom">
                <Button
                    style={{ height: '100px' }}
                    className="bg-white rounded-none-bottom btn-transition-none  rounded-top"
                    size="lg"
                    id="zoom"
                    block
                >
                    <FontAwesomeIcon className="text-meili" icon={faSearch} />
                    <br />
                    <strong className="text-black">Search</strong>
                </Button>
                <UncontrolledPopover
                    trigger="legacy"
                    popperClassName="popover-full-width"
                    placement="left"
                    target="zoom"
                >
                    <PopoverBody>
                        <Formik
                            initialValues={{}}
                            onSubmit={(data, action) => {
                                dispatch(getLocation(data.location)).then(({ payload }) => {
                                    action.setSubmitting(false)
                                    if (payload) {
                                        setResult(payload.data)
                                    }
                                })
                            }}
                        >
                            {({ values, isSubmitting }) => {
                                return (
                                    <Form style={{ minWidth: '300px' }}>
                                        <FormGroup row className="mb-0">
                                            <Col md={12}>
                                                <InputGroup>
                                                    <Field
                                                        component={customInputForm}
                                                        bsSize="md"
                                                        required={true}
                                                        type="text"
                                                        value={values.location || ''}
                                                        name="location"
                                                    />
                                                    <Button
                                                        color="meili"
                                                        type="submit"
                                                        className="py-1 btn-transition-none"
                                                    >
                                                        Find
                                                        {isSubmitting && (
                                                            <Spinner color="white ml-1" size="sm" />
                                                        )}
                                                    </Button>
                                                </InputGroup>
                                            </Col>
                                            {result && (
                                                <Col md={12}>
                                                    <SearchResult data={result} />
                                                </Col>
                                            )}
                                        </FormGroup>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </PopoverBody>
                </UncontrolledPopover>
            </NavItem>
        </Nav>
    )
}
export default OutsideMapCreateSideBar
