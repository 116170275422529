import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { Button, FormGroup, Label, Container, Col, Spinner, Row } from 'reactstrap'

import { customInputForm, CommonErrors } from 'ui/common'
import { signup } from 'features/user/actionTypes'
import { SignupSchema } from 'schemas/user'

const TrialSignup = () => {
    const { type, token } = useParams()
    const dispatch = useDispatch()
    let history = useHistory()

    const invitationType = useMemo(() => {
        if (type === 'organizations') return 'org_token'
        if (type === 'teams') return 'team_token'
        if (type === 'selfboarding') return 'invitation_token'
        return
    }, [type])

    const isValid = () => !!invitationType

    const getNextUrl = () => {
        if (invitationType === 'invitation_token')
            return `/login/?next=/dashboard/organization/selfboarding/${token}/`
        return `/activate/${type}/${token}/`
    }

    const handleSubmit = (data, { setErrors, setSubmitting }) => {
        if (!invitationType) return setErrors({ non_field_errors: 'Token is not valid' })
        const payload = {
            ...data,
            [invitationType]: token,
        }
        dispatch(signup({ data: payload })).then(({ error }) => {
            setSubmitting(false)
            if (!error) {
                history.push(getNextUrl())
                return
            }
            const { data } = error.response
            setErrors(data)
        })
    }

    useEffect(() => {
        document.title = 'Invitation'
    }, [])

    return (
        <Container className="d-flex flex-row justify-content-center">
            <Col md={6} className="m-4">
                <Row className="mb-2">
                    <h4 className="mr-3">Signup for trial</h4>
                </Row>
                <Formik initialValues={{}} validationSchema={SignupSchema} onSubmit={handleSubmit}>
                    {({ values, isSubmitting }) => (
                        <Form>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Field
                                    component={customInputForm}
                                    bsSize="lg"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={values.email || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="username">Username</Label>
                                <Field
                                    component={customInputForm}
                                    bsSize="lg"
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    value={values.username || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="first_name">First Name</Label>
                                <Field
                                    component={customInputForm}
                                    bsSize="lg"
                                    type="text"
                                    name="first_name"
                                    placeholder="First Name"
                                    value={values.first_name || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="last_name">Last Name</Label>
                                <Field
                                    component={customInputForm}
                                    bsSize="lg"
                                    type="text"
                                    name="last_name"
                                    placeholder="Last Name"
                                    value={values.last_name || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Field
                                    component={customInputForm}
                                    bsSize="lg"
                                    type="password"
                                    name="password"
                                    placeholder="password"
                                    value={values.password || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password_confirm">Password Confirm</Label>
                                <Field
                                    component={customInputForm}
                                    bsSize="lg"
                                    type="password"
                                    name="password_confirm"
                                    placeholder="Password Confirm"
                                    value={values.password_confirm || ''}
                                />
                            </FormGroup>
                            <Button
                                disabled={isSubmitting || !isValid()}
                                type="submit"
                                color="meili"
                                block={true}
                            >
                                Register
                                {isSubmitting && <Spinner size="sm" color="white ml-3" />}
                            </Button>
                            <CommonErrors extraFields={['org_token', 'team_token']} />
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    )
}

export default TrialSignup
