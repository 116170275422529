import React from 'react'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import {
    Button,
    Input,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    Label,
    FormGroup,
} from 'reactstrap'
import { getOrgVehiclePage, resetVehicles } from 'features/dashboard/Vehicle.actionTypes'
import {
    getOrgDeviceModels,
    getOrgSetups,
    resetDeviceModels,
    resetSetups,
} from 'features/dashboard/RosSetup.actionTypes'
import { deviceModelTypes } from 'components/dashboard-components/organization/OrgTeamHelpers'

const Filter = ({
    slug,
    initValues,
    setInitValues,
    dropdownOpen,
    setLastClickedPage,
    toggle,
    table,
    teams,
    categories,
}) => {
    const dispatch = useDispatch()
    const resetActions = {
        vehicles: resetVehicles,
        devices: resetDeviceModels,
        setups: resetSetups,
    }

    const dispatchActions = {
        vehicles: getOrgVehiclePage,
        devices: getOrgDeviceModels,
        setups: getOrgSetups,
    }

    const handleSubmit = (values) => {
        const resetAction = resetActions[table]
        const dispatchAction = dispatchActions[table]

        if (resetAction && dispatchAction) {
            toggle()
            setLastClickedPage(1)
            dispatch(resetAction())
            return dispatch(dispatchAction({ slug, filters: values }))
        }

        return null
    }

    const cancelValues = () => {
        switch (table) {
            case 'vehicles':
                return setInitValues({
                    team: [],
                    category: [],
                    status: '',
                })
            case 'devices':
                return setInitValues({
                    team: [],
                    device_model_type: [],
                })
            default:
                return setInitValues({
                    team: [],
                    type: [],
                })
        }
    }

    const renderFilters = (values, setFieldValue) => {
        switch (table) {
            case 'vehicles':
                return (
                    <>
                        <div>
                            <span className="text-black">Team</span>
                            {teams.map((team) => (
                                <FormGroup check key={team.uuid}>
                                    <Input
                                        key={team.uuid}
                                        type="checkbox"
                                        id={team.slug}
                                        value={team.slug}
                                        checked={initValues.team.includes(team.slug)}
                                        name="team"
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const isChecked = e.target.checked
                                            if (isChecked) {
                                                if (initValues.team.includes(value)) return
                                                setFieldValue('team', [...values.team, value])
                                                setInitValues({
                                                    ...values,
                                                    team: [...values.team, value],
                                                })
                                            } else {
                                                setFieldValue(
                                                    'team',
                                                    values.team.filter((team) => team !== value)
                                                )
                                                setInitValues({
                                                    ...values,
                                                    team: values.team.filter(
                                                        (team) => team !== value
                                                    ),
                                                })
                                            }
                                        }}
                                    />
                                    <Label check> {team.title}</Label>
                                </FormGroup>
                            ))}
                        </div>
                        <div>
                            <span className="text-black">Category</span>
                            {categories.map((category) => (
                                <FormGroup check key={category.uuid}>
                                    <Input
                                        key={category.uuid}
                                        type="checkbox"
                                        name="category"
                                        checked={initValues.category.includes(category.title)}
                                        id={category.title}
                                        value={category.title}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const isChecked = e.target.checked
                                            if (isChecked) {
                                                if (initValues.category.includes(value)) return
                                                setFieldValue('category', [
                                                    ...values.category,
                                                    value,
                                                ])
                                                setInitValues({
                                                    ...values,
                                                    category: [...values.category, value],
                                                })
                                            } else {
                                                setFieldValue(
                                                    'category',
                                                    values.category.filter(
                                                        (category) => category !== value
                                                    )
                                                )
                                                setInitValues({
                                                    ...values,
                                                    category: values.category.filter(
                                                        (category) => category !== value
                                                    ),
                                                })
                                            }
                                        }}
                                    />
                                    <Label check>{category.title}</Label>
                                </FormGroup>
                            ))}
                        </div>
                        <div>
                            <span className="text-black">Status</span>
                            <FormGroup check>
                                <Input
                                    type="checkbox"
                                    name="status"
                                    checked={
                                        values.status === 'active' || initValues.status === 'active'
                                    }
                                    id="active"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setFieldValue('status', 'active')
                                            if (initValues.status === 'inactive') {
                                                setInitValues({ ...values, status: 'active' })
                                            } else {
                                                setInitValues({ ...values, status: 'active' })
                                            }
                                        }
                                    }}
                                />
                                <Label check className="mt-1">
                                    {' '}
                                    Active{' '}
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    type="checkbox"
                                    id="inactive"
                                    checked={
                                        values.status === 'inactive' ||
                                        initValues.status === 'inactive'
                                    }
                                    name="status"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setFieldValue('status', 'inactive')
                                            if (initValues.status === 'active') {
                                                setInitValues({ ...values, status: 'inactive' })
                                            } else {
                                                setInitValues({ ...values, status: 'inactive' })
                                            }
                                        }
                                    }}
                                />
                                <Label check> Inactive</Label>
                            </FormGroup>
                        </div>
                    </>
                )
            case 'devices':
                return (
                    <>
                        <div>
                            <span className="text-black mb-5">Team</span>
                            {teams.map((team) => (
                                <FormGroup check key={team.uuid}>
                                    <Input
                                        className="mt-2"
                                        key={team.uuid}
                                        type="checkbox"
                                        id={team.slug}
                                        value={team.slug}
                                        checked={initValues.team.includes(team.slug)}
                                        name="team"
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const isChecked = e.target.checked
                                            if (isChecked) {
                                                if (initValues.team.includes(value)) return
                                                setFieldValue('team', [...values.team, value])
                                                setInitValues({
                                                    ...values,
                                                    team: [...values.team, value],
                                                })
                                            } else {
                                                setFieldValue(
                                                    'team',
                                                    values.team.filter((team) => team !== value)
                                                )
                                                setInitValues({
                                                    ...values,
                                                    team: values.team.filter(
                                                        (team) => team !== value
                                                    ),
                                                })
                                            }
                                        }}
                                    />{' '}
                                    <Label check className="mt-1">
                                        {team.title}{' '}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                        <div>
                            <span className="text-black">Device model type</span>
                            {deviceModelTypes.map((type) => (
                                <FormGroup check key={type}>
                                    <Input
                                        className="mt-2"
                                        key={type}
                                        type="checkbox"
                                        id={type}
                                        value={type}
                                        checked={initValues.device_model_type?.includes(type)}
                                        name="team"
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const isChecked = e.target.checked
                                            if (isChecked) {
                                                if (initValues.device_model_type.includes(value))
                                                    return
                                                setFieldValue('device_model_type', [
                                                    ...values.device_model_type,
                                                    value,
                                                ])
                                                setInitValues({
                                                    ...values,
                                                    device_model_type: [
                                                        ...values.device_model_type,
                                                        value,
                                                    ],
                                                })
                                            } else {
                                                setFieldValue(
                                                    'device_model_type',
                                                    values.device_model_type.filter(
                                                        (ty) => ty !== value
                                                    )
                                                )
                                                setInitValues({
                                                    ...values,
                                                    device_model_type:
                                                        values.device_model_type.filter(
                                                            (ty) => ty !== value
                                                        ),
                                                })
                                            }
                                        }}
                                    />
                                    <Label check className="mt-1">
                                        {type}{' '}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div>
                            <span className="text-black">Team</span>
                            {teams.map((team) => (
                                <FormGroup check key={team.uuid}>
                                    <Input
                                        className="mt-2"
                                        key={team.uuid}
                                        type="checkbox"
                                        id={team.slug}
                                        value={team.slug}
                                        checked={initValues.team.includes(team.slug)}
                                        name="team"
                                        label={team.title}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const isChecked = e.target.checked
                                            if (isChecked) {
                                                if (initValues.team.includes(value)) return
                                                setFieldValue('team', [...values.team, value])
                                                setInitValues({
                                                    ...values,
                                                    team: [...values.team, value],
                                                })
                                            } else {
                                                setFieldValue(
                                                    'team',
                                                    values.team.filter((team) => team !== value)
                                                )
                                                setInitValues({
                                                    ...values,
                                                    team: values.team.filter(
                                                        (team) => team !== value
                                                    ),
                                                })
                                            }
                                        }}
                                    />
                                    <Label check className="mt-1">
                                        {' '}
                                        {team.title}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                        <div>
                            <span className="text-black">Type</span>
                            {['iot', 'robot'].map((type) => (
                                <FormGroup check key={type}>
                                    <Input
                                        className="mt-2"
                                        key={type}
                                        type="checkbox"
                                        id={type}
                                        value={type}
                                        checked={initValues.type.includes(type)}
                                        name="type"
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const isChecked = e.target.checked
                                            if (isChecked) {
                                                if (initValues.type.includes(value)) return
                                                setFieldValue('type', [...values.type, value])
                                                setInitValues({
                                                    ...values,
                                                    type: [...values.type, value],
                                                })
                                            } else {
                                                setFieldValue(
                                                    'type',
                                                    values.type.filter((type) => type !== value)
                                                )
                                                setInitValues({
                                                    ...values,
                                                    type: values.type.filter(
                                                        (team) => team !== value
                                                    ),
                                                })
                                            }
                                        }}
                                    />
                                    <Label check className="mt-1">
                                        {' '}
                                        {type.charAt(0).toUpperCase() + type.slice(1)}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                    </>
                )
        }
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
            <DropdownToggle
                outline
                id="filterId"
                className="filter-button-org org-page-btns mx-2"
                style={{ height: '40px' }}
            >
                <img
                    src="/svgs/organization/buttons/filter.svg"
                    width={16}
                    height={10}
                    alt="filter"
                />
                Filters
            </DropdownToggle>
            <DropdownMenu className="shadow-sm rounded p-4">
                <Formik initialValues={initValues} onSubmit={handleSubmit}>
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    gap: '2rem',
                                    justifyContent: 'center',
                                    alignItems: 'start',
                                }}
                            >
                                {renderFilters(values, setFieldValue)}
                            </div>
                            <hr className="w-100" />
                            <div className="d-flex pull-right">
                                <Button
                                    className="cancel-btn-modal modals-new-btns"
                                    onClick={() => {
                                        cancelValues()
                                        toggle()
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="save-btn-modal modals-new-btns"
                                    style={{ marginLeft: '12px' }}
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </DropdownMenu>
        </Dropdown>
    )
}

export default Filter
