import { actionTypes } from './Form.actionTypes'

export const formsState = {
    status: 'loading',
    errors: {},
    variables: [],
    customForm: [],
    variableValue: [],
    form: [],
    orgForm: [],
}

export const formReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.ADD_VARIABLE_VALUE:
        case actionTypes.CREATE_VARIABLE:
        case actionTypes.REMOVE_VARIABLE:
            return {
                ...state,
                status: 'removing',
            }

        case actionTypes.GET_VARIABLE:
            return {
                ...state,
                variables: [],
                status: 'loading',
            }
        case actionTypes.GET_GENERICFORM:
            return {
                ...state,
                orgForm: [],
                status: 'loading',
            }

        case actionTypes.GET_FORM:
            return {
                ...state,
                customForm: [],
                status: 'loading',
            }
        case actionTypes.CREATE_VARIABLE_FAIL:
            return {
                ...state,
                status: 'error',
                errors: action.error?.response?.data,
            }
        case actionTypes.ADD_VARIABLE_VALUE_FAIL:
           
            return {
                ...state,
                status: 'error',
                errors: action.error?.response?.data,
            }

        case actionTypes.GET_VARIABLE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                variables: action.payload.data,
            }
        case actionTypes.GET_VARIABLE_VALUE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                variableValue: action.payload.data,
            }
        case actionTypes.GET_FORM_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                customForm: action.payload.data,
            }
        case actionTypes.GET_GENERICFORM_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                OrgForm: action.payload.data,
            }
        case actionTypes.REMOVE_VARIABLE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                variables: state.variables.filter(
                    (variable) => variable.uuid !== action.payload.data
                ),
            }

        case actionTypes.CREATE_VARIABLE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                variables: [...state.variables, action.payload.data],
            }

        case actionTypes.ADD_VARIABLE_VALUE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                variableValue: action.payload.data,
            }

        default:
            return state
    }
}
