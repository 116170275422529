import React from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { roleAndDescription } from 'components/dashboard-components/organization/OrgTeamHelpers'

const DropdownSelect = ({
    selected,
    setSelected,
    options,
    setFieldValue,
    fieldValue,
    onClick,
    index,
    setCurrentPage,
    nextPagePresets,
}) => {
    if (!options) return
    return (
        <UncontrolledDropdown className="w-100" data-testid="dropDown">
            <DropdownToggle
                style={{
                    border: '1px solid var(--grey-100, #e7e9ec)',
                    borderRadius: '8px',
                    padding: '8px 12px',
                    fontSize: '16px',
                    height: '40px',
                    width: '100%',
                    background: 'white',
                    color: '#3d414c',
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <span data-testid="selected">{selected}</span>
                <FontAwesomeIcon color="black" size="xs" icon={faChevronDown} />
            </DropdownToggle>
            <DropdownMenu
                data-testid="testmenu"
                className="col-12 scrolable"
                style={{ overflow: 'auto', maxHeight: 300, zIndex: 1000 }}
            >
                {options?.map((item) => (
                    <DropdownItem
                        key={item.uuid}
                        value={item.uuid}
                        onClick={() => {
                            if (fieldValue !== 'task_preset') {
                                if (index !== undefined) {
                                    setFieldValue(fieldValue, item.uuid)
                                    return
                                }
                                if (fieldValue === 'category') {
                                    return onClick(item.uuid, setFieldValue, item.title)
                                }
                                if (fieldValue === 'membership_type') {
                                    if (document.getElementById('membership_description-modal')) {
                                        document.getElementById(
                                            'membership_description-modal'
                                        ).innerText = roleAndDescription['organization'][item.uuid]
                                    } else {
                                        document.getElementById(
                                            'membership_description'
                                        ).innerText = roleAndDescription['organization'][item.uuid]
                                    }
                                }
                                setFieldValue(fieldValue, item.uuid)
                                setSelected(item.title || item.verbose_name || item.name)
                            } else {
                                onClick(setFieldValue, item)
                            }
                        }}
                    >
                        {item.title || item.verbose_name || item.name}
                    </DropdownItem>
                ))}
                {(fieldValue === 'task_preset' || fieldValue === 'entry_preset') &&
                    nextPagePresets && (
                        <DropdownItem
                            className="text-black"
                            style={{ borderTop: '1px solid lightgray', fontWeight: '500' }}
                            onClick={() => setCurrentPage((prev) => prev + 1)}
                        >
                            Load more
                        </DropdownItem>
                    )}
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default DropdownSelect
