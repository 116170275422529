import { Formik } from 'formik'
import React from 'react'
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'

const TaskAwaitModal = ({ modal, toggle, task, handleAwait }) => {
    const awaitOptions = [
        { value: 'continue_same', label: 'Assign the mission to the same robot' },
        { value: 'continue_different', label: 'Assign the mission to a different robot' },
        { value: 'abort', label: 'Abort the mission completely' },
    ]
    return (
        <Modal isOpen={modal} toggle={toggle} centered className="new-modals">
            <ModalBody
                style={{
                    padding: '24px 24px 0px 24px',
                    position: 'relative',
                    overflowY: 'auto',
                }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/modal-icons/modal-station-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column pb-4">
                    <span className="org-modal-header">Awaiting mission</span>
                    <span>
                        The robot is currently awaiting a mission. Please select a mission for the
                        robot to continue.
                    </span>
                </div>
                <Formik
                    initialValues={{ decision: 'wait' }}
                    onSubmit={(data) => {
                        handleAwait({
                            uuid: task.uuid,
                            data: { ...data, uuid: task.uuid },
                        })
                    }}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            {awaitOptions.map((opt) => (
                                <FormGroup check key={opt.value}>
                                    <Input
                                        className="mt-2"
                                        key={opt.value}
                                        type="radio"
                                        role="radio"
                                        name="decision"
                                        id={opt.value}
                                        value={opt.value}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const isChecked = e.target.checked

                                            if (isChecked) {
                                                setFieldValue('decision', value)
                                            } else {
                                                const anyChecked = awaitOptions.some(
                                                    (option) =>
                                                        option.value !== value &&
                                                        option.value === values.decision
                                                )
                                                if (!anyChecked) {
                                                    setFieldValue('decision', 'wait')
                                                }
                                            }
                                        }}
                                    />
                                    <Label> {opt.label}</Label>
                                </FormGroup>
                            ))}

                            <div className="d-flex py-3 pull-right">
                                <Button
                                    className="cancel-btn-modal modals-new-btns bg-white"
                                    onClick={toggle}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="save-btn-modal modals-new-btns bg-meili"
                                    style={{ marginLeft: '12px' }}
                                    type="submit"
                                    disabled={values.decision === 'wait'}
                                >
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default TaskAwaitModal
