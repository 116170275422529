import React, { useEffect, useState } from 'react'
import { Field, Formik } from 'formik'
import { Button, Form, FormGroup, Label, Modal, ModalBody } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { useDispatch, useSelector } from 'react-redux'
import {
    createOrgDeviceModel,
    getOrgDeviceModels,
    updateDeviceModel,
} from 'features/dashboard/RosSetup.actionTypes'
import { HiOutlineTrash } from 'react-icons/hi'
import { selectors } from 'features/dashboard'
import { deviceModelTypes } from './OrgTeamHelpers'
import DropdownSelect from 'layout-components/Dropdown'

const DeviceModelsModal = ({
    form,
    modal,
    toggle,
    slug,
    device = {},
    setForm,
    currentPageNumber,
    filters,
}) => {
    const dispatch = useDispatch()
    const teams = useSelector(selectors.getOrganizationTeams)
    const [fieldCount, setFieldCount] = useState(0)
    const [scrollPosition, setScrollPosition] = useState('top')
    const [team, setTeam] = useState(device?.team?.title || '')
    const [deviceType, setDeviceType] = useState(
        device?.device_model_type?.slice(0, 1).toUpperCase() + device?.device_model_type?.slice(1) || ''
    )

    useEffect(() => {
        setScrollPosition('top')
    }, [fieldCount])

    const handleSubmit = (data, action) => {
        const { uuid } = data
        if (uuid) {
            dispatch(updateDeviceModel({ data, uuid })).then(({ error }) => {
                action.setSubmitting(false)
                if (error) return action.setErrors(error.response.data)
                dispatch(getOrgDeviceModels({ slug, page: currentPageNumber, filters }))
                toggle()
            })
        } else {
            dispatch(createOrgDeviceModel({ data, slug })).then(({ error }) => {
                action.setSubmitting(false)
                if (error) return action.setErrors(error.response.data)
                dispatch(getOrgDeviceModels({ slug, page: currentPageNumber, filters }))
                toggle()
            })
        }
    }

    const addField = (values, setFieldValue) => {
        if (device.uuid) {
            setFieldValue('states', [...values.states, { title: '', payload: '' }])
        } else {
            setFieldCount(fieldCount + 1)
        }
    }

    const removeField = (values, setFieldValue, index) => {
        if (device.uuid) {
            setFieldValue('states', [...values.states.filter((_, i) => i !== index)])
        } else {
            setFieldCount(fieldCount - 1)
        }
    }

    const renderArrow = () => {
        if (fieldCount > 0) {
            if (scrollPosition === 'bottom') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-up-arrow-modals"
                    />
                )
            } else if (scrollPosition === 'top') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-down-arrow-modals"
                    />
                )
            } else {
                return (
                    <div className="d-flex">
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-up-arrow-modals"
                        />
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-down-arrow-modals"
                        />
                    </div>
                )
            }
        }
        return <div></div>
    }

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight
        const top = e.target.scrollTop === 0

        if (bottom) {
            setScrollPosition('bottom')
        } else if (top) {
            setScrollPosition('top')
        } else {
            setScrollPosition('middle')
        }
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="vehicle-modal-org">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
                onScroll={handleScroll}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/task-modal/smiley-robot.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column pb-4">
                    <span className="org-modal-header">
                        {form === 'add-device-model' ? 'New device model' : 'Device model settings'}
                    </span>
                </div>
                <Formik
                    initialValues={{ ...device, team: device?.team?.uuid || '' }}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            <FormGroup>
                                <Label className="modals-labels">Name *</Label>
                                <Field
                                    className="modals-inputs"
                                    type="text"
                                    name="name"
                                    required
                                    placeholder="Name"
                                    component={customInputForm}
                                    value={values.name || ''}
                                ></Field>
                            </FormGroup>
                            <FormGroup>
                                <Label className="modals-labels" for="communication_protocol">
                                    Team *
                                </Label>
                                <DropdownSelect
                                    selected={team}
                                    setSelected={setTeam}
                                    options={teams.map((team) => team)}
                                    setFieldValue={setFieldValue}
                                    fieldValue="team"
                                />
                                {/* <Field
                                    className="modals-inputs"
                                    component={customInputForm}
                                    required
                                    type="select"
                                    value={values.team || ''}
                                    name="team"
                                >
                                    <option value=""></option>
                                    {teams.map((team) => (
                                        <option key={team.uuid} value={team.uuid}>
                                            {team.title}
                                        </option>
                                    ))}
                                </Field> */}
                            </FormGroup>
                            <FormGroup>
                                <Label className="modals-labels">Device model type *</Label>
                                <DropdownSelect
                                    selected={deviceType}
                                    setSelected={setDeviceType}
                                    options={deviceModelTypes.map((type) => {
                                        return {
                                            name: type.slice(0, 1).toUpperCase() + type.slice(1),
                                            uuid: type,
                                        }
                                    })}
                                    setFieldValue={setFieldValue}
                                    fieldValue="device_model_type"
                                />
                                {/* <Field
                                    className="modals-inputs"
                                    type="select"
                                    name="device_model_type"
                                    placeholder="Device model type"
                                    component={customInputForm}
                                    required
                                    value={values.device_model_type || ''}
                                >
                                    <option value=""></option>
                                    {deviceModelTypes.map((option) => (
                                        <option key={option} value={option}>
                                            {option.slice(0, 1).toUpperCase() + option.slice(1)}
                                        </option>
                                    ))}
                                </Field> */}
                            </FormGroup>
                            {device.uuid ? (
                                <>
                                    {values.states.map((variable, index) => (
                                        <div key={index}>
                                            <FormGroup>
                                                <Label for={`title_${index}`}>
                                                    Title {index === 0 ? null : index + 1} *
                                                </Label>
                                                <div className="d-flex">
                                                    <Field
                                                        type="text"
                                                        component={customInputForm}
                                                        name={`states[${index}].title`}
                                                        required
                                                        className="modals-inputs"
                                                        placeholder={`Title ${index + 1}`}
                                                        value={values.states[index].title || ''}
                                                    />
                                                    {values.states.length > 1 && (
                                                        <img
                                                            alt="x"
                                                            src="/svgs/close-icon/x-dark-default.svg"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() =>
                                                                removeField(
                                                                    values,
                                                                    setFieldValue,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for={`payload_${index}`}>
                                                    {' '}
                                                    Payload {index === 0 ? null : index + 1} *
                                                </Label>
                                                <Field
                                                    component={customInputForm}
                                                    type="text"
                                                    required
                                                    className="modals-inputs"
                                                    name={`states[${index}].payload`}
                                                    placeholder={`Payload ${index + 1}`}
                                                    value={values.states[index].payload || ''}
                                                />
                                            </FormGroup>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {[...Array(fieldCount)].map((_, index) => (
                                        <div key={index}>
                                            <FormGroup>
                                                <Label for={`title_${index}`}>
                                                    Title {index === 0 ? null : index + 1} *
                                                </Label>
                                                <div className="d-flex">
                                                    <Field
                                                        type="text"
                                                        component={customInputForm}
                                                        name={`states[${index}].title`}
                                                        required
                                                        className="modals-inputs"
                                                        placeholder={`Title ${index + 1}`}
                                                    />
                                                    <img
                                                        alt="x"
                                                        src="/svgs/close-icon/x-dark-default.svg"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                            removeField(
                                                                values,
                                                                setFieldValue,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for={`payload_${index}`}>
                                                    {' '}
                                                    Payload {index === 0 ? null : index + 1} *
                                                </Label>
                                                <Field
                                                    component={customInputForm}
                                                    type="text"
                                                    required
                                                    className="modals-inputs"
                                                    name={`states[${index}].payload`}
                                                    placeholder={`Payload ${index + 1}`}
                                                />
                                            </FormGroup>
                                        </div>
                                    ))}
                                </>
                            )}

                            <div
                                style={{
                                    margin: '24px 0px',
                                    border: '1px dashed var(--grey-100, #e7e9ec)',
                                    cursor: 'pointer',
                                }}
                                className="modals-inputs"
                                onClick={() => addField(values, setFieldValue)}
                            >
                                <img src="/svgs/trigger-actions/add-remove.svg" alt="add-circle" />
                                <span className="ml-2" style={{ color: '#9DA3B0' }}>
                                    Add state
                                </span>
                            </div>
                            <div className="new-modal-footer">
                                {form === 'edit-device-model' ? (
                                    <Button
                                        color="none"
                                        onClick={() => {
                                            setForm('remove-device-model')
                                        }}
                                        className="modals-delete-btn"
                                    >
                                        <HiOutlineTrash size={20} className="mr-1" />
                                        Delete
                                    </Button>
                                ) : (
                                    renderArrow()
                                )}
                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={
                                            form === 'edit-device-model' &&
                                            JSON.stringify(values) === JSON.stringify(device)
                                        }
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default DeviceModelsModal
