import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { Button, FormGroup, Label, Container, Spinner } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import { CommonErrors } from 'ui/common'
import { setPasswordByToken } from 'features/user/actionTypes'
import { SetPasswordSchema } from 'schemas/user'

const SetPassword = () => {
    const dispatch = useDispatch()
    const [success, setSuccess] = useState(false)
    const { token } = useParams()
    const history = useHistory()
    const [type, setType] = useState('password')
    const [icon, setIcon] = useState(false)

    const handleSubmit = (data, { setErrors, setSubmitting }) => {
        dispatch(setPasswordByToken({ data, token })).then(({ error }) => {
            setSubmitting(false)
            if (!error) return setSuccess(true)
            const { data } = error.response
            setErrors(data)
        })
    }

    useEffect(() => {
        document.title = 'Reset Password'
    }, [dispatch])
    const handleToggle = () => {
        if (type === 'password') {
            setType('text')
            setIcon(!icon)
        } else {
            setIcon(!icon)
            setType('password')
        }
    }

    return (
        <Container
            className="d-flex flex-column justify-content-center mx-auto"
            style={{ height: '450px', width: '340px' }}
        >
            {success ? (
                <div className="pb-32 pt-32 d-flex flex-column justify-centent-center align-items-center">
                    <img className="mt-2 pr-2" alt="key" src="/svgs/user-icon/done.svg" />
                    <h2 className="size-32 text-black pt-32 pb-2 text-center">Password reset</h2>
                    <span className="size-16 grey-600 pt-2 text-center">
                        {' '}
                        Your password has been successfully reset. Click below to log in to your
                        account.
                    </span>
                    <Button
                        style={{
                            height: '40px',
                            marginTop: '24px',
                            marginBottom: '24px',
                        }}
                        block={true}
                        color="meili"
                        className="login-border"
                        onClick={() => history.push('/login')}
                    >
                        Log in
                    </Button>
                </div>
            ) : (
                <>
                    <div className="pb-32 pt-32 d-flex flex-column justify-centent-center align-items-center">
                        <img className="mt-2 pr-2" alt="key" src="/svgs/user-icon/forgetKey.svg" />
                        <h2 className="size-32 pt-32 text-black text-center">Set new password</h2>
                    </div>
                    <Formik
                        initialValues={{}}
                        validationSchema={SetPasswordSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, values, isSubmitting }) => (
                            <Form>
                                <FormGroup>
                                    <Label className="size-14 grey-900" for="password">
                                        New password
                                    </Label>
                                    <div className="d-flex">
                                        <Field
                                            className="show-pass"
                                            type={type}
                                            style={{
                                                width: '340px',
                                                height: '40px',
                                                paddingLeft: '12px',
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            name="password"
                                            placeholder="Enter your password"
                                            value={values.password || ''}
                                        />
                                        <span className="icon-pass" onClick={handleToggle}>
                                            {icon ? (
                                                <img
                                                    width="24px"
                                                    height="24px"
                                                    className="mt-2 pr-2"
                                                    alt="eyeOff"
                                                    src="/svgs/user-icon/eyeOff.svg"
                                                />
                                            ) : (
                                                <img
                                                    width="24px"
                                                    height="24px"
                                                    className="mt-2 pr-2"
                                                    alt="eyeOn"
                                                    src="/svgs/user-icon/eyeOn.svg"
                                                />
                                            )}
                                        </span>
                                    </div>
                                    <small className="size-14 grey-500">
                                        Must be at least 8 characters
                                    </small>
                                    {errors.password && touched.password ? (
                                        <div className="error-message">{errors.password}</div>
                                    ) : null}
                                </FormGroup>

                                <FormGroup>
                                    <Label className="size-14 grey-900" for="confirm_password">
                                        Confirm password
                                    </Label>
                                    <div className="d-flex">
                                        <Field
                                            className="show-pass"
                                            type={type}
                                            style={{
                                                width: '340px',
                                                height: '40px',
                                                paddingLeft: '12px',
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            name="password_confirm"
                                            placeholder="Confirm your password"
                                            value={values.password_confirm || ''}
                                        />
                                        <span className="icon-pass" onClick={handleToggle}>
                                            {icon ? (
                                                <img
                                                    width="24px"
                                                    height="24px"
                                                    className="mt-2 pr-2"
                                                    alt="eyeOff"
                                                    src="/svgs/user-icon/eyeOff.svg"
                                                />
                                            ) : (
                                                <img
                                                    width="24px"
                                                    height="24px"
                                                    className="mt-2 pr-2"
                                                    alt="eyeOn"
                                                    src="/svgs/user-icon/eyeOn.svg"
                                                />
                                            )}
                                        </span>
                                    </div>
                                    {errors.password_confirm && touched.password_confirm ? (
                                        <div className="error-message">
                                            {errors.password_confirm}
                                        </div>
                                    ) : null}
                                </FormGroup>
                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    color="meili"
                                    block={true}
                                    className="login-border"
                                    style={{ marginTop: '24px', marginBottom: '24px' }}
                                >
                                    Reset password
                                    {isSubmitting && <Spinner size="sm" color="white ml-3" />}
                                </Button>
                                <div
                                    className="grey-600 text-center logout-border size-14 weight-500"
                                    onClick={() => history.push('/login/')}
                                    style={{ width: '340px' }}
                                >
                                    <span
                                        style={{ cursor: 'pointer', lineHeight: '28px' }}
                                        className="mr-5"
                                    >
                                        <img
                                            src="/svgs/user-icon/arrow.svg"
                                            style={{ paddingRight: '8px', verticalAlign: 'middle' }}
                                            alt="Logout"
                                        ></img>
                                        Back to log in
                                    </span>
                                </div>
                                <CommonErrors />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Container>
    )
}

export default SetPassword
