import React from 'react'
import { Text, Line } from 'react-konva'

const NewPathLayer = ({ pos, hover, newZoneRef, connectPoint, size }) => {
    return (
        <>
            <Line
                ref={newZoneRef}
                points={connectPoint}
                stroke={'#BA4FFA'}
                strokeWidth={1}
                rotationSnaps={[0, 90, 180, 270]}
                perfectDrawEnabled={false}
            />
            <Text
                x={pos[0]}
                y={pos[1] / 1.2}
                align={'center'}
                padding={8}
                text={hover}
                fontSize={size}
                fontStyle="bold"
                stroke="#670d95"
                strokeWidth={0.1}
            />
        </>
    )
}

export default NewPathLayer
