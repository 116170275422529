import React from 'react'

const SidebarFooter = ({ hidden, toggle }) => {
    return (
        <div className="text-white d-flex flex-column justify-content-between align-items-center w-100 pb-2">
            {hidden ? (
                <span
                    className="animated-text text-footer-sidebar d-flex flex-column justify-content-center align-items-center"
                >
                    Powered by <br /> <strong>Meili FMS</strong>
                </span>
            ) : (
                <span className="animated-text text-footer-sidebar-open">
                    Powered by <strong>Meili FMS</strong>
                </span>
            )}
            <img
                src={`/svgs/sidebar-icons/${hidden ? 'arrow-open.svg' : 'arrow-close.svg'}`}
                alt="arrow-close"
                width="40px"
                height="40px"
                onClick={toggle}
                style={{ cursor: 'pointer', placeSelf: hidden ? 'center' : 'start' }}
            />
        </div>
    )
}

export default SidebarFooter
