import React from 'react'
import { FormGroup, Label, Button, Spinner, Alert } from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { customInputForm, CommonErrors } from 'ui/common'
import { outdoorPathSchema } from 'schemas/dashboard'
import {
    deleteOutdoorPath,
    createOutdoorPath,
} from 'features/dashboard/Map.actionTypes'
import { selectors } from 'features/dashboard'

const OutdoorPathForm = ({ path, toggle }) => {
    const dispatch = useDispatch()
    const map = useSelector(selectors.getTeamMap)
    const { slug } = useParams()

    const handleCreate = (data, action) => {
        dispatch(createOutdoorPath({ slug, data })).then(({ error }) => {
            action.setSubmitting(false)
            if (error) return action.setErrors(error.response.data)
            toggle()
        })
    }

    const handleRemovePath = ({ uuid }, action) => {
        dispatch(deleteOutdoorPath({ uuid })).then(({ error }) => {
            action.setSubmitting(false)
            if (error) return action.setErrors(error.response.data)
            toggle()
        })
    }

    return (
        <>
            {!path?.uuid ? (
                <Formik
                    initialValues={{
                        points_uuids: ['', ''],
                    }}
                    validationSchema={outdoorPathSchema}
                    onSubmit={handleCreate}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup>
                                <Label for="from">From</Label>
                                <Field
                                    type="select"
                                    component={customInputForm}
                                    name="points_uuids[0]"
                                >
                                    <option value=""></option>
                                    {Object.values(map.outdoorPoints).map(
                                        ({ properties }) => (
                                            <option
                                                key={properties.uuid}
                                                value={properties.uuid}
                                            >
                                                {properties.name}
                                            </option>
                                        )
                                    )}
                                </Field>
                            </FormGroup>
                            <FormGroup>
                                <Label for="to">To</Label>
                                <Field
                                    type="select"
                                    name="points_uuids[1]"
                                    id="to"
                                    component={customInputForm}
                                >
                                    <option value=""></option>
                                    {Object.values(map.outdoorPoints).map(
                                        ({ properties }) => (
                                            <option
                                                key={properties.uuid}
                                                value={properties.uuid}
                                            >
                                                {properties.name}
                                            </option>
                                        )
                                    )}
                                </Field>
                            </FormGroup>
                            <CommonErrors extraFields={['points_uuids']} />
                            <Button
                                disabled={isSubmitting}
                                color="neutral-primary"
                                className="btn-transition-none"
                                type="submit"
                            >
                                Save
                                {isSubmitting && (
                                    <Spinner size="sm" color="white ml-2" />
                                )}
                            </Button>
                            <Button
                                color="outline-warning"
                                onClick={toggle}
                                className="pull-right btn-transition-none"
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : (
                <>
                    <Alert color="danger">
                        Are you sure you want to delete the path?
                    </Alert>
                    <div className="divider my-3"></div>
                    <Formik initialValues={path} onSubmit={handleRemovePath}>
                        {({ isSubmitting }) => (
                            <Form>
                                <Button
                                    color="danger"
                                    size="md"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Yes
                                    {isSubmitting && (
                                        <Spinner size="sm" color="white ml-2" />
                                    )}
                                </Button>{' '}
                                {'  '}
                                <Button
                                    color="secondary pull-right"
                                    size="md"
                                    onClick={toggle}
                                >
                                    No
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </>
    )
}

export default OutdoorPathForm
