import React from 'react'
import { Row, Col } from 'reactstrap'
import PricePlan from './PricePlan'

const Plans = () => {
    const planData = [
        {
            title: 'Intro',
            price: '4000',
            perRobot: '0',
            robots: '1-4',
        },
        {
            title: 'Base',
            price: '4375',
            perRobot: '75',
            robots: '5-9',
        },
        {
            title: 'Growing',
            price: '4650',
            perRobot: '65',
            robots: '10-19',
        },
        // {
        //     title: 'Professional',
        //     price: '5200',
        //     perRobot: '60',
        //     robots: '20-29',
        // },
        // {
        //     title: 'Enterprise',
        //     price: '5650',
        //     perRobot: '55',
        //     robots: '30+',
        // },
    ]

    return (
        <Row className="my-3">
            <Col md={12}>
                <h1>Plans</h1>
            </Col>
            {planData.map((plan, index) => (
                <Col md={4} key={index}>
                    <PricePlan plan={plan} />
                </Col>
            ))}
        </Row>
    )
}

export default Plans
