import React, { useState } from 'react'
import { Table } from 'reactstrap'
import VehicleTopicsForm from './VehicleTopicsForm'

const RosSetupVehDev = ({ setup, handleUpdate }) => {
    const [vehicle, setVehicle] = useState(null)
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)
    return (
        <div className="big-org-container" style={{ padding: '0', marginTop: '32px' }}>
            <Table bordered className="my-custom-table border-0">
                {setup.vehicles.length > 0 ? (
                    <>
                        {' '}
                        <thead>
                            <tr>
                                <th className="custom-header">Vehicle Name</th>
                                <th className="custom-header">External ID</th>
                                <th className="custom-header">Prefix</th>
                                {(setup.integration_type === 'ROS' ||
                                    setup.integration_type === 'NOOVELIA') && (
                                    <th className="custom-header">Topics</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {setup.vehicles.map((veh, index) => (
                                <tr key={index}>
                                    <td className="custom-cell">{veh?.vehicle.verbose_name}</td>
                                    <td className="custom-cell">
                                        {veh?.vehicle.external_identifier || '-'}
                                    </td>
                                    <td className="custom-cell">{veh.prefix}</td>
                                    {(setup.integration_type === 'ROS' ||
                                        setup.integration_type === 'NOOVELIA') && (
                                        <td className="custom-cell">
                                            <img
                                                style={{
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                }}
                                                src="/svgs/trigger-actions/edit.svg"
                                                alt="edit"
                                                onClick={() => {
                                                    setVehicle(veh)
                                                    toggle()
                                                }}
                                            />
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </>
                ) : (
                    <>
                        {' '}
                        <thead>
                            <tr>
                                <th className="custom-header">Device Name</th>
                                <th className="custom-header">Model Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="custom-cell">{setup.device_model?.name}</td>
                                <td className="custom-cell">
                                    {setup.device_model?.device_model_type}
                                </td>
                            </tr>
                        </tbody>
                    </>
                )}
            </Table>
            <VehicleTopicsForm
                modal={modal}
                toggle={toggle}
                vehicle={vehicle}
                handleUpdate={handleUpdate}
            />
        </div>
    )
}

export default RosSetupVehDev
