import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getMapAreaDetails } from 'features/dashboard/Map.actionTypes'
import InfoPage from 'components/InfoPage'
import { Spinner } from 'reactstrap'
import { getTeam } from 'features/dashboard/Team.actionTypes'

const TeamPageProtectedRoute = ({ children, ...rest }) => {
    const dispatch = useDispatch()
    const { slug } = rest.computedMatch.params
    const [status, setStatus] = useState('init')

    const mapModifyLocation = `/dashboard/${slug}/maps/modify/`

    const validateInsideTeam = () => {
        dispatch(getMapAreaDetails(slug)).then(({ error, payload }) => {
            if (error) {
                const { status } = error.response
                if (status === 403) setStatus('error-permission')
                if (status === 404) setStatus('error-not-found')
                return
            }
            if (payload.data.length === 0) {
                setStatus('no-map')
                return
            }
            setStatus('done')
        })
    }

    useEffect(() => {
        dispatch(getTeam({ slug })).then(({ payload }) => {
            if (payload?.data.operation_type === 'outside') {
                setStatus('done')
                return
            }
            validateInsideTeam()
        })
    }, [slug]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (status === 'init')
                    return (
                        <InfoPage
                            message={
                                <>
                                    Preparing... <Spinner color="meili" />
                                </>
                            }
                        />
                    )
                if (status === 'error-permission')
                    return (
                        <InfoPage message="You don't have permission, please contact to your team admin." />
                    )
                if (status === 'error-not-found') return <InfoPage message="Team does not exist!" />
                if (status === 'no-map' && location.pathname !== mapModifyLocation)
                    return <Redirect to={mapModifyLocation} />
                return children
            }}
        />
    )
}

export default TeamPageProtectedRoute
