import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import { Button, ButtonGroup, Form, FormGroup, Label, Modal, ModalBody } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { TIMEZONES } from 'export'
import { handleDrag, handleDrop, handleFileChange } from './OrgTeamHelpers'
import { addImage, createTeam, updateTeam } from 'features/dashboard/Team.actionTypes'
import { getOrgTeams } from 'features/dashboard/Organization.actionTypes'
import { removeEmptyStringsFromObj, removeNullFromObj } from 'utils'
import DropdownSelect from 'layout-components/Dropdown'

const TeamsModal = ({ slug, form, setForm, modal, toggle, team = {} }) => {
    const dispatch = useDispatch()
    const [tab, setTab] = useState('general')
    const [scrollPosition, setScrollPosition] = useState('top')
    const [dragActive, setDragActive] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [type, setType] = useState(team?.operation_type || '')
    const [timeZone, setTimeZone] = useState(team?.timezone || '')
    const inputFile = useRef(null)

    const handleSubmit = ({ uuid, imageUrl, ...data }) => {
        if (form === 'edit-team') {
            const sortedData = removeEmptyStringsFromObj(data)
            const sortedAddress = removeNullFromObj(data.address)
            sortedData.address = sortedAddress
            if (data.image === team.image || !data.image) {
                dispatch(updateTeam({ uuid, data: sortedData })).then(({ error }) => {
                    if (!error) {
                        dispatch(getOrgTeams(slug, true))
                        toggle()
                    }
                })
            } else {
                const formData = new FormData()
                formData.append('image', data.image)
                dispatch(addImage({ uuid, data: formData })).then(({ error }) => {
                    delete data.image
                    if (!error) {
                        dispatch(updateTeam({ uuid, data: sortedData })).then(({ error }) => {
                            if (!error) {
                                dispatch(getOrgTeams(slug, true))
                                toggle()
                            }
                        })
                    }
                })
            }
        }

        if (form === 'add-team') {
            dispatch(createTeam({ slug, data })).then(({ error, payload }) => {
                if (!error) {
                    const teamUUID = payload.data.uuid
                    if (data.image === team.image || !data.image) {
                        dispatch(getOrgTeams(slug))
                        toggle()
                    } else {
                        const formData = new FormData()
                        formData.append('image', data.image)
                        dispatch(addImage({ uuid: teamUUID, data: formData })).then(({ error }) => {
                            if (!error) {
                                dispatch(getOrgTeams(slug, true))
                                toggle()
                            }
                        })
                    }
                }
            })
        }
    }

    const renderArrow = () => {
        if (tab === 'general') {
            if (scrollPosition === 'bottom') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-up-arrow-modals"
                    />
                )
            } else if (scrollPosition === 'top') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-down-arrow-modals"
                    />
                )
            } else {
                return (
                    <div className="d-flex">
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-up-arrow-modals"
                        />
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-down-arrow-modals"
                        />
                    </div>
                )
            }
        }
        return <div></div>
    }

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight
        const top = e.target.scrollTop === 0

        if (bottom) {
            setScrollPosition('bottom')
        } else if (top) {
            setScrollPosition('top')
        } else {
            setScrollPosition('middle')
        }
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="vehicle-modal-org">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
                onScroll={handleScroll}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/organization/members/team-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">
                        {form === 'add-team' ? 'New team' : 'Team settings'}
                    </span>
                    <div className="modals-container-buttons">
                        <ButtonGroup className="org-button-group">
                            <Button
                                className={`${
                                    tab === 'general' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('general')
                                }}
                            >
                                General
                            </Button>
                            <Button
                                className={`${
                                    tab === 'logo' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('logo')
                                }}
                            >
                                Logo
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <Formik initialValues={team} onSubmit={handleSubmit}>
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form
                            style={{ marginTop: '14px' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            {tab === 'general' && (
                                <>
                                    <FormGroup>
                                        <Label className="modals-labels" for="verbose_name">
                                            Name *
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            name="title"
                                            placeholder="Name"
                                            value={values.title || ''}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="verbose_name">
                                            Description
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            value={values.description || ''}
                                            name="description"
                                            placeholder="Description"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels">Type</Label>
                                        <DropdownSelect
                                            selected={type}
                                            setSelected={setType}
                                            options={[
                                                '',
                                                { uuid: 'inside', title: 'Inside' },
                                                { uuid: 'outside', title: 'Outside' },
                                            ]}
                                            setFieldValue={setFieldValue}
                                            fieldValue="operation_type"
                                        />
                                        {/* <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="select"
                                            name="operation_type"
                                            value={values.operation_type || ''}
                                        >
                                            <option value=""></option>
                                            <option value="inside">Inside</option>
                                            <option value="outside">Outside</option>
                                        </Field> */}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="timezone">
                                            Timezone
                                        </Label>
                                        <DropdownSelect
                                            selected={timeZone}
                                            setSelected={setTimeZone}
                                            options={TIMEZONES.map((timezone) => {
                                                return { uuid: timezone, name: timezone }
                                            })}
                                            setFieldValue={setFieldValue}
                                            fieldValue="timezone"
                                        />
                                        {/* <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="select"
                                            name="timezone"
                                            value={values.timezone || ''}
                                        >
                                            <option value=""></option>
                                            {TIMEZONES.map((timezone) => (
                                                <option key={timezone} value={timezone}>
                                                    {timezone}
                                                </option>
                                            ))}
                                        </Field> */}
                                    </FormGroup>
                                </>
                            )}

                            {tab === 'logo' && (
                                <>
                                    <span className="modals-labels mb-0">
                                        {!team.image && !values.image
                                            ? ' Add a logo to your team'
                                            : `Replace or delete your team's logo.`}
                                    </span>
                                    {imageUrl && values.image !== team.image ? (
                                        <div
                                            className="image-wrapper"
                                            style={{
                                                position: 'relative',
                                                width: '90px',
                                                height: '90px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setImageUrl(null)
                                                setFieldValue('image', null)
                                            }}
                                        >
                                            <div
                                                className="rounded-circle mt-2"
                                                style={{
                                                    width: '90px',
                                                    height: '90px',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <img
                                                    id="user"
                                                    src={imageUrl}
                                                    alt="user"
                                                    width={90}
                                                    height={90}
                                                    style={{
                                                        objectFit: 'cover',
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="trash-icon"
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <HiOutlineTrash size={35} color="black" />
                                            </div>
                                        </div>
                                    ) : values.image ? (
                                        <div
                                            className="image-wrapper"
                                            style={{
                                                position: 'relative',
                                                width: '90px',
                                                height: '90px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setImageUrl(null)
                                                setFieldValue('image', null)
                                            }}
                                        >
                                            <div
                                                className="rounded-circle mt-2"
                                                style={{
                                                    width: '90px',
                                                    height: '90px',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <img
                                                    id="user"
                                                    src={values.image}
                                                    alt="user"
                                                    width={90}
                                                    height={90}
                                                    style={{
                                                        objectFit: 'cover',
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="trash-icon"
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <HiOutlineTrash size={35} color="black" />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`logo-container mt-1 ${
                                                dragActive ? 'border-gray' : ' border-meili'
                                            }`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => inputFile.current.click()}
                                            onDragEnter={(e) => handleDrag(e, setDragActive)}
                                            onDragLeave={(e) => handleDrag(e, setDragActive)}
                                            onDragOver={(e) => handleDrag(e, setDragActive)}
                                            onDrop={(e) =>
                                                handleDrop(
                                                    e,
                                                    setFieldValue,
                                                    setDragActive,
                                                    setImageUrl
                                                )
                                            }
                                        >
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={inputFile}
                                                accept={'.png, .pgm, .svg, .jpg'}
                                                onChange={(event) => {
                                                    handleFileChange(
                                                        event,
                                                        setFieldValue,
                                                        setImageUrl
                                                    )
                                                }}
                                            />
                                            <img
                                                id="upload"
                                                src="/svgs/settings-page/upload.svg"
                                                alt="user"
                                                width={48}
                                                height={48}
                                            />

                                            <div className="d-flex flex-column align-items-center">
                                                <span className="click-text">Click to upload</span>
                                                <span
                                                    className="click-text"
                                                    style={{ color: '#24272D' }}
                                                >
                                                    or drag and drop
                                                </span>
                                            </div>
                                            <span className="format-text">
                                                JPG, PNG or SVG <br /> (max. 400 x 400px)
                                            </span>
                                        </div>
                                    )}
                                </>
                            )}

                            <div className="new-modal-footer">
                                {form === 'add-team' ? (
                                    renderArrow()
                                ) : (
                                    <Button
                                        color="none"
                                        onClick={() => {
                                            setForm('remove-team')
                                        }}
                                        className="modals-delete-btn"
                                    >
                                        <HiOutlineTrash size={20} className="mr-1" />
                                        Delete
                                    </Button>
                                )}
                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={
                                            form === 'edit-team' &&
                                            JSON.stringify(values) === JSON.stringify(team)
                                        }
                                    >
                                        {form === 'add-team' ? 'Add' : 'Save'}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default TeamsModal
