export const actionTypes = {
    GET_SIMULATION_STATUS_STATE: 'GET_SIMULATION_STATUS_STATE',
    START_SIMULATION_STATE: 'START_SIMULATION_STATE',
    STOP_SIMULATION_STATE: 'STOP_SIMULATION_STATE',
}

export function startSimulationState() {
    return {
        type: actionTypes.START_SIMULATION_STATE,
        simulationStarted: true,
    }
}
export function stopSimulationState() {
    return {
        type: actionTypes.STOP_SIMULATION_STATE,
        simulationStarted: false,
    }
}
