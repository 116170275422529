import React from 'react'
import { Arc } from 'react-konva'

const IntermediatePoints = ({
    intermediatepoint,
    path,
    width,
    height,
    onDragEndChange,
    handleMouse,
}) => {
    if (!intermediatepoint) return
    return (
        <Arc
            draggable
            angle={360}
            outerRadius={width < 100 ? 2 * 0.22 : height > 700 ? 6 * 0.65 : 6 * 0.3}
            x={intermediatepoint[0]}
            y={intermediatepoint[1]}
            fill="#BA4FFA"
            onMouseEnter={(e) => {
                const container = e.target.getStage().container()
                container.style.cursor = 'move'
            }}
            onMouseLeave={(e) => {
                const container = e.target.getStage().container()
                container.style.cursor = 'default'
            }}
            onDragMove={handleMouse}
            onDragEnd={(e) => onDragEndChange(e, path, intermediatepoint)}
        />
    )
}

export default IntermediatePoints
