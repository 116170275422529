import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import RunMissionPreset from '../Tasks/RunMissionPreset'
import CreateTaskNewVersion from '../Tasks/CreateTaskNewVersion'
import { selectors } from 'features/dashboard'

const TaskSidebar = ({
    sidebar,
    toggle,
    presets,
    actions,
    actionsPresets,
    categories,
    stations,
    devices,
    slug,
    setIcon,
    status,
    vehicles,
    setCurrentPage,
    nextPagePresets,
}) => {
    const [isCreateNew, setIsCreateNew] = useState(false)
    return (
        <div
            data-testid="taskSidebar"
            style={{
                width: '392px',
                height: window.innerHeight - 150,
                display: sidebar ? 'block' : 'none',
                position: 'fixed',
                right: '0px',
                bottom: '0px',
                padding: '24px',
                borderTopLeftRadius: '10px',
                background: '#fff',
                boxShadow:
                    '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
                overflowY: 'auto',
            }}
        >
            <img
                src="/svgs/close-icon/x-dark-default.svg"
                alt="obstacle-icon"
                width="24px"
                height="24px"
                data-testid="createViewbtn"
                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }}
                onClick={() => {
                    toggle()
                    setIsCreateNew(false)
                    setIcon(false)
                }}
            />

            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                {!isCreateNew ? (
                    <RunMissionPreset
                        presets={presets}
                        setCurrentPage={setCurrentPage}
                        nextPagePresets={nextPagePresets}
                        slug={slug}
                        status={status}
                        toggle={toggle}
                        isCreateNew={isCreateNew}
                        setIsCreateNew={setIsCreateNew}
                        setIcon={setIcon}
                        categories={categories}
                        vehicles={vehicles}
                    />
                ) : (
                    <CreateTaskNewVersion
                        slug={slug}
                        setIcon={setIcon}
                        toggle={() => {
                            toggle()
                            setIsCreateNew(false)
                        }}
                        actions={actions}
                        actionsPresets={actionsPresets}
                        categories={categories}
                        vehicles={vehicles}
                        stations={stations}
                        devices={devices}
                    />
                )}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        categories: selectors.getVehiclesCategoires(state),
        vehicles: selectors.getVehicles(state),
        form_values: selectors.getTask(state).formValues,
        preset: selectors.getPreset(state),
    }
}

export default compose(withRouter, connect(mapStateToProps))(TaskSidebar)
