import { actionTypes } from './Team.actionTypes'
import { vehicleReducer, vehiclesState } from './VehicleReducer'
import { memberReducer, memberState } from './MembershipReducer'
import mapReducer, { mapState } from './MapReducer'
import { rosSetupReducer, rosSetupState } from './RosSetupReducer'
import { teamAnalyticsReducer, teamAnalyticsState } from './TeamAnalyticsReducer'
import { teamDashboardReducer, teamDashboardState } from './TeamDashboardReducer'
import { formsState, formReducer } from './FormReducer'
import triggerReducer, { triggerState } from './TriggerReducer'

const initialState = {
    status: 'loading',
    list: [],
    results: [],
    details: {},
    image: null,
    webhooks: [],
    path_planning: {},
    forms: formsState,
    vehicles: vehiclesState,
    members: memberState,
    analytics: teamAnalyticsState,
    whitedevDashboard: teamDashboardState,
    setup: rosSetupState,
    teamMap: mapState,
    teamTriggers: triggerState,
}

export const TeamReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TEAMS:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.GET_TEAMS_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                list: action.payload.data,
            }
        case actionTypes.UPDATE_TEAM:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.UPDATE_TEAM_SUCCESS: {
            let { data } = action.payload
            return {
                ...state,
                status: 'loaded',
                list: state.list.map((team) => {
                    if (data.uuid === team.uuid) return data
                    return data
                }),
            }
        }

        case actionTypes.UPDATE_TEAM_MEMBER_FAIL:
            return {
                ...state,
                message: 'update-membership-error',
            }
        case actionTypes.UPDATE_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                results: state.results.map((team) => {
                    if (team.uuid === action.payload.data.uuid) return action.payload.data
                    return team
                }),
            }
        case actionTypes.GET_PATH:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.GET_PATH_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                path_planning: action.payload.data,
            }
        case actionTypes.UPDATE_PATH_PLANNING:
            return {
                ...state,
                status: 'loading',
            }

        case actionTypes.UPDATE_PATH_PLANNING_FAIL:
            return {
                ...state,
            }
        case actionTypes.UPDATE_PATH_PLANNING_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                path_planning: action.payload.data,
            }

        case actionTypes.GET_TEAM:
            return {
                ...state,
                details: {},
                status: 'team-loading',
            }
        case actionTypes.GET_ALL_TEAM_MEMBERS:
            return {
                ...state,
                results: [],
                status: 'loading',
            }
        case actionTypes.GET_TEAM_SUCCESS:
            return {
                ...state,
                status: 'team-loaded',
                details: action.payload.data,
            }

        case actionTypes.GET_ALL_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                status: 'team-loaded',
                results: action.payload.data.results,
            }

        case actionTypes.REMOVE_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                results: state?.members?.results?.filter(
                    (member) => member.uuid !== action.payload.data.uuid
                ),
            }
        case actionTypes.REMOVE_TEAM_MEMBER_FAIL:
            return {
                ...state,
            }
        case actionTypes.CREATE_MEMBER:
            return {
                ...state,
                status: 'creating',
            }
        case actionTypes.CREATE_MEMBER_SUCCESS:
            return {
                ...state,
                results: [...state.results, action.payload.data],
            }

        case actionTypes.CREATE_TEAM_WEBHOOK_SUCCESS:
            return {
                ...state,
                webhooks: [...state.webhooks, action.payload.data],
            }
        case actionTypes.ADD_IMAGE_SUCCESS:
            return {
                ...state,
                image: action.payload.data,
            }
        case actionTypes.ADD_IMAGE_FAIL:
            return {
                ...state,
            }
        case actionTypes.GET_TEAM_WEBHOOKS_SUCCESS:
            return {
                ...state,
                webhooks: action.payload.data,
            }
        case actionTypes.UPDATE_TEAM_WEBHOOK_SUCCESS:
            return {
                ...state,
                webhooks: state.webhooks.map((webhook) =>
                    webhook.uuid === action.payload.data.uuid ? action.payload.data : webhook
                ),
            }
        case actionTypes.DELETE_TEAM_WEBHOOK_SUCCESS:
            return {
                ...state,
                webhooks: state.webhooks.filter(
                    (webhook) => webhook.uuid !== action.payload.data.uuid
                ),
            }
        default:
            return {
                ...state,
                setup: rosSetupReducer(state.setup, action),
                teamMap: mapReducer(state.teamMap, action),
                vehicles: vehicleReducer(state.vehicles, action),
                members: memberReducer(state.members, action),
                analytics: teamAnalyticsReducer(state.analytics, action),
                whitedevDashboard: teamDashboardReducer(state.whitedevDashboard, action),
                forms: formReducer(state.forms, action),
                teamTriggers: triggerReducer(state.teamTriggers, action),
            }
    }
}
