import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { Input, FormFeedback, Spinner, Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import { useFormikContext, ErrorMessage, getIn } from 'formik'
import { useEffect } from 'react'

export const CommonErrors = ({ extraFields = [], ...props }) => {
    const { isSubmitting, setTouched, touched } = useFormikContext()
    const allFieldsArray = ['detail', 'non_field_errors', ...extraFields]

    const availableFields = allFieldsArray.reduce((acc, field) => {
        acc[field] = true
        return acc
    }, {})

    useEffect(() => {
        if (isSubmitting) {
            setTouched({ ...availableFields, ...touched })
        }
    }, [isSubmitting]) // eslint-disable-line react-hooks/exhaustive-deps

    return allFieldsArray.map((field, index) => (
        <ErrorMessage
            name={field}
            key={index}
            className="text-danger text-center"
            component="p"
            {...props}
        />
    ))
}

export const customInputForm = ({ field, form: { touched, errors }, ...props }) => {
    const touch = getIn(touched, field.name)
    const error = getIn(errors, field.name)
    const freeToGo = field.name === 'custom_manufacturer' || field.name === 'vehicle_serial_number'
    return (
        <>
            <Input invalid={!freeToGo && !!(touch && error)} {...field} {...props} />
            <FormFeedback>
                <ErrorMessage name={field.name} />
            </FormFeedback>
        </>
    )
}

export const Loading = (props) => (
    <div
        className="text-center position-absolute d-flex flex-column align-items-center justify-content-center w-100 h-100"
        {...props}
    >
        <div className="mb-2">
            <Spinner
                type="grow"
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem',
                }}
            />
            <Spinner
                type="grow"
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem',
                }}
            />
            <Spinner
                type="grow"
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem',
                }}
            />
        </div>
        <div className="ml-4">{props.message ? <h4>{props.message}</h4> : <h4>Loading...</h4>}</div>
    </div>
)

export const ConfirmAlert = ({ show, message, handleConfirm, handleCancel, type }) => {
    const container = document.createElement('div')
    document.body.appendChild(container)

    const closeModal = () => {
        ReactDOM.unmount(container)
    }

    createRoot(
        <Modal isOpen={show} fade={false} backdrop={'static'} centered={true}>
            <ModalBody>
                <h4>{message}</h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => {
                        if (handleCancel) handleCancel()
                        closeModal()
                    }}
                    color="secondary"
                    className="mr-2"
                >
                    No
                </Button>
                <Button
                    color={`${type}`}
                    onClick={() => {
                        handleConfirm()
                        closeModal()
                    }}
                >
                    Yes
                </Button>
            </ModalFooter>
        </Modal>,
        container
    )
}
