export const actionTypes = {
    GET_TEAM_DASHBOARD: 'GET_TEAM_DASHBOARD',
    GET_TEAM_DASHBOARD_FAIL: 'GET_TEAM_DASHBOARD_FAIL',
    GET_TEAM_DASHBOARD_SUCCESS: 'GET_TEAM_DASHBOARD_SUCCESS',
}

export function getTeamDashboard({ slug }) {
    return {
        type: actionTypes.GET_TEAM_DASHBOARD,
        payload: {
            request: {
                url: `api/teams/${slug}/dashboard/`,
                method: 'GET',
            },
        },
    }
}
