import React, { useState } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { renderDifferentActions } from './MapEditorHelpers'
import { handleZoomBtns } from './helpers'
import EyeShow from 'layout-components/Eye/EyeShow'

export const MiniMapButtons = ({
    areaUuid,
    toggle,
    icon,
    setIcon,
    hidePaths,
    setHidePaths,
    vehicle,
    sidebar,
    stageRef,
    showTagZonesNames,
    setShowTagZonesNames,
    showVehicleNames,
    setShowVehicleNames,
}) => {
    const [buttonState, setButtonState] = useState({
        isEyeHovered: false,
        zoomIsHover: false,
        zoomIsClicked: false,
        zoomOutIsHover: false,
        zoomOutIsClicked: false,
        saveIsHover: false,
        saveIsClicked: false,
    })
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)

    const handleButtonState = ({ button, value }) => {
        setButtonState({ ...buttonState, [button]: value })
    }

    const handleButtonEvent = ({ button, value }) => {
        return () => {
            handleButtonState({ button, value })
        }
    }

    const buttonClass = 'p-0 iconBoader'

    return (
        <div
            className="d-flex flex-column mt-3"
            style={{ zIndex: 2, position: 'fixed', right: sidebar ? '27rem' : '2rem' }}
        >
            <Button
                size="sm"
                id="save-btn"
                className="p-0 iconBoader my-2"
                onClick={toggle}
                onMouseDown={handleButtonEvent({ button: 'saveIsClicked', value: true })}
                onMouseUp={handleButtonEvent({ button: 'saveIsClicked', value: false })}
                onMouseEnter={handleButtonEvent({ button: 'saveIsHover', value: true })}
                onMouseLeave={handleButtonEvent({ button: 'saveIsHover', value: false })}
            >
                {renderDifferentActions(
                    buttonState.saveIsHover,
                    '/svgs/map-editor/save-hover.svg',
                    buttonState.saveIsClicked,
                    '/svgs/map-editor/save-clicked.svg',
                    '/svgs/map-editor/save.svg',
                    'save'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="save-btn"
                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
            >
                Save current view
            </UncontrolledTooltip>

            <EyeShow
                icon={icon}
                vehicle={vehicle}
                areaUuid={areaUuid}
                setIcon={setIcon}
                hidePaths={hidePaths}
                setHidePaths={setHidePaths}
                buttonState={buttonState}
                toggle={dropdownToggle}
                dropdownOpen={dropdownOpen}
                handleButtonEvent={handleButtonEvent}
                place="minimap"
                showTagZonesNames={showTagZonesNames}
                setShowTagZonesNames={setShowTagZonesNames}
                showVehicleNames={showVehicleNames}
                setShowVehicleNames={setShowVehicleNames}
            />
            <Button
                size="sm"
                id="zoomin"
                className={`${buttonClass} my-2`}
                onClick={() => handleZoomBtns(stageRef, true)}
                onMouseDown={handleButtonEvent({ button: 'zoomIsClicked', value: true })}
                onMouseUp={handleButtonEvent({ button: 'zoomIsClicked', value: false })}
                onMouseEnter={handleButtonEvent({ button: 'zoomIsHover', value: true })}
                onMouseLeave={handleButtonEvent({ button: 'zoomIsHover', value: false })}
            >
                {renderDifferentActions(
                    buttonState.zoomIsHover,
                    '/svgs/map-editor/zoom-in-hover.svg',
                    buttonState.zoomIsClicked,
                    '/svgs/map-editor/zoom-in-clicked.svg',
                    '/svgs/map-editor/zoom-in.svg',
                    'zoom-in'
                )}
            </Button>
            <UncontrolledTooltip placement="auto" target="zoomin" className="tooltip-style">
                Zoom in
                <br />
                (CMD/Ctrl + scroll up)
            </UncontrolledTooltip>
            <Button
                size="sm"
                id="zoomout"
                className={buttonClass}
                onClick={() => handleZoomBtns(stageRef, false)}
                onMouseDown={handleButtonEvent({ button: 'zoomOutIsClicked', value: true })}
                onMouseUp={handleButtonEvent({ button: 'zoomOutIsClicked', value: false })}
                onMouseEnter={handleButtonEvent({ button: 'zoomOutIsHover', value: true })}
                onMouseLeave={handleButtonEvent({ button: 'zoomOutIsHover', value: false })}
            >
                {renderDifferentActions(
                    buttonState.zoomOutIsHover,
                    '/svgs/map-editor/zoom-out-hover.svg',
                    buttonState.zoomOutIsClicked,
                    '/svgs/map-editor/zoom-out-clicked.svg',
                    '/svgs/map-editor/zoom-out.svg',
                    'zoom-out'
                )}
            </Button>
            <UncontrolledTooltip placement="auto" target="zoomout" className="tooltip-style">
                Zoom out
                <br />
                (CMD/Ctrl + scroll down)
            </UncontrolledTooltip>

            {/* <UncontrolledTooltip placement="auto" target="hide_paths" className="tooltip-style">
                Hide paths
            </UncontrolledTooltip> */}
        </div>
    )
}
