import React from 'react'
import UploadFile from './UploadFile'
import SuccessUpload from './SuccessUpload'

const FileUploaderBridge = ({
    setFile,
    success,
    setSuccess,
    fileType,
}) => {
    return (
        <>
            {success ? (
                <SuccessUpload fileType={fileType} />
            ) : (
                <UploadFile
                    setSuccess={setSuccess}
                    setFile={setFile}
                    fileType={fileType}
                />
            )}
        </>
    )
}

export default FileUploaderBridge
