import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import { selectors } from 'features/dashboard'
import {
    Col,
    Row,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownToggle,
    Button,
    UncontrolledTooltip,
    ButtonGroup,
} from 'reactstrap'
import {
    TotalUVChours,
    TotalAFhours,
    TotalSShours,
    Totaldistancetravel,
    TotalHOCLValue,
    OperationValue,
} from './DashboardCards'
import { getAnalytics, getWhitedevDashboard } from 'features/dashboard/selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faObjectGroup,
    faCheckSquare,
    faUndo,
    faCheck,
    faEyeSlash,
    faTimes,
    faArrowsAlt,
    faLink,
} from '@fortawesome/free-solid-svg-icons'

const CardWrapper = forwardRef((props, ref) => {
    return (
        <div className="row mr-md-2 mr-sm-0" ref={ref}>
            {props.children}
        </div>
    )
})

const WhitelabelVehicle = ({ match }) => {
    const analytics = useSelector(getAnalytics)
    const vehicles = useSelector(selectors.getVehicles)
    const whitedevDashboard = useSelector(getWhitedevDashboard)
    const [showEdit, setEdit] = useState(false)
    const [savedDash, setSavedDash] = useState(
        () =>
            JSON.parse(window.localStorage.getItem('dashCards')) || {
                sorted: [],
                hidden: [],
            }
    )
    const [dashItems, setDashItems] = useState([])
    const customValue = useSelector(selectors.getCustom)

    const primaryColor = {
        backgroundColor: customValue.primary_color,
        color: 'white',
    }
    const defaultColor = {
        backgroundColor: 'black',
        color: 'white',
    }

    const isExistFilter = (target) => (item) => target === item

    const CustomDashItems = {
        totalUVChours: {
            name: 'totalUVChours',
            component: <TotalUVChours whitedevDashboard={whitedevDashboard.data} />,
            class: 'mb-4 h-200',
            size: { sm: 6, md: 6, lg: 6, xl: 3 },
            show: true,
        },

        totalAFhours: {
            name: 'totalAFhours',
            component: <TotalAFhours whitedevDashboard={whitedevDashboard.data} />,
            class: 'mb-4 h-200',
            size: { sm: 6, md: 6, lg: 6, xl: 3 },
            show: true,
        },
        totalSShours: {
            name: 'totalSShours',
            component: <TotalSShours whitedevDashboard={whitedevDashboard.data} />,
            size: { sm: 6, md: 6, lg: 6, xl: 3 },
            class: 'mb-4 h-200',
            show: true,
        },
        totaldistancetravel: {
            name: 'totaldistancetravel',
            component: <Totaldistancetravel whitedevDashboard={whitedevDashboard.data} />,
            size: { sm: 6, md: 6, lg: 6, xl: 3 },
            class: 'mb-4 h-200',
            show: true,
        },
        totalHOCLvalue: {
            name: 'totalHOCLvalue',
            component: <TotalHOCLValue whitedevDashboard={whitedevDashboard.data} />,
            size: { sm: 6, md: 6, lg: 6, xl: 3 },
            class: 'mb-4 h-200',
            show: true,
        },
        operation: {
            name: 'operation',
            component: <OperationValue whitedevDashboard={whitedevDashboard.data} />,
            size: { sm: 6, md: 6, lg: 6, xl: 3 },
            class: 'mb-4 h-200',
            show: true,
        },
    }

    const sortAndHideDashCards = () => {
        let remainedDashItems = CustomDashItems
        const sortedItems = savedDash.sorted.reduce((acc, item) => {
            const card = remainedDashItems[item]
            if (card) {
                acc.push(card)
                delete remainedDashItems[item]
            }
            return acc
        }, [])
        sortedItems.push(...Object.values(remainedDashItems))
        const sortedAndHiddenCards = hideDashCards(sortedItems)
        setDashItems(sortedAndHiddenCards)
    }

    const hideDashCards = (cards) => {
        return cards.map((item) => {
            const isHidden = savedDash.hidden.findIndex(isExistFilter(item.name)) > -1
            return {
                ...item,
                show: !isHidden,
            }
        })
    }
    useEffect(() => {
        sortAndHideDashCards()
    }, [analytics]) // eslint-disable-line react-hooks/exhaustive-deps

    const resetLayout = () => {
        window.localStorage.removeItem('dashCards')
        setSavedDash({
            sorted: [],
            hidden: [],
        })
        sortAndHideDashCards()
    }

    const toggleCard = ({ card, index }) => {
        dashItems[index].show = !card.show
        setDashItems([...dashItems])
        handleSaveChanges()
    }

    const handleSaveChanges = () => {
        const { hidden, sorted } = dashItems.reduce(
            (acc, item) => {
                if (!item.show) acc.hidden.push(item.name)
                acc.sorted.push(item.name)
                return acc
            },
            { hidden: [], sorted: [] }
        )
        window.localStorage.setItem(
            'dashCards',
            JSON.stringify({
                hidden,
                sorted,
            })
        )
    }

    const DashBoardSettingBar = () => {
        return (
            <Fragment>
                <Row className="mb-4">
                    <Col md={6} className="heading-2">
                        {analytics.updatedAt && (
                            <>Today, {analytics.updatedAt.format('MMMM d , YYYY')}</>
                        )}
                        {analytics.status === 'loading' && <>Loading data...</>}
                    </Col>

                    <Col className="mr-auto text-right" md={5}>
                        <Button
                            id="dashboardBtnUndo"
                            style={customValue.primary_color ? primaryColor : defaultColor}
                            className="btn-circle mr-4"
                        >
                            <FontAwesomeIcon className="font-size-xl" icon={faUndo} />{' '}
                        </Button>
                        <UncontrolledTooltip placement="left" target="dashboardBtnUndo">
                            Update dashboard data
                        </UncontrolledTooltip>
                        <Button
                            id="dashboardEditBtn"
                            style={customValue.primary_color ? primaryColor : defaultColor}
                            className="btn-circle mr-4"
                            onClick={() => {
                                setEdit(!showEdit)
                            }}
                        >
                            <FontAwesomeIcon
                                className="font-size-xl"
                                icon={showEdit ? faCheckSquare : faObjectGroup}
                            />{' '}
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="dashboardEditBtn">
                            Edit Dashboard Cards
                        </UncontrolledTooltip>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col className="p-3 d-flex">
                        <div className="mr-1 pr-3 pt-2">Robots DropDown Lists</div>
                        <div>
                            <select className="dropbtn">
                                {vehicles.results.map((vehicle, key) => {
                                    return (
                                        <option key={key} value={vehicle.uuid}>
                                            {' '}
                                            {vehicle.verbose_name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        )
    }

    return (
        <>
            <DashBoardSettingBar />
            {showEdit && (
                <Row className="text-center justify-content-center mb-3 mt-3">
                    <Button onClick={resetLayout} color="warning" className="mr-3">
                        Reset Layout
                    </Button>
                    <Button
                        onClick={() => {
                            handleSaveChanges()
                            setEdit(!showEdit)
                        }}
                        color="success"
                        className="mr-3"
                    >
                        Save changes
                    </Button>
                    <UncontrolledButtonDropdown className="mr-3">
                        <DropdownToggle color="meili" caret>
                            <strong className="text-capitalize mr-4">All Cards</strong>
                        </DropdownToggle>
                        <DropdownMenu
                            className="shadow-none bg-sunrise-purple"
                            style={{ minWidth: '14rem' }}
                        >
                            {dashItems.map((card, index) => (
                                <Button
                                    color="meili"
                                    className="text-left"
                                    block
                                    onClick={() => {
                                        toggleCard({ card, index })
                                    }}
                                    key={index}
                                >
                                    <strong className="text-capitalize">{card.name}</strong>
                                    <FontAwesomeIcon
                                        className="pull-right text-white"
                                        icon={card.show ? faCheck : faEyeSlash}
                                    />
                                </Button>
                            ))}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </Row>
            )}
            <ReactSortable
                tag={CardWrapper}
                handle=".handle"
                list={dashItems}
                setList={(list) => {
                    if (list.length > 0) setDashItems(list)
                }}
                animation={150}
                onMove={() => {
                    return showEdit
                }}
            >
                {dashItems.map((card, index) => (
                    <Col
                        {...card.size}
                        key={card.name}
                        className={cx(card.class, { 'd-none': !card.show })}
                    >
                        {showEdit && (
                            <ButtonGroup className="mb-1">
                                <Button
                                    onClick={() => toggleCard({ card, index })}
                                    color="danger"
                                    className="px-2"
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button color="warning" className="handle px-3">
                                    <FontAwesomeIcon icon={faArrowsAlt} />
                                </Button>
                            </ButtonGroup>
                        )}
                        {card.component}
                    </Col>
                ))}
            </ReactSortable>
            <Row className="ml-1 mt-5">
                <div className="d-flex">
                    <h5 className="mr-1">Grafana Link for Topic Monitoring</h5>
                    <a
                        href="https://meilirobots.grafana.net/d/kyaHUKznz/arche?orgId=1"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        <FontAwesomeIcon className="font-size-xl" icon={faLink} />{' '}
                    </a>
                </div>
            </Row>
        </>
    )
}
export default WhitelabelVehicle
