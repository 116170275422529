import React from 'react'
import { Button } from 'reactstrap'

const FAQs = ({ setIsActive }) => {
    return (
        <>
            <div className="my-5 d-flex flex-column justify-content-center w-100 align-items-center">
                <span style={{ color: '#670D95' }}>FAQs</span>
                <h1>Frequently asked questions</h1>
                <span>Have questions? We are here to help.</span>
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '4rem',
                    padding: '2rem',
                }}
            >
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img src="/svgs/support-page/plus-purple.svg" alt="plus" />
                    <strong className="my-2">How do I create a new task?</strong>
                    <span>
                        You can create a task in different ways: through the API, web application,
                        or from ERP systems. You can find detailed instructions in our
                        documentation.
                    </span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img src="/svgs/support-page/calendar-purple.svg" alt="plus" />
                    <strong className="my-2">How do I create a recurring task?</strong>
                    <span>
                        Using our Mission scheduler on the dashboard, you will be given a set of
                        options such as waypoints, robot profile, and date and time. It can be done
                        in as little as 10 seconds.
                    </span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img src="/svgs/support-page/map-purple.svg" alt="plus" />
                    <strong className="my-2">How does Meili Robots do the map updates?</strong>
                    <span>
                        Editing the map is easily done in the web interface. To help with
                        transferring maps from and to robots, we have created a CLI tool which helps
                        you with that. You can upload and download maps by using one line command.
                    </span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img src="/svgs/support-page/checklist-purple.svg" alt="plus" />
                    <strong className="my-2">
                        How many tasks can I send out at the same time?
                    </strong>
                    <span>
                        Meili FMS can accept multiple tasks and queue them based on the order they
                        came in. If you would like to have a task skip to the front of the queue,
                        you can change the order by changing the task’s priority.
                    </span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img src="/svgs/support-page/clock-purple.svg" alt="plus" />
                    <strong className="my-2">How can I schedule a task?</strong>
                    <span>
                        You can schedule tasks for both one-time operations and recurring tasks.
                        These are scheduled by date, day of the week, and/or the time you would like
                        the task to be carried out.
                    </span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img src="/svgs/support-page/play-purple.svg" alt="plus" />
                    <strong className="my-2">
                        How many robots can I control at the same time?
                    </strong>
                    <span>
                        At the moment there are no limitations, however, when the fleet scales and
                        diversifies, the complexity will grow. We plan not to allow complications
                        and therefore commit to ensuring the fleet can scale heterologously.
                    </span>
                </div>
            </div>
            <div className="w-100 px-4 my-4 d-flex justify-content-center align-items-center">
                <div
                    className="rounded w-100 p-4 d-flex justify-content-between align-items-center"
                    style={{ background: '#F9FAFB', height: '10rem' }}
                >
                    <div className="d-flex flex-column">
                        <strong>Can't find the answer you're looking for?</strong>
                        <span className="mt-2">
                            Have a look at our documentation or contact us directly to chat to one
                            of our team members.
                        </span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <Button
                           className="cancel-btn-modal modals-new-btns rounded"
                            style={{ height: '2.5rem', background: 'white' }}
                        >
                            <a className="text-black" href="https://docs.meilirobots.com/">
                                Browse documentation
                            </a>
                        </Button>
                        <Button
                          className="save-btn-modal modals-new-btns rounded ml-3"
                            style={{ height: '2.5rem' }}
                            color="meili"
                            onClick={() => setIsActive('contact')}
                        >
                            Contact us
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQs