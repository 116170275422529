import React from 'react'
import { Field, Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import { Button, Form, FormGroup, Label, Modal, ModalBody } from 'reactstrap'
import { customInputForm } from 'ui/common'

const WebhookHeadersForm = ({
    modal,
    toggle,
    initialValues = {},
    handleAdd,
    handleRemove,
    handleUpdate,
}) => {
    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalBody
                style={{
                    padding: '24px 24px 0px 24px',
                    position: 'relative',
                    overflowY: 'auto',
                }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/trigger-actions/add-plus.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="mb-4">
                    <span className="org-modal-header">
                        {Object.values(initialValues).length > 0
                            ? 'Edit webhook header'
                            : 'Add webhook header'}
                    </span>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(data) => {
                        if (Object.values(initialValues).length > 0) return handleUpdate(data)
                        return handleAdd(data)
                    }}
                >
                    {({ values, handleSubmit }) => {
                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    handleSubmit()
                                }}
                            >
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <FormGroup>
                                        <Label className="modals-labels" for="key">
                                            Key
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            style={{ width: '220px' }}
                                            component={customInputForm}
                                            type="text"
                                            value={values.key || ''}
                                            name="key"
                                            placeholder="Key"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="value">
                                            Value
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            style={{ width: '220px' }}
                                            component={customInputForm}
                                            type="text"
                                            value={values.value || ''}
                                            name="value"
                                            placeholder="Value"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="new-modal-footer">
                                    {Object.values(initialValues).length === 0 ? (
                                        <div></div>
                                    ) : (
                                        <Button
                                            color="none"
                                            onClick={handleRemove}
                                            className="modals-delete-btn"
                                        >
                                            <HiOutlineTrash size={20} className="mr-1" />
                                            Delete
                                        </Button>
                                    )}
                                    <div className="d-flex">
                                        <Button
                                            className="cancel-btn-modal modals-new-btns"
                                            onClick={() => {
                                                toggle()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            style={{ marginLeft: '12px' }}
                                            type="submit"
                                        >
                                            {Object.values(initialValues).length === 0
                                                ? 'Add'
                                                : 'Save'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default WebhookHeadersForm
