
export const actionTypes = {
    SET_MESSAGES: 'SET_MESSAGES',
    CLEAR_MESSAGES: 'CLEAR_MESSAGES',
}

export function setMessages(messages) {
    return {
        type: actionTypes.SET_MESSAGES,
        messages
    }
}