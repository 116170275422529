import React, { useEffect } from 'react'
import cx from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from 'features/dashboard'
import { SidebarHeader, SidebarMenu, SidebarFooter } from 'layout-components'
import { getCustomValue } from 'features/dashboard/Organization.actionTypes'

const Sidebar = ({ children, hidden, toggle }) => {
    // const customValue = useSelector(selectors.getCustom)
    const team = useSelector(selectors.getTeam)
    const dispatch = useDispatch()

    const caprasSlugs = [
        'bosch',
        'danish-crown',
        'imap-amor',
        'firac',
        'capra-robotics',
        'plibot',
        'velux',
        'dohler',
        'bull-robotics',
        'actemium'
    ]

    // const primaryColor = {
    //     backgroundColor: customValue.primary_color,
    // }

    const defaultValues = {
        meili: {
            logo: hidden ? '/svgs/white-logo.svg' : '/svgs/white-logo-text.svg',
            backgroundColor: '#670D95',
        },
        capras: {
            logo: hidden
                ? '/svgs/temporary-capra/white-big.svg'
                : '/svgs/temporary-capra/white-no-txt.svg',
            backgroundColor: '#112A57',
        },
    }

    const showDefaultValues = () => {
        if (team.status === 'team-loading') return null
        if (
            caprasSlugs.includes(team?.details.organization.slug) ||
            window.location.href.includes('capra-app.meilirobots')
        ) {
            return {
                color: defaultValues.capras.backgroundColor,
                logo: defaultValues.capras.logo,
                marginTop: '10px',
            }
        } else {
            return {
                color: defaultValues.meili.backgroundColor,
                logo: defaultValues.meili.logo,
                marginTop: '0px',
            }
        }
    }

    useEffect(() => {
        dispatch(getCustomValue())
    }, [dispatch])

    if (team.status === 'team-loading') return null

    return (
        <>
            <div
                id="sidebar-wrapper"
                className={cx('app-sidebar d-flex flex-column justify-content-between', {
                    'app-sidebar-collapsed': hidden,
                })}
                style={{ backgroundColor: showDefaultValues().color }}
            >
                <div className="d-flex flex-column">
                    <SidebarHeader
                        hidden={hidden}
                        logo={showDefaultValues().logo}
                        marginLogo={showDefaultValues().marginTop}
                    />
                    <SidebarMenu hidden={hidden} />
                </div>
                {children}
                <SidebarFooter hidden={hidden} toggle={toggle} />
            </div>
        </>
    )
}

export default Sidebar
