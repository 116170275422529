import React, { useState } from 'react'
import { Text } from 'react-konva'
import { Modal, ModalBody, Button } from 'reactstrap'
import { Image as KonvaImage } from 'react-konva'
import useImage from 'use-image'
import { Html } from 'react-konva-utils'

const IndoorMapPoint = ({
    pointKey,
    editPoint,
    point,
    handleDragEnd,
    handleDragStart,
    largestPoint,
    aoi,
    handleSubmit,
    slug,
    icon,
    sizeText,
    setSelectedStation,
    ...props
}) => {
    const [stationDefault] = useImage('/svgs/map-layer/station-default.svg')
    const [defaultHover] = useImage('/svgs/map-layer/station-hover.svg')
    const [charging] = useImage('/svgs/map-layer/charging-station.svg')
    const [chargingHover] = useImage('/svgs/map-layer/charging-hover.svg')
    const [resting] = useImage('/svgs/map-layer/resting-station.svg')
    const [restingHover] = useImage('/svgs/map-layer/resting-hover.svg')
    const [traversal] = useImage('/svgs/map-layer/traversal-default.svg')
    const [traversalHover] = useImage('/svgs/map-layer/traversal-hover.svg')
    const [stationDisabled] = useImage('/svgs/map-layer/station-disabled.svg')
    const [chargingDisabled] = useImage('/svgs/map-layer/charging-disabled.svg')
    const [restingDisabled] = useImage('/svgs/map-layer/resting-disabled.svg')
    const [traversalDisabled] = useImage('/svgs/map-layer/traversal-disabled.svg')
    const [text, setText] = useState('')
    const [openPopover, setOpenPopover] = useState(false)
    const [highlight, sethighlight] = useState(false)
    const toggle = () => {
        setOpenPopover(!openPopover)
    }

    // function pointNameInPixels(str) {
    //     let canvas = document.createElement('canvas')
    //     let ctx = canvas.getContext('2d', { willReadtFrequently: true })
    //     let width = ctx.measureText(str).width
    //     return width
    // }

    const showImage = () => {
        switch (point.station_type) {
            case 'charging':
                if (highlight && point.is_active) return chargingHover
                if (!point.is_active) {
                    return chargingDisabled
                }
                return charging
            case 'resting':
                if (highlight && point.is_active) return restingHover
                if (!point.is_active) {
                    return restingDisabled
                }
                return resting
            case 'traversal':
                if (highlight) return traversalHover
                if (!point.is_active) {
                    return traversalDisabled
                }
                return traversal
            default:
                if (highlight && point.is_active) return defaultHover
                if (!point.is_active) {
                    return stationDisabled
                }
                return stationDefault
        }
    }

    return (
        <>
            <KonvaImage
                image={showImage()}
                width={point?.station_dimensions_in_pixels[1]}
                height={point?.station_dimensions_in_pixels[1]}
                draggable={editPoint}
                {...props}
                onDragEnd={(event) => handleDragEnd({ event, pointKey })}
                onDragStart={(e) => handleDragStart(e)}
                offsetX={point?.station_dimensions_in_pixels[1] / 2}
                offsetY={point?.station_dimensions_in_pixels[1] / 2}
                y={point.points[1]}
                x={point.points[0]}
                rotation={point.rotation}
                onMouseEnter={(e) => {
                    if (point.station_type === 'traversal' || point.station_type === 'action')
                        return
                    setText(point.title)
                    sethighlight(true)
                    const container = e.target.getStage().container()
                    editPoint
                        ? (container.style.cursor = 'move')
                        : (container.style.cursor = 'default')
                    container.style.cursor = 'pointer'
                }}
                onMouseLeave={(e) => {
                    if (point.station_type === 'traversal' || point.station_type === 'action')
                        return
                    setText('')
                    sethighlight(false)
                    const container = e.target.getStage().container()
                    container.style.cursor = 'default'
                }}
                onClick={() => {
                    if (
                        point.station_type === 'traversal' ||
                        point.station_type === 'action' ||
                        localStorage.getItem('nuke') === 'stopped'
                    )
                        return
                    if (!point.is_active) return
                    toggle()
                }}
            />

            <Text
                x={point.points[0]}
                y={point.points[1] - sizeText}
                offsetX={point?.station_dimensions_in_pixels[1] / 2}
                offsetY={point?.station_dimensions_in_pixels[1] / 2}
                stroke="#670d95"
                strokeWidth={0.1}
                text={icon ? point.title : text}
                fontStyle="bold"
                fontSize={sizeText}
            />

            <Html>
                <Modal isOpen={openPopover} toggle={toggle} centered className="new-modals">
                    <ModalBody>
                        <div
                            style={{ marginBottom: '24px' }}
                            className={`w-100 d-flex justify-content-between`}
                        >
                            <img
                                src="/svgs/task-modal/smiley-robot.svg"
                                alt="plus"
                                width="48px"
                                height="48px"
                            />

                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="x"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={toggle}
                            />
                        </div>
                        <span className="modals-little-head">Send a robot</span>
                        <br />
                        <span className="modals-description">
                            Send any robot to {point.title} or manually select a robot.
                        </span>

                        <div
                            className="d-flex align-items-center justify-content-end"
                            style={{ marginTop: '36px' }}
                        >
                            <Button
                                style={{ marginRight: '24px' }}
                                color="none"
                                className="modals-delete-btn"
                                onClick={() => {
                                    toggle()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="none"
                                className="modals-cancel-btn modals-new-btns"
                                onClick={() => {
                                    setSelectedStation(point.uuid)
                                    toggle()
                                }}
                            >
                                Select
                            </Button>
                            <Button
                                color="meili"
                                className="save-btn-modal modals-new-btns"
                                onClick={() => {
                                    handleSubmit(point.uuid)
                                    toggle()
                                }}
                            >
                                Any robot
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Html>
        </>
    )
}

export default IndoorMapPoint
