import { actionTypes } from './Simulation.actionTypes'

const initialState = {
    simulationStarted: window.localStorage.getItem('simulationStatus') || false,
}

export const SimulationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_SIMULATION_STATE:
            window.localStorage.setItem('simulationStatus', true)
            return {
                simulationStarted: action.simulationStarted,
            }
        case actionTypes.STOP_SIMULATION_STATE:
            window.localStorage.removeItem('simulationStatus')
            return {
                simulationStarted: action.simulationStarted,
            }
        default:
            return state
    }
}
