import React from 'react'
// import { Text } from 'react-konva'
import { Image as KonvaImage } from 'react-konva'
import useImage from 'use-image'

const MapEditorStations = ({
    pointKey,
    point,
    largestPoint,
    aoi,
    icon,
    switchVersion,
    editPoint,
    ...props
}) => {
    const [stationDefault] = useImage('/svgs/map-layer/station-default.svg')
    const [charging] = useImage('/svgs/map-layer/charging-station.svg')
    const [resting] = useImage('/svgs/map-layer/resting-station.svg')
    const [traversal] = useImage('/svgs/map-layer/traversal-default.svg')
    const [stationDisabled] = useImage('/svgs/map-layer/station-disabled.svg')
    const [chargingDisabled] = useImage('/svgs/map-layer/charging-disabled.svg')
    const [restingDisabled] = useImage('/svgs/map-layer/resting-disabled.svg')
    const [traversalDisabled] = useImage('/svgs/map-layer/traversal-disabled.svg')

    const showImage = () => {
        switch (point.station_type) {
            case 'charging':
                if (!point.is_active) {
                    return chargingDisabled
                }
                return charging
            case 'resting':
                if (!point.is_active) {
                    return restingDisabled
                }
                return resting
            case 'traversal':
                if (!point.is_active) {
                    return traversalDisabled
                }
                return traversal
            default:
                if (!point.is_active) {
                    return stationDisabled
                }
                return stationDefault
        }
    }

    if (editPoint && (point.station_type === 'charging' || point.station_type === 'resting')) return

    return (
        <>
            {/* <Text
                x={point.points[0]}
                y={point.points[1] + iconsize / 1.8}
                offsetX={pointNameInPixels(point.title) / 3.8}
                stroke="#670d95"
                strokeWidth={0.1}
                text={point.title}
                fontStyle={width * height < smallerMapSize ? 'normal' : 'bold'}
                fontSize={width * height < smallerMapSize ? 1.6 : size || 0}
            /> */}

            <KonvaImage
                image={showImage()}
                width={point?.station_dimensions_in_pixels[1]}
                height={point?.station_dimensions_in_pixels[1]}
                {...props}
                offsetX={point?.station_dimensions_in_pixels[1] / 2}
                offsetY={point?.station_dimensions_in_pixels[1] / 2}
                x={point.points[0]}
                y={point.points[1]}
                rotation={point.rotation}
                listening={false}
            />
        </>
    )
}

export default MapEditorStations
