import React, { useEffect, useState } from 'react'
import { Field, Form, Formik, FieldArray } from 'formik'
import { Button, Label, FormGroup, Col, Modal, ModalBody, ButtonGroup } from 'reactstrap'
import { customInputForm } from 'ui/common'

const VehicleTopicsForm = ({ modal, toggle, vehicle, handleUpdate }) => {
    const [activeTab, setActiveTab] = useState('pose')

    useEffect(() => {
        if (vehicle?.topic_overrides.length !== 0) {
            const merged = { ...ROS_CONSTANTS }
            vehicle?.topic_overrides.forEach((override) => {
                const key = override.label
                if (key in merged) {
                    merged[key] = { ...merged[key], ...override }
                }
            })
            return
        } else {
            const existedTopic = vehicle.topic_overrides.find(
                (override) => override.label === 'pose'
            )
            if (!existedTopic) {
                vehicle.topic_overrides = [
                    ...vehicle.topic_overrides,
                    {
                        label: 'pose',
                        topic: '/amcl_pose',
                        message_type: 'geometry_msgs/PoseWithCovarianceStamped',
                    },
                ]
                return
            }
        }
    }, [vehicle]) // eslint-disable-line

    const ROS_CONSTANTS = {
        pose: {
            label: 'pose',
            topic: '/amcl_pose',
            message_type: 'geometry_msgs/PoseWithCovarianceStamped',
        },
        battery: {
            label: 'battery',
            topic: '/battery_state',
            message_type: 'sensor_msgs/BatteryState',
        },
        goal: {
            label: 'goal',
            topic: '/move_base_simple/goal',
            message_type: 'geometry_msgs/PoseStamped',
        },
        goal_cancell: {
            label: 'goal_cancell',
            topic: '/move_base/cancel',
            message_type: 'actionlib_msgs/GoalID',
        },
        goalArray: {
            label: 'goalArray',
            topic: '/move_base/status',
            message_type: 'actionlib_msgs/GoalStatusArray',
        },
        goalPlan: {
            label: 'goalPlan',
            topic: '/move_base/NavfnROS/plan',
            message_type: 'nav_msgs/Path',
        },
    }

    const addTopicOverrides = ({ values, setFieldValue, topic }) => {
        const existedTopic = values.topic_overrides.find(
            (override) => override.label === topic.label
        )
        if (!existedTopic) setFieldValue('topic_overrides', [...values.topic_overrides, topic])
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="lg">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/task-modal/smiley-robot.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">Edit topics</span>
                </div>
                <Formik
                    initialValues={vehicle}
                    // validationSchema={rossetupVehicleSchema}
                    onSubmit={(data) => {
                        const topic_overrides = data.topic_overrides.filter((override) => {
                            return (
                                JSON.stringify(override) !==
                                JSON.stringify(ROS_CONSTANTS[override.label])
                            )
                        })
                        return handleUpdate({ ...data, topic_overrides }, toggle)
                    }}
                >
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <div className="modals-container-buttons">
                                    <ButtonGroup className="org-button-group">
                                        {Object.values(ROS_CONSTANTS).map((topic) => (
                                            <Button
                                                key={topic.label}
                                                className={`${
                                                    activeTab === topic.label
                                                        ? 'org-button-selected'
                                                        : ''
                                                } org-button`}
                                                style={{ borderRadius: '6px' }}
                                                onClick={() => {
                                                    addTopicOverrides({
                                                        values,
                                                        setFieldValue,
                                                        topic,
                                                    })
                                                    setActiveTab(topic.label)
                                                }}
                                            >
                                                {topic.label.charAt(0).toUpperCase() +
                                                    topic.label.slice(1)}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                                <FieldArray
                                    name="topic_overrides"
                                    render={() =>
                                        values.topic_overrides.map((topic, index) => (
                                            <div key={index}>
                                                {activeTab === topic.label && (
                                                    <FormGroup row>
                                                        <Col md={12}>
                                                            <Label className="modals-labels" for="">
                                                                Label
                                                            </Label>
                                                            <Field
                                                                component={customInputForm}
                                                                className="modals-inputs"
                                                                type="text"
                                                                value={topic.label}
                                                                name={`topic_overrides[${index}].label`}
                                                                readOnly={true}
                                                            />
                                                        </Col>
                                                        <Col md={12} className="my-2">
                                                            <Label className="modals-labels" for="">
                                                                Topic
                                                            </Label>
                                                            <Field
                                                                component={customInputForm}
                                                                className="modals-inputs"
                                                                type="text"
                                                                values={topic.topic}
                                                                name={`topic_overrides[${index}].topic`}
                                                            />
                                                        </Col>
                                                        <Col md={12}>
                                                            <Label className="modals-labels" for="">
                                                                Message type
                                                            </Label>
                                                            <Field
                                                                component={customInputForm}
                                                                className="modals-inputs"
                                                                type="text"
                                                                values={topic.message_type}
                                                                name={`topic_overrides[${index}].message_type`}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                            </div>
                                        ))
                                    }
                                />
                                <div className="new-modal-footer">
                                    <div className="d-flex justify-content-end w-100">
                                        <Button
                                            className="cancel-btn-modal modals-new-btns"
                                            onClick={toggle}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            style={{ marginLeft: '12px' }}
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default VehicleTopicsForm
