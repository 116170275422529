import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Col } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'

import { Loading } from 'ui/common'
import { selectors } from 'features/dashboard'
import { activateToken } from 'features/user/actionTypes'

const ActivateToken = () => {
    const user = useSelector(selectors.getUser)
    const dispatch = useDispatch()
    const { type, token } = useParams()
    const history = useHistory()

    useEffect(() => {
        document.title = 'Invitation'
        dispatch(
            activateToken({
                token,
                type,
            })
        ).then(({ error }) => {
            if (!error) return history.push('/login')
        })
    }, [dispatch, type, token]) // eslint-disable-line react-hooks/exhaustive-deps

    const Status = () => {
        if (user.status === 'error')
            return <h3 className="text-warning">Invalid token</h3>
        if (user.status === 'loaded')
            return <h3 className="text-success">You are now a {type} member</h3>
        return <Loading />
    }

    return (
        <Container className="d-flex mt-4 mb-4 flex-row justify-content-center">
            <Col md={6} className="m-4">
                <Status />
            </Col>
        </Container>
    )
}

export default ActivateToken
