import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader, Button } from 'reactstrap'

import {
    getNotifications,
    markAllRead,
    deleteAll,
    markRead,
    deleteReadAll,
} from 'features/dashboard/Notification.actionTypes'
import { getNotification } from 'features/dashboard/selectors'
import NotificationBody from './NotificationBody'
import Paginator from './Paginator'
import { getPaginationPages } from 'utils'

const NotificationCard = () => {
    const notification = useSelector(getNotification)
    const [currentPage, setCurrentPage] = useState(0)
    const dispatch = useDispatch()
    const [pagination, setPagination] = useState([])

    useEffect(() => {
        const pagesNumbers = getPaginationPages(currentPage, notification.items)
        setPagination([...pagesNumbers])
    }, [currentPage, notification.items])

    useEffect(() => {
        dispatch(getNotifications())
    }, [dispatch])

    const { status, items } = notification
    return (
        <Card
            className="card-box shadow-none border-0 text-dark"
            style={{
                height: '30rem',
                overflowY: notification.items.length > 0 ? 'scroll' : 'hidden',
            }}
        >
            <CardHeader className="d-flex justify-content-between align-items-center">
                <span className="pr-3"> Notifications </span>
                <div>
                    <span className="d-flex">
                        <Button
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(deleteReadAll())
                            }}
                            className="cancel-btn-modal modals-new-btns"
                            style={{
                                width: '80px',
                                height: '20px',
                                fontSize: '11px',
                                padding: '5px',
                            }}
                        >
                            Delete read
                        </Button>
                        <Button
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(deleteAll())
                            }}
                            className="cancel-btn-modal modals-new-btns mx-2"
                            style={{
                                width: '80px',
                                height: '20px',
                                fontSize: '11px',
                                padding: '5px',
                            }}
                        >
                            Delete all
                        </Button>
                        <Button
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(markAllRead())
                            }}
                            className="save-btn-modal modals-new-btns"
                            style={{
                                width: '80px',
                                height: '20px',
                                fontSize: '11px',
                                padding: '5px',
                            }}
                        >
                            Read all
                        </Button>
                    </span>
                </div>
            </CardHeader>
            <CardBody className="p-1">
                <div className="p-2">
                    <NotificationBody
                        items={items[currentPage]}
                        status={status}
                        handleRead={(id) => dispatch(markRead({ id }))}
                    />
                </div>
                {notification.items.length > 1 && (
                    <Paginator
                        totalPages={notification.items.length}
                        pages={pagination}
                        activePage={currentPage}
                        handlePageChange={(page) => setCurrentPage(page)}
                    />
                )}
            </CardBody>
        </Card>
    )
}
export default NotificationCard
