import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { customInputForm } from 'ui/common'
import {
    getChannel,
    getSetup,
    updateChannel,
    updateSetup,
} from 'features/dashboard/RosSetup.actionTypes'
import RosSetupVehDev from './RosSetupVehDev'
import DropdownSelect from 'layout-components/Dropdown'
import { removeNullFromObj } from 'utils'

const RosSetupEditModal = ({ setForm, modal, toggle, setup }) => {
    const dispatch = useDispatch()
    const communication_protocols = ['http', 'mqtt', 'websocket', 'opc_ua']
    const communication_format = ['raw', 'yaml', 'json', 'xml']
    const [tab, setTab] = useState('details')
    const [communicationProtocol, setCommunicationProtocol] = useState(
        setup?.channel?.communication_protocol || ''
    )
    const [communicationFormat, setCommunicationFormat] = useState(
        setup?.channel?.communication_format || ''
    )
    const [deviceType, setDeviceType] = useState(
        setup?.channel?.device_type[0].toUpperCase() + setup?.channel?.device_type.slice(1) || ''
    )

    const handleSubmit = (data) => {
        const { channel } = data
        dispatch(updateChannel({ uuid: channel.uuid, data: channel })).then((res) => {
            dispatch(getChannel({ uuid: channel.uuid, setupuuid: setup.uuid }))
        })
        toggle()
    }

    const handleUpdateVehTopics = (output, toggleSmall) => {
        const cleanOutput = removeNullFromObj(output)
        const { uuid } = setup
        const data = {
            ...setup,
            team: setup.team.uuid,
            vehicles: setup.vehicles.map((vehicle) => {
                if (vehicle.uuid === cleanOutput.uuid) {
                    return cleanOutput
                }
                return vehicle
            }),
        }
        const cleanData = removeNullFromObj(data)
        dispatch(updateSetup({ uuid, data: cleanData })).then(({ error }) => {
            if (!error) {
                dispatch(getSetup({ uuid }))
                toggleSmall()
                toggle()
            }
        })
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="vehicle-modal-org">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/task-modal/smiley-robot.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">Fleet setup details</span>
                    <div className="modals-container-buttons">
                        <ButtonGroup className="org-button-group">
                            <Button
                                className={`${
                                    tab === 'details' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('details')
                                }}
                            >
                                Details
                            </Button>
                            <Button
                                className={`${
                                    tab === 'channel' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('channel')
                                }}
                            >
                                Channel
                            </Button>
                            <Button
                                className={`${
                                    tab === 'vehicles' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('vehicles')
                                }}
                            >
                                {setup.vehicles.length > 0 ? 'Vehicles' : 'Device model'}
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <Formik initialValues={setup} onSubmit={handleSubmit}>
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form
                            style={{ marginTop: '14px' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            {tab === 'details' && (
                                <>
                                    {/* <FormGroup>
                                        <Label className="modals-labels" for="verbose_name">
                                            Name
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            value={values.verbose_name || ''}
                                            name="verbose_name"
                                            placeholder="Name"
                                        />
                                    </FormGroup> */}
                                    <FormGroup>
                                        <Label className="modals-labels">Pin</Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            value={values.pin || ''}
                                            name="pin"
                                            placeholder="Pin"
                                            readOnly
                                        />
                                    </FormGroup>
                                </>
                            )}
                            {tab === 'channel' && (
                                <>
                                    <FormGroup>
                                        <Label
                                            className="modals-labels"
                                            for="communication_protocol"
                                        >
                                            Communication protocol
                                        </Label>
                                        <DropdownSelect
                                            selected={communicationProtocol}
                                            setSelected={setCommunicationProtocol}
                                            options={communication_protocols.map((protocol) => {
                                                return { uuid: protocol, name: protocol }
                                            })}
                                            setFieldValue={setFieldValue}
                                            fieldValue="channel.communication_protocol"
                                        />
                                        {/* <Field
                                            component={customInputForm}
                                            className="modals-inputs"
                                            type="select"
                                            value={values.channel.communication_protocol || ''}
                                            name="channel.communication_protocol"
                                        >
                                            <option value=""></option>
                                            {communication_protocols.map((protocol) => (
                                                <option key={protocol} value={protocol}>
                                                    {protocol}
                                                </option>
                                            ))}
                                        </Field> */}
                                    </FormGroup>
                                    {values.channel.communication_protocol === 'mqtt' && (
                                        <div className="px-3">
                                            {' '}
                                            <FormGroup>
                                                <Label className="modals-labels" for="root_level">
                                                    Root level
                                                </Label>
                                                <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    type="text"
                                                    value={values.channel.root_level || ''}
                                                    name="channel.root_level"
                                                    placeholder="Root level"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label
                                                    className="modals-labels"
                                                    for="command_level"
                                                >
                                                    Command level
                                                </Label>
                                                <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    type="text"
                                                    value={values.channel.command_level || ''}
                                                    name="channel.command_level"
                                                    placeholder="Command level"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="modals-labels" for="status_level">
                                                    Status level
                                                </Label>
                                                <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    type="text"
                                                    value={values.channel.status_level || ''}
                                                    name="channel.status_level"
                                                    placeholder="Status level"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="modals-labels" for="device_type">
                                                    Device type
                                                </Label>
                                                <DropdownSelect
                                                    selected={deviceType}
                                                    setSelected={setDeviceType}
                                                    options={[
                                                        { uuid: 'iot', name: 'Iot' },
                                                        { uuid: 'robot', name: 'Robot' },
                                                    ]}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue="channel.device_type"
                                                />
                                                {/* <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    type="select"
                                                    value={values.channel.device_type || ''}
                                                    name="channel.device_type"
                                                >
                                                    <option value=""></option>
                                                    <option value="iot">Iot</option>
                                                    <option value="robot">Robot</option>
                                                </Field> */}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="modals-labels" for="client_id">
                                                    Client id
                                                </Label>
                                                <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    type="text"
                                                    value={values.channel.client_id || ''}
                                                    name="client_id"
                                                    readOnly
                                                />
                                            </FormGroup>
                                        </div>
                                    )}
                                    {values.channel.communication_protocol === 'http' && (
                                        <div className="px-3">
                                            {' '}
                                            <FormGroup>
                                                <Label className="modals-labels" for="headers">
                                                    Headers
                                                </Label>
                                                <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    type="text"
                                                    value={values.channel.headers || ''}
                                                    name="channel.headers"
                                                    placeholder="Headers"
                                                />
                                            </FormGroup>
                                        </div>
                                    )}
                                    <FormGroup>
                                        <Label className="modals-labels" for="communication_format">
                                            Communication format
                                        </Label>
                                        <DropdownSelect
                                            selected={communicationFormat}
                                            setSelected={setCommunicationFormat}
                                            options={communication_format.map((option) => {
                                                return { uuid: option, name: option }
                                            })}
                                            setFieldValue={setFieldValue}
                                            fieldValue="channel.communication_format"
                                        />
                                        {/* <Field
                                            component={customInputForm}
                                            className="modals-inputs"
                                            type="select"
                                            value={values.channel.communication_format || ''}
                                            name="channel.communication_format"
                                        >
                                            <option value=""></option>
                                            {communication_format.map((format) => (
                                                <option key={format} value={format}>
                                                    {format}
                                                </option>
                                            ))}
                                        </Field> */}
                                    </FormGroup>
                                    <FormGroup switch className="py-2">
                                        <Input
                                            style={{ height: '20px', width: '36px' }}
                                            type="switch"
                                            id="secure"
                                            role="switch"
                                            className="custom-switch-form modals-labels"
                                            checked={values.channel.secure || false}
                                            name="channel.secure"
                                            onChange={(e) => {
                                                setFieldValue(e.target.name, !values.channel.secure)
                                            }}
                                        />
                                        <Label heck className="mt-1 ml-2">
                                            Secure
                                        </Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="Password">
                                            Password
                                        </Label>
                                        <Field
                                            component={customInputForm}
                                            className="modals-inputs"
                                            type="text"
                                            value={values.channel.password || ''}
                                            name="channel.password"
                                            readOnly
                                        />
                                    </FormGroup>
                                </>
                            )}
                            {tab === 'vehicles' && (
                                <RosSetupVehDev
                                    setup={setup}
                                    handleUpdate={handleUpdateVehTopics}
                                />
                            )}
                            <div className="new-modal-footer">
                                <Button
                                    color="none"
                                    onClick={() => {
                                        setForm('remove-setup')
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete
                                </Button>

                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={toggle}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={
                                            JSON.stringify(values.channel) ===
                                            JSON.stringify(setup.channel)
                                        }
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default RosSetupEditModal
