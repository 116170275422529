import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap'
import { TITLE } from 'config'
// import LoadingSpinner from 'components/utils/LoadingSpinner'
import { getWebhooks } from 'features/dashboard/Organization.actionTypes'
import ManageOrganizationModals from './ManageOrganizationModals'
import WebhookManage from './WebhookManage'
import { deleteWebhook } from 'features/dashboard/Team.actionTypes'
import OrganizationDetails from './OrganizationDetails'

const OrganisationSettings = () => {
    const dispatch = useDispatch()
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState(null)
    const [dataOnClick, setDataOnClick] = useState({})
    const [activeTab, setActiveTab] = useState('details')
    const toggle = () => setModal(!modal)

    useEffect(() => {
        document.title = `${TITLE} - ${
            activeTab === 'details' ? 'Organisation details' : 'Webhooks'
        }`
    }, [activeTab])

    useEffect(() => {
        dispatch(getWebhooks(currentOrg))
    }, [currentOrg.slug, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modal) {
            setDataOnClick({})
            setForm(null)
        }
    }, [modal])

    const handleRemoveFromOrg = ({ uuid }) => {
        if (activeTab === 'webhooks') {
            dispatch(deleteWebhook({ uuid })).then(({ error }) => {
                if (!error) {
                    dispatch(getWebhooks(currentOrg))
                }
                toggle()
            })
        }
    }

    return (
        <div className="big-org-container">
            <div className="buttons-container-org">
                <ButtonGroup className="org-button-group">
                    <Button
                        className={`${
                            activeTab === 'details' ? 'org-button-selected' : ''
                        } org-button`}
                        style={{ borderRadius: '6px' }}
                        onClick={() => {
                            setActiveTab('details')
                        }}
                    >
                        Organisation details
                    </Button>
                    <Button
                        className={`${
                            activeTab === 'webhooks' ? 'org-button-selected' : ''
                        } org-button`}
                        style={{ borderRadius: '6px' }}
                        onClick={() => {
                            setActiveTab('webhooks')
                        }}
                    >
                        Webhooks
                    </Button>
                </ButtonGroup>
            </div>

            {activeTab === 'details' && currentOrg && (
                <OrganizationDetails organisation={currentOrg} />
            )}

            {activeTab === 'webhooks' && (
                <WebhookManage
                    setDataOnClick={setDataOnClick}
                    setForm={setForm}
                    toggle={toggle}
                    setModal={setModal}
                />
            )}

            <ManageOrganizationModals
                slug={currentOrg?.slug}
                form={form}
                modal={modal}
                toggle={toggle}
                setForm={setForm}
                dataOnClick={dataOnClick}
                submitDelete={handleRemoveFromOrg}
            />
        </div>
    )
}

export default OrganisationSettings
