import React from 'react'
import cx from 'classnames'
import { Loading } from 'ui/common'
import { UncontrolledTooltip } from 'reactstrap'

const NotificationBody = ({ items, status }) => {
    if (status === 'team-notifications-loading') return <Loading />
    if (status === 'error') return <p className="text-center text-danger">Something went wrong</p>
    if (!items) return <p className="mt-3 text-center text-info">Notification center is empty!</p>

    return items.map((item, key) => (
        <div
            className={cx('mb-1 rounded-0', {
                'read ': !item.read,
            })}
            key={item._id}
            style={{ paddingBottom: '10px' }}
        >
            <div className="d-flex justify-centent-center">
                {item.notification_type === 'system' && (
                    <>
                        <img
                            alt="system"
                            id={`system-${key}`}
                            className="mr-2 mb-4"
                            src="/svgs/dashboard-icons/settings-icon.svg"
                        />
                        <UncontrolledTooltip placement="top" target={`system-${key}`}>
                            System notification
                        </UncontrolledTooltip>
                    </>
                )}
                {item.notification_type === 'vehicle' && (
                    <>
                        <img
                            alt="vehicle"
                            id={`vehicle-${key}`}
                            className="mr-2 mb-4"
                            src="/svgs/dashboard-icons/vehicle-icon.svg"
                        />
                        <UncontrolledTooltip placement="top" target={`vehicle-${key}`}>
                            Vehicle notification
                        </UncontrolledTooltip>
                    </>
                )}
                <p
                    className={cx('unread  ', {
                        read: item.read,
                    })}
                >
                    {item.text}
                </p>
            </div>
        </div>
    ))
}

export default NotificationBody
