export const actionTypes = {
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_FAIL: 'GET_NOTIFICATIONS_FAIL',
    GET_NOTIFICATIONS_SETTING: 'GET_NOTIFICATIONS_SETTING',
    GET_NOTIFICATIONS_SETTING_SUCCESS: 'GET_NOTIFICATIONS_SETTING_SUCCESS',
    GET_NOTIFICATIONS_SETTING_FAIL: 'GET_NOTIFICATIONS_SETTING_FAIL',
    UPDATE_NOTIFICATIONS_SETTING: 'UPDATE_NOTIFICATIONS_SETTING',
    UPDATE_NOTIFICATIONS_SETTING_SUCCESS: 'UPDATE_NOTIFICATIONS_SETTING_SUCCESS',
    UPDATE_NOTIFICATIONS_SETTING_FAIL: 'UPDATE_NOTIFICATIONS_SETTING_FAIL',
    MARK_ALL_READ: 'MARK_ALL_READ',
    MARK_ALL_READ_SUCCESS: 'MARK_ALL_READ_SUCCESS',
    MARK_ALL_READ_FAIL: 'MARK_ALL_READ_FAIL',
    MARK_READ: 'MARK_READ',
    MARK_READ_SUCCESS: 'MARK_READ_SUCCESS',
    MARK_READ_FAIL: 'MARK_READ_FAIL',

    MARK_READ_BULK: 'MARK_READ_BULK',
    MARK_READ_BULK_SUCCESS: 'MARK_READ_BULK_SUCCESS',
    MARK_READ_BULK_FAIL: 'MARK_READ_BULK_FAIL',

    DELETE_ALL_READ: 'DELETE_ALL_READ',
    DELETE_ALL_READ_SUCCESS: 'DELETE_ALL_READ_SUCCESS',
    DELETE_ALL_READ_FAIL: 'DELETE_ALL_READ_FAIL',

    DELETE_ALL: 'DELETE_ALL',
    DELETE_ALL_SUCCESS: 'DELETE_ALL_SUCCESS',
    DELETE_ALL_FAIL: 'DELETE_ALL_FAIL',
    DELETE_ONE: 'DELETE_ONE',
    DELETE_ONE_SUCCESS: 'DELETE_ONE_SUCCESS',
    DELETE_ONE_FAIL: 'DELETE_ONE_FAIL',
    DELETE_BULK: 'DELETE_BULK',
    DELETE_BULK_SUCCESS: 'DELETE_BULK_SUCCESS',
    DELETE_BULK_FAIL: 'DELETE_BULK_FAIL',
    SAVE_TOKEN: 'SAVE_TOKEN',
    SAVE_TOKEN_SUCCESS: 'SAVE_TOKEN_SUCCESS',
    SAVE_TOKEN_FAIL: 'SAVE_TOKEN_FAIL',
    DELETE_TOKEN: 'DELETE_TOKEN',
    DELETE_TOKEN_SUCCESS: 'DELETE_TOKEN_SUCCESS',
    DELETE_TOKEN_FAIL: 'DELETE_TOKEN_FAIL',

    // Team notifications

    GET_TEAM_NOTIFICATIONS: 'GET_TEAM_NOTIFICATIONS',
    GET_TEAM_NOTIFICATIONS_SUCCESS: 'GET_TEAM_NOTIFICATIONS_SUCCESS',
    GET_TEAM_NOTIFICATIONS_FAIL: 'GET_TEAM_NOTIFICATIONS_FAIL',
    DELETE_ALL_TEAM_NOTIFICATIONS: 'DELETE_ALL_TEAM_NOTIFICATIONS',
    DELETE_ALL_TEAM_NOTIFICATIONS_SUCCESS: 'DELETE_ALL_TEAM_NOTIFICATIONS_SUCCESS',
    DELETE_ALL_TEAM_NOTIFICATIONS_FAIL: 'DELETE_ALL_TEAM_NOTIFICATIONS_FAIL',
    MARK_ALL_TEAM_NOTIFICATIONS_READ: 'MARK_ALL_TEAM_NOTIFICATIONS_READ',
    MARK_ALL_TEAM_NOTIFICATIONS_READ_SUCCESS: 'MARK_ALL_TEAM_NOTIFICATIONS_READ_SUCCESS',
    MARK_ALL_TEAM_NOTIFICATIONS_READ_FAIL: 'MARK_ALL_TEAM_NOTIFICATIONS_READ_FAIL',
    MARK_ONE_TEAM_NOTIFICATION: 'MARK_ONE_TEAM_NOTIFICATION',
    MARK_ONE_TEAM_NOTIFICATION_SUCCESS: 'MARK_ONE_TEAM_NOTIFICATION_SUCCESS',
    MARK_ONE_TEAM_NOTIFICATION_FAIL: 'MARK_ONE_TEAM_NOTIFICATION_FAIL',
    MARK_BULK_TEAM_NOTIFICATIONS: 'MARK_BULK_TEAM_NOTIFICATIONS',
    MARK_BULK_TEAM_NOTIFICATIONS_SUCCESS: 'MARK_BULK_TEAM_NOTIFICATIONS_SUCCESS',
    MARK_BULK_TEAM_NOTIFICATIONS_FAIL: 'MARK_BULK_TEAM_NOTIFICATIONS_FAIL',
    DELETE_ONE_TEAM_NOTIFICATION: 'DELETE_ONE_TEAM_NOTIFICATION',
    DELETE_ONE_TEAM_NOTIFICATION_SUCCESS: 'DELETE_ONE_TEAM_NOTIFICATION_SUCCESS',
    DELETE_ONE_TEAM_NOTIFICATION_FAIL: 'DELETE_ONE_TEAM_NOTIFICATION_FAIL',
    DELETE_BULK_TEAM_NOTIFICATIONS: 'DELETE_BULK_TEAM_NOTIFICATIONS',
    DELETE_BULK_TEAM_NOTIFICATIONS_SUCCESS: 'DELETE_BULK_TEAM_NOTIFICATIONS_SUCCESS',
    DELETE_BULK_TEAM_NOTIFICATIONS_FAIL: 'DELETE_BULK_TEAM_NOTIFICATIONS_FAIL',
}

export function getNotificationSettings() {
    return {
        type: actionTypes.GET_NOTIFICATIONS_SETTING,
        payload: {
            request: {
                url: `api/me/notifications/`,
                method: 'GET',
            },
        },
    }
}

export function updateNotifications(data) {
    return {
        type: actionTypes.UPDATE_NOTIFICATIONS_SETTING,
        payload: {
            request: {
                url: `api/me/notifications/`,
                method: 'PUT',
                data,
            },
        },
    }
}

export function getNotifications() {
    return {
        type: actionTypes.GET_NOTIFICATIONS,
        payload: {
            request: {
                url: `notifications/`,
                method: 'GET',
            },
        },
    }
}

export function markRead({ id }) {
    return {
        type: actionTypes.MARK_READ,
        payload: {
            request: {
                url: `notifications/${id}/`,
                method: 'POST',
            },
        },
    }
}

export function markReadBulk({ data }) {
    return {
        type: actionTypes.MARK_READ_BULK,
        payload: {
            request: {
                url: `notifications/bulk/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function markAllRead() {
    return {
        type: actionTypes.MARK_ALL_READ,
        payload: {
            request: {
                url: `notifications/`,
                method: 'POST',
            },
        },
    }
}

export function deleteOne({ id }) {
    return {
        type: actionTypes.DELETE_ONE,
        payload: {
            request: {
                url: `notifications/${id}/`,
                method: 'DELETE',
            },
        },
    }
}

export function deleteBulk({ data }) {
    return {
        type: actionTypes.DELETE_BULK,
        payload: {
            request: {
                url: `notifications/bulk/`,
                method: 'DELETE',
                data,
            },
        },
    }
}

export function deleteAll() {
    return {
        type: actionTypes.DELETE_ALL,
        payload: {
            request: {
                url: `notifications/`,
                method: 'DELETE',
            },
        },
    }
}

export function deleteReadAll() {
    return {
        type: actionTypes.DELETE_ALL_READ,
        payload: {
            request: {
                url: `notifications/read/`,
                method: 'DELETE',
            },
        },
    }
}

// Team notifications

export function getTeamNotifications({ uuid }) {
    return {
        type: actionTypes.GET_TEAM_NOTIFICATIONS,
        payload: {
            request: {
                url: `notifications/team/${uuid}/`,
                method: 'GET',
            },
        },
    }
}

export function deleteAllTeamNotifications({ uuid }) {
    return {
        type: actionTypes.DELETE_ALL_TEAM_NOTIFICATIONS,
        payload: {
            request: {
                url: `notifications/team/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function readAllTeamNotifications({ uuid }) {
    return {
        type: actionTypes.MARK_ALL_TEAM_NOTIFICATIONS_READ,
        payload: {
            request: {
                url: `notifications/team/${uuid}/`,
                method: 'POST',
            },
        },
    }
}

export function readOneTeamNotification({ teamUuid, id }) {
    return {
        type: actionTypes.MARK_ONE_TEAM_NOTIFICATION,
        payload: {
            request: {
                url: `notifications/team/${teamUuid}/${id}/`,
                method: 'POST',
            },
        },
    }
}

export function readBulkTeamNotifications({ uuid, data }) {
    return {
        type: actionTypes.MARK_BULK_TEAM_NOTIFICATIONS,
        payload: {
            request: {
                url: `notifications/team/${uuid}/bulk/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function deleteOneTeamNotification({ teamUuid, id }) {
    return {
        type: actionTypes.DELETE_ONE_TEAM_NOTIFICATION,
        payload: {
            request: {
                url: `notifications/team/${teamUuid}/${id}/`,
                method: 'DELETE',
            },
        },
    }
}

export function deleteBulkTeamNotifications({ uuid, data }) {
    return {
        type: actionTypes.DELETE_BULK_TEAM_NOTIFICATIONS,
        payload: {
            request: {
                url: `notifications/team/${uuid}/bulk/`,
                method: 'DELETE',
                data,
            },
        },
    }
}

// Token

export function deleteToken({ token }) {
    return {
        type: actionTypes.DELETE_TOKEN,
        payload: {
            request: {
                url: `notifications/tokens/${token}`,
                method: 'DELETE',
            },
        },
    }
}
