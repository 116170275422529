import { produce } from 'immer'

import { actionTypes } from './Billing.actionTypes'

const initialState = {
    status: 'loading',
    errors: {},
    plans: [],
    active: {},
    subs: [],
    invoices: [],
}

export default produce((draft, { type, payload, error }) => {
    switch (type) {
        case actionTypes.GET_ACTIVE_SUB:
            draft.status = 'loading-sub'
            draft.active = {}
            return draft
        case actionTypes.GET_SUBS:
            draft.status = 'loading-subs'
            draft.subs = []
            return draft
        case actionTypes.GET_PLANS:
            draft.status = 'loading-plans'
            draft.plans = []
            return draft
        case actionTypes.GET_ACTIVE_SUB_SUCCESS:
            draft.active = payload.data
            draft.status = 'loaded-sub'
            return draft
        case actionTypes.GET_SUBS_SUCCESS:
            draft.plans = payload.data
            draft.status = 'loaded-subs'
            return draft
        case actionTypes.GET_PLANS_SUCCESS:
            draft.plans = payload.data
            draft.status = 'loaded-plans'
            return draft
        case actionTypes.GET_INVOICES:
            draft.status = 'loading-invoices'
            draft.invoices = []
            return draft
        case actionTypes.GET_INVOICES_SUCCESS:
            draft.status = 'loaded-invoices'
            draft.invoices = payload.data
            return draft
        case actionTypes.GET_ACTIVE_SUB_FAIL:
        case actionTypes.GET_INVOICES_FAIL:
        case actionTypes.GET_PLANS_FAIL:
            draft.status = 'error'
            draft.errors = error
            return draft
        default:
            return draft
    }
}, initialState)
