import React, { useState } from 'react'
import { Arc, Arrow } from 'react-konva'
import { defaultSize } from './MapLayerHelpers'

const OriginPoint = ({
    ox,
    oy,
    editPoint,
    stageWidth,
    new_origin_x,
    new_origin_y,
    onDragEndHandle,
    onDragMoveHandle,
    scale,
}) => {
    const [isMoving, setIsMoving] = useState(false)

    // Calculate the offset for the arrows relative to the origin point
    const arrowOffset = stageWidth * 7

    return (
        <>
            <Arrow
                stroke="#670d95"
                strokeWidth={stageWidth / 1.5}
                tension={0.1}
                points={!isMoving && [ox, oy, ox, oy - arrowOffset]}
                pointerLength={defaultSize(scale)}
                pointerWidth={defaultSize(scale)}
                pointerAtBeginning={false}
            />
            <Arrow
                stroke="#BA4FFA"
                strokeWidth={stageWidth / 1.5}
                tension={0.1}
                points={!isMoving && [ox, oy, ox + arrowOffset, oy]}
                pointerLength={defaultSize(scale)}
                pointerWidth={defaultSize(scale)}
                pointerAtBeginning={false}
            />
            <Arc
                draggable={editPoint}
                angle={360}
                outerRadius={stageWidth / 1.2}
                x={ox}
                y={oy}
                fill="#D99EF7"
                onMouseOver={(e) => {
                    if (editPoint) {
                        e.target.getStage().container().style.cursor = 'move';
                    }
                }}
                onMouseOut={(e) => {
                    e.target.getStage().container().style.cursor = 'default';
                }}
                onDragStart={() => setIsMoving(true)}
                onDragMove={onDragMoveHandle}
                onDragEnd={() => {
                    onDragEndHandle(new_origin_x, new_origin_y)
                    setTimeout(() => {
                        setIsMoving(false)
                    }, [300])
                }}
            />
        </>
    )
}

export default OriginPoint
