import React from 'react'
import {
    Card,
    CardHeader,
    Button,
    Table,
    Modal,
    ModalBody,
    Alert,
    CardBody,
    Spinner,
    ModalHeader,
} from 'reactstrap'
import { faPlus, faCog } from '@fortawesome/free-solid-svg-icons'
import { Form, Formik, ErrorMessage } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createOutdoorPoint } from 'features/dashboard/Map.actionTypes'

import { CommonErrors } from 'ui/common'
import OutdoorPointForm from './OutdoorPointForm'

class OutdoorPointsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            currentPoint: {},
        }
    }

    toggleModal(point = {}) {
        this.setState((state) => ({
            ...state,
            currentPoint: point,
            modal: !state.modal,
        }))
    }

    handleSavePoint(action) {
        const { slug } = this.props.match.params
        const {
            name,
            point_type,
            getNewPointLocationData,
        } = this.props.newPoint
        action.setSubmitting(false)

        this.props
            .dispatch(
                createOutdoorPoint({
                    slug,
                    data: {
                        name,
                        point_type,
                        location_data: getNewPointLocationData(),
                    },
                })
            )
            .then(({ error }) => {
                action.setSubmitting(false)
                if (error) {
                    action.setErrors(error.response.data)
                    return
                }
                this.props.handleRemovePoint()
                this.setState({
                    modal: false,
                })
            })
    }

    NewPointComponent = ({ point }) => {
        return (
            <Formik
                onSubmit={(data, action) => {
                    this.handleSavePoint(action)
                }}
                initialValues={{}}
                enableReinitialize
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Alert color="meili" className="rounded-0">
                            Please drag the new point to the area first, and
                            press Save.
                        </Alert>
                        <Button
                            onClick={(e) => e.preventDefault()}
                            color="link btn-link-meili"
                        >
                            <span>{point.name}</span>
                        </Button>
                        <Button
                            color="warning"
                            size="sm"
                            className="py-1 px-1 mr-1 pull-right"
                            onClick={() => this.props.handleRemovePoint()}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={isSubmitting}
                            className="pull-right mr-1 py-1 px-2"
                            type="submit"
                            color="success"
                            size="sm"
                        >
                            Save
                            {isSubmitting && (
                                <Spinner size="sm" color="white ml-2" />
                            )}
                        </Button>
                        <CommonErrors />
                        <ErrorMessage name="details" />
                    </Form>
                )}
            </Formik>
        )
    }

    PointsList = () => (
        <div className="scroll-area rounded bg-white shadow-overflow">
            <PerfectScrollbar options={{ wheelPropagation: false }}>
                <div className="table-responsive">
                    <Table hover borderless className="text-nowrap mb-0">
                        <thead>
                            <tr>
                                <th className="text-left">Point Name</th>
                                <th className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.points.map((point) => {
                                return (
                                    <tr key={point.properties.uuid}>
                                        <td>{point.properties.name}</td>
                                        <td className="text-center">
                                            <Button
                                                onClick={() =>
                                                    this.toggleModal({
                                                        ...point.properties,
                                                        location_data:
                                                            point.geometry
                                                                .coordinates,
                                                    })
                                                }
                                                color="outline-warning"
                                                className="px-2"
                                                size="sm"
                                            >
                                                <FontAwesomeIcon icon={faCog} />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </PerfectScrollbar>
        </div>
    )

    render() {
        const { PointsList, NewPointComponent } = this
        const { newPoint } = this.props

        return (
            <>
                <Card className="card-box shadow-none border-0">
                    <CardHeader>
                        <strong className="mr-auto">Points</strong>
                        <Button
                            onClick={() => this.toggleModal()}
                            disabled={!!newPoint.active}
                            color="success"
                            size="sm"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </CardHeader>
                    <CardBody className="p-0">
                        {newPoint.active ? (
                            <NewPointComponent point={newPoint} />
                        ) : (
                            <PointsList />
                        )}
                    </CardBody>
                </Card>
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.toggleModal()}
                >
                    <ModalHeader toggle={() => this.toggleModal()} />
                    <ModalBody>
                        <OutdoorPointForm
                            point={this.state.currentPoint}
                            handleNewPoint={this.props.handleNewPoint}
                            toggle={() => this.toggleModal()}
                        />
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default connect()(withRouter(OutdoorPointsList))
