import React, { useState } from 'react'
import { compose } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { FormGroup, Label, Button, Input } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { selectors } from 'features/dashboard'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import {
    canRedo,
    canUndo,
    createZonesPath,
    getMapStations,
    getZonesPath,
    updateZonesPath,
} from 'features/dashboard/Map.actionTypes'
import { HiOutlineTrash } from 'react-icons/hi'
import { removeNullFromObj } from 'utils'
import DropdownSelect from 'layout-components/Dropdown'

const ZonesPathModal = ({ slug, toggle, stations, path, setWindows }) => {
    const dispatch = useDispatch()
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const handleSubmit = (data) => {
        const cleanData = removeNullFromObj(data)
        if (data.uuid) {
            const modData = cleanData.switch_direction
                ? {
                      ...cleanData,
                      source_point: cleanData.destination_point,
                      destination_point: cleanData.source_point,
                  }
                : {
                      uuid: cleanData.uuid,
                      bidirectional: cleanData.bidirectional,
                      speed_limit: cleanData.speed_limit === '' ? null : cleanData.speed_limit,
                  }
            dispatch(updateZonesPath({ uuid: data.uuid, data: modData })).then(({ error }) => {
                if (!error) {
                    dispatch(getZonesPath(data.uuid))
                    dispatch(canRedo(slug))
                    dispatch(canUndo(slug))
                    toggle()
                }
            })
        } else {
            dispatch(createZonesPath({ slug, data: cleanData })).then(({ error }) => {
                if (!error) {
                    dispatch(getMapStations(slug))
                    dispatch(canRedo(slug))
                    dispatch(canUndo(slug))
                    toggle()
                }
            })
        }
    }
    return (
        <>
            <div className="w-100 d-flex justify-content-between" style={{ marginBottom: '24px' }}>
                <img
                    src="/svgs/modal-icons/path-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <Formik
                initialValues={
                    path
                        ? {
                              uuid: path.uuid,
                              bidirectional: path.bidirectional,
                              source_point: path.source_point,
                              destination_point: path.destination_point,
                              speed_limit: path.speed_limit === -1 ? null : path.speed_limit,
                              switch_direction: false,
                          }
                        : {
                              source_point: '',
                              destination_point: '',
                              bidirectional: true,
                              speed_limit: null,
                          }
                }
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        {!path && (
                            <>
                                <FormGroup>
                                    <Label className="modals-labels">From *</Label>
                                    <DropdownSelect
                                        selected={from}
                                        setSelected={setFrom}
                                        options={stations.map((station) => station)}
                                        setFieldValue={setFieldValue}
                                        fieldValue="source_point"
                                    />
                                    {/* <Field
                                        type="select"
                                        component={customInputForm}
                                        name="source_point"
                                        className="modals-inputs"
                                        required
                                        value={values.source_point}
                                    >
                                        <option value=""></option>
                                        {stations?.map((station) => (
                                            <option value={station.uuid} key={station.uuid}>
                                                {station.title}
                                            </option>
                                        ))}
                                    </Field> */}
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels">To *</Label>
                                    <DropdownSelect
                                        selected={to}
                                        setSelected={setTo}
                                        options={stations.map((station) => station)}
                                        setFieldValue={setFieldValue}
                                        fieldValue="destination_point"
                                    />
                                    {/* <Field
                                        type="select"
                                        component={customInputForm}
                                        name="destination_point"
                                        className="modals-inputs"
                                        required
                                        value={values.destination_point}
                                    >
                                        <option value=""></option>
                                        {stations?.map((station) => (
                                            <option value={station.uuid} key={station.uuid}>
                                                {station.title}
                                            </option>
                                        ))}
                                    </Field> */}
                                </FormGroup>
                            </>
                        )}
                        <FormGroup>
                            <Label className="modals-labels">Speed limit</Label>
                            <div className="d-flex align-items-center">
                                <Field
                                    min={0}
                                    step={0.1}
                                    type="number"
                                    name="speed_limit"
                                    className="modals-inputs"
                                    value={values.speed_limit || ''}
                                    component={customInputForm}
                                />
                                <span className="modals-labels" style={{ marginLeft: '-6.2rem' }}>
                                    metres/s
                                </span>
                            </div>
                        </FormGroup>
                        <FormGroup check className="d-flex align-items-center">
                            <Input
                                type="checkbox"
                                id="bidirectional"
                                checked={values.bidirectional}
                                onChange={() => {
                                    setFieldValue('bidirectional', !values.bidirectional)
                                }}
                            />
                            <Label check className="modals-labels mt-1">
                                Bidirectional{' '}
                            </Label>
                        </FormGroup>
                        {path && !values.bidirectional && (
                            <FormGroup className="d-flex align-items-center">
                                <Input
                                    type="checkbox"
                                    id="switch_direction"
                                    checked={values?.switch_direction === false ? false : true}
                                    onChange={() => {
                                        setFieldValue('switch_direction', !values.switch_direction)
                                    }}
                                />
                                <Label check className="modals-labels">
                                    Switch direction{' '}
                                </Label>
                            </FormGroup>
                        )}

                        <div
                            className={`d-flex w-100 ${
                                path ? 'justify-content-between' : 'justify-content-end'
                            } align-items-center`}
                        >
                            {path ? (
                                <Button
                                    color="none"
                                    onClick={() => {
                                        setWindows({ removeZonesPath: true })
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete path
                                </Button>
                            ) : null}
                            <div className="d-flex">
                                <Button
                                    onClick={toggle}
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                >
                                    Cancel
                                </Button>

                                <Button type="submit" className="save-btn-modal modals-new-btns">
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

function mapStateToProps(state) {
    return {
        stations: selectors.getMapStations(state),
    }
}

export default compose(withRouter, connect(mapStateToProps))(ZonesPathModal)
