import React from 'react'
import { Container, Col } from 'reactstrap'
import error from 'assets/images/illustrations/404.svg'

const NoMatch = () => (
    <Container className="d-flex flex-row justify-content-center">
        <Col md={12}>
            <img src={error} alt="" />
        </Col>
    </Container>
)

export default NoMatch
