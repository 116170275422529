import { normalize } from 'normalizr'
import { produce, current } from 'immer'
import { tasksNormalizer } from 'schemas/normalizers'
import { actionTypes } from './Task.actionTypes'

export const initialState = {
    status: 'loading',
    presets: [],
    schedules: [],
    list: {},
    task: {},
    presetsV2: [],
    preset: {},
    actionsPresets: [],
    listV2: {},
    schedulesV2: [],
    taskDefinitions: [],
    newTask: {},
    newPresets: [],
    formValues: [],
    zonePresets: {},
}
export default produce((draft, { type, payload, error }) => {
    switch (type) {
        case actionTypes.UPDATE_PRESET_TASK_V2:
        case actionTypes.UPDATE_ACTIONS_PRESETS:
        case actionTypes.UPDATE_TASK_DEFINITIONS:
        case actionTypes.UPDATE_ZONE_PRESETS:
            draft.status = 'updating'
            return draft
        case actionTypes.GET_TASK:
            draft.status = 'loading'
            draft.task = {}
            return draft
        case actionTypes.GET_TASK_SUCCESS:
            draft.status = 'loaded'
            draft.task = payload?.data
            return draft
        case actionTypes.GET_SCHEDULE_TASKS_V2:
        case actionTypes.GET_SCHEDULE_TASKS:
            draft.status = 'loading-schedules'
            return draft
        case actionTypes.GET_TASK_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.UPDATE_PRESET_TASK_SUCCESS:
            draft.status = 'loaded'
            draft.presets = draft.presets?.map((preset) =>
                preset.uuid === payload.data.uuid ? payload.data : preset
            )
            return draft
        case actionTypes.UPDATE_PRESET_TASK_V2_SUCCESS:
            draft.status = 'loaded'
            draft.presetsV2 = {
                ...draft.presetsV2,
                results: draft.presetsV2?.results?.map((preset) =>
                    preset.uuid === payload.data.uuid ? payload.data : preset
                ),
            }
            return draft
        case actionTypes.UPDATE_ACTIONS_PRESETS_SUCCESS:
            draft.status = 'loaded'
            draft.actionsPresets = draft.actionsPresets.map((preset) =>
                preset.uuid === payload.data.uuid ? payload.data : preset
            )
            return draft
        case actionTypes.UPDATE_TASK_DEFINITIONS_SUCCESS:
            draft.status = 'loaded'
            draft.taskDefinitions = draft.taskDefinitions?.map((definition) =>
                definition.uuid === payload.data.uuid ? payload.data : definition
            )
            return draft
        case actionTypes.UPDATE_TASK_DEFINITIONS_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.DELETE_PRESET_TASK:
        case actionTypes.DELETE_PRESET_TASK_V2:
        case actionTypes.DELETE_ACTIONS_PRESETS:
            draft.status = 'loading'
            return draft
        case actionTypes.DELETE_PRESET_TASK_SUCCESS:
            draft.presets = draft.presets?.filter((preset) => preset.uuid !== payload.data.uuid)
            draft.status = 'deleted'
            return draft
        case actionTypes.DELETE_PRESET_TASK_V2_SUCCESS:
            draft.presetsV2 = {
                ...draft.presetsV2,
                results: draft.presetsV2?.results?.filter(
                    (preset) => preset.uuid !== payload.data.uuid
                ),
            }
            draft.status = 'deleted'
            return draft
        case actionTypes.DELETE_ACTIONS_PRESETS_SUCCESS:
            draft.actionsPresets = draft.actionsPresets.filter(
                (preset) => preset.uuid !== payload.data.uuid
            )
            draft.status = 'deleted'
            return draft

        case actionTypes.DELETE_TASK_V2:
            draft.status = 'loading'
            return draft
        case actionTypes.DELETE_TASK_V2_SUCCESS:
            delete draft.listV2[payload.data?.uuid]
            draft.status = 'deleted'
            return draft
        case actionTypes.CREATE_NEW_TASK:
        case actionTypes.GET_PRESET_TASKS:
        case actionTypes.RUN_PRESET_TASK:
        case actionTypes.RUN_PRESET_TASK_V2:
        case actionTypes.GET_PRESET_TASKS_V2:
        case actionTypes.GET_PRESET_V2:
        case actionTypes.GET_ACTIONS_PRESETS:
        case actionTypes.GET_ZONE_PRESETS:
            draft.status = 'loading'
            return draft
        case actionTypes.CREATE_PRESET_TASK_SUCCESS:
            draft.status = 'saved'
            draft.presets?.push(payload.data)
            return draft

        case actionTypes.CREATE_PRESET_TASK_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.GET_PRESET_TASKS_V2_SUCCESS:
            draft.status = 'loaded'
            draft.presetsV2 = {
                ...payload.data,
                results: draft.presetsV2.results
                    ? [
                          ...draft.presetsV2.results,
                          ...payload.data.results.filter(
                              (item) =>
                                  !draft.presetsV2.results.some(
                                      (existingItem) => existingItem.uuid === item.uuid
                                  )
                          ),
                      ]
                    : payload.data.results,
            }
            return draft
        case actionTypes.GET_PRESET_V2_SUCCESS:
            draft.status = 'loaded'
            draft.preset = payload.data
            return draft
        case actionTypes.GET_ACTIONS_PRESETS_SUCCESS:
            draft.status = 'loaded'
            draft.actionsPresets = payload.data
            return draft
        case actionTypes.GET_TASKS_V2:
            draft.status = 'loading'
            return draft

        case actionTypes.UPDATE_SCHEDULE_TASK_SUCCESS:
            draft.status = 'updated'
            draft.schedules = draft.schedules.map((schedule) =>
                schedule.uuid === payload.data.uuid ? payload.data : schedule
            )
            return draft
        case actionTypes.UPDATE_SCHEDULE_TASK_V2_SUCCESS:
            draft.status = 'updated'
            draft.schedulesV2 = draft.schedulesV2.map((schedule) =>
                schedule.uuid === payload.data.uuid ? payload.data : schedule
            )
            return draft
        case actionTypes.DELETE_SCHEDULE_TASK_V2_SUCCESS:
            draft.status = 'deleted'
            draft.schedulesV2 = draft.schedulesV2.filter(
                (schedule) => schedule.uuid !== payload.data.uuid
            )
            return draft
        case actionTypes.CREATE_SCHEDULE_TASK_V2_SUCCESS:
            draft.status = 'saved'
            draft.schedulesV2.push(payload.data)
            return draft
        case actionTypes.CREATE_SCHEDULE_TASK_V2_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.GET_SCHEDULE_TASKS_V2_SUCCESS:
            draft.status = 'loaded'
            draft.schedulesV2 = payload.data
            return draft
        case actionTypes.GET_TASKS_V2_SUCCESS:
            draft.status = 'loaded'
            draft.listV2 =
                normalize(
                    payload.data?.results.sort(
                        (a, b) => new Date(a.executed_at) - new Date(b.executed_at)
                    ),
                    tasksNormalizer
                ).entities.tasks || {}
            return draft
        case actionTypes.UPDATE_TASK_FROM_WEBHOOK_V2:
        case actionTypes.RUN_PRESET_TASK_V2_SUCCESS:
        case actionTypes.UPDATE_TASK_V2_SUCCESS:
        case actionTypes.CREATE_NEW_TASK_SUCCESS:
            draft.status = 'loaded'
            draft.listV2[payload.data.uuid] = payload.data
            draft.listV2 =
                normalize(
                    Object.values(current(draft.listV2)).sort(
                        (a, b) => new Date(a.executed_at) - new Date(b.executed_at)
                    ),
                    tasksNormalizer
                ).entities.tasks || {}
            return draft
        case actionTypes.CREATE_ONETIME_TASK_FAIL:
            draft.errors = error?.response.data
            draft.status = 'error'
            return draft
        case actionTypes.GET_TASK_DEFINITIONS:
            draft.status = 'loading'
            return draft
        case actionTypes.GET_TASK_DEFINITIONS_SUCCESS:
            draft.status = 'loaded'
            draft.taskDefinitions = payload.data
            return draft
        case actionTypes.GET_TASK_DEFINITIONS_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.GET_FORM_VALUES:
            draft.status = 'loading'
            return draft
        case actionTypes.UPDATE_FORM_VALUES: {
            draft.status = 'loaded'
            const newFormValues = [...draft.formValues]
            newFormValues.splice(payload.data, 1)
            draft.formValues = newFormValues
            return draft
        }
        case actionTypes.GET_FORM_VALUES_SUCCESS: {
            draft.status = 'loaded'
            const variables = payload.data.variables.map(({ variable }) => {
                const { variable_name, variable_type } = variable
                return { [variable_name]: variable_type }
            })
            if (variables.length > 1) {
                draft.formValues = [
                    ...draft.formValues,
                    variables.reduce((acc, curr) => {
                        const [key] = Object.keys(curr)
                        const value = curr[key]
                        acc[key] = value
                        return acc
                    }, {}),
                ]
            }
            if (variables.length === 1) {
                draft.formValues = [...draft.formValues, variables[0]]
            }

            return draft
        }
        case actionTypes.GET_FORM_VALUES_FAIL:
            draft.status = 'error'
            draft.formValues = [...draft.formValues, {}]
            return draft
        case actionTypes.CLEAN_FORM_VALUES:
            draft.status = 'loaded'
            draft.formValues = payload.data
            return draft
        case actionTypes.UPDATE_FORM_VALUES_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.CREATE_TASK_DEFINITIONS:
            draft.status = 'loading'
            return draft
        case actionTypes.CREATE_TASK_DEFINITIONS_SUCCESS:
            draft.status = 'created'
            draft.taskDefinitions = [...draft.taskDefinitions, payload.data]
            return draft
        case actionTypes.CREATE_TASK_DEFINITIONS_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.DELETE_TASK_DEFINITIONS:
            draft.status = 'deleting'
            return draft
        case actionTypes.DELETE_TASK_DEFINITIONS_SUCCESS:
            draft.taskDefinitions = draft.taskDefinitions.filter(
                (definition) => definition.uuid !== payload.data.uuid
            )
            draft.status = 'deleted'
            return draft
        case actionTypes.DELETE_TASK_DEFINITIONS_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.CREATE_NEW_TASK_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.CREATE_NEW_PRESET:
        case actionTypes.CREATE_ACTIONS_PRESETS:
            draft.status = 'loading'
            return draft
        case actionTypes.CREATE_NEW_PRESET_SUCCESS:
            draft.status = 'created'
            draft.presetsV2.results = [...draft.presetsV2.results, payload.data]
            return draft
        case actionTypes.CREATE_ACTIONS_PRESETS_SUCCESS:
            draft.status = 'created'
            draft.actionsPresets = [...draft.actionsPresets, payload.data]
            return draft
        case actionTypes.CREATE_NEW_PRESET_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.CREATE_ACTIONS_PRESETS_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.GET_ZONE_PRESETS_SUCCESS:
            draft.status = 'loaded'
            draft.zonePresets = payload.data
            return draft
        case actionTypes.GET_ZONE_PRESETS_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.UPDATE_ZONE_PRESETS_SUCCESS:
            draft.status = 'updated'
            draft.zonePresets = payload.data
            return draft
        case actionTypes.UPDATE_ZONE_PRESETS_FAIL:
            draft.status = 'error'
            return draft
        default:
            return draft
    }
}, initialState)
