import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Stage, Layer, Text } from 'react-konva'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { Col, CardBody, Card, Row, Button, Modal, ModalBody } from 'reactstrap'
import { withSize } from 'react-sizeme'
import { selectors } from 'features/dashboard'
import { Image as KonvaImage } from 'react-konva'
import saveSvg from '../../../assets/images/illustrations/save-icon.svg'

import {
    getMapAreaDetails,
    getMapAreaOfInterest,
    getAreaZones,
    getZonesPaths,
    getMapStations,
    getAreaMapSettings,
    updateAreaMapSettings,
} from 'features/dashboard/Map.actionTypes'
import {
    getVehicleCategories,
    updateVehiclesFromWebkook,
} from 'features/dashboard/Vehicle.actionTypes'
import { fileClient } from 'middleware/api'
import {
    createNewTask,
    getActionsPresets,
    getPresetTasksV2,
    getTaskDefinitions,
    getTasksV2,
} from 'features/dashboard/Task.actionTypes'
import IndoorMapPoint from './IndoorMapPoint'
import VehiclePointer from './IndoorVehiclePoint'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import MapObstacle from './MapObstacle'
import RenderZonesPaths from './RenderZonesPaths'
import { MiniMapButtons } from './MiniMapButtons'
import { handleWheel } from './helpers'
import { getDevices, updateDevicesFromWebhook } from 'features/dashboard/RosSetup.actionTypes'
import IndoorMapDevices from './IndoorMapDevices'

const IndoorMiniMap = ({
    getRobot,
    actions,
    sidebar,
    icon,
    setIcon,
    lastJsonMessage,
    taskHovered,
    collapse,
}) => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const map = useSelector(selectors.getTeamMap)
    const areaSettings = useSelector(selectors.getTeamMap).areaSettings
    const stations = useSelector(selectors.getMapStations)
    const devices = useSelector(selectors.getDevicess)
    const vehicles = useSelector(selectors.getVehicles)
    const [image, setImage] = useState(null)
    const [status, setStatus] = useState('loading')
    const [selectStation, setSelectedStation] = useState(null)
    const [stageRef, setStageRef] = useState(null)
    const [robotHovered, setRobotHovered] = useState(false)
    const [sizeText, setSizeText] = useState(14 / stageRef?.scaleX() || 0)
    const [hidePaths, setHidePaths] = useState(!areaSettings?.show_paths || false)
    const [showTagZonesNames, setShowTagZonesNames] = useState(
        areaSettings?.show_tag_zones_names || false
    )
    const [showVehicleNames, setShowVehicleNames] = useState(
        areaSettings?.show_vehicles_names || false
    )

    const [messageReceived, setMessageReceived] = useState(true)
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)
    // const [oneIsActive, setOneVehicleActive] = useState(false)

    const [sizes, setSize] = useState({
        width: 1,
        height: 600,
        ratio: 4,
        scale: 0.5,
    })
    const [pos, setPos] = useState('')

    const isEveryValueFalse = Object.values(collapse).every((value) => value === false)
    const biggerMapSize = 1000000
    const colors = {}

    const winRef = useRef(null)
    const { height, width, x, y } = map?.aoi
    const { original_height, original_width, uuid } = map?.areaDetails
    const largestPoint = original_height > original_width ? original_height : original_width
    const ratio = width / height

    useEffect(() => {
        setIcon(areaSettings?.show_stations_names)
        setHidePaths(!areaSettings?.show_paths)
        setShowTagZonesNames(areaSettings?.show_tag_zones_names)
        setShowVehicleNames(areaSettings?.show_vehicle_names)
    }, [areaSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (status !== 'image-error') {
            setSize({
                width: winRef.current.offsetWidth,
                height: winRef.current.offsetWidth / ratio,
                largestPoint,
                scale: areaSettings.zoom
                    ? areaSettings.zoom
                    : (winRef.current?.offsetWidth - window.innerWidth / 2.5) / width,
                x: areaSettings.center_position
                    ? areaSettings.center_position[0]
                    : -x * sizes?.scale + window.innerWidth / numberSidebar || 0,
                y: areaSettings.center_position
                    ? areaSettings.center_position[1]
                    : -y * sizes?.scale || 0,
            })
        }
    }, [map, winRef, areaSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        Promise.all([
            fileClient.get(`/indoors/team/${slug}/gen.svg`),
            dispatch(getMapAreaDetails(slug)),
            dispatch(getMapAreaOfInterest(slug)),
            dispatch(getAreaZones({ slug, type: 'action' })),
            dispatch(getAreaZones({ slug, type: 'forbidden' })),
            dispatch(getAreaZones({ slug, type: 'charging' })),
            dispatch(getAreaZones({ slug, type: 'resting' })),
            dispatch(getAreaZones({ slug, type: 'tag' })),
            dispatch(getAreaMapSettings({ uuid })),
            dispatch(getZonesPaths(slug)),
            dispatch(getMapStations(slug)),
            dispatch(getDevices({ slug })),
        ])
            .then(([imageRes]) => {
                setStatus('loading')
                const reader = new window.FileReader()
                reader.readAsDataURL(imageRes.data)
                reader.onload = () => {
                    const image = new window.Image()
                    image.src = reader.result
                    setImage(image)
                    setStatus('loaded')
                }
            })
            .catch(() => {
                setStatus('image-error')
            })
    }, [slug, winRef]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     // Check if at least one vehicle is active
    //     const hasActive = vehicles.teamVehicle.some((veh) => veh.is_online)
    //     setOneVehicleActive(hasActive)
    // }, [vehicles])

    // This calls are set to be called after the map is loaded and mostly are used for task sidebar.
    useEffect(() => {
        if (status === 'loaded') {
            dispatch(getActionsPresets(slug))
            dispatch(getTaskDefinitions(slug))
            dispatch(getPresetTasksV2({ slug, page: 1 }))
            dispatch(getVehicleCategories({ slug }))
        }
    }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastJsonMessage && lastJsonMessage.vehicles) {
            dispatch(updateVehiclesFromWebkook(lastJsonMessage.vehicles))
            setMessageReceived(true)
        }
        if (lastJsonMessage && lastJsonMessage.devices) {
            dispatch(updateDevicesFromWebhook(lastJsonMessage.devices))
            setMessageReceived(true)
        }
    }, [dispatch, lastJsonMessage])

    useEffect(() => {
        const interval = setInterval(() => {
            setMessageReceived(false)
        }, 30000)

        return () => clearInterval(interval)
    }, [lastJsonMessage])

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            setSelectedStation(false)
        }
    }, [])

    const handleDragEnd = (e) => {
        if (e.target._lastPos) {
            const { x, y } = e.target._lastPos

            setSize((prevSize) => ({
                ...prevSize,
                x,
                y,
            }))
        } else {
            const { x, y } = e.target.attrs

            setSize((prevSize) => ({
                ...prevSize,
                x: sizes.x + x,
                y: sizes.y + y,
            }))
        }

    }

    const handleSave = () => {
        const data = {
            center_position: [sizes.x.toFixed(5), sizes.y.toFixed(5)],
            zoom: sizes.scale.toFixed(5),
            width: sizes.width,
        }

        dispatch(updateAreaMapSettings({ uuid, data })).then(({ error }) => {
            if (!error) {
                dispatch(getAreaMapSettings({ uuid }))
                toggle()
            }
        })
    }

    const onStageRefUpdate = (node) => {
        if (node && !stageRef) {
            setStageRef(node)
        }
    }

    const handlePointSubmit = (point) => {
        const data = { subtasks: [{ action: { point, definition: actions[0].uuid } }] }
        dispatch(createNewTask({ slug, data })).then((res) => {
            dispatch(getTasksV2(slug))
        })
    }

    const handlePointSubmitSelect = (point, vehiclePoint) => {
        const data = {
            subtasks: [{ action: { point, definition: actions[0].uuid } }],
            vehicle_uuid: vehiclePoint,
        }
        return dispatch(createNewTask({ slug, data })).then((res) => {
            dispatch(getTasksV2(slug))
            setSelectedStation(null)
        })
    }

    const handleMouse = (e) => {
        const stage = stageRef
        setSizeText(14 / stage?.scaleX())
        const mousePointTo = getCurrentMousePointer(stage)
        const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
        setPos(pos)
    }

    const getCurrentMousePointer = (stage) => {
        if (!stage.children[0].attrs.x && !stage.children[0].attrs.y) {
            return {
                x: (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX(),
                y: (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY(),
            }
        }
        return {
            x:
                (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX() -
                stage.children[0].attrs.x,
            y:
                (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY() -
                stage.children[0].attrs.y,
        }
    }

    const numberSidebar = sidebar ? 18 : 4.5
    const vehicle = vehicles.teamVehicle?.map((vehicle) => vehicle.is_online)
    return (
        <Row className="m-0 p-0 w-100">
            <Col>
                <div ref={winRef}>
                    {status !== 'image-error' && (
                        <Card className="card-box shadow-none border-0">
                            {status === 'loading' ? (
                                <div style={{ marginTop: '10rem' }}>
                                    <LoadingSpinner />
                                </div>
                            ) : (
                                <CardBody className="m-0 p-0 d-flex justify-content-center">
                                    <Stage
                                        md={7}
                                        width={sizes.width || 400}
                                        height={sizes.height || 600}
                                        scaleX={sizes.scale}
                                        scaleY={sizes.scale}
                                        x={sizes.x}
                                        y={sizes.y}
                                        offsetY={isEveryValueFalse ? -30 : 0} // check this if it's needed for improvement
                                        onWheel={(e) => {
                                            const isScrollingDown = e.evt.deltaY > 0
                                            handleWheel(
                                                e,
                                                stageRef,
                                                isScrollingDown,
                                                sizes,
                                                setSize
                                            )
                                            setSizeText(14 / stageRef?.scaleX())
                                        }}
                                        ref={(node) => onStageRefUpdate(node)}
                                        onMouseMove={handleMouse}
                                        onDragMove={handleDragEnd}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <Layer draggable offsetX={0} offsetY={0}>
                                            <KonvaImage
                                                width={original_width}
                                                height={original_height}
                                                image={image}
                                            />

                                            {status !== 'loading'
                                                ? map.zones.tag?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone?.uuid}
                                                          zone={zone}
                                                          size={sizeText}
                                                          showTagZonesNames={showTagZonesNames}
                                                      />
                                                  ))
                                                : null}

                                            {status !== 'loading'
                                                ? map.zones.charging?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone?.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}
                                            {status !== 'loading'
                                                ? map.zones.resting?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone?.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}
                                            {status === 'loaded' &&
                                                !hidePaths &&
                                                map.zonesPaths.map((path) => (
                                                    <RenderZonesPaths
                                                        key={path?.uuid}
                                                        path={path}
                                                        scale={sizes.scale}
                                                        slug={slug}
                                                        onlyView={true}
                                                        aoi={map.aoi}
                                                    />
                                                ))}
                                            {status !== 'loading'
                                                ? map.zones.action?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}

                                            {devices &&
                                                status === 'loaded' &&
                                                devices.map((device) => (
                                                    <IndoorMapDevices
                                                        key={device?.uuid}
                                                        device={device}
                                                        aoi={map.aoi}
                                                    />
                                                ))}

                                            {status === 'loaded' &&
                                                stations.map((point) => (
                                                    <IndoorMapPoint
                                                        slug={slug}
                                                        icon={icon}
                                                        aoi={map.aoi}
                                                        draggable={false}
                                                        point={point}
                                                        key={point?.uuid}
                                                        sizeText={sizeText}
                                                        handleSubmit={handlePointSubmit}
                                                        largestPoint={sizes.largestPoint}
                                                        setSelectedStation={setSelectedStation}
                                                    />
                                                ))}

                                            {status !== 'loading'
                                                ? map.zones.forbidden?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone?.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}

                                            {messageReceived &&
                                                vehicles.teamVehicle?.map(
                                                    ({ details, ...vehicle }) => (
                                                        <VehiclePointer
                                                            key={vehicle?.uuid}
                                                            stage={stageRef}
                                                            fill={colors[vehicle?.uuid]}
                                                            details={details}
                                                            vehicle={vehicle}
                                                            sizeText={sizeText}
                                                            selectStation={selectStation}
                                                            getRobot={getRobot}
                                                            biggerMapSize={biggerMapSize}
                                                            taskHovered={taskHovered}
                                                            setRobotHovered={setRobotHovered}
                                                            handlePointSubmitSelect={
                                                                handlePointSubmitSelect
                                                            }
                                                            showVehicleNames={showVehicleNames}
                                                        />
                                                    )
                                                )}
                                            {selectStation && !robotHovered ? (
                                                <Text
                                                    x={pos[0]}
                                                    y={pos[1] / 1.2}
                                                    align={'center'}
                                                    text="Pick a robot"
                                                    fontSize={sizeText}
                                                    fontStyle="bold"
                                                    stroke="#670d95"
                                                    strokeWidth={0.1}
                                                ></Text>
                                            ) : (
                                                ''
                                            )}
                                        </Layer>
                                    </Stage>
                                    {status !== 'loading' && (
                                        <MiniMapButtons
                                            areaUuid={uuid}
                                            toggle={toggle}
                                            icon={icon}
                                            setIcon={setIcon}
                                            hidePaths={hidePaths}
                                            setHidePaths={setHidePaths}
                                            sizes={sizes}
                                            setSize={setSize}
                                            vehicle={vehicle}
                                            sidebar={sidebar}
                                            stageRef={stageRef}
                                            setSizeText={setSizeText}
                                            showTagZonesNames={showTagZonesNames}
                                            setShowTagZonesNames={setShowTagZonesNames}
                                            showVehicleNames={showVehicleNames}
                                            setShowVehicleNames={setShowVehicleNames}
                                        />
                                    )}
                                </CardBody>
                            )}
                        </Card>
                    )}
                </div>

                <Modal isOpen={modal} toggle={toggle} centered className="new-modals">
                    <ModalBody>
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{ marginBottom: '24px' }}
                        >
                            <img alt="save" width="48px" height="48px" src={saveSvg}></img>
                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="obstacle-icon"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={toggle}
                            />
                        </div>
                        Are you sure you want to save this display preferences? This will be default
                        view for this map.
                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100 justify-content-between align-items-center`}
                        >
                            <Button
                                className="cancel-btn-modal modals-new-btns w-50 mr-2"
                                onClick={toggle}
                            >
                                No
                            </Button>
                            <Button
                                className="save-btn-modal modals-new-btns w-50"
                                onClick={handleSave}
                            >
                                Yes
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                {status === 'image-error' && <Redirect to={`/dashboard/${slug}/maps/upload/`} />}
            </Col>
        </Row>
    )
}

export default withSize({ monitorHeight: true })(IndoorMiniMap)
