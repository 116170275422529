export const actionTypes = {
    GET_PLANS: 'GET_PLANS',
    GET_PLANS_FAIL: 'GET_PLANS_FAIL',
    GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
    GET_SUBS: 'GET_SUBS',
    GET_SUBS_FAIL: 'GET_SUBS_FAIL',
    GET_SUBS_SUCCESS: 'GET_SUBS_SUCCESS',
    GET_INVOICES: 'GET_INVOICES',
    GET_INVOICES_FAIL: 'GET_INVOICES_FAIL',
    GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
    GET_ACTIVE_SUB: 'GET_ACTIVE_SUB',
    GET_ACTIVE_SUB_FAIL: 'GET_ACTIVE_SUB_FAIL',
    GET_ACTIVE_SUB_SUCCESS: 'GET_ACTIVE_SUB_SUCCESS',
}

export function getPlans() {
    return {
        type: actionTypes.GET_PLANS,
        payload: {
            client: 'guest',
            request: {
                url: `api/billing/plans/`,
                method: 'GET',
            },
        },
    }
}

export function getSubs({ slug }) {
    return {
        type: actionTypes.GET_SUBS,
        payload: {
            request: {
                url: `api/organizations/${slug}/subscriptions/`,
                method: 'GET',
            },
        },
    }
}

export function getActiveSub({ slug }) {
    return {
        type: actionTypes.GET_ACTIVE_SUB,
        payload: {
            request: {
                url: `api/organizations/${slug}/subscriptions/active/`,
                method: 'GET',
            },
        },
    }
}

export function getInvoices({ slug }) {
    return {
        type: actionTypes.GET_INVOICES,
        payload: {
            request: {
                url: `api/organizations/${slug}/invoices/`,
                method: 'GET',
            },
        },
    }
}
