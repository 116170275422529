import * as Yup from 'yup'

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
    return this.test('unique', message, function (list) {
        return list.length === new Set(list.map(mapper)).size
    })
})

Yup.addMethod(Yup.array, 'notSameLast', function (message) {
    return this.test('notSameToLast', message, function (list) {
        return JSON.stringify(list[list.length - 1]) !== JSON.stringify(list[list.length - 2])
    })
})

export const orgSchema = Yup.object().shape({
    uuid: Yup.string(),
    title: Yup.string().required('Required'),
    default_email: Yup.string().email('Invalid email').required('Required'),
})

export const supportSchema = Yup.object().shape({
    subject: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
})

export const newMissionSchema = Yup.object().shape({
    by: Yup.string(),
    week_days: Yup.array().when('by', {
        is: 'week_days',
        then: Yup.array().required('Required'),
        otherwise: Yup.array().notRequired().nullable(),
    }),
    date: Yup.date()
        .nullable()
        .when('by', {
            is: 'date',
            then: Yup.date().required('Required'),
            otherwise: Yup.date().notRequired().nullable(),
        }),
    preset: Yup.string().required('Required'),
    enabled: Yup.bool().notRequired(),
    name: Yup.string().notRequired(),
    time: Yup.string().required('Required'),
})

export const teamSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
})
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

export const fileSchema = Yup.object().shape({
    image: Yup.mixed()
        .nullable()
        .required('A file is required')
        .test('File size', 'upload file', (value) => !value || (value && value.size <= 1024 * 1024))
        .test(
            'format',
            'upload file',
            (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        ),
})

export const topicScheam = Yup.object().shape({
    topic: Yup.string()
        .required('Required')
        .matches(/^\/\S+$/, {
            message: 'The topic should start with the "/" eg. "/mytopic"',
        }),
    message_type: Yup.string().required('Required'),
})

export const outdoorPointSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    point_type: Yup.string().required('Type is required'),
})
export const pointSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    point_type: Yup.string().required('Type is required'),
    rotation: Yup.number()
        .integer('Only number is allowed')
        .positive('Rotation shoule be positive')
        .lessThan(360, 'Rotation should not be more than 360')
        .min(0)
        .required('Required'),
})

export const outdoorPathSchema = Yup.object().shape({
    points_uuids: Yup.array().of(
        Yup.string().required('Required'),
        Yup.string().required('Required')
    ),
})

export const pathSchema = Yup.object().shape({
    destination_points: Yup.array().of(
        Yup.string().required('Required'),
        Yup.string().required('Required')
    ),
})

export const oneTimeTaskSchema = Yup.object().shape({
    task_number: Yup.string().notRequired(),
    task_note: Yup.string().notRequired('Required'),
    preset: Yup.lazy(() =>
        Yup.string().when('preset', {
            is: (val) => val?.length === 0,
            otherwise: () => Yup.string().max(0, 'Only one of preset or task can be selected'),
            then: () => Yup.string().required(),
        })
    ),
    point: Yup.lazy(() =>
        Yup.array().when('point', {
            is: (val) => val === '' || val === undefined,
            otherwise: () =>
                Yup.array().notRequired().max(0, 'Only one of preset or task can be selected'),
            then: () => Yup.array().required(),
        })
    ),
})

export const webhookSchema = Yup.object().shape({
    url: Yup.string().required('Required!'),
    headers: Yup.array()
        .of(
            Yup.object({
                key: Yup.string().notRequired(),
                value: Yup.string().notRequired(),
            })
        )
        .notRequired(),
    action_list: Yup.array().notRequired(),
    active: Yup.boolean().notRequired(),
})

export const presetTaskSchema = Yup.object().shape({
    uuid: Yup.string(),
    title: Yup.string().required('Required'),
    point: Yup.array().required('Required'),
})

export const variableValueSchema = Yup.object({
    entries: Yup.array().of(
        Yup.object({
            variable_name: Yup.string().notRequired(),
            value: Yup.string().notRequired(),
        })
    ),
})

export const categorySchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    max_load: Yup.number().notRequired().nullable(),
    dimensions: Yup.array().of(Yup.number('Only number is allowed')).notRequired().nullable(),
    container_dimensions: Yup.array(Yup.number('Only number is allowed'))
        .notRequired()
        .min(0)
        .max(3)
        .nullable(),
    vehicle_type: Yup.string().notRequired().nullable(),
    extra_details: Yup.string().notRequired().nullable(),
})

export const vehicleSchema = Yup.object().shape({
    external_identifier: Yup.string().notRequired().nullable(),
    verbose_name: Yup.string().notRequired(),
    active: Yup.boolean(),
})

export const rossetupSchema = Yup.object().shape({
    verbose_name: Yup.string().notRequired(),
    vehicles: Yup.array()
        .of(
            Yup.object().shape({
                uuid: Yup.string().required('Required'),
                prefix: Yup.string()
                    .notRequired()
                    .matches(
                        /^(\/\S+|(\b25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b)$/,
                        {
                            message: 'The prefix should start with the "/" eg. "/robot1"',
                        }
                    ),
                topic_overrides: Yup.array()
                    .notRequired()
                    .of(
                        Yup.object().shape({
                            label: Yup.string().required('Required'),
                            topic: Yup.string()
                                .required('Required')
                                .matches(/^\/\S+$/, {
                                    message: 'The topic should start with the "/" eg. "/robot1"',
                                }),
                            message_type: Yup.string().required('Message type is required'),
                        })
                    ),
            })
        )
        .unique('Duplicated vehicle!', (a) => a.uuid)
        .required('Vehicle is required'),
})

export const triggerSchema = Yup.object().shape({
    verbose_name: Yup.string().notRequired(),
    Point: Yup.string(),
    task_preset: Yup.string(),
    use_http: Yup.boolean(),
    use_mqtt: Yup.boolean(),
    use_token_authentication: Yup.boolean(),
    action: Yup.string(),
})

export const rossetupVehicleSchema = Yup.object().shape({
    uuid: Yup.string().required('Required'),
    prefix: Yup.string()
        .notRequired()
        .matches(
            /^(\/\S+|(\b25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b)$/,
            {
                message: 'The prefix should start with the "/" eg. "/robot1"',
            }
        ),
    topic_overrides: Yup.array()
        .notRequired()
        .of(
            Yup.object().shape({
                label: Yup.string().required('Required'),
                topic: Yup.string()
                    .required('Required')
                    .matches(/^\/\S+$/, {
                        message: 'The topic should start with the "/" eg. "/robot1"',
                    }),
                message_type: Yup.string().required('Vehicle is Required'),
            })
        ),
})
