import React, { useEffect, useState } from 'react'
import { Button, UncontrolledTooltip, Popover } from 'reactstrap'
import { renderDifferentActions } from './MapEditorHelpers'
import { handleZoomBtns } from './helpers'
import EyeShow from 'layout-components/Eye/EyeShow'

export const TopButtons = ({
    setWindows,
    toggleModal,
    icon,
    setIcon,
    setEditpoint,
    hidePaths,
    setHidePaths,
    canRedoState,
    canUndoState,
    handleUndo,
    handleRedo,
    showTagZonesNames,
    setShowTagZonesNames,
}) => {
    const [buttonStates, setButtonStates] = useState({
        isSave: { hovered: false, clicked: false },
        isReset: { hovered: false, clicked: false },
        undo: { hovered: false, clicked: false },
        redo: { hovered: false, clicked: false },
        isEyeHovered: false,
    })
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)

    const handleButtonState = ({ button, eventType, value }) => {
        if (button === 'isEyeHovered') {
            setButtonStates({
                ...buttonStates,
                [button]: value,
            })
        } else {
            setButtonStates({
                ...buttonStates,
                [button]: { ...buttonStates[button], [eventType]: value },
            })
        }
    }

    const handleButtonEvent = ({ button, eventType, value }) => {
        return () => {
            handleButtonState({ button, eventType, value })
        }
    }

    const buttonClass = 'p-0 iconBoader'

    return (
        <div className="d-flex" style={{ position: 'fixed', top: '10rem', right: '1rem' }}>
            <Button
                size="sm"
                id="save-btn"
                className={buttonClass}
                onClick={() => {
                    setWindows({ save: true })
                    setEditpoint(false)
                    toggleModal()
                }}
                onMouseDown={handleButtonEvent({
                    button: 'isSave',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'isSave',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'isSave',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'isSave',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.isSave.hovered,
                    '/svgs/map-editor/save-hover.svg',
                    buttonStates.isSave.clicked,
                    '/svgs/map-editor/save-clicked.svg',
                    '/svgs/map-editor/save.svg',
                    'save'
                )}
            </Button>
            <UncontrolledTooltip placement="bottom" target="save-btn" className="tooltip-style">
                Save current changes
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="reset-btn"
                className={`${buttonClass} mx-1`}
                onClick={() => {
                    setWindows({ reset: true })
                    setEditpoint(false)
                    toggleModal()
                }}
                onMouseDown={handleButtonEvent({
                    button: 'isReset',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'isReset',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'isReset',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'isReset',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.isReset.hovered,
                    '/svgs/map-editor/reset-hover.svg',
                    buttonStates.isReset.clicked,
                    '/svgs/map-editor/reset-clicked.svg',
                    '/svgs/map-editor/reset.svg',
                    'reset'
                )}
            </Button>
            <UncontrolledTooltip placement="bottom" target="reset-btn" className="tooltip-style">
                Reset all current changes
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="undo-btn"
                className="p-0 iconBoader"
                onClick={handleUndo}
                disabled={!canUndoState}
                onMouseDown={handleButtonEvent({
                    button: 'undo',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'undo',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'undo',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'undo',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.undo.hovered,
                    '/svgs/map-editor/undo-hover.svg',
                    buttonStates.undo.clicked,
                    '/svgs/map-editor/undo-clicked.svg',
                    '/svgs/map-editor/undo.svg',
                    'undo'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="undo-btn"
                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
            >
                Undo
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="redo-btn"
                className="p-0 mx-1 iconBoader"
                onClick={handleRedo}
                disabled={!canRedoState}
                onMouseDown={handleButtonEvent({
                    button: 'redo',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'redo',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'redo',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'redo',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.redo.hovered,
                    '/svgs/map-editor/redo-hover.svg',
                    buttonStates.redo.clicked,
                    '/svgs/map-editor/redo-clicked.svg',
                    '/svgs/map-editor/redo.svg',
                    'redo'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="redo-btn"
                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
            >
                Redo
            </UncontrolledTooltip>

            <EyeShow
                icon={icon}
                setIcon={setIcon}
                hidePaths={hidePaths}
                setHidePaths={setHidePaths}
                buttonState={buttonStates}
                place="map_layer"
                toggle={dropdownToggle}
                dropdownOpen={dropdownOpen}
                handleButtonEvent={handleButtonEvent}
                showTagZonesNames={showTagZonesNames}
                setShowTagZonesNames={setShowTagZonesNames}
            />
        </div>
    )
}

export const RightButtons = ({
    setIcon,
    editPoint,
    setEditpoint,
    setWindows,
    windows,
    toggleModal,
    setNewPoints,
    handleNewPoint,
    popover,
    setPopover,
    pathPopover,
    setPathPopover,
    stageRef,
    setSizeText,
}) => {
    const [buttonStates, setButtonStates] = useState({
        zoomIn: { hovered: false, clicked: false },
        zoomOut: { hovered: false, clicked: false },
        hidePaths: { hovered: false, clicked: false },
        drag: { hovered: false },
        station: { hovered: false, clicked: false },
        path: { hovered: false, clicked: false },
        device: { hovered: false, clicked: false },
    })

    const handleButtonState = (button, eventType, value) => {
        setButtonStates({
            ...buttonStates,
            [button]: { ...buttonStates[button], [eventType]: value },
        })
    }

    const handleButtonEvent = (button, eventType, value) => {
        return () => {
            handleButtonState(button, eventType, value)
        }
    }

    useEffect(() => {
        if (!popover) {
            handleButtonState('station', 'clicked', false)
        }
    }, [popover]) // eslint-disable-line

    useEffect(() => {
        if (!pathPopover) {
            handleButtonState('path', 'clicked', false)
        }
    }, [pathPopover]) // eslint-disable-line

    return (
        <div
            className="d-flex flex-column"
            style={{ position: 'fixed', top: '12.5rem', right: '1rem' }}
        >
            <Button
                size="sm"
                id="zoomin-btn"
                className="mb-2 p-0 iconBoader"
                onClick={() => {
                    handleZoomBtns(stageRef, true)
                    setSizeText(14 / stageRef.scaleX())
                }}
                onMouseDown={handleButtonEvent('zoomIn', 'clicked', true)}
                onMouseUp={handleButtonEvent('zoomIn', 'clicked', false)}
                onMouseEnter={handleButtonEvent('zoomIn', 'hovered', true)}
                onMouseLeave={handleButtonEvent('zoomIn', 'hovered', false)}
            >
                {renderDifferentActions(
                    buttonStates.zoomIn.hovered,
                    '/svgs/map-editor/zoom-in-hover.svg',
                    buttonStates.zoomIn.clicked,
                    '/svgs/map-editor/zoom-in-clicked.svg',
                    '/svgs/map-editor/zoom-in.svg',
                    'zoom-in'
                )}
            </Button>

            <UncontrolledTooltip placement="auto" target="zoomin-btn" className="tooltip-style">
                Zoom in
                <br />
                (CMD/Ctrl + scroll up)
            </UncontrolledTooltip>
            <Button
                size="sm"
                id="zoomout-btn"
                className="mb-2 p-0 iconBoader"
                onClick={() => {
                    handleZoomBtns(stageRef, false)
                    setSizeText(14 / stageRef.scaleX())
                }}
                onMouseDown={handleButtonEvent('zoomOut', 'clicked', true)}
                onMouseUp={handleButtonEvent('zoomOut', 'clicked', false)}
                onMouseEnter={handleButtonEvent('zoomOut', 'hovered', true)}
                onMouseLeave={handleButtonEvent('zoomOut', 'hovered', false)}
            >
                {renderDifferentActions(
                    buttonStates.zoomOut.hovered,
                    '/svgs/map-editor/zoom-out-hover.svg',
                    buttonStates.zoomOut.clicked,
                    '/svgs/map-editor/zoom-out-clicked.svg',
                    '/svgs/map-editor/zoom-out.svg',
                    'zoom-out'
                )}
            </Button>

            <UncontrolledTooltip placement="auto" target="zoomout-btn" className="tooltip-style">
                Zoom out
                <br />
                (CMD/Ctrl + scroll down)
            </UncontrolledTooltip>
            <Button
                size="sm"
                id="draggable"
                className="p-0 mb-2 iconBoader"
                onClick={() => {
                    setEditpoint(!editPoint)
                    setWindows({ editStation: !editPoint })
                }}
                onMouseEnter={handleButtonEvent('drag', 'hovered', true)}
                onMouseLeave={handleButtonEvent('drag', 'hovered', false)}
            >
                {renderDifferentActions(
                    buttonStates.drag.hovered,
                    '/svgs/map-editor/drag-hover.svg',
                    editPoint,
                    '/svgs/map-editor/drag-selected.svg',
                    '/svgs/map-editor/drag.svg',
                    'drag'
                )}
                <UncontrolledTooltip placement="auto" target="draggable" className="tooltip-style">
                    {editPoint ? 'Disable draggable points' : 'Enable draggable points'}
                </UncontrolledTooltip>
            </Button>
            <Button
                size="sm"
                id="create-device"
                className="p-0 mb-2 iconBoader"
                onClick={() => {
                    setWindows({ createDevice: !windows.createDevice })
                    toggleModal()
                }}
                onMouseDown={handleButtonEvent('device', 'clicked', true)}
                onMouseUp={handleButtonEvent('device', 'clicked', false)}
                onMouseEnter={handleButtonEvent('device', 'hovered', true)}
                onMouseLeave={handleButtonEvent('device', 'hovered', false)}
            >
                {renderDifferentActions(
                    buttonStates.device.hovered,
                    '/svgs/map-layer/device-btn-hover.svg',
                    buttonStates.device.clicked,
                    '/svgs/map-layer/device-btn-selected.svg',
                    '/svgs/map-layer/device-btn-default.svg',
                    'station'
                )}
            </Button>
            <UncontrolledTooltip placement="left" target="create-device" className="tooltip-style">
                Create device
            </UncontrolledTooltip>
            <Button
                size="sm"
                id="create-point"
                className="p-0 mb-2 iconBoader"
                onClick={() => handleButtonState('station', 'clicked', true)}
                onMouseEnter={handleButtonEvent('station', 'hovered', true)}
                onMouseLeave={handleButtonEvent('station', 'hovered', false)}
            >
                {renderDifferentActions(
                    buttonStates.station.hovered,
                    '/svgs/map-editor/station-hover.svg',
                    buttonStates.station.clicked,
                    '/svgs/map-editor/station-selected.svg',
                    '/svgs/map-editor/station.svg',
                    'station'
                )}
            </Button>
            <UncontrolledTooltip placement="left" target="create-point" className="tooltip-style">
                Create station
            </UncontrolledTooltip>
            <Popover
                placement="left"
                isOpen={popover}
                target="create-point"
                toggle={() => {
                    setPopover(!popover)
                    setPathPopover(false)
                }}
                className="popoverBorder"
            >
                <div className="pointPopver">
                    <span
                        className="statioList"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setNewPoints(true)
                            handleNewPoint({})
                            setPopover(!popover)
                            handleButtonState('station', 'clicked', false)
                        }}
                    >
                        Quick station
                    </span>

                    <span
                        className="statioList"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setWindows({ createStation: !windows.createStation })
                            setPopover(!popover)
                            handleButtonState('station', 'clicked', false)
                            toggleModal()
                        }}
                    >
                        Advanced station
                    </span>
                </div>
            </Popover>

            <Button
                id="create-path"
                className="mb-2 p-0 iconBoader"
                onClick={() => handleButtonState('path', 'clicked', true)}
                onMouseEnter={handleButtonEvent('path', 'hovered', true)}
                onMouseLeave={handleButtonEvent('path', 'hovered', false)}
            >
                {renderDifferentActions(
                    buttonStates.path.hovered,
                    '/svgs/map-editor/path-hover.svg',
                    buttonStates.path.clicked,
                    '/svgs/map-editor/path-selected.svg',
                    '/svgs/map-editor/path.svg',
                    'path'
                )}
            </Button>
            <UncontrolledTooltip placement="left" target="create-path" className="tooltip-style">
                Create path
            </UncontrolledTooltip>

            <Popover
                placement="left"
                isOpen={pathPopover}
                target="create-path"
                toggle={() => {
                    setPathPopover(!pathPopover)
                    setPopover(false)
                }}
                className="popoverBorder"
            >
                <div className="pointPopver">
                    <span
                        className="statioList"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setWindows({ manualPath: !windows.manualPath })
                            setPathPopover(!pathPopover)
                            handleButtonState('path', 'clicked', false)
                            setEditpoint(false)
                        }}
                    >
                        Quick path
                    </span>

                    <span
                        className="statioList"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setWindows({ zonesPath: !windows.zonesPath })
                            setPathPopover(!pathPopover)
                            handleButtonState('path', 'clicked', false)
                            setEditpoint(false)
                            setIcon(true)
                            toggleModal()
                        }}
                    >
                        Advanced path
                    </span>
                </div>
            </Popover>
        </div>
    )
}
