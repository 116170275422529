import React from 'react'
import { Button } from 'reactstrap'
import saveSvg from '../../../assets/images/illustrations/save-icon.svg'
import warningSvg from '../../../assets/images/illustrations/warning-triangle.svg'

const MapModal = ({ toggle, saveMap, resetMap, status, isOriginalImage, slug }) => {
    return (
        <>
            <div className="w-100 d-flex justify-content-between" style={{ marginBottom: '24px' }}>
                <img
                    alt="save"
                    width="48px"
                    height="48px"
                    src={status === 'save' ? saveSvg : warningSvg}
                ></img>
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            {status === 'save'
                ? 'Are you sure you want to save this map?'
                : `Are you sure you want to reset this map? ${
                      slug
                          ? isOriginalImage
                              ? 'This will reset the map to its original state.'
                              : 'This will clear all zones from the map.'
                          : 'This will clear all paths and stations from the map.'
                  } `}

            <div
                style={{ marginTop: '24px' }}
                className={`d-flex w-100 justify-content-between align-items-center`}
            >
                <Button
                    className="prompt-modal-footer-decline-button"
                    onClick={() => {
                        isOriginalImage
                            ? (window.location.href = `/dashboard/${slug}/maps/modify/`)
                            : toggle()
                    }}
                >
                    No
                </Button>
                <Button
                    className="prompt-modal-footer-accept-button"
                    onClick={() => {
                        status === 'save' ? saveMap() : resetMap()
                        toggle()
                    }}
                >
                    Yes
                </Button>
            </div>
        </>
    )
}
export default MapModal
