import React from 'react'
import { Alert } from 'reactstrap'

export const AlertSwitcher = ({ condition, text }) => {
    switch (condition) {
        case 'updated':
            return (
                <Alert color="success" className="text-center mx-auto notification position-fixed">
                    {text} Updated Successfully
                </Alert>
            )
        case 'created':
            return (
                <Alert color="success" className="text-center mx-auto notification position-fixed">
                    {text} Created Successfully
                </Alert>
            )
        case 'deleted':
            return (
                <Alert color="success" className="text-center mx-auto notification position-fixed">
                    {text} Deleted Successfully
                </Alert>
            )
        case 'createdError':
            return (
                <Alert color="danger" className="text-center mx-auto notification position-fixed">
                    {text} Creation Error
                </Alert>
            )
        case 'updateError':
            return (
                <Alert color="danger" className="text-center mx-auto notification position-fixed">
                    {text} Update Error
                </Alert>
            )
        default:
            return
    }
}

export const pointNameInPixels = (str) => {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d', { willReadFrequently: true })
    let width = ctx.measureText(str).width
    return width
}

export const calculateArrowPositions = (points, interval) => {
    const arrowPositions = []

    for (let i = 0; i < points.length - 1; i++) {
        const [x1, y1] = points[i]
        const [x2, y2] = points[i + 1]
        const dx = x2 - x1
        const dy = y2 - y1
        const distance = Math.sqrt(dx * dx + dy * dy)
        let arrowCount
        if (distance < 10) {
            i = i + 2
            arrowCount = 2
        } else arrowCount = Math.floor(distance / interval)

        const angle = Math.atan2(dy, dx)

        for (let j = 1; j < arrowCount; j++) {
            const t = j / arrowCount
            const x = x1 + dx * t
            const y = y1 + dy * t
            arrowPositions.push({ x, y, angle })
        }
    }

    return arrowPositions
}

const zoneAreaM = (arr) => {
    const zoneArea = arr[0] * arr[1]
    return zoneArea
}

export const stationIconSize = (station, width) => {
    const icon_proportion = 0.035
    if (station.station_type === 'action') return (icon_proportion * width) / 1.6
    if (station?.station_type === 'resting' || station?.station_type === 'charging') {
        if (station?.zone_information?.zone_dimensions_in_pixels) {
            const zoneArea = zoneAreaM(station?.zone_information.zone_dimensions_in_pixels)
            const new_width = zoneArea
            if (icon_proportion * new_width * 0.5 < 5)
                return icon_proportion * new_width * 0.5 * 3.2
            if (icon_proportion * new_width * 0.5 > 20) return icon_proportion * width
            return icon_proportion * new_width * 0.5
        }
        return icon_proportion * width
    }
    return icon_proportion * width
}

export const defaultSize = (scale) => {
    const inverseScale = 1 / scale
    return ((window.innerWidth * window.innerHeight) / inverseScale) * 0.00000014
}

export const pathDefaultSize = (width, height, scale) => {
    return {
        defaultPathSize:
            scale > 1
                ? defaultSize(15 / scale)
                : width * height > 20000000
                ? defaultSize((scale * width * height) / 80000)
                : defaultSize((scale * width * height) / 150000),
        defaultArrowSize:
            scale > 1
                ? defaultSize(20 / scale)
                : width * height > 20000000
                ? defaultSize((scale * width * height) / 200000)
                : defaultSize((scale * width * height) / 500000),
    }
}
