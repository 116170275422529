import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CardBody, Button } from 'reactstrap'
import { addDashboardCard, getDashboardCard } from 'features/dashboard/TeamAnalytics.actionTypes'
import { MdAdd, MdDownloadDone } from 'react-icons/md'
import { getallCard, getSavedViewData } from 'features/dashboard/selectors'

const AddCard = ({ slug, uuid, addToggle, setAddcard, dots }) => {
    const dispatch = useDispatch()
    const savedViewCards = useSelector(getSavedViewData)
    const cardList = useSelector(getallCard)
    const [list, setList] = useState([])

    const idList = savedViewCards?.map((view) => view.uuid)

    useEffect(() => {
        setList(idList)
    }, [savedViewCards]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CardBody className="">
            <div
                style={{ marginBottom: '24px' }}
                className={`w-100 d-flex justify-content-between`}
            >
                <img
                    src="/svgs/trigger-actions/add-plus.svg"
                    alt="add-card"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        addToggle()
                        setAddcard(false)
                    }}
                />
            </div>
            <div className="d-flex justify-content-between" style={{ marginBottom: '24px' }}>
                <span className="view-setting grey-700 weight-500 font-16">Add Cards</span>
                <div className="d-flex justify-content-center align-items-center">
                    <Button
                        onClick={() => {
                            addToggle()
                            setAddcard(false)
                        }}
                        className="modals-cancel-btn"
                        color="none"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="font-14 modals-save-btn"
                        color="meili"
                        onClick={() => {
                            dispatch(getDashboardCard({ slug, uuid }))
                            addToggle()
                            setAddcard(false)
                        }}
                    >
                        Add
                    </Button>
                </div>
            </div>

            <>
                {cardList.length > 0 ? (
                    Object.values(cardList)?.map((item) => (
                        <div key={item.uuid}>
                            {!item.is_hidden && item.title !== 'Notifications' && (
                                <div className="dash-cards-setting w-100 d-flex justify-content-between">
                                    <div key={item}>
                                        <img
                                            src={dots}
                                            alt="dots"
                                            className="ml-1 btn btn-icon add-icon"
                                        ></img>
                                        <span style={{ marginLeft: '4px' }} className="dash-title">
                                            {item.title}
                                        </span>
                                    </div>

                                    <span
                                        onClick={() => {
                                            const data = {
                                                location: list.length + 1,
                                                card_preset: item.uuid,
                                            }
                                            dispatch(addDashboardCard({ slug, uuid, data }))
                                            setList([...list, item.uuid])
                                        }}
                                    >
                                        {list.includes(item.uuid) ? (
                                            <MdDownloadDone size={20} className="btn btn-icon" />
                                        ) : (
                                            <MdAdd size={20} className="btn btn-icon" />
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p className="mt-3 text-center text-info">No more cards to add!</p>
                )}
            </>
        </CardBody>
    )
}

export default AddCard
