import { selectors } from 'features/dashboard'
import { changePassword } from 'features/user/actionTypes'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, FormGroup, Label, Spinner } from 'reactstrap'
import { PasswordUpdateSchema } from 'schemas/user'
import { customInputForm } from 'ui/common'

const Password = () => {
    const dispatch = useDispatch()
    const user = useSelector(selectors.getUser)
    const [showOldPass, setShowOldPass] = useState(false)
    const [showNewPass, setShowNewPass] = useState(false)
    const [showRepeatPass, setShowRepeatPass] = useState(false)

    const handleSubmit = ({ data, setErrors, resetForm }) => {
        dispatch(changePassword({ data })).then(({ error }) => {
            if (error) {
                setErrors(error.response.data)
            } else {
                resetForm()
            }
        })
    }

    return (
        <div style={{ paddingRight: '32px' }}>
            <div className="mt-5">
                <h4>Password</h4>
                <span>Please enter your current password to update your password.</span>
            </div>
            <hr />
            <Formik
                initialValues={{
                    old_password: '',
                    new_password: '',
                    password_confirm: '',
                }}
                validationSchema={PasswordUpdateSchema}
                onSubmit={(data, { setErrors, resetForm }) => {
                    handleSubmit({ data, setErrors, resetForm })
                }}
            >
                {({ values, isSubmitting, handleSubmit }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ width: '70%' }}
                        >
                            <Label for="old_password" style={{ fontWeight: 'bold' }}>
                                Current password
                            </Label>
                            <div className="d-flex justify-content-center align-items-center mt-2">
                                <FormGroup className="d-flex align-items-center">
                                    <Field
                                        id="old_password"
                                        component={customInputForm}
                                        type={showOldPass ? 'text' : 'password'}
                                        name="old_password"
                                        placeholder="Current password"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.old_password || ''}
                                    />
                                    <img
                                        src={
                                            showOldPass
                                                ? '/svgs/user-icon/eyeOn.svg'
                                                : '/svgs/user-icon/eyeOff.svg'
                                        }
                                        alt="eye"
                                        style={{ marginLeft: '-2rem', cursor: 'pointer' }}
                                        onClick={() => setShowOldPass(!showOldPass)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ width: '70%' }}
                        >
                            <Label for="new-password" style={{ fontWeight: 'bold' }}>
                                New password
                            </Label>
                            <div className="d-flex flex-column justify-content-center align-items-start mt-2">
                                <FormGroup className="d-flex align-items-center">
                                    <Field
                                        id="new-password"
                                        autocomplete="new-password"
                                        name="new_password"
                                        type={showNewPass ? 'text' : 'password'}
                                        placeholder="Create a new password"
                                        component={customInputForm}
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.new_password || ''}
                                    />
                                    <img
                                        src={
                                            showNewPass
                                                ? '/svgs/user-icon/eyeOn.svg'
                                                : '/svgs/user-icon/eyeOff.svg'
                                        }
                                        alt="eye"
                                        style={{ marginLeft: '-2rem', cursor: 'pointer' }}
                                        onClick={() => setShowNewPass(!showNewPass)}
                                    />
                                </FormGroup>
                                <span
                                    style={{
                                        marginTop: '-15px',
                                        lineHeight: '20px',
                                        fontSize: '14px',
                                    }}
                                >
                                    Your new password must be at least 8 characters.
                                </span>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ width: '70%' }}
                        >
                            <Label for="password_confirm" style={{ fontWeight: 'bold' }}>
                                Repeat new password
                            </Label>
                            <div className="d-flex justify-content-center align-items-center mt-2">
                                <FormGroup className="d-flex align-items-center">
                                    <Field
                                        id="password_confirm"
                                        type={showRepeatPass ? 'text' : 'password'}
                                        name="password_confirm"
                                        component={customInputForm}
                                        placeholder="Repeat new password"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.password_confirm || ''}
                                    />
                                    <img
                                        src={
                                            showRepeatPass
                                                ? '/svgs/user-icon/eyeOn.svg'
                                                : '/svgs/user-icon/eyeOff.svg'
                                        }
                                        alt="eye"
                                        style={{ marginLeft: '-2rem', cursor: 'pointer' }}
                                        onClick={() => setShowRepeatPass(!showRepeatPass)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-end mr-3 my-4">
                            <Button
                                type="submit"
                                className="save-btn-modal modals-new-btns"
                                style={{ height: '2.5rem', width: '5rem' }}
                                disabled={
                                    user.status === 'loading' ||
                                    JSON.stringify({
                                        old_password: '',
                                        new_password: '',
                                        password_confirm: '',
                                    }) === JSON.stringify(values)
                                }
                            >
                                Save
                                {isSubmitting && <Spinner size="sm" color="white ml-2" />}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Password
