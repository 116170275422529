import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Loading } from 'ui/common'
import { getProfile } from 'features/user/actionTypes'
import DashboardRoutes from 'routes/DashboardRoutes'
import ErrorBoundary from 'components/ErrorBoundary'
import { Redirect } from 'react-router-dom'
import ErrorPage from 'components/InfoPage'

const DashboardRoutesWithAuthCheck = () => {
    const dispatch = useDispatch()
    const [status, setStatus] = useState('loading')

    useEffect(() => {
        dispatch(getProfile()).then(({ error }) => {
            if (error && !error.response) return setStatus('server-error')
            if (error && error.response.status === 401)
                return setStatus('error')
            return setStatus('loaded')
        })
    }, [dispatch])

    if (status === 'loaded')
        return (
            <ErrorBoundary>
                <DashboardRoutes />
            </ErrorBoundary>
        )
    if (status === 'error') return <Redirect to="/login" push />
    if (status === 'server-error')
        return <ErrorPage message="Can not access server!" />
    return <Loading />
}

export default DashboardRoutesWithAuthCheck
