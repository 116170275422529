import React from 'react'
import useImage from 'use-image'
import { Image as KonvaImage } from 'react-konva'
import { stationIconSize } from './MapLayerHelpers'

const MapEditorDevices = ({ device, aoi }) => {
    const [deviceDefault] = useImage('/svgs/map-layer/device.svg')
    const [closedDoorDefault] = useImage('/svgs/map-layer/closed-door.svg')
    const [switchOnDefault] = useImage('/svgs/map-layer/switch.svg')
    const [elevatorDefault] = useImage('/svgs/map-layer/elevator.svg')
    const [trafficLightRedDefault] = useImage('/svgs/map-layer/traffic-light-red.svg')
    const { width, height } = aoi
    const biggerMapSize = 1000000

    const showImage = () => {
        switch (device.model_type) {
            case 'door':
                return closedDoorDefault
            case 'switch':
                return switchOnDefault
            case 'elevator':
                return elevatorDefault
            case 'traffic_light':
                return trafficLightRedDefault
            default:
                return deviceDefault
        }
    }

    if (!device) return
    if (!device.points) return

    return (
        <>
            <KonvaImage
                image={showImage()}
                width={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                height={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                offsetX={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                offsetY={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                x={device.points[0]}
                y={device.points[1]}
            />
        </>
    )
}

export default MapEditorDevices
