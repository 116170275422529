import React from 'react'
import { Button, Spinner, Alert } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useDispatch } from 'react-redux'

import { CommonErrors } from 'ui/common'
import { formDispatchHandler } from 'utils'

const OutdoorMapSaveButton = ({ getMapData, saveAction }) => {
    const dispatch = useDispatch()
    const { slug } = useParams()

    const handleSaveMap = formDispatchHandler(dispatch, saveAction)

    return (
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(_, action) => {
                handleSaveMap({ data: getMapData(), slug }, action)
            }}
        >
            {({ isSubmitting, status }) => (
                <Form>
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        color="meili mr-3"
                        size="lg"
                    >
                        Save
                        {isSubmitting && <Spinner size="sm" className="ml-3" />}
                    </Button>

                    {status === 'saved' && (
                        <Alert
                            className="col-4 mt-2 text-center ml-auto"
                            color="meili"
                        >
                            Map updated successfully.
                        </Alert>
                    )}
                    {status === 'error' && (
                        <Alert
                            className="col-4 mt-2 text-center ml-auto"
                            color="danger"
                        >
                            <p>Something went Wrong.</p>
                            <CommonErrors
                                extraFields={[
                                    'areas',
                                    'figures',
                                    'points',
                                    'title',
                                ]}
                            />
                        </Alert>
                    )}
                </Form>
            )}
        </Formik>
    )
}

export default OutdoorMapSaveButton
