import React from 'react'
import Login from './users/Login'

const Home = () => {
    //const history = useHistory()

    return (
        <Login/>
    )
}

export default Home
