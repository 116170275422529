import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { Button, FormGroup, Label, Container, Spinner, Alert } from 'reactstrap'
import { CommonErrors } from 'ui/common'
import { signup, resendEmailByClick } from 'features/user/actionTypes'
import { UserSchema } from 'schemas/user'
import StepProgressBar from './StepProgressBar'
import { getAllAdminOrgAndTeam, getOrgs } from 'features/dashboard/Organization.actionTypes'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const SignupByInvation = () => {
    const { type, token } = useParams()
    const dispatch = useDispatch()
    const [signupStatus, setSignupStatus] = useState('started')
    const [values, setValues] = useState('')
    const history = useHistory()
    const [emailId, setEmailId] = useState('')
    const [resend, setResend] = useState(false)
    const [progressStep, setProgreeStep] = useState(0)
    const [filledColor, setFilledColor] = useState(0)
    const invitationType = useMemo(() => {
        if (type === 'organizations') return 'org_token'
        if (type === 'teams') return 'team_token'
        if (type === 'selfboarding') return 'invitation_token'
        return
    }, [type])

    const getStarted = (data, { setErrors }) => {
        if (!invitationType) return setErrors({ non_field_errors: 'Token is not valid' })
        setValues(data)
        setProgreeStep(1)
        setFilledColor(33)
        setSignupStatus('accountDetails')
    }

    const createAccount = (data, { setErrors, setSubmitting }) => {
        if (!invitationType) return setErrors({ non_field_errors: 'Token is not valid' })
        const payload = {
            ...data,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,

            [invitationType]: token,
        }

        dispatch(signup({ data: payload })).then(({ error }) => {
            setSubmitting(false)
            if (!error) {
                setSignupStatus('emailSent')
                setEmailId(values.email)
                setFilledColor(66)
                setProgreeStep(2)
                localStorage.setItem('orgtoken', token)
                localStorage.setItem('invitationtype', invitationType)
                return
            }
            const { data } = error.response
            setErrors(data)
        })
    }
    useEffect(() => {
        setTimeout(() => {
            resend && setResend(false)
        }, 2000)
    }, [resend])

    useEffect(() => {
        dispatch(getAllAdminOrgAndTeam())
        dispatch(getOrgs())
        document.title = 'Invitation'
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="d-flex flex-column">
            <Container
                className="d-flex flex-column"
                style={{ width: '390px', marginBottom: '24px' }}
            >
                {resend && <Alert color="success">A verification email has been resent </Alert>}
                {signupStatus === 'started' && (
                    <>
                        <div className="pb-32 d-flex flex-column justify-centent-center align-items-center">
                            <img
                                className="mt-2 pr-2"
                                alt="key"
                                src="/svgs/user-icon/meili-logo.svg"
                            />
                            <h2 className="size-32 text-black pt-32"> Create an account</h2>
                            <span className="size-16 grey-600 text-center">Add your details.</span>
                        </div>
                        <Formik
                            initialValues={{}}
                            validationSchema={UserSchema}
                            onSubmit={getStarted}
                        >
                            {({ values, isSubmitting }) => (
                                <Form>
                                    <FormGroup>
                                        <Label
                                            className="required size-14 weight-400"
                                            for="first_name"
                                        >
                                            First name
                                        </Label>
                                        <Field
                                            className="inputbox-border"
                                            style={{
                                                width: '340px',
                                                height: '40px',
                                                paddingLeft: '12px',
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            type="text"
                                            name="first_name"
                                            placeholder="Enter your first name"
                                            value={values.first_name || ''}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label
                                            className="required size-14 weight-400"
                                            for="last_name"
                                        >
                                            Last name
                                        </Label>
                                        <Field
                                            className="inputbox-border"
                                            style={{
                                                width: '340px',
                                                height: '40px',
                                                paddingLeft: '12px',
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            type="text"
                                            name="last_name"
                                            placeholder="Enter your last name"
                                            value={values.last_name || ''}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="required size-14 weight-400" for="email">
                                            Email
                                        </Label>
                                        <Field
                                            className="inputbox-border"
                                            style={{
                                                width: '340px',
                                                height: '40px',
                                                paddingLeft: '12px',
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            type="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            value={values.email || ''}
                                        />
                                    </FormGroup>
                                    <Button
                                        block={true}
                                        color="meili"
                                        disabled={isSubmitting}
                                        type="submit"
                                        style={{ width: '340px', height: '40px' }}
                                        className="login-border mt-32"
                                    >
                                        Get started
                                        {isSubmitting && <Spinner size="sm" color="white ml-3" />}
                                    </Button>

                                    <p className="size-14 text-black weight-400 text-center pt-4 mr-1">
                                        Already have an account?
                                        <span
                                            className="text-meili weight-500"
                                            style={{ cursor: 'pointer', lineHeight: '28px' }}
                                            onClick={() => history.push('/login/')}
                                        >
                                            {' '}
                                            Log in
                                        </span>
                                    </p>
                                    <CommonErrors extraFields={['org_token', 'team_token']} />
                                </Form>
                            )}
                        </Formik>
                    </>
                )}

                {signupStatus === 'accountDetails' && (
                    <>
                        <div className="pb-32 d-flex flex-column justify-centent-center align-items-center">
                            <img
                                className="mt-2 pr-2"
                                alt="key"
                                src="/svgs/user-icon/meili-logo.svg"
                            />
                            <h2 className="size-32 text-black pt-32"> Account details</h2>
                            <span className="size-16 grey-600 text-center">
                                Password must be at least 8 characters.
                            </span>
                        </div>
                        <Formik initialValues={{}} onSubmit={createAccount}>
                            {({ values, isSubmitting }) => (
                                <Form>
                                    <FormGroup>
                                        <Label
                                            className="required size-14 weight-400"
                                            for="username"
                                        >
                                            Username
                                        </Label>
                                        <Field
                                            className="inputbox-border"
                                            style={{
                                                width: '340px',
                                                height: '40px',
                                                paddingLeft: '12px',
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            type="text"
                                            name="username"
                                            placeholder="Choose username"
                                            value={values.username || ''}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label
                                            className="required size-14 weight-400"
                                            for="password"
                                        >
                                            Password
                                        </Label>
                                        <Field
                                            className="inputbox-border"
                                            style={{
                                                width: '340px',
                                                height: '40px',
                                                paddingLeft: '12px',
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            type="password"
                                            name="password"
                                            placeholder="Choose password"
                                            value={values.password || ''}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label
                                            className="required size-14 weight-400"
                                            for="Confirm password"
                                        >
                                            Confirm password
                                        </Label>
                                        <Field
                                            className="inputbox-border"
                                            style={{
                                                width: '340px',
                                                height: '40px',
                                                paddingLeft: '12px',
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            type="password"
                                            name="password_confirm"
                                            placeholder="Password confirm"
                                            value={values.password_confirm || ''}
                                        />
                                    </FormGroup>

                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        color="meili"
                                        block={true}
                                        style={{ width: '340px', height: '40px' }}
                                        className="login-border mt-32"
                                    >
                                        continue
                                        {isSubmitting && <Spinner size="sm" color="white ml-3" />}
                                    </Button>
                                    <div
                                        className="grey-600 text-center logout-border mr-2  pt-4 size-14 weight-500"
                                        onClick={() => {
                                            setSignupStatus('started')
                                            setProgreeStep(0)
                                        }}
                                        style={{ width: '340px' }}
                                    >
                                        <img
                                            src="/svgs/user-icon/arrow.svg"
                                            style={{ cursor: 'pointer', paddingRight: '8px' }}
                                            alt="previous"
                                        ></img>
                                        <span style={{ cursor: 'pointer', lineHeight: '28px' }}>
                                            Previous step
                                        </span>
                                    </div>

                                    <CommonErrors extraFields={['org_token', 'team_token']} />
                                </Form>
                            )}
                        </Formik>
                    </>
                )}

                {signupStatus === 'emailSent' && (
                    <div className="pb-32 pt-32 d-flex flex-column justify-content-center align-items-center">
                        <img className="mt-2 pr-2" alt="key" src="/svgs/user-icon/email.svg" />
                        <h2 className="size-32 text-black pt-32 pb-2">Check your email</h2>
                        <span className="size-16 grey-600 pt-2 text-center">
                            {' '}
                            We have sent an account activation link to {emailId}
                        </span>

                        <small className="size-14 grey-600 mt-3 text-center weight-500">
                            Didn’t receive the email?{' '}
                            <span
                                className="text-meili"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    const data = { email: emailId }
                                    dispatch(resendEmailByClick({ data }))
                                    setResend(true)
                                }}
                            >
                                Click to resend
                            </span>
                        </small>
                    </div>
                )}
            </Container>
            <Container
                className="d-flex flex-column"
                style={{ width: '1500px', marginBottom: '24px', marginTop: '202px' }}
            >
                <StepProgressBar
                    filledColor={filledColor}
                    progressStep={progressStep}
                    className="bg-primary"
                />
            </Container>
        </div>
    )
}

export default SignupByInvation
