import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, FormGroup, Label, Modal, ModalBody } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { HiOutlineTrash } from 'react-icons/hi'
import { getTeam, updateTeamMembership } from 'features/dashboard/Team.actionTypes'
import { roleAndDescription } from './OrgTeamHelpers'
import DropdownSelect from 'layout-components/Dropdown'

const TeamMembersModal = ({ slug, form, modal, toggle, member = {}, setForm, setTeam }) => {
    const dispatch = useDispatch()
    const [memberType, setMemberType] = useState(
        (member?.membership_type &&
            member?.membership_type[0].toUpperCase() + member?.membership_type.substring(1)) ||
            'Observer'
    )

    const handleSubmit = (data) => {
        dispatch(updateTeamMembership(data)).then(({ error }) => {
            if (!error) {
                dispatch(getTeam({ slug })).then(({ error, payload }) => {
                    if (!error) {
                        setTeam(payload.data)
                    }
                })
                toggle()
            }
        })
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="lg">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/organization/members/team-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column pb-4">
                    <span className="org-modal-header">
                        {form === 'add-member' ? 'Add organisation members' : 'Member settings'}
                    </span>
                </div>
                <Formik initialValues={member} onSubmit={handleSubmit}>
                    {({ values, handleSubmit, setFieldValue }) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            <FormGroup>
                                <Label className="modals-labels">Membership role</Label>
                                <DropdownSelect
                                    selected={memberType}
                                    setSelected={setMemberType}
                                    options={Object.keys(roleAndDescription['organization']).map(
                                        (user) => {
                                            return {
                                                uuid: user,
                                                title: `${user[0].toUpperCase()}${user.substring(
                                                    1
                                                )}`,
                                            }
                                        }
                                    )}
                                    setFieldValue={setFieldValue}
                                    fieldValue="membership_type"
                                />
                                {/* <Field
                                    className="modals-inputs"
                                    component={customInputForm}
                                    id="type"
                                    name="membership_type"
                                    type="select"
                                    value={values.membership_type || ''}
                                    onChange={(e) => {
                                        document.getElementById(
                                            'membership_description-modal'
                                        ).innerText =
                                            roleAndDescription['organization'][e.target.value]

                                        setFieldValue('membership_type', e.target.value)
                                    }}
                                >
                                    {Object.keys(roleAndDescription['organization']).map((user) => (
                                        <option key={user} value={user}>
                                            {`${user[0].toUpperCase()}${user.substring(1)}`}
                                        </option>
                                    ))}
                                </Field> */}
                                <small
                                    color="none"
                                    id="membership_description-modal"
                                    style={{
                                        color: 'black',
                                    }}
                                />
                            </FormGroup>
                            <div className="new-modal-footer">
                                <Button
                                    color="none"
                                    onClick={() => {
                                        setForm('remove-team-member')
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete
                                </Button>

                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={
                                            form === 'edit-member' &&
                                            JSON.stringify(values) === JSON.stringify(member)
                                        }
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default TeamMembersModal
