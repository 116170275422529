import React, { useRef, useState } from 'react'
import { Button } from 'reactstrap'
import { FaFileImage, FaFileUpload } from 'react-icons/fa'

const UploadFile = ({ setFile, fileType, setSuccess }) => {
    const [dragActive, setDragActive] = useState(false)
    const inputFile = useRef(null)

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true)
        } else if (e.type === 'dragleave') {
            setDragActive(false)
        }
    }

    const handleDrop = (e) => {
        const { files } = e.dataTransfer
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (files && files.length) {
            setFile(files[0])
            setSuccess(true)
        }
    }

    const handleUploadClick = (e) => {
        const { files } = e.target
        if (files && files.length) {
            setFile(files[0])
            setSuccess(true)
        }
    }

    const onButtonClick = () => {
        inputFile.current.click()
    }

    return (
        <div
            style={{ borderStyle: 'dashed' }}
            className={`d-flex flex-column w-100 w-md-50 m-2 align-items-center justify-content-between p-5 border-2 border-gray rounded ${
                dragActive ? 'bg-gray' : ''
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            {fileType === 'image' ? (
                <FaFileImage
                    id="cloud"
                    className={`w-25 h-25 ${dragActive ? 'text-white' : 'text-gray'}`}
                />
            ) : (
                <FaFileUpload
                    id="cloud"
                    className={`w-25 h-25 ${dragActive ? 'text-white' : 'text-gray'}`}
                />
            )}

            <strong className="my-3 text-center">Drag and drop {fileType} here</strong>
            <span className="my-1">or</span>
            <input
                className="d-none"
                ref={inputFile}
                onChange={handleUploadClick}
                type="file"
                multiple="multiple"
                accept={fileType === 'image' ? '.png, .pgm' : '.yaml'}
            />
            <Button
                id="browse-btn"
                onClick={onButtonClick}
                className="save-btn-modal modals-new-btns rounded-pill py-3 mt-3"
                style={{ width: '150px' }}
            >
                Attach
            </Button>
        </div>
    )
}

export default UploadFile
