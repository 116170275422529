import React from 'react'

const CustomCheckbox = ({ values, setFieldValue }) => {

    const onCheckboxBtnClick = (selected) => {
        if (selected.label === 'None') {
            if(selected.name === 'vehicle_notification_none') {
                setFieldValue('vehicle_notification', false)
            }
            setFieldValue(selected.web, false)
            setFieldValue(selected.email, false)
            setFieldValue(selected.name, true)
        }
        if(selected.name === 'vehicle_notification') {
            setFieldValue(selected.name, !selected.value)
        }
        setFieldValue(selected.name, !selected.value)
        setFieldValue(selected.none, false)
    }

    const others = values.filter((val) => val.label !== 'None')
    const checkFalse = others.every((other) => other.value === false || other.value === undefined)

    return (
        <div
            className="d-flex justify-content-center align-items-center rounded"
            style={{
                overflow: 'hidden',
                height: '2rem',
                boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
        >
            {values.map((val, i) => (
                <div
                    key={i}
                    className={`border px-3 py-2 d-flex justify-content-center align-items-center`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onCheckboxBtnClick(val)}
                >
                    <div
                        className={`border rounded-circle mr-2 ${
                            val.value || (val.label === 'None' && checkFalse) ? 'bg-meili' : ''
                        }`}
                        style={{ width: '1rem', height: '1rem', borderColor: '#D9DBE0' }}
                    />
                    {val.label}
                </div>
            ))}
        </div>
    )
}

export default CustomCheckbox
