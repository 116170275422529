import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'

import { Loading } from 'ui/common'
import { selectors } from 'features/dashboard'
import { activateEmail } from 'features/user/actionTypes'

const VerifyEmailByToken = () => {
    const user = useSelector(selectors.getUser)
    const dispatch = useDispatch()
    const { token } = useParams()
    const history = useHistory()

    useEffect(() => {
        document.title = 'Activating Email'
        dispatch(
            activateEmail({
                token,
            })
        ).then(({ error }) => {
            if (!error) return history.push('/login')
        })
    }, [dispatch, token]) // eslint-disable-line react-hooks/exhaustive-deps

    const Status = () => {
        if (user.status === 'loaded' && Boolean(user.errors.detail))
            return <h3 className="text-warning">Invalid token</h3>
        if (user.status === 'loaded')
            return <h3 className="text-success">Your Email was activated</h3>
        return <Loading message="Validating Email..." />
    }

    return (
        <Row className="d-flex mt-4 mb-4 flex-row text-center">
            <Col md={12} className="m-4">
                <Status />
            </Col>
        </Row>
    )
}

export default VerifyEmailByToken
