import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getMessages } from 'features/generic/selectors'
import { actionTypes } from 'features/generic/Message.actionTypes'
import { Alert } from 'reactstrap'

const NotificationMessages = () => {
    const dispatch = useDispatch()
    const messages = useSelector(getMessages)
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        if (messages.length > 0) {
            setNotifications((prevNotifications) => [...prevNotifications, ...messages])
            dispatch({ type: actionTypes.CLEAR_MESSAGES })

            setTimeout(() => {
                setNotifications([])
            }, 3000)
        }
    }, [messages, dispatch])

    return (
        <div>
            {notifications.length > 0 &&
                notifications.map(({ text, type }, index) => (
                    <Alert
                        key={index}
                        color={type}
                        className="text-center mx-auto notification position-fixed"
                    >
                        {text}
                    </Alert>
                ))}
        </div>
    )
}

export default NotificationMessages
