import React, { useEffect, useState } from 'react'
import useImage from 'use-image'
import { Image, Text, Transformer, Label, Tag } from 'react-konva'
import icon from './icon.png'

const StationRotate = ({
    aoi,
    stage,
    slug,
    width,
    height,
    offsetX,
    offsetY,
    cursor,
    x,
    y,
    shape,
    isSelected,
    onSelect,
    rotation,
    onChange,
    handleMouseDown,
    size,
    ...props
}) => {
    const [defaultSelect] = useImage('/svgs/map-layer/station-selected.svg')
    const [chargingSelect] = useImage('/svgs/map-layer/charging-selected.svg')
    const [restingSelect] = useImage('/svgs/map-layer/resting-selected.svg')
    const [traversalSelected] = useImage('/svgs/map-layer/traversal-selected.svg')
    const shapeRef = React.useRef()
    const transformRef = React.useRef()
    const [text, setText] = useState('')
    const [hover, setHover] = useState('')

    useEffect(() => {
        if (isSelected && shape.station_type !== 'traversal') {
            // we need to attach transformer manually
            transformRef.current.setNode(shapeRef.current)
            transformRef.current.getLayer().batchDraw()
            stage?.findOne('.rotater').on('mouseenter', () => {
                stage.content.style.cursor = 'url(' + icon + ') 10 20, default'
                setHover(`Drag to rotate (/shift to snap)`)
            })
            stage?.findOne('.rotater').on('mouseleave', () => {
                setHover('')
            })
        }
    }, [isSelected, stage, shape])

    const showImage = () => {
        switch (shape.station_type) {
            case 'charging':
                return chargingSelect
            case 'resting':
                return restingSelect
            case 'traversal':
                return traversalSelected
            default:
                return defaultSelect
        }
    }

    const onMouseEnter = (event) => {
        event.target.getStage().container().style.cursor = 'grab'
    }

    const onMouseLeave = (event) => {
        event.target.getStage().container().style.cursor = 'default'
    }

    const rotationFix = (rotation) => {
        const minusValue = Math.sign(rotation) === -1
        if (minusValue) {
            return Math.round(rotation) + 360
        }
        return Math.round(rotation)
    }

    const handleTransformer = (e) => {
        const node = shapeRef.current.rotation()
        const minusValue = Math.sign(node) === -1
        setHover(`Drag to rotate (/shift to snap)`)
        if (minusValue) {
            const rotate = Math.round(node) + 360
            e.evt.shiftKey ? setText(getSnapRotation(rotate)) : setText(rotate)
        } else {
            const rotate = Math.round(node)
            e.evt.shiftKey ? setText(getSnapRotation(rotate)) : setText(rotate)
        }
    }

    const getSnapRotation = (rotation) => {
        // Calculate the nearest multiple of 15 degrees
        const snapValue = 15
        const snappedRotation = Math.round(rotation / snapValue) * snapValue
        return snappedRotation
    }

    return (
        <>
            <Image
                image={showImage()}
                width={width}
                height={height}
                offsetX={shape.station_type !== 'traversal' ? offsetX : offsetX - 5}
                offsetY={shape.station_type !== 'traversal' ? offsetY : offsetY - 10}
                x={x}
                y={y}
                {...props}
                onMouseDown={() => {
                    onSelect()
                }}
                ref={shapeRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...shape}
                onTransform={handleTransformer}
                onTransformEnd={(e) => {
                    const node = shapeRef.current
                    e.evt.shiftKey
                        ? getSnapRotation(node.rotation(rotationFix(node.rotation())))
                        : node.rotation(rotationFix(node.rotation()))

                    if (shape.station_type === 'traversal') return

                    onChange({
                        ...shape,
                        points: [Math.round(node.x()), Math.round(node.y())],
                        rotation: e.evt.shiftKey
                            ? getSnapRotation(rotationFix(node.rotation()))
                            : rotationFix(node.rotation()),
                    })
                }}
            />

            {isSelected && shape.station_type !== 'traversal' && (
                <Transformer
                    ref={transformRef}
                    enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                    anchorSize={13}
                    rotateAnchorOffset={30}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox
                        }
                        return newBox
                    }}
                />
            )}

            {isSelected && shape.station_type !== 'traversal' && shape.points && (
                <>
                    <Label x={cursor[0] + 5} y={cursor[1] - size}>
                        <Tag fill="#f0f1f3" cornerRadius={50}></Tag>
                        <Text
                            width={size * 6}
                            height={size * 6}
                            align="center"
                            lineHeight={3}
                            text={text + '°'}
                            fontSize={size * 2}
                            fontStyle="bold"
                        ></Text>
                    </Label>
                    <Text
                    x={cursor[0] + 5}
                    y={cursor[1] - size * 2.2}
                    stroke="#670d95"
                    strokeWidth={0.1}
                    text={hover ? hover : 'Press ESC to cancel'}
                    fontStyle="bold"
                    fontSize={size}
                />
                </>
            )}
        </>
    )
}

export default StationRotate
