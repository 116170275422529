import React from 'react'
import { FormGroup, Label, Button, Spinner, Input, Alert } from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { customInputForm, CommonErrors } from 'ui/common'
import { pathSchema } from 'schemas/dashboard'
import { deletePath, createPath } from 'features/dashboard/Map.actionTypes'
import { selectors } from 'features/dashboard'

const PathForm = ({ path, toggle, windows }) => {
    const dispatch = useDispatch()
    const map = useSelector(selectors.getTeamMap)
    const { slug } = useParams()
    const handleCreate = (data, action) => {
        dispatch(createPath({ slug, data })).then(({ error }) => {
            action.setSubmitting(false)
            if (error) return action.setErrors(error.response.data)
            toggle()
        })
    }

    const handleRemovePath = ({ uuid }, action) => {
        dispatch(deletePath({ uuid })).then(({ error }) => {
            action.setSubmitting(false)
            if (error) return action.setErrors(error.response.data)
            toggle()
        })
    }
    return (
        <>
            {windows.path ? (
                <Formik
                    initialValues={{
                        destination_points: ['', ''],
                    }}
                    validationSchema={pathSchema}
                    onSubmit={handleCreate}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <FormGroup>
                                <Label for="from">From</Label>
                                <Field
                                    type="select"
                                    component={customInputForm}
                                    name="destination_points[0]"
                                >
                                    <option value=""></option>

                                    {/* this codeblock does the same on destinationpoints[1]
                                    First it looks if theres an value in the other selecter. if there is
                                    it will create the whole list normaly */}
                                    {values?.destination_points[1] === '' &&
                                        Object.keys(map.points).map((pointKey) => (
                                            <option className="DS1" key={pointKey} value={pointKey}>
                                                {map?.points[pointKey]?.name}
                                            </option>
                                        ))}

                                    {/* If theres a value in the other selecter and
                                    that value has a point type in the map.points
                                    which is not charging, it will disable all charging points
                                    and if the other selector has a selected value, that will also 
                                    be disabled, so you cant choose the same point to make a path from and to*/}
                                    {(values?.destination_points?.[1] &&
                                        map?.points?.[values?.destination_points?.[1]]
                                            ?.point_type !== 'charging' &&
                                        Object.keys(map?.points).map((pointKey) => (
                                            <option
                                                className="DS1"
                                                key={pointKey}
                                                value={pointKey}
                                                disabled={
                                                    map?.points[pointKey].point_type ===
                                                        'charging' ||
                                                    map?.points[pointKey] ===
                                                        map?.points[values?.destination_points[1]]
                                                }
                                            >
                                                {map.points[pointKey].name}
                                            </option>
                                        ))) ||
                                        /* Does the opposite of the above */
                                        (values?.destination_points?.[1] &&
                                            map?.points?.[values?.destination_points?.[1]]
                                                ?.point_type === 'charging' &&
                                            values?.destination_points?.[1] !== '' &&
                                            Object.keys(map?.points).map((pointKey) => (
                                                <option
                                                    className="DS1"
                                                    key={pointKey}
                                                    value={pointKey}
                                                    disabled={
                                                        map?.points[pointKey].point_type !==
                                                            'charging' ||
                                                        map?.points[pointKey] ===
                                                            map?.points[
                                                                values?.destination_points[1]
                                                            ]
                                                    }
                                                >
                                                    {map.points[pointKey].name}
                                                </option>
                                            )))}
                                </Field>
                            </FormGroup>
                            <FormGroup>
                                <Label for="to">To</Label>
                                <Field
                                    type="select"
                                    name="destination_points[1]"
                                    id="to"
                                    component={customInputForm}
                                >
                                    <option value=""></option>

                                    {values?.destination_points[0] === '' &&
                                        Object.keys(map.points).map((pointKey) => (
                                            <option className="DS2" key={pointKey} value={pointKey}>
                                                {map?.points[pointKey]?.name}
                                            </option>
                                        ))}

                                    {(values?.destination_points?.[0] &&
                                        map?.points?.[values?.destination_points?.[0]]
                                            ?.point_type !== 'charging' &&
                                        Object.keys(map?.points).map((pointKey) => (
                                            <option
                                                className="DS2"
                                                key={pointKey}
                                                value={pointKey}
                                                disabled={
                                                    map?.points[pointKey].point_type ===
                                                        'charging' ||
                                                    map?.points[pointKey] ===
                                                        map?.points[values?.destination_points[0]]
                                                }
                                            >
                                                {map.points[pointKey].name}
                                            </option>
                                        ))) ||
                                        (values?.destination_points?.[0] &&
                                            map?.points?.[values?.destination_points?.[0]]
                                                ?.point_type === 'charging' &&
                                            values?.destination_points?.[0] !== '' &&
                                            Object.keys(map?.points).map((pointKey) => (
                                                <option
                                                    className="DS2"
                                                    key={pointKey}
                                                    value={pointKey}
                                                    disabled={
                                                        map?.points[pointKey].point_type !==
                                                            'charging' ||
                                                        map?.points[pointKey] ===
                                                            map?.points[
                                                                values?.destination_points[0]
                                                            ]
                                                    }
                                                >
                                                    {map.points[pointKey].name}
                                                </option>
                                            )))}
                                </Field>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    id="bidirectional"
                                    checked={values?.bidirectional === false ? false : true}
                                    onChange={() => {
                                        setFieldValue('bidirectional', !values.bidirectional)
                                    }}
                                />
                                <Label check> Bidirectional</Label>
                            </FormGroup>
                            <CommonErrors />
                            <Button
                                disabled={isSubmitting}
                                color="neutral-primary"
                                className="btn-transition-none"
                                type="submit"
                            >
                                Save
                                {isSubmitting && <Spinner size="sm" color="white ml-2" />}
                            </Button>
                            <Button
                                color="outline-warning"
                                onClick={toggle}
                                className="pull-right btn-transition-none"
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : (
                <>
                    <Alert color="danger">Are you sure you want to delete the path?</Alert>
                    <div className="divider my-3"></div>
                    <Formik initialValues={path} onSubmit={handleRemovePath}>
                        {({ isSubmitting }) => (
                            <Form>
                                <Button
                                    color="danger"
                                    size="md"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Yes
                                    {isSubmitting && <Spinner size="sm" color="white ml-2" />}
                                </Button>{' '}
                                {'  '}
                                <Button color="secondary pull-right" size="md" onClick={toggle}>
                                    No
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </>
    )
}

export default PathForm
