import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import {
    Button,
    ButtonGroup,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    UncontrolledTooltip,
} from 'reactstrap'
import { customInputForm } from 'ui/common'
import {
    createOrgVehicleCategory,
    getOrgVehicleCategories,
    updateOrgVehicleCategory,
} from 'features/dashboard/Organization.actionTypes'
import { removeNullFromObj } from 'utils'
import DropdownSelect from 'layout-components/Dropdown'

const CategoriesModal = ({ slug, form, setForm, modal, toggle, category = {} }) => {
    const dispatch = useDispatch()
    const [tab, setTab] = useState('general')
    const [vehicleType, setVehicleType] = useState(category?.vehicle_type?.toUpperCase() || '')

    const handleSubmit = (data) => {
        if (form === 'add-category') {
            dispatch(createOrgVehicleCategory({ slug, ...data })).then(() => {
                dispatch(getOrgVehicleCategories({ slug }))
                toggle()
            })
        }

        if (form === 'edit-category') {
            const sortedData = removeNullFromObj(data)
            dispatch(updateOrgVehicleCategory(sortedData)).then(() => {
                dispatch(getOrgVehicleCategories({ slug }))
                toggle()
            })
        }
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="vehicle-modal-org">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/task-modal/smiley-robot.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">
                        {form === 'add-category' ? 'New category' : 'Category settings'}
                    </span>
                    <div className="modals-container-buttons">
                        <ButtonGroup className="org-button-group">
                            <Button
                                className={`${
                                    tab === 'general' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('general')
                                }}
                            >
                                General
                            </Button>
                            <Button
                                className={`${
                                    tab === 'dimensions' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('dimensions')
                                }}
                            >
                                Dimensions
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <Formik initialValues={category} onSubmit={handleSubmit}>
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form
                            style={{ marginTop: '14px' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            {tab === 'general' && (
                                <>
                                    <FormGroup>
                                        <Label className="modals-labels" for="verbose_name">
                                            Name *
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            value={values.title || ''}
                                            name="title"
                                            placeholder="Name"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="team">
                                            Max load
                                        </Label>
                                        <div className="d-flex align-items-center">
                                            <Field
                                                className="modals-inputs"
                                                style={{ paddingRight: '2rem' }}
                                                component={customInputForm}
                                                type="number"
                                                value={values.max_load || ''}
                                                name="max_load"
                                                placeholder="Max load"
                                            />
                                            <span style={{ marginLeft: '-1.5rem' }}>Kg</span>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="vehicle_type">
                                            Vehicle Type *
                                        </Label>
                                        <img
                                            src="/svgs/trigger-actions/question-mark.svg"
                                            alt="plus"
                                            id="type_info"
                                            width={16}
                                            height={16}
                                            style={{
                                                cursor: 'pointer',
                                                color: '#C2C5CE',
                                                marginLeft: '4px',
                                            }}
                                        />
                                        <UncontrolledTooltip
                                            placement="left"
                                            target="type_info"
                                            style={{
                                                maxWidth: '20rem',
                                                background: 'white',
                                                textAlign: 'start',
                                                borderRadius: '1rem',
                                                whiteSpace: 'break-spaces',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            -Autonomous Mobile Robots (AMR) <br />
                                            -Automated Guided Vehicles (AGV) <br />
                                            -AGVs are not able to run tasks sent by Meili FMS
                                        </UncontrolledTooltip>
                                        <DropdownSelect
                                            selected={vehicleType}
                                            setSelected={setVehicleType}
                                            options={[
                                                { uuid: 'agv', name: 'AGV' },
                                                { uuid: 'amr', name: 'AMR' },
                                            ]}
                                            setFieldValue={setFieldValue}
                                            fieldValue="vehicle_type"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="extra_details">
                                            Extra details
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            placeholder="Extra details"
                                            value={values.extra_details || ''}
                                            name="extra_details"
                                        />
                                    </FormGroup>
                                </>
                            )}
                            {tab === 'dimensions' && (
                                <>
                                    <Label className="modals-labels" for="dimensions">
                                        Vehicle dimensions *
                                    </Label>
                                    <br />
                                    <div className="d-flex justify-content-between align-items-center p-2">
                                        <FormGroup>
                                            <Label className="modals-labels">Length</Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.dimensions?.[0] || ''}
                                                    name="dimensions[0]"
                                                    placeholder="X"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels ml-2">Width</Label>
                                            <div className="d-flex align-items-center mx-2">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.dimensions?.[1] || ''}
                                                    name="dimensions[1]"
                                                    placeholder="Y"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels">Height</Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.dimensions?.[2] || ''}
                                                    name="dimensions[2]"
                                                    placeholder="Z"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <Label className="modals-labels" for="dimensions">
                                        Container dimensions
                                    </Label>
                                    <br />
                                    <div className="d-flex justify-content-between align-items-center p-2">
                                        <FormGroup>
                                            <Label className="modals-labels">Length</Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.container_dimensions?.[0] || ''}
                                                    name="container_dimensions[0]"
                                                    placeholder="X"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels ml-2">Width</Label>
                                            <div className="d-flex align-items-center mx-2">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.container_dimensions?.[1] || ''}
                                                    name="container_dimensions[1]"
                                                    placeholder="Y"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels">Height</Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.container_dimensions?.[2] || ''}
                                                    name="container_dimensions[2]"
                                                    placeholder="Z"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </>
                            )}
                            <div className="new-modal-footer">
                                {form === 'edit-category' ? (
                                    <Button
                                        color="none"
                                        onClick={() => {
                                            setForm('remove-category')
                                        }}
                                        className="modals-delete-btn"
                                    >
                                        <HiOutlineTrash size={20} className="mr-1" />
                                        Delete
                                    </Button>
                                ) : (
                                    <div></div>
                                )}
                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={
                                            form === 'edit-category' &&
                                            JSON.stringify(values) === JSON.stringify(category)
                                        }
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default CategoriesModal
