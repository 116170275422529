import { actionTypes } from './Trigger.actionTypes'
import { produce } from 'immer'

export const triggerState = {
    status: 'loading',
    triggers: [],
}
export default produce((draft, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_TRIGGER:
            draft.status = 'loading'
            return draft
        case actionTypes.CREATE_TRIGGER:
        case actionTypes.GET_TRIGGER_FAIL:
            draft.status = 'error'
            return draft

        case actionTypes.GET_TRIGGER_SUCCESS:
            draft.status = 'loaded'
            draft.triggers = payload.data
            return draft

        case actionTypes.CREATE_TRIGGER_SUCCESS:
            draft.status = 'created'
            draft.triggers.push(payload.data)
            return draft

        case actionTypes.CREATE_TRIGGER_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.UPDATE_TRIGGER:
            draft.status = 'updating'
            return draft
        case actionTypes.UPDATE_TRIGGER_SUCCESS:
            draft.status = 'updated'
            draft.triggers = draft.triggers.map((trigger) =>
                trigger.uuid === payload.data?.uuid ? payload.data : trigger
            )
            return draft
        case actionTypes.UPDATE_TRIGGER_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.DELETE_TRIGGER:
            draft.status = 'deleting'
            return draft
        case actionTypes.DELETE_TRIGGER_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.DELETE_TRIGGER_SUCCESS:
            draft.status = 'deleted'
            draft.triggers = draft.triggers?.filter(
                (trigger) => trigger.uuid !== payload.data?.uuid
            )
            return draft
        default:
            return draft
    }
}, triggerState)
