import React, { useState } from 'react'
import { Card, CardHeader, Button, Table, Modal, ModalBody } from 'reactstrap'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'

import { useToggler } from 'utils'
import PathForm from './OutdoorPathForm'
import { getTeamMap } from 'features/dashboard/selectors'

const OutdoorPathsListCard = () => {
    const [modal, toggleModal] = useToggler()
    const [path, setPath] = useState({})
    const { paths } = useSelector(getTeamMap)

    const getPathName = (path) =>
        path.points.map((point) => point.name).join(' - ')

    return (
        <>
            <Card className="card-box mt-2 shadow-none border-0">
                <CardHeader className="d-flex">
                    <strong className="mr-auto">Paths</strong>
                    <Button
                        color="success"
                        onClick={() => {
                            setPath({})
                            toggleModal()
                        }}
                        size="sm"
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </CardHeader>
                <div className="scroll-area bg-white">
                    <PerfectScrollbar options={{ wheelPropagation: false }}>
                        <div className="table-responsive">
                            <Table
                                hover
                                borderless
                                className="text-nowrap mb-0"
                            >
                                <thead>
                                    <tr>
                                        <th className="text-left">From - To</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paths.features &&
                                        paths.features.map(({ properties }) => {
                                            return (
                                                <tr key={properties.uuid}>
                                                    <td>
                                                        {getPathName(
                                                            properties
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            size="sm"
                                                            color="neutral-danger"
                                                            id="ViewDetailsTooltip6"
                                                            onClick={() => {
                                                                setPath(properties)
                                                                toggleModal()
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTimes}
                                                            />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    </PerfectScrollbar>
                </div>
            </Card>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalBody>
                    <PathForm toggle={toggleModal} path={path} />
                </ModalBody>
            </Modal>
        </>
    )
}
export default OutdoorPathsListCard
