import React from 'react'
import useImage from 'use-image'
import { Image as KonvaImage, Text } from 'react-konva'
import { stationIconSize } from './MapLayerHelpers'

const MapDeviceNew = ({ device, aoi, cursor, x, y, size }) => {
    const [deviceSelect] = useImage('/svgs/map-layer/device-select.svg')
    const { width, height } = aoi
    const biggerMapSize = 1000000

    if (!device) return

    return (
        <>
            <KonvaImage
                image={deviceSelect}
                width={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                height={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                offsetX={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                offsetY={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                x={x}
                y={y}
                onMouseEnter={(e) => {
                    const container = e.target.getStage().container()
                    container.style.cursor = 'grab'
                }}
                onMouseLeave={(e) => {
                    const container = e.target.getStage().container()
                    container.style.cursor = 'default'
                }}
            />
            <Text
                x={cursor && cursor[0]}
                y={cursor && cursor[1] / 1.5}
                align={'center'}
                text='Press ESC to cancel'
                fontSize={size}
                fontStyle="bold"
            ></Text>
        </>
    )
}

export default MapDeviceNew
