import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { TITLE } from 'config'
import BootstrapTable from 'react-bootstrap-table-next'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ManageOrganizationModals from './ManageOrganizationModals'
import {
    deleteDeviceModel,
    getOrgDeviceModels,
    resetDeviceModels,
} from 'features/dashboard/RosSetup.actionTypes'
import dayjs from 'dayjs'
import { selectors } from 'features/dashboard'
import { getOrgTeams } from 'features/dashboard/Organization.actionTypes'
import { validateDate } from './OrgTeamHelpers'
import Filter from 'layout-components/Filter'
import { HiOutlineX } from 'react-icons/hi'

const DeviceModelsManage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const setupStore = useSelector(selectors.getSetup)
    const teams = useSelector(selectors.getOrganizationTeams)
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState(null)
    const [dataOnClick, setDataOnClick] = useState({})
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPage, setLastClickedPage] = useState(1)
    const [sort, setSort] = useState('asc')
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [initialFilterValues, setInitialFilterValues] = useState({
        team: [],
        device_model_type: [],
    })
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggle = () => setModal(!modal)

    useEffect(() => {
        document.title = `${TITLE} - Device models manage`
    }, [])

    useEffect(() => {
        dispatch(getOrgTeams(currentOrg.slug))
        dispatch(getOrgDeviceModels({ slug: currentOrg.slug, page: currentPageNumber }))
    }, [currentOrg.slug, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentPageNumber > 1) {
            dispatch(
                getOrgDeviceModels({
                    slug: currentOrg.slug,
                    page: currentPageNumber,
                    filters: initialFilterValues,
                })
            )
        }
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modal) {
            setDataOnClick({})
            setForm(null)
        }
    }, [modal])

    const handleRemoveDeviceModel = (data) => {
        dispatch(deleteDeviceModel(data.uuid)).then(({ error }) => {
            if (!error) {
                dispatch(
                    getOrgDeviceModels({
                        slug: currentOrg.slug,
                        page: currentPageNumber,
                        filters: initialFilterValues,
                    })
                )
            }
            toggle()
        })
    }

    const RenderDeviceManage = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/map-editor/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )

    const dataDevices = useMemo(() => {
        return setupStore?.device_models?.results?.map((device) => {
            return {
                uuid: device.uuid,
                name: device.name || '-',
                team: device.team.title || '-',
                teamObj: device.team,
                device_model_type: device.device_model_type || '-',
                created_at: (
                    <div className="text-dark">
                        {validateDate(dayjs(device.metadata.created_at))}
                    </div>
                ),
                updated_at: (
                    <div className="text-dark">
                        {validateDate(dayjs(device.metadata.updated_at))}
                    </div>
                ),
                action: (
                    <RenderDeviceManage
                        item={device}
                        form={{ edit: 'edit-device-model', remove: 'remove-device-model' }}
                    />
                ),
            }
        })
    }, [setupStore.device_models.results])

    const devicesColumns = [
        {
            dataField: 'name',
            text: 'Name',
            headerClasses: 'custom-header pl-4',
            classes: 'custom-cell',
            style: { paddingLeft: '24px' },
            sort: true,
            onSort: (field, order) => {
                setSort(order)
            },
        },
        {
            dataField: 'team',
            text: 'Team',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
            formatter: (cell, row) => {
                return (
                    <div
                        className="team-div-cell"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push({
                                pathname: `/dashboard/organization/teams/${row.teamObj.slug}/`,
                                state: { slug: row.teamObj.slug, team: row.teamObj },
                            })
                        }
                    >
                        {cell}
                    </div>
                )
            },
        },
        {
            dataField: 'device_model_type',
            text: 'Device Model Type',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
        },
        // {
        //     dataField: 'pin',
        //     text: 'PIN',
        //     sort: true,
        //     filter: textFilter(),
        // },
        {
            dataField: 'created_at',
            text: 'Created At',
            headerClasses: 'custom-header',
        },
        {
            dataField: 'updated_at',
            text: 'Updated at',
            headerClasses: 'custom-header',
        },
        {
            dataField: 'action',
            text: '',
            headerClasses: 'custom-header',
            headerStyle: { width: '128px' },
            style: { paddingRight: '24px' },
        },
    ]

    return (
        <div className="big-org-container">
            <div className="heading-container-org" style={{ marginTop: '32px' }}>
                <div>
                    <div className="mb-2 d-flex align-items-center">
                        <span className="header-text-org">Device models</span>
                        {setupStore.status === 'loaded' && (
                            <span
                                className="text-meili rounded px-2"
                                style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                            >
                                {setupStore?.device_models?.count} device models
                            </span>
                        )}
                    </div>
                    <span className="small-text-org">
                        Manage your device models and their settings here.
                    </span>
                </div>
                <div className="d-flex">
                    <Filter
                        table="devices"
                        slug={currentOrg.slug}
                        initValues={initialFilterValues}
                        setInitValues={setInitialFilterValues}
                        setLastClickedPage={setLastClickedPage}
                        dropdownOpen={dropdownOpen}
                        toggle={dropdownToggle}
                        teams={teams}
                    />
                    <Button
                        className="new-buttons-desing-org-purple org-page-btns text-white"
                        onClick={() => {
                            setForm('add-device-model')
                            toggle()
                        }}
                        style={{ height: '40px' }}
                    >
                        <img src="/svgs/trigger-actions/add.svg" alt="plus" />
                        Add device model
                    </Button>
                </div>
            </div>
            {setupStore.status === 'loading' ? (
                <LoadingSpinner />
            ) : (
                <>
                    {[...initialFilterValues.team, ...initialFilterValues.device_model_type]
                        .length > 0 && (
                        <div className="d-flex align-items-start">
                            {[
                                ...initialFilterValues.team,
                                ...initialFilterValues.device_model_type,
                            ].map((item, index) => (
                                <div
                                    key={index}
                                    className="team-div-cell d-flex align-items-center text-meili"
                                    style={{
                                        backgroundColor: '#F9EFFE',
                                        marginRight: '8px',
                                    }}
                                >
                                    <HiOutlineX
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setInitialFilterValues((prevValues) => {
                                                const newTeam = prevValues.team.filter(
                                                    (t) => t !== item
                                                )
                                                const newDeviceModelType =
                                                    prevValues.device_model_type.filter(
                                                        (type) => type !== item
                                                    )

                                                const updatedValues = {
                                                    team: newTeam,
                                                    device_model_type: newDeviceModelType,
                                                }

                                                dispatch(resetDeviceModels())
                                                setLastClickedPage(1)
                                                dispatch(
                                                    getOrgDeviceModels({
                                                        slug: currentOrg.slug,
                                                        filters: updatedValues,
                                                    })
                                                )

                                                return updatedValues
                                            })
                                        }}
                                    />
                                    {item}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="rounded-table-container">
                        {dataDevices?.length > 0 ? (
                            <BootstrapTable
                                bootstrap4={true}
                                classes="my-custom-table"
                                keyField="uuid"
                                data={dataDevices}
                                columns={devicesColumns}
                                pagination={paginationFactory({
                                    page: lastClickedPage,
                                    sizePerPage: 7,
                                    sizePerPageList: [],
                                    onPageChange: (page, sizePerPage) => setLastClickedPage(page),
                                })}
                                defaultSorted={[
                                    {
                                        dataField: 'name',
                                        order: sort,
                                    },
                                ]}
                            />
                        ) : (
                            <h5 className="p-4">There are currently no device models.</h5>
                        )}

                        {setupStore?.device_models?.next &&
                            lastClickedPage === Math.ceil(dataDevices.length / 7) && (
                                <Button
                                    style={{
                                        position: 'absolute',
                                        bottom: '0.8rem',
                                        left: '24px',
                                        borderRadius: '8px',
                                        background: '#f9fafb',
                                        color: '#4e5462',
                                    }}
                                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                                >
                                    Load more (
                                    {setupStore.device_models.count -
                                        setupStore.device_models.results.length}
                                    )
                                </Button>
                            )}
                    </div>
                </>
            )}
            <ManageOrganizationModals
                slug={currentOrg?.slug}
                form={form}
                modal={modal}
                toggle={toggle}
                filters={initialFilterValues}
                setForm={setForm}
                dataOnClick={dataOnClick}
                currentPageNumber={currentPageNumber}
                submitDelete={handleRemoveDeviceModel}
            />
        </div>
    )
}

export default DeviceModelsManage
