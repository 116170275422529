import React, { useState, forwardRef, useEffect } from 'react'
import TaskDetailV2 from './TaskDetailV2'

const Wrapper = forwardRef((props, ref) => (
    <div className="row" ref={ref}>
        {props.children}
    </div>
))

const TasksGridView = ({ tasks, filterLabel, robot, slug, setTaskHovered }) => {
    const [list, setList] = useState([])

    useEffect(() => {
        if (JSON.stringify(tasks) !== JSON.stringify(list))
            setList(
                tasks.map((task) => ({
                    id: task.uuid,
                    ...task,
                }))
            )
    }, [tasks]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="pt-1 pl-0 bg-white shadow-none cards-wrap">
            <div
                tag={Wrapper}
                handle=".handle"
                list={list}
                className="d-flex w-100"
                style={Object.assign(
                    list.length && { overflowY: 'hidden' },
                    {
                        overflowX: 'auto',
                    },
                    { height: '180px' }
                )}
            >
                {list.length === 0 && (
                    <h5 className="pl-3">There are currently no missions in {filterLabel}.</h5>
                )}
                {list.map((task) => (
                    <div key={task.uuid} className="mb-3 mx-3">
                        <TaskDetailV2 task={task} robot={robot} slug={slug} setTaskHovered={setTaskHovered} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TasksGridView
