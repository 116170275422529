
import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('something went wrong', error, errorInfo)
  }

  render() {
    if (this.state.hasError && process.env.NODE_ENV === 'production') {
      // window.location = '/error'
      return
    }

    return this.props.children; 
  }
}