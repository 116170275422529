import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Label, FormGroup, Modal, ModalBody } from 'reactstrap'
import { HiOutlineTrash } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { getDeviceModels } from 'features/dashboard/RosSetup.actionTypes'
import DropdownSelect from 'layout-components/Dropdown'

const DeviceModal = ({
    modal,
    toggle,
    team,
    setFieldValueTop,
    initialValues,
    device_models,
    deviceModelsList,
}) => {
    const dispatch = useDispatch()
    const [deviceModel, setDeviceModel] = useState(initialValues?.name || '')

    useEffect(() => {
        dispatch(getDeviceModels({ slug: team, text: 'no_pagination=true' }))
    }, [dispatch, team])

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="lg">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/task-modal/smiley-robot.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">
                        {initialValues ? 'Edit device model' : 'Add device model'}
                    </span>
                </div>
                <Formik
                    initialValues={{
                        device_model: initialValues?.uuid || '',
                    }}
                    onSubmit={(data) => {
                        const selectedDevice = device_models?.find(
                            (device) => device.uuid === data.device_model
                        )
                        if (initialValues) {
                            const updatedDeviceModels = [...deviceModelsList]
                            const index = updatedDeviceModels.findIndex(
                                (device) => device.uuid === initialValues.uuid
                            )
                            if (index === -1) return
                            updatedDeviceModels[index] = selectedDevice
                            setFieldValueTop('device_models', updatedDeviceModels)
                        } else {
                            setFieldValueTop('device_models', [...deviceModelsList, selectedDevice])
                        }
                        toggle()
                    }}
                >
                    {({ setFieldValue }) => {
                        return (
                            <Form>
                                <FormGroup className="mt-4">
                                    <Label className="modals-labels">Device model *</Label>
                                    <DropdownSelect
                                        selected={deviceModel}
                                        setSelected={setDeviceModel}
                                        options={device_models?.map((device) => device)}
                                        setFieldValue={setFieldValue}
                                        fieldValue="device_model"
                                    />
                                </FormGroup>
                                <div className="new-modal-footer">
                                    {initialValues ? (
                                        <Button
                                            color="none"
                                            onClick={() => {
                                                setFieldValueTop(
                                                    'device_models',
                                                    deviceModelsList.filter(
                                                        (d) => d.uuid !== initialValues.uuid
                                                    )
                                                )
                                                setDeviceModel('')
                                                toggle()
                                            }}
                                            className="modals-delete-btn"
                                        >
                                            <HiOutlineTrash size={20} className="mr-1" />
                                            Delete
                                        </Button>
                                    ) : (
                                        <div></div>
                                    )}

                                    <div className="d-flex">
                                        <Button
                                            className="cancel-btn-modal modals-new-btns"
                                            onClick={toggle}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            style={{ marginLeft: '12px' }}
                                            type="submit"
                                        >
                                            {initialValues ? 'Update' : 'Add'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default DeviceModal
