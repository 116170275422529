import React, { forwardRef, useEffect, useState } from 'react'
import TaskDetailV2 from './TaskDetailV2'
import ScheduleTaskDetailV2 from './ScheduleTaskDetailV2'

const Wrapper = forwardRef((props, ref) => (
    <div className="row" ref={ref}>
        {props.children}
    </div>
))

const AllTasksGridView = ({ allTasks, upcoming, robot, slug, upcomingTask, setTaskHovered }) => {
    const [upcominglist, setUpcominglist] = useState(upcomingTask)

    let finallist = [...allTasks?.all]

    useEffect(() => {
        if (JSON.stringify(upcoming) !== JSON.stringify(upcominglist))
            setUpcominglist(
                upcoming?.map((task) => ({
                    id: task.uuid,
                    ...task,
                }))
            )
    }, [upcoming]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="pt-1 pl-0 bg-white shadow-none cards-wrap rounded" data-testId="alltasks">
            <div
                tag={Wrapper}
                handle=".handle"
                list={finallist}
                finallist={(sortedList) => {
                    if (sortedList.length > 0) finallist(sortedList)
                }}
                className="d-flex"
                style={Object.assign(
                    finallist?.length && { overflowY: 'hidden' },
                    {
                        overflowX: 'auto',
                    },
                    { height: '180px' }
                )}
            >
                {finallist?.length === 0 && upcominglist?.length === 0 && (
                    <h5 className="pl-3">There are currently no missions.</h5>
                )}
                {finallist.map((task) => (
                    <div key={task.uuid} className="mb-3 mx-3">
                        {<TaskDetailV2 task={task} robot={robot} slug={slug} setTaskHovered={setTaskHovered} />}
                    </div>
                ))}
                {upcominglist?.map((task) => (
                    <div key={task.uuid} className="mb-3 mx-3">
                        {<ScheduleTaskDetailV2 task={task} slug={slug} setTaskHovered={setTaskHovered} />}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AllTasksGridView
