import React from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar'
import 'react-step-progress-bar/styles.css'


export default function StepProgressBar({filledColor, progressStep}) {

  return (
    <div> <ProgressBar percent={filledColor} filledBackground="#BA4FFA" className="">
                    <Step transition="scale">
                        {()   => (  
                          <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                                {progressStep === 0 ? (
                                    <img width="35"  alt ="progress"  src="/svgs/user-icon/inProgress.svg" />
                                ) : progressStep > 0 ? (
                                    <img width="30" alt ="stepdone"  src="/svgs/user-icon/stepdone.svg" />
                                ) : (
                                    <img width="30" alt = "notinProgress" src="/svgs/user-icon/stepNotProgress.svg" />
                                )}
                                <span className="font-14 grey-700">Your details</span>
                                <span className="font-14 grey-500">Name and email</span>
                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {() => (
                            <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                                {progressStep === 1 ? (
                                    <img width="35"  alt ="progress" src="/svgs/user-icon/inProgress.svg" />
                                ) : progressStep === 2 || progressStep > 2 ? (
                                    <img width="30" alt ="stepdone"  src="/svgs/user-icon/stepdone.svg" />
                                ) : (
                                    <img width="30"  alt = "notinProgress"  src="/svgs/user-icon/stepNotProgress.svg" />
                                )}

                                <span className="font-14 grey-700">Account details</span>
                                <span className="font-14 grey-500">Username and password</span>
                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {() => (
                            <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                                {progressStep === 2 ? (
                                    <img width="35" alt ="progress"  src="/svgs/user-icon/inProgress.svg" />
                                ) : progressStep === 3 || progressStep > 3 ? (
                                    <img width="30" alt ="stepdone"  src="/svgs/user-icon/stepdone.svg" />
                                ) : (
                                    <img width="30"  alt = "notinProgress"  src="/svgs/user-icon/stepNotProgress.svg" />
                                )}
                                <span className="font-14 grey-700">Create organisation</span>
                                <span className="font-14 grey-500">set up your organisation</span>
                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {() => (
                            <div
                                className="d-flex flex-column justify-content-center align-items-center pt-5"
                                style={{ width: '340px' }}
                            >
                                {progressStep === 3 ? (
                                    <img width="35"  alt ="progress" src="/svgs/user-icon/inProgress.svg" />
                                ) : progressStep === 4 || progressStep > 4 ? (
                                    <img width="30" alt ="stepdone" src="/svgs/user-icon/stepdone.svg" />
                                ) : (
                                    <img width="30"  alt = "notinProgress" src="/svgs/user-icon/stepNotProgress.svg" />
                                )}
                                <span className="font-14 grey-700">Invite team members</span>
                                <span className="font-14 grey-500">Collaborate with your team</span>
                            </div>
                        )}
                    </Step>
                </ProgressBar></div>
  )
}
