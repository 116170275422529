import React from 'react'
import { Route, Switch } from 'react-router-dom'

import NoMatch from 'components/NoMatch'
import Home from 'components/Home'
import Login from 'components/users/Login'
import SetPassword from 'components/users/SetPassword'
import Logout from 'components/users/Logout'
import SignupByInvation from 'components/users/SignupByInvitation'
import ActivateToken from 'components/users/ActivateToken'
import ResetPassword from 'components/users/ResetPassword'
import VerifyEmailByToken from 'components/users/VerifyEmailByToken'
import ErrorPage from 'components/InfoPage'
import Plans from 'components/Plans'
import TrialSignup from 'components/users/TrialSignup'
import ValidateAccount from 'components/users/ValidateAccount'

const PublicRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/logout">
                <Logout />
            </Route>
            <Route strict path="/users/reset-password/:token/">
                <SetPassword />
            </Route>
            <Route exact path="/plans/">
                <Plans />
            </Route>
            <Route exact path="/trial-signup/">
                <TrialSignup />
            </Route>
            <Route exact path="/users/reset-password/">
                <ResetPassword />
            </Route>
            <Route strict path="/invitation/:type/:token/">
            <SignupByInvation />
            </Route>
            <Route strict path="/account-validation/:token/">
               <ValidateAccount/>
            </Route>
            <Route strict path="/activate/:type/:token/">
                <ActivateToken />
            </Route>
            <Route strict path="/active/:token/">
                <VerifyEmailByToken />
            </Route>
            <Route exact path="/error">
                <ErrorPage />
            </Route>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    )
}

export default PublicRoutes
