import React from 'react'
import { Route, useRouteMatch, Switch } from 'react-router-dom'
// import OrganizationBoarding from 'components/users/OrganizationBoarding'
import OrganizationManage from 'components/dashboard-components/organization/OrganizationManage'
import VehiclesCategoriesManage from 'components/dashboard-components/organization/VehiclesCategoriesManage'
import RosSetupManage from 'components/dashboard-components/organization/RosSetupManage'
// import BillingPage from 'components/dashboard-components/organization/BillingPage'
// import Manufacturer from 'components/dashboard-components/organization/Manufacturer'
// import FormVariable from 'components/dashboard-components/organization/FormVariable'
// import Events from 'components/dashboard-components/organization/Events'
// import WMSSetup from 'components/dashboard-components/organization/WMSSetup'
// import TeamMemberships from 'components/dashboard-components/organization/TeamMemberships'
import DeviceModelsManage from 'components/dashboard-components/organization/DeviceModelsManage'
import OrganizationNavbar from 'layout-components/OrganizationNavbar/Navbar'
import OrganizationSettings from 'components/dashboard-components/organization/OrganizationSettings'
import TeamManage from 'components/dashboard-components/organization/TeamManage'

const OrganizationRoutes = () => {
    let { path } = useRouteMatch()

    return (
        <div>
            <OrganizationNavbar path={path} />
            <div className="p-1">
                <Switch>
                    <Route exact path={`${path}/`}>
                        <OrganizationManage />
                    </Route>
                    <Route strict path={`${path}vehicles/`}>
                        <VehiclesCategoriesManage />
                    </Route>
                    <Route exact path={`${path}device-models/`}>
                        <DeviceModelsManage />
                    </Route>
                    <Route exact path={`${path}rossetup/`}>
                        <RosSetupManage />
                    </Route>
                    <Route exact path={`${path}settings/`}>
                        <OrganizationSettings />
                    </Route>
                    <Route exact path={`${path}teams/:teamId/`}>
                        <TeamManage />
                    </Route>
                    {/* <Route strict path={`${path}selfboarding/:token/`}>
                        <OrganizationBoarding />
                    </Route>
                    <Route strict path={`${path}teams/`}>
                        <TeamMemberships />
                    </Route> */}
                    {/* <Route exact path={`${path}billing/`}>
                        <BillingPage />
                    </Route>
                    <Route exact path={`${path}wms-setup/`}>
                        <WMSSetup />
                    </Route>
                    <Route exact path={`${path}manufacturer/`}>
                        <Manufacturer />
                    </Route>
                    <Route exact path={`${path}form/`}>
                        <FormVariable />
                    </Route>
                    <Route exact path={`${path}events/`}>
                        <Events />
                    </Route> */}
                </Switch>
            </div>
        </div>
    )
}

export default OrganizationRoutes
