import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'

const RemoveModalOrg = ({ form, modal, toggle, dataOnClick, submitDelete }) => {
    const showText = () => {
        switch (form) {
            case 'remove-member':
            case 'remove-team-member':
                return {
                    text: 'member',
                    name:
                        dataOnClick?.user?.first_name && dataOnClick?.user?.last_name
                            ? `${dataOnClick?.user?.first_name} ${dataOnClick?.user?.last_name}`
                            : `${dataOnClick?.user?.username}`,
                }
            case 'remove-team':
                return { text: 'team', name: dataOnClick.slug }
            case 'remove-setup':
                return { text: 'fleet setup', name: dataOnClick.verbose_name }
            case 'remove-vehicle':
                return { text: 'vehicle', name: dataOnClick.verbose_name }
            case 'remove-webhook':
                return { text: 'webhook', name: dataOnClick.url }
            case 'remove-category':
                return { text: 'category', name: dataOnClick.title }
            case 'remove-device-model':
                return { text: 'device model', name: dataOnClick.name }
            default:
                return
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggle} centered className="remove-modal-org">
            <ModalBody style={{ padding: '24px' }}>
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '16px' }}
                >
                    <img
                        src="/svgs/modal-icons/trash-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">Delete {showText().text}</span>
                    <span className="org-modal-text">
                        Are you sure you want to delete {showText().name}? This action cannot be
                        undone.
                    </span>
                </div>
                <div
                    style={{ marginTop: '40px' }}
                    className={`d-flex w-100 justify-content-between align-items-center`}
                >
                    <Button
                        className="org-modal-cancel-button org-modal-btns"
                        onClick={() => {
                            toggle()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="org-modal-delete-button org-modal-btns"
                        onClick={() => submitDelete(dataOnClick)}
                    >
                        Delete
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default RemoveModalOrg
