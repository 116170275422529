import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { produce } from 'immer'

import { WEEKDAYS } from 'export'
import { useEffect } from 'react'

const Scheduler = ({ onChange, days = [], isDisabled = false }) => {
    const dayIndexToWeekday = (dayList) =>
        dayList.reduce((acc, dayIndex) => {
            acc[dayIndex] = WEEKDAYS[dayIndex]
            return acc
        }, {})

    const [selectedDays, setSelectedDays] = useState(() => dayIndexToWeekday(days))

    const handleChanges = () => {
        const updatedDays = Object.keys(selectedDays)
        if (updatedDays.length > 0) onChange(updatedDays)
    }

    useEffect(() => {
        if (!isDisabled) handleChanges()
    }, [isDisabled]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleChanges()
    }, [selectedDays]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {Object.values(WEEKDAYS).map((day) => (
                <Button
                    disabled={isDisabled}
                    onClick={() =>
                        setSelectedDays(() =>
                            produce(selectedDays, (draft) => {
                                !draft[day.index]
                                    ? (draft[day.index] = day)
                                    : delete draft[day.index]
                            })
                        )
                    }
                    active={!!selectedDays[day.index]}
                    key={day.index}
                    size="sm"
                    className="m-1 rounded"
                    color="gray"
                >
                    {day.short}
                </Button>
            ))}
        </>
    )
}

export default Scheduler
