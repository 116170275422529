import React, { useEffect, useRef, useState } from 'react'
import { Rect, Transformer, Label, Tag, Text } from 'react-konva'
import icon from './icon.png'

const Annotation = ({
    shapeProps,
    isSelected,
    onSelect,
    onAnnotationChange,
    stage,
    stageWidth,
    width,
    height,
    smallerMapSize,
    biggerMapSize,
    cursor,
    zone,
    fill,
    handleDragState,
    handleDelete,
    mouseMove,
    ...props
}) => {
    const shapeRef = React.useRef()
    const transformRef = React.useRef()
    const patternRef = useRef(document.createElement('canvas'))
    const [text, setText] = useState('')
    const [hover, setHover] = useState('')

    useEffect(() => {
        if (isSelected) {
            transformRef.current.setNode(shapeRef.current)
            transformRef.current.getLayer().batchDraw()
            stage?.findOne('.rotater').on('mouseenter', () => {
                stage.content.style.cursor = 'url(' + icon + ') 10 20, default'
                setHover('Drag to rotate')
            })
            stage?.findOne('.rotater').on('mouseleave', () => {
                setHover('')
            })
        }
    }, [isSelected, stage])

    useEffect(() => {
        const patternCanvas = patternRef.current
        patternCanvas.width = 15
        patternCanvas.height = 15
        const ctx = patternCanvas.getContext('2d')

        ctx.beginPath()
        ctx.strokeStyle = '#E7E9EC'
        ctx.moveTo(0, 15)
        ctx.lineTo(15, 0)
        ctx.stroke()

        const shape = shapeRef.current
        if (shape) {
            shape.fillPatternImage(patternCanvas)
            shape.fillPatternScaleX(1)
            shape.fillPatternScaleY(1)
            shape.getLayer().batchDraw()
        }
    }, [])

    const onMouseEnter = (event) => {
        event.target.getStage().container().style.cursor = 'move'
    }

    const onMouseLeave = (event) => {
        event.target.getStage().container().style.cursor = 'default'
    }

    const handleTransformer = () => {
        const node = shapeRef.current.rotation()
        const minusValue = Math.sign(node) === -1
        setHover('Drag to rotate')
        if (minusValue) {
            const rotate = Math.round(node) + 363
            setText(rotate)
        } else {
            const rotate = Math.round(node)
            setText(rotate)
        }
    }

    const zoneTypeColor = () => {
        switch (true) {
            case shapeProps.newZone === 'obstacle':
            case shapeProps.figure_type === 'obstacle':
            case shapeProps.zone_type === 'obstacle':
                return 'lightgray'
            case shapeProps.newZone === 'safe_zone' || shapeProps.figure_type === 'safezone':
                return 'lightgreen'
            case shapeProps.newZone === 'action':
                return '#F9F5FF'
            case shapeProps.newZone === 'tag':
                return null
            default:
                return '#FEE4E2'
        }
    }

    const zoneBorderColor = () => {
        switch (true) {
            case shapeProps.newZone === 'obstacle' || shapeProps.figure_type === 'obstacle':
            case shapeProps.newZone === 'safe_zone' || shapeProps.figure_type === 'safezone':
            case shapeProps.zone_type === 'obstacle':
                return 'black'
            case shapeProps.newZone === 'action':
                return '#B692F6'
            case shapeProps.newZone === 'tag':
                return '#E7E9EC'
            default:
                return '#F04438'
        }
    }

    return (
        <>
            <Rect
                stroke={zoneBorderColor()}
                fill={zoneTypeColor()}
                strokeWidth={
                    width * height < smallerMapSize
                        ? 0.4
                        : width * height > biggerMapSize
                        ? 7
                        : width < 200
                        ? 1
                        : 1.8
                }
                onMouseDown={onSelect}
                opacity={0.6}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onDragMove={mouseMove}
                onDragEnd={(event) => {
                    const node = shapeRef.current
                    const scaleX = node.scaleX()
                    const scaleY = node.scaleY()
                    let corners = []

                    corners[0] = { x: 0, y: 0 } // top left
                    corners[1] = { x: shapeProps.width, y: 0 } // top right
                    corners[2] = { x: shapeProps.width, y: shapeProps.height } // bottom right
                    corners[3] = { x: 0, y: shapeProps.height } // bottom left

                    for (let i = 0; i < 4; i++) {
                        // Here be the magic
                        corners[i] = node.getTransform().point(corners[i])
                    }

                    let transformedArr = corners.map((cor) => [
                        Math.round(cor?.x),
                        Math.round(cor?.y),
                    ])

                    onAnnotationChange({
                        ...shapeProps,
                        x: Math.floor(event.target.x()),
                        y: Math.floor(event.target.y()),
                        // set minimal value
                        width: Math.round(Math.max(5, node.width() * scaleX)),
                        height: Math.round(Math.max(node.height() * scaleY)),
                        points: transformedArr,
                    })
                }}
                onClick={(e) => {
                    if (e.evt.button === 2) {
                        handleDelete()
                    }
                }}
                onTransform={handleTransformer}
                onTransformEnd={() => {
                    const node = shapeRef.current
                    const scaleX = node.scaleX()
                    const scaleY = node.scaleY()

                    let corners = []

                    corners[0] = { x: 0, y: 0 } // top left
                    corners[1] = { x: shapeProps.width, y: 0 } // top right
                    corners[2] = { x: shapeProps.width, y: shapeProps.height } // bottom right
                    corners[3] = { x: 0, y: shapeProps.height } // bottom left

                    for (let i = 0; i < corners.length; i++) {
                        // Here be the magic
                        corners[i] = node.getTransform().point(corners[i])
                    }

                    let transformedArr = corners.map((cor) => [
                        Math.round(cor?.x),
                        Math.round(cor?.y),
                    ])

                    onAnnotationChange({
                        ...shapeProps,
                        x: Math.round(node.x()),
                        y: Math.round(node.y()),
                        // set minimal value
                        width: Math.round(Math.max(5, node.width() * scaleX)),
                        height: Math.round(Math.max(node.height() * scaleY)),
                        points: transformedArr,
                    })
                }}
            />
            {isSelected && (
                <Transformer
                    ref={transformRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox
                        }
                        return newBox
                    }}
                />
            )}
            {isSelected && (
                <Label x={cursor.x + 4} y={cursor.y - 15}>
                    <Tag fill="#f0f1f3" cornerRadius={50}></Tag>
                    <Text
                        width={25}
                        height={25}
                        align={'center'}
                        offsetY={-2.3}
                        padding={8}
                        text={text + '°'}
                        fontSize={5}
                        fontStyle="bold"
                    ></Text>
                </Label>
            )}
            {hover !== '' && (
                <Text
                    x={cursor.x}
                    y={cursor.y - stageWidth}
                    align={'center'}
                    text={hover}
                    fontSize={stageWidth}
                    fontStyle="bold"
                ></Text>
            )}
        </>
    )
}

export default Annotation
