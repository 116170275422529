import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const Paginator = ({ pages, totalPages, activePage, handlePageChange }) => {
    return (
        <Pagination
            listClassName="justify-content-center p-2"
            size="sm"
            aria-label="Pages"
        >
            <PaginationItem>
                <PaginationLink
                    first
                    onClick={() => {
                        handlePageChange(0)
                    }}
                />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink
                    previous
                    onClick={() => {
                        if (activePage === 0) return
                        handlePageChange(activePage - 1)
                    }}
                />
            </PaginationItem>
            {pages.map((page) => (
                <PaginationItem active={page === activePage} key={page}>
                    <PaginationLink onClick={() => handlePageChange(page)}>
                        {page + 1}
                    </PaginationLink>
                </PaginationItem>
            ))}{' '}
            <PaginationItem>
                <PaginationLink
                    next
                    onClick={() => {
                        if (activePage === totalPages) return
                        handlePageChange(activePage + 1)
                    }}
                />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink
                    last
                    onClick={() => {
                        handlePageChange(totalPages - 1)
                    }}
                />
            </PaginationItem>
        </Pagination>
    )
}

export default Paginator
