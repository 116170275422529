import React, { Fragment, useEffect, useRef, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Row, Col, Spinner } from 'reactstrap'
import { useParams } from 'react-router-dom'
import cx from 'classnames'
import {
    MapContainer as Map,
    TileLayer,
    GeoJSON,
    Marker,
    Popup,
    Tooltip,
    LayerGroup,
} from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { divIcon } from 'leaflet'
import {
    faExpandAlt,
    faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons'

import { getTeamVehicles } from 'features/dashboard/Vehicle.actionTypes'
import { selectors } from 'features/dashboard'
import {
    getOutdoorArea,
    getOutdoorMapPoints,
} from 'features/dashboard/Map.actionTypes'
import { useToggler } from 'utils'
// import { Util } from 'konva'

const COPENHAGEN = { lat: 55.686724, lng: 12.5700724 }

const OutdoorMiniMap = () => {
    const dispatch = useDispatch()
    const [status, setStatus] = useState('loading')
    const [expand, setExpand] = useToggler()
    const { slug } = useParams()
    const mapStore = useSelector(selectors.getTeamMap)
    const vehicles = useSelector(selectors.getVehicles)
    const areaRef = useRef()
    const [colors, setColors] = useState({})

    const customMarkerIcon = ({ uuid, rotation }) => {
        return divIcon({
            html: renderToStaticMarkup(
                <FontAwesomeIcon
                    key={`icon_${uuid}`}
                    icon={faArrowCircleRight}
                    size="2x"
                    color={colors[uuid] || 'black'}
                    style={{ transform: `rotate(-${rotation}deg)` }}
                />
            ),
        })
    }
    useEffect(() => {
        setColors(() =>
            vehicles.results.reduce((acc, vehicle) => {
                if (!acc[vehicle.uuid])
                    acc[vehicle.uuid] = "grey"
                return acc
            }, colors)
        )
    }, [vehicles]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        Promise.all([
            dispatch(getOutdoorArea({ slug })),
            dispatch(getOutdoorMapPoints({ slug })),
        ]).then(() => setStatus('loaded'))

        const getVehicles = setInterval(() => {
            dispatch(getTeamVehicles({ slug, text: 'status=true' }))
        }, 2000)
        return () => clearInterval(getVehicles)
    }, [slug]) // eslint-disable-line

    return (
        <Row className={cx({ 'mini-map-expanded': expand })}>
            {status === 'loading' && <Spinner />}
            {status === 'loaded' && (
                <Col md={12} className={cx({ 'mini-map-expanded': expand })}>
                    <Map
                        whenReady={(e) => {
                            if (!mapStore.area.geometry) return
                            const bounds = mapStore.area.geometry.coordinates[0].map(
                                (coo) => [coo[1], coo[0]]
                            )
                            e.target.fitBounds(bounds)
                        }}
                        center={COPENHAGEN}
                        zoom={13}
                        style={{ maxHeight: expand ? '620px' : '400px' }}
                    >
                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-right">
                                <div className="leaflet-control-zoom leaflet-bar leaflet-control">
                                    <a
                                        className="leaflet-control-zoom-out"
                                        href="#expand"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setExpand()
                                            setTimeout(() => {
                                                areaRef.current._map.invalidateSize()
                                                const bounds = mapStore.area.geometry.coordinates[0].map(
                                                    (coo) => [coo[1], coo[0]]
                                                )
                                                areaRef.current._map.fitBounds(
                                                    bounds
                                                )
                                            }, 200)
                                        }}
                                        title="Expand"
                                        role="button"
                                        aria-label="Expand mini map"
                                    >
                                        <FontAwesomeIcon icon={faExpandAlt} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {mapStore.area?.geometry && (
                            <GeoJSON data={mapStore.area} ref={areaRef} />
                        )}
                        <LayerGroup>
                            {vehicles.results.map((vehicle) => {
                                const { details, uuid } = vehicle

                                if (details) {
                                    return (
                                        <Fragment key={uuid}>
                                            <Marker
                                                icon={customMarkerIcon({
                                                    uuid,
                                                    rotation: details.rotation,
                                                })}
                                                id={uuid}
                                                position={[
                                                    details.lat,
                                                    details.lon,
                                                ]}
                                            >
                                                <Tooltip>
                                                    {vehicle.verbose_name ||
                                                        vehicle.external_identifier}
                                                </Tooltip>
                                            </Marker>
                                        </Fragment>
                                    )
                                }
                                return null
                            })}
                            {Object.values(mapStore.outdoorPoints).map(
                                (point) => {
                                    return (
                                        <Marker
                                            key={point.properties.uuid}
                                            position={[
                                                point.geometry.coordinates[1],
                                                point.geometry.coordinates[0],
                                            ]}
                                        >
                                            <Popup>
                                                {point.properties.name}
                                            </Popup>
                                            <Tooltip>
                                                {point.properties.name}
                                            </Tooltip>
                                        </Marker>
                                    )
                                }
                            )}
                        </LayerGroup>
                    </Map>
                </Col>
            )}
        </Row>
    )
}

export default OutdoorMiniMap
