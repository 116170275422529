import React from 'react'
import * as Sentry from '@sentry/react'

import App from './App'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import configureStore from './store'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './i18n'
import './assets/base.scss'
import './assets/styles.scss'

// New import for createRoot
import { createRoot } from 'react-dom/client'

const store = configureStore()

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    sampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 0,
})

const rootElement = document.getElementById('root')

// New way to render the app using createRoot
if (rootElement) {
    const root = createRoot(rootElement)
    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    )
}

// Service worker configuration
serviceWorker.unregister()
