import { actionTypes } from './Notification.actionTypes'
import { chunkArray } from 'utils'

const initialState = {
    status: 'loading',
    settings: {},
    items: [],
    teamItems: [],
}

export const NotificationReducer = (state = initialState, action) => {
    const { payload } = action

    switch (action.type) {
        case actionTypes.MARK_ALL_READ:
        case actionTypes.DELETE_ALL:
        case actionTypes.DELETE_ONE:
        case actionTypes.DELETE_ALL_READ:
        case actionTypes.GET_NOTIFICATIONS:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.GET_TEAM_NOTIFICATIONS:
        case actionTypes.DELETE_ALL_TEAM_NOTIFICATIONS:
        case actionTypes.MARK_ALL_TEAM_NOTIFICATIONS_READ:
            return {
                ...state,
                status: 'team-notifications-loading',
            }
        case actionTypes.DELETE_ALL_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                items: [],
            }
        case actionTypes.DELETE_BULK_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                items: state.items.filter(function (item) {
                    return payload.data.uuids.indexOf(item) === -1
                }),
            }

        case actionTypes.DELETE_ONE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                items: state.items.filter((item) => item._id !== payload.data.uuid),
            }
        case actionTypes.GET_NOTIFICATIONS_SETTING:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.GET_NOTIFICATIONS_SETTING_SUCCESS:
            return {
                ...state,
                status: 'loading',
                settings: payload.data,
            }
        case actionTypes.MARK_ALL_READ_SUCCESS:
        case actionTypes.DELETE_ALL_READ_SUCCESS:
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            if (payload.data.notifications) {
                return {
                    ...state,
                    status: 'loaded',
                    items: chunkArray(payload.data.notifications, 10),
                }
            } else {
                return {
                    ...state,
                    status: 'loaded',
                    items: [],
                }
            }
        case actionTypes.MARK_READ_BULK_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                items: state.items.filter(function (item) {
                    return payload.data.uuids.indexOf(item) === -1
                }),
            }
        case actionTypes.MARK_READ_SUCCESS:
            return {
                ...state,
                items: state.items.map((chunkedItems) =>
                    chunkedItems.map((item) => {
                        if (item._id === payload.data._id) return payload.data
                        return item
                    })
                ),
            }
        case actionTypes.GET_TEAM_NOTIFICATIONS_SUCCESS:
        case actionTypes.MARK_ALL_TEAM_NOTIFICATIONS_READ_SUCCESS:
            if (payload.data) {
                return {
                    ...state,
                    status: 'team-notifications-loaded',
                    teamItems: chunkArray(payload.data, 10),
                }
            } else {
                return {
                    ...state,
                    status: 'team-notifications-loaded',
                    teamItems: [],
                }
            }
        case actionTypes.MARK_ONE_TEAM_NOTIFICATION_SUCCESS:
            return {
                ...state,
                teamItems: state.teamItems.map((chunkedItems) =>
                    chunkedItems.map((item) => {
                        if (item._id === payload.data._id) return payload.data
                        return item
                    })
                ),
            }
        case actionTypes.MARK_BULK_TEAM_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                status: 'team-notifications-loaded',
                teamItems: state.teamItems.filter(function (item) {
                    return payload.data.uuids.indexOf(item) === -1
                }),
            }

        case actionTypes.DELETE_ALL_TEAM_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                status: 'team-notifications-loaded',
                teamItems: [],
            }
        case actionTypes.DELETE_BULK_TEAM_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                status: 'team-notifications-loaded',
                teamItems: state.teamItems.filter(function (item) {
                    return payload.data.uuids.indexOf(item) === -1
                }),
            }

        case actionTypes.DELETE_ONE_TEAM_NOTIFICATION_SUCCESS:
            return {
                ...state,
                status: 'team-notifications-loaded',
                teamItems: state.teamItems.filter((item) => item._id !== payload.data.uuid),
            }

        case actionTypes.GET_NOTIFICATIONS_SETTING_FAIL:
        case actionTypes.MARK_ALL_READ_FAIL:
        case actionTypes.DELETE_ALL_FAIL:
        case actionTypes.GET_NOTIFICATIONS_FAIL:
            return {
                ...state,
                status: 'error',
                items: [],
            }
        case actionTypes.GET_TEAM_NOTIFICATIONS_FAIL:
        case actionTypes.DELETE_ALL_TEAM_NOTIFICATIONS_FAIL:
        case actionTypes.MARK_ALL_TEAM_NOTIFICATIONS_READ_FAIL:
            return {
                ...state,
                status: 'team-notifications-error',
                teamItems: [],
            }
        default:
            return state
    }
}
