import React from 'react'

const InfoPage = ({ message }) => (
    <div className="d-flex align-content-center justify-content-center flex-wrap vh-100">
        <div className="text-center">
            <h4 className="display-3 text-meili">{message ? message : 'Something went wrong!'}</h4>
        </div>
    </div>
)

export default InfoPage
