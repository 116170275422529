import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Tooltip,
    Polygon,
    Polyline,
    LayerGroup,
} from 'react-leaflet'
import { produce } from 'immer'

import { getOutdoorArea, saveOutdoorMapPointAndFinalize } from 'features/dashboard/Map.actionTypes'
import OutsideMapEditSideBar from './OutsideMapEditSideBar'
import OutdoorPathsListCard from './OutdoorPathsListCard'
import OutDoorPointsList from './OutDoorPointsList'
import OutdoorMapSaveButton from './OudoorMapSaveButton'

const COPENHAGEN = { lat: 55.686724, lng: 12.5700724 }

const OutsideMapLayer = ({ area, pointsStore, pathsStore }) => {
    const dispatch = useDispatch()
    const [newPoint, setNewPoint] = useState({
        active: false,
    })
    const [points, setPoints] = useState(pointsStore)
    const [paths, setPaths] = useState(pathsStore)
    const { slug } = useParams()
    const areaRef = useRef()
    const pointsRef = useRef()
    const markerRef = useRef()

    const updatePathsFromPoint = ({ lat, lng, uuid }) => {
        const newPaths = produce(paths, (draft) => {
            draft.features.map((path) => {
                const pointIndex = path.properties.points.findIndex(
                    (target) => target.uuid === uuid
                )
                if (pointIndex > -1) {
                    path.geometry.coordinates[pointIndex] = [lng, lat]
                }
                return path
            })
        })
        setPaths(newPaths)
    }

    const updatePoint = ({ lat, lng, uuid }) => {
        setPoints(() =>
            produce(points, (draft) => {
                draft[uuid].geometry.coordinates = [lng, lat]
            })
        )
    }

    const getNewPointLocationData = () => {
        const { lat, lng } = markerRef.current.getLatLng()
        return [lng, lat]
    }

    const getPointsData = () => {
        return Object.values(points).map((point) => ({
            ...point.properties,
            location_data: point.geometry.coordinates,
        }))
    }

    useEffect(() => {
        setPoints(pointsStore)
    }, [pointsStore])

    useEffect(() => {
        setPaths(pathsStore)
    }, [pathsStore])

    useEffect(() => {
        dispatch(getOutdoorArea({ slug }))
    }, [slug, dispatch])

    return (
        <>
            <Row className="pr-3 mb-3">
                <Col className="text-right" md={12}>
                    <OutdoorMapSaveButton
                        saveAction={saveOutdoorMapPointAndFinalize}
                        getMapData={() => getPointsData()}
                    />
                </Col>
            </Row>
            <Row className="pr-5">
                <Col md={8}>
                    <MapContainer
                        whenReady={(e) => {
                            const bounds = area.geometry.coordinates[0].map((coo) => [
                                coo[1],
                                coo[0],
                            ])
                            e.target.fitBounds(bounds)
                        }}
                        center={COPENHAGEN}
                        zoom={13}
                    >
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {area?.geometry && (
                            <Polygon
                                positions={area.geometry.coordinates[0].map((coo) => [
                                    coo[1],
                                    coo[0],
                                ])}
                                ref={areaRef}
                            />
                        )}
                        <LayerGroup ref={pointsRef}>
                            {Object.values(points).map((point) => {
                                return (
                                    <Marker
                                        key={point.properties.uuid}
                                        draggable
                                        eventHandlers={{
                                            dragend: (layer) => {
                                                const { lat, lng } = layer.target._latlng
                                                updatePathsFromPoint({
                                                    lat,
                                                    lng,
                                                    uuid: point.properties.uuid,
                                                })
                                                updatePoint({
                                                    lat,
                                                    lng,
                                                    uuid: point.properties.uuid,
                                                })
                                            },
                                        }}
                                        position={[
                                            point.geometry.coordinates[1],
                                            point.geometry.coordinates[0],
                                        ]}
                                    >
                                        <Popup>{point.properties.name}</Popup>
                                        <Tooltip>{point.properties.name}</Tooltip>
                                    </Marker>
                                )
                            })}
                        </LayerGroup>
                        {paths?.features.map((path) => {
                            const coordinates = path.geometry.coordinates.map((coordinate) => [
                                coordinate[1],
                                coordinate[0],
                            ])
                            return <Polyline key={path.properties.uuid} positions={coordinates} />
                        })}
                        {newPoint.active && (
                            <Marker
                                ref={markerRef}
                                title={newPoint.name}
                                draggable={true}
                                position={[newPoint.location_data[1], newPoint.location_data[0]]}
                                on
                            >
                                <Popup>{newPoint.name}</Popup>
                                <Tooltip>{newPoint.name}</Tooltip>
                            </Marker>
                        )}
                    </MapContainer>
                </Col>
                <Col md={3}>
                    <OutDoorPointsList
                        points={Object.values(points) || []}
                        newPoint={newPoint}
                        handleRemovePoint={() => {
                            setNewPoint({ active: false })
                        }}
                        handleNewPoint={(data) => {
                            const areaCenter = Object.values(areaRef?.current.getCenter()).reverse()
                            const location_data = data.location_data.map((loc, index) =>
                                !loc ? areaCenter[index] : loc
                            )
                            setNewPoint({
                                active: true,
                                getNewPointLocationData,
                                ...data,
                                location_data,
                            })
                            markerRef.current.getElement().style.filter = 'hue-rotate(45deg)'
                        }}
                    />
                    <OutdoorPathsListCard />
                </Col>
                <Col md={1}>
                    <OutsideMapEditSideBar onSelect={(loc) => this.changeLocation(loc)} />
                </Col>
            </Row>
        </>
    )
}

export default OutsideMapLayer
