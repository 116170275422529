import React from 'react'

import { Row, Card, CardBody, CardHeader, CardFooter, Button } from 'reactstrap'

import robot from 'assets/images/illustrations/robot.svg'

const PricePlan = ({ isActive = false, plan, onChange, onSelect }) => {
    return (
        <Card className="text-center">
            <CardHeader>
                <h3 className="text-uppercase text-bold">{plan.title}</h3>
            </CardHeader>
            <CardBody>
                {!isActive && <p>Starting From</p>}
                <h2>€ {parseFloat(plan.price || plan.base_price)} </h2>
                <p className="text-muted">{plan.subscription_type}</p>
                <Row
                    className="justify-content-center align-items-center"
                    style={{ height: '200px' }}
                >
                    {Array(2)
                        .fill(0)
                        .map((i, key) => (
                            <img
                                src={robot}
                                alt=""
                                width="30%"
                                key={`img_${i}_${key}`}
                            />
                        ))}
                </Row>
                {!isActive && (
                    <>
                        <p>€ {plan.perRobot} / Robot</p>
                        <p>
                            <b>{plan.robots}</b> Robots{' '}
                        </p>
                    </>
                )}
            </CardBody>
            <CardFooter>
                {isActive ? (
                    <Button block onClick={onChange} outline color="meili">
                        Change
                    </Button>
                ) : (
                    <Button block onClick={onSelect} outline color="meili">
                        Select
                    </Button>
                )}
            </CardFooter>
        </Card>
    )
}

export default PricePlan
