import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

const SidebarMenu = ({ hidden }) => {
    const { slug } = useParams()

    return (
        <div className="nav-deselect d-flex flex-column justify-content-center align-items-center">
            <NavLink
                className="d-flex justify-content-start align-items-center"
                style={{ width: hidden ? '' : '100%', marginBottom: '8px' }}
                activeClassName="nav-selected"
                exact
                to={`/dashboard/${slug}/`}
            >
                <img
                    src="/svgs/sidebar-icons/dashboard.svg"
                    alt="missions"
                    width="48px"
                    height="48px"
                    style={{ zIndex: '100' }}
                />
                {!hidden && <b style={{ zIndex: '100' }}>Dashboard</b>}
            </NavLink>
            <NavLink
                to={`/dashboard/${slug}/vehicles&devices/`}
                className="d-flex justify-content-start align-items-center"
                style={{ width: hidden ? '' : '100%', marginBottom: '8px' }}
                activeClassName="nav-selected"
            >
                <img
                    src="/svgs/sidebar-icons/vehicles.svg"
                    alt="missions"
                    width="48px"
                    height="48px"
                    style={{ zIndex: '100' }}
                />
                {!hidden && <b style={{ zIndex: '100' }}>Vehicles and devices</b>}
            </NavLink>
            <NavLink
                to={`/dashboard/${slug}/missions/`}
                className="d-flex justify-content-start align-items-center"
                style={{ width: hidden ? '' : '100%', marginBottom: '8px' }}
                activeClassName="nav-selected"
            >
                <img
                    src="/svgs/sidebar-icons/missions.svg"
                    alt="missions"
                    width="48px"
                    height="48px"
                    style={{ zIndex: '100' }}
                />

                {!hidden && <b style={{ zIndex: '100' }}>Missions</b>}
            </NavLink>
            <NavLink
                to={`/dashboard/${slug}/maps/`}
                className="d-flex justify-content-start align-items-center"
                style={{ width: hidden ? '' : '100%', marginBottom: '8px' }}
                activeClassName="nav-selected"
            >
                <img
                    src="/svgs/sidebar-icons/maps.svg"
                    alt="missions"
                    width="48px"
                    height="48px"
                    style={{ zIndex: '100' }}
                />
                {!hidden && <b style={{ zIndex: '100' }}>Maps</b>}
            </NavLink>
            <NavLink
                className="d-flex justify-content-start align-items-center"
                style={{ width: hidden ? '' : '100%', marginBottom: '8px' }}
                activeClassName="nav-selected"
                to={`/dashboard/${slug}/actions/`}
            >
                <img
                    src="/svgs/sidebar-icons/actions.svg"
                    alt="missions"
                    width="48px"
                    height="48px"
                    style={{ zIndex: '100' }}
                />
                {!hidden && <b style={{ zIndex: '100' }}>Actions</b>}
            </NavLink>
            {/* <NavLink
                className="d-flex justify-content-start align-items-center"
                style={{ width: hidden ? '' : '100%', marginBottom: '8px' }}
                activeClassName="nav-selected"
                to={`/dashboard/${slug}/analytics/`}
            >
                <img
                    src="/svgs/sidebar-icons/analytics.svg"
                    alt="missions"
                    width="48px"
                    height="48px"
                       style={{ zIndex: '100' }}
                />
                {!hidden && <b    style={{ zIndex: '100' }}>Analytics</b>}
            </NavLink> */}
            <div className="w-100 my-2" style={{ borderTop: '1px solid #BA4FFA' }}>
                {' '}
            </div>
            <NavLink
                className="d-flex justify-content-start align-items-center"
                style={{ width: hidden ? '' : '100%', marginBottom: '8px' }}
                activeClassName="nav-selected"
                to={`/dashboard/${slug}/support/`}
            >
                <img
                    src="/svgs/sidebar-icons/support.svg"
                    alt="missions"
                    width="48px"
                    height="48px"
                    style={{ zIndex: '100' }}
                />
                {!hidden && <b style={{ zIndex: '100' }}>Support</b>}
            </NavLink>
            <NavLink
                className="d-flex justify-content-start align-items-center"
                style={{ width: hidden ? '' : '100%', marginBottom: '8px' }}
                activeClassName="nav-selected"
                to={`/dashboard/${slug}/settings/`}
            >
                <img
                    src="/svgs/sidebar-icons/settings.svg"
                    alt="missions"
                    width="48px"
                    height="48px"
                    style={{ zIndex: '100' }}
                />
                {!hidden && <b style={{ zIndex: '100' }}>Settings</b>}
            </NavLink>
        </div>
    )
}

export default SidebarMenu
