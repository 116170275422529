import React from 'react'
import { FaCheck } from 'react-icons/fa'

const SuccessUpload = ({ fileType }) => {
    return fileType ? (
        <div className="d-flex flex-column w-100 w-md-50 m-2 text-center align-items-center justify-content-between border-1 rounded border-gray p-5">
            <FaCheck className={`w-25 h-25 text-success`} />

            <strong className="my-3">The {fileType} is attached</strong>
        </div>
    ) : (
        <div className="d-flex flex-column w-100 w-md-50 m-2 text-center align-items-center justify-content-between border-1 rounded border-gray p-5">
            <FaCheck className={`w-25 h-25 text-success`} />

            <strong className="my-3">The map was successfully uploaded</strong>
        </div>
    )
}

export default SuccessUpload
