import React, { useEffect, useState } from 'react'
import { Field, Formik } from 'formik'
import { Button, Form, FormGroup, Label, Modal, ModalBody } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { useDispatch, useSelector } from 'react-redux'
import { HiOutlineTrash } from 'react-icons/hi'
import { selectors } from 'features/dashboard'
import { createMember } from 'features/dashboard/Team.actionTypes'
import {
    createOrgMember,
    getOrgMembers,
    getOrgTeams,
    updateOrgMembership,
} from 'features/dashboard/Organization.actionTypes'
import { roleAndDescription } from './OrgTeamHelpers'
import DropdownSelect from 'layout-components/Dropdown'

const MembersModal = ({ form, modal, toggle, slug, member = {}, setForm }) => {
    const dispatch = useDispatch()
    const teams = useSelector(selectors.getOrganizationTeams)
    const [addTeam, setAddTeam] = useState(false)
    const [team, setTeam] = useState('')
    const [memberType, setMemberType] = useState(
        (member?.membership_type &&
            member?.membership_type[0].toUpperCase() + member?.membership_type.substring(1)) ||
            'Observer'
    )

    useEffect(() => {
        if (document.getElementById('membership_description'))
            document.getElementById('membership_description').innerText =
                roleAndDescription['organization']['observer']
    }, [roleAndDescription]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (data) => {
        if (form === 'add-member') {
            if (data.team) {
                return dispatch(
                    createMember({
                        slug: data.team,
                        data: {
                            email: data.email,
                            membership_type: data.membership_type,
                        },
                    })
                ).then(({ error }) => {
                    if (!error) {
                        toggle()
                    }
                    dispatch(getOrgMembers(slug))
                    dispatch(getOrgTeams(slug, true))
                })
            }
            return dispatch(createOrgMember({ slug, data })).then(({ error }) => {
                if (!error) {
                    toggle()
                }
                dispatch(getOrgMembers(slug))
                dispatch(getOrgTeams(slug, true))
            })
        }

        if (form === 'edit-member') {
            dispatch(updateOrgMembership(data)).then(({ error }) => {
                if (!error) {
                    toggle()
                }
                dispatch(getOrgMembers(slug))
                dispatch(getOrgTeams(slug, true))
            })
        }
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="lg">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/organization/members/team-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column pb-4">
                    <span className="org-modal-header">
                        {form === 'add-member' ? 'Add organisation members' : 'Member settings'}
                    </span>
                </div>
                <Formik initialValues={member} onSubmit={handleSubmit}>
                    {({ values, handleSubmit, setFieldValue }) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            {form === 'add-member' && (
                                <>
                                    <FormGroup>
                                        <Label className="modals-labels">Email</Label>
                                        <div className="d-flex justify-content-start align-items-center w-100">
                                            <div className="w-75 mr-1">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ height: '40px' }}
                                                    type="email"
                                                    name="email"
                                                    placeholder="name@email.com"
                                                    component={customInputForm}
                                                />
                                            </div>
                                            <div className="w-25 ml-1">
                                                <DropdownSelect
                                                    selected={memberType}
                                                    setSelected={setMemberType}
                                                    options={Object.keys(
                                                        roleAndDescription['organization']
                                                    ).map((user) => {
                                                        return {
                                                            uuid: user,
                                                            title: `${user[0].toUpperCase()}${user.substring(
                                                                1
                                                            )}`,
                                                        }
                                                    })}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue="membership_type"
                                                />
                                            </div>
                                            {/* <Field
                                                className="modals-inputs w-25 ml-2"
                                                component={customInputForm}
                                                id="type"
                                                name="membership_type"
                                                type="select"
                                                value={values.membership_type || ''}
                                                onChange={(e) => {
                                                    document.getElementById(
                                                        'membership_description'
                                                    ).innerText =
                                                        roleAndDescription['organization'][
                                                            e.target.value
                                                        ]

                                                    setFieldValue('membership_type', e.target.value)
                                                }}
                                            >
                                                {Object.keys(
                                                    roleAndDescription['organization']
                                                ).map((user) => (
                                                    <option key={user} value={user}>
                                                        {`${user[0].toUpperCase()}${user.substring(
                                                            1
                                                        )}`}
                                                    </option>
                                                ))}
                                            </Field> */}
                                        </div>
                                        <small
                                            color="none"
                                            id="membership_description"
                                            style={{
                                                color: 'black',
                                            }}
                                        />
                                    </FormGroup>

                                    {addTeam ? (
                                        <FormGroup>
                                            <Label
                                                className="modals-labels"
                                                for="communication_protocol"
                                            >
                                                Team
                                            </Label>
                                            <div className="d-flex align-items-center">
                                                <DropdownSelect
                                                    selected={team}
                                                    setSelected={setTeam}
                                                    options={teams.map((team) => team)}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue="team"
                                                />
                                                {/* <Field
                                                    className="modals-inputs"
                                                    component={customInputForm}
                                                    type="select"
                                                    value={values.team || ''}
                                                    name="team"
                                                >
                                                    <option value=""></option>
                                                    {teams.map((team) => (
                                                        <option key={team.uuid} value={team.uuid}>
                                                            {team.title}
                                                        </option>
                                                    ))}
                                                </Field> */}
                                                <img
                                                    src="/svgs/close-icon/x-dark-default.svg"
                                                    alt="obstacle-icon"
                                                    width="20px"
                                                    height="20px"
                                                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                                                    onClick={() => {
                                                        setAddTeam(false)
                                                        setFieldValue('team', null)
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    ) : (
                                        <Button
                                            className="w-100 bg-white my-2"
                                            style={{
                                                color: '#4E5462',
                                                fontSize: '14px',
                                                lineHeight: '28px',
                                                borderRadius: '8px',
                                                border: '1px solid #E7E9EC',
                                                height: '40px',
                                                padding: '6px 14px 6px 14px',
                                            }}
                                            onClick={() => setAddTeam(true)}
                                        >
                                            Add to team
                                        </Button>
                                    )}
                                </>
                            )}

                            {form === 'edit-member' && (
                                <>
                                    <FormGroup>
                                        <Label className="modals-labels">Membership role</Label>
                                        <DropdownSelect
                                            selected={memberType}
                                            setSelected={setMemberType}
                                            options={Object.keys(
                                                roleAndDescription['organization']
                                            ).map((user) => {
                                                return {
                                                    uuid: user,
                                                    title: `${user[0].toUpperCase()}${user.substring(
                                                        1
                                                    )}`,
                                                }
                                            })}
                                            setFieldValue={setFieldValue}
                                            fieldValue="membership_type"
                                        />
                                        {/* <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="type"
                                            name="membership_type"
                                            type="select"
                                            value={values.membership_type || ''}
                                            onChange={(e) => {
                                                document.getElementById(
                                                    'membership_description'
                                                ).innerText =
                                                    roleAndDescription['organization'][
                                                        e.target.value
                                                    ]

                                                setFieldValue('membership_type', e.target.value)
                                            }}
                                        >
                                            {Object.keys(roleAndDescription['organization']).map(
                                                (user) => (
                                                    <option key={user} value={user}>
                                                        {`${user[0].toUpperCase()}${user.substring(
                                                            1
                                                        )}`}
                                                    </option>
                                                )
                                            )}
                                        </Field> */}
                                        <small
                                            color="none"
                                            id="membership_description"
                                            style={{
                                                color: 'black',
                                            }}
                                        />
                                    </FormGroup>
                                </>
                            )}

                            {/* <div
                                style={{
                                    marginTop: '24px',
                                    border: '1px dashed var(--grey-100, #e7e9ec)',
                                    cursor: 'pointer',
                                }}
                                className="modals-inputs"
                                onClick={() => addField(values, setFieldValue)}
                            >
                                <img src="/svgs/trigger-actions/add-remove.svg" alt="add-circle" />
                                <span className="ml-2" style={{ color: '#9DA3B0' }}>
                                    Add state
                                </span>
                            </div> */}
                            <div className="new-modal-footer">
                                {form === 'edit-member' ? (
                                    <Button
                                        color="none"
                                        onClick={() => {
                                            setForm('remove-member')
                                        }}
                                        className="modals-delete-btn"
                                    >
                                        <HiOutlineTrash size={20} className="mr-1" />
                                        Delete
                                    </Button>
                                ) : (
                                    <div></div>
                                )}
                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={
                                            form === 'edit-member' &&
                                            JSON.stringify(values) === JSON.stringify(member)
                                        }
                                    >
                                        {form === 'edit-member' ? 'Save' : 'Send invite'}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default MembersModal
