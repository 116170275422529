export const handleConversion = (data) => {
    if (data.robotPositioning) {
        return data
    }
    if (data.length) {
        const savedData = data.map((point) => {
            point = point = { ...point, rotation: 450 - point.rotation }
            if (point.rotation > 360) point = { ...point, rotation: point.rotation - 360 }
            return point
        })
        return savedData
    }
    data.rotation = 450 - data.rotation
    if (data.rotation > 360) data.rotation = data.rotation - 360
    return data
}

export const handleWheel = (e, stageRef, isScrollingDown, sizes, setSize) => {
    if (e.evt.ctrlKey || e.evt.metaKey) {
        e.evt.preventDefault()
        const scaleBy = 1.05
        const stage = stageRef
        const oldScale = stage.scaleX()
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
        }
        const invertedScaleBy = isScrollingDown ? 1 / scaleBy : scaleBy
        const newScale = oldScale * invertedScaleBy
        const stageX = -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale
        const stageY = -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale

        if (sizes && setSize) {
            setSize({ ...sizes, y: stageY, x: stageX, scale: newScale, width: stage.width(), height: stage.height() })
        }

        stage.position({
            x: stageX,
            y: stageY,
        })
        stage.scale({ x: newScale, y: newScale })
        stage.batchDraw()
    }
}

export const handleZoomBtns = (stageRef, zoomIn) => {
    const scaleBy = 1.05
    const stage = stageRef
    const oldScale = stage.scaleX()

    const newScaleX = zoomIn ? oldScale * scaleBy : oldScale / scaleBy
    const newScaleY = zoomIn ? oldScale * scaleBy : oldScale / scaleBy

    const mousePointTo = {
        x: window.innerWidth / 2 / oldScale - stage.x() / oldScale,
        y: window.innerHeight / 2 / oldScale - stage.y() / oldScale,
    }

    const stageX = -(mousePointTo.x - window.innerWidth / 2 / newScaleX) * newScaleX
    const stageY = -(mousePointTo.y - window.innerHeight / 2 / newScaleY) * newScaleY

    stage.position({
        x: stageX,
        y: stageY,
    })
    stage.scale({ x: newScaleX, y: newScaleY })
    stage.batchDraw()
}
