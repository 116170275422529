const messageUpdaterMiddleware = (store) => (next) => (action) => {
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

    // Check if the action type is one that should update the message state
    if (
        (action.type.includes('_FAIL') || action.type.includes('_SUCCESS')) &&
        !action.type.includes('GET_') &&
        !action.type.includes('CAN_') &&
        !action.type.includes('FILTER_')
    ) {
        // Dispatch a new action to update the message state
        store.dispatch({
            type: 'SET_MESSAGES',
            messages: [
                {
                    text: action.type.includes('_FAIL')
                        ? action.error.data
                            ? action.error.data
                            : action.error.response.data?.detail
                            ? action.error.response.data?.detail
                            : action.error.response.data?.non_field_errors
                            ? action.error.response.data?.non_field_errors
                            : 'Something went wrong'
                        : 'Success',
                    type: action.type.includes('_FAIL') ? 'danger' : 'success',
                },
            ],
        })
    }

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
}

export default messageUpdaterMiddleware
