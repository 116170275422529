import React, { useState } from 'react'
import cx from 'classnames'
import {
    Col,
    NavLink,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    Button,
    FormGroup,
    Label,
    Spinner,
    Row,
    Input,
    UncontrolledTooltip,
} from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from 'features/dashboard'
import { CommonErrors, customInputForm } from 'ui/common'
import { createPointWithPosition, getMapPoints } from 'features/dashboard/Map.actionTypes'
import { getTeamMap } from 'features/dashboard/selectors'
import { BsInfoCircle } from 'react-icons/bs'

const PointForm = ({ point, slug, handleNewPoint, toggle }) => {
    const [activeTab, setActiveTab] = useState('Details')
    const dispatch = useDispatch()
    const map = useSelector(getTeamMap)
    const vehicles = useSelector(selectors.getVehicles)

    const handleSubmit = (data, action) => {
        data.robotPositioning
            ? dispatch(createPointWithPosition({ slug, data })).then(({ error }) => {
                  action.setSubmitting(false)
                  if (error) {
                      action.setErrors(error.response.data)
                      return
                  }
                  dispatch(getMapPoints(slug))
              })
            : handleNewPoint(data)
        toggle()
        return
    }
    const calcToMetric = (value, name) => {
        const data = map.areaDetails
        const ox = Math.abs(Math.floor(data.origin_x / data.resolution))
        const oy = Math.abs(Math.floor(data.origin_y / data.resolution))

        //takes Metric X and convert it into user input(?)
        if (name === 'xm') {
            return Number(Math.floor(value / data.resolution) + ox)
        }
        //takes Metric Y and convert it into user input(?)
        else if (name === 'ym') {
            return Number(Math.floor(-1 * (value / data.resolution) + (data.original_height - oy)))
        }
        //takes Position X and convert it into metric
        else if (name === 'x') {
            return Number((value - ox) * data.resolution).toFixed(2)
        }
        //takes Position Y and convert it into metric
        else if (name === 'y') {
            return Number((-1 * value + (data.original_height - oy)) * data.resolution).toFixed(2)
        } else return false
    }
    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={cx({ active: activeTab === 'Details' })}
                        onClick={() => setActiveTab('Details')}
                    >
                        Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={cx({ active: activeTab === 'Position' })}
                        onClick={() => setActiveTab('Position')}
                    >
                        Position
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="Details">
                    <Col className="my-3" md={12}>
                        <Formik
                            initialValues={{
                                ...point,
                            }}
                            onSubmit={handleSubmit}
                        >
                            {({ values, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <FormGroup>
                                        <Label for="name">Name</Label>
                                        <Field
                                            component={customInputForm}
                                            bsSize="md"
                                            type="text"
                                            name="name"
                                            value={values.name || ''}
                                            placeholder="Name"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="point_type">Point Type</Label>
                                        <Field
                                            component={customInputForm}
                                            bsSize="md"
                                            type="select"
                                            name="point_type"
                                            value={values.point_type || ''}
                                        >
                                            <option value=""></option>
                                            <option value="drop_off">Drop off</option>
                                            <option value="charging">Charging Station</option>
                                            <option value="entrance">Entrance</option>
                                        </Field>
                                    </FormGroup>
                                    <FormGroup check className="mb-0">
                                        <Input
                                            type="checkbox"
                                            label="Use Robot Positioning"
                                            id="robotPositioning"
                                            onChange={() => {
                                                setFieldValue(
                                                    'robotPositioning',
                                                    !values.robotPositioning
                                                )
                                            }}
                                        />
                                        <Label check> Use Robot Positioning</Label>
                                    </FormGroup>
                                    {values.robotPositioning && (
                                        <FormGroup>
                                            <Label for="rotation">Connected Robots</Label>

                                            <Field
                                                component={customInputForm}
                                                bsSize="lg"
                                                type="select"
                                                name="vehicle"
                                                value={values.vehicle || ''}
                                            >
                                                <option value=""></option>
                                                {vehicles.teamVehicle.map(
                                                    ({ details, ...vehicle }, key) =>
                                                        details && (
                                                            <option key={key} value={vehicle.uuid}>
                                                                {vehicle.verbose_name}
                                                            </option>
                                                        )
                                                )}
                                            </Field>
                                        </FormGroup>
                                    )}

                                    {!values.robotPositioning && (
                                        <FormGroup>
                                            <Label for="rotation">Rotation</Label>
                                            <Field
                                                component={customInputForm}
                                                bsSize="md"
                                                type="number"
                                                name="rotation"
                                                placeholder=""
                                                min={0}
                                                max={360}
                                                value={values.rotation >= 0 ? values.rotation : ''}
                                                onClick={(e) => e.target.select()}
                                            />
                                        </FormGroup>
                                    )}

                                    <Button type="submit" color="primary" disabled={isSubmitting}>
                                        {values.uuid ? 'Save' : 'Add'}
                                        {isSubmitting && <Spinner size="sm" color="white ml-2" />}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </TabPane>

                <TabPane tabId="Position">
                    <Col className="my-3" md={12}>
                        <Formik
                            initialValues={{
                                // ...point,
                                xm: calcToMetric(Number(point?.x), 'x'),
                                ym: calcToMetric(Number(point?.y), 'y'),
                            }}
                            onSubmit={handleSubmit}
                            initialStatus={{}}
                        >
                            {({ values, isSubmitting, setFieldValue, status, setValues }) => (
                                <Form>
                                    <Row>
                                        <Col md={2}></Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="xm">
                                                    Metric X
                                                    <BsInfoCircle
                                                        id="metricx"
                                                        className="font-size-md info-icon rounded-circle ml-1 mb-1"
                                                    ></BsInfoCircle>
                                                </Label>
                                                <UncontrolledTooltip
                                                    placement="top"
                                                    target="metricx"
                                                >
                                                    Coordinate relative to the origin of the map
                                                    displayed in metres
                                                </UncontrolledTooltip>
                                                <Field
                                                    component={customInputForm}
                                                    bsSize="md"
                                                    type="number"
                                                    name="xm"
                                                    values={status.xm}
                                                    step="0.05"
                                                    placeholder="Metric X"
                                                    onChange={(e) => {
                                                        setValues({
                                                            ...values,
                                                            xm: Number(e.target.value),
                                                        })
                                                        setFieldValue(
                                                            'x',
                                                            calcToMetric(
                                                                Number(e.target.value),
                                                                'xm'
                                                            )
                                                        )
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="x">
                                                    Pixel X
                                                    <BsInfoCircle
                                                        id="coordinatex"
                                                        className="font-size-md info-icon rounded-circle ml-1 mb-1"
                                                    ></BsInfoCircle>
                                                </Label>
                                                <UncontrolledTooltip
                                                    placement="top"
                                                    target="coordinatex"
                                                >
                                                    Absolute coordinate displayed in pixels
                                                </UncontrolledTooltip>
                                                <Field
                                                    component={customInputForm}
                                                    bsSize="md"
                                                    type="number"
                                                    name="x"
                                                    values={values.x || 0}
                                                    placeholder="Position X"
                                                    onChange={(e) => {
                                                        setValues({
                                                            ...values,
                                                            x: Number(e.target.value),
                                                        })
                                                        setFieldValue(
                                                            'xm',
                                                            calcToMetric(
                                                                Number(e.target.value),
                                                                'x'
                                                            )
                                                        )
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={2}></Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="ym">Metric Y</Label>
                                                <Field
                                                    component={customInputForm}
                                                    bsSize="md"
                                                    type="number"
                                                    name="ym"
                                                    values={status.ym}
                                                    placeholder="Metric Y"
                                                    step="0.05"
                                                    onChange={(e) => {
                                                        setValues({
                                                            ...values,
                                                            ym: Number(e.target.value),
                                                        })
                                                        setFieldValue(
                                                            'y',
                                                            calcToMetric(
                                                                Number(e.target.value),
                                                                'ym',
                                                                values.uuid
                                                            )
                                                        )
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="y">Pixel Y</Label>
                                                <Field
                                                    component={customInputForm}
                                                    bsSize="md"
                                                    type="number"
                                                    name="y"
                                                    values={values.y || 0}
                                                    placeholder="Position Y"
                                                    onChange={(e) => {
                                                        setValues({
                                                            ...values,
                                                            y: Number(e.target.value),
                                                        })
                                                        setFieldValue(
                                                            'ym',
                                                            calcToMetric(
                                                                Number(e.target.value),
                                                                'y',
                                                                values.uuid
                                                            )
                                                        )
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <CommonErrors />
                                    {point?.uuid && (
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                        >
                                            {values.uuid ? 'Save' : 'Add'}
                                            {isSubmitting && (
                                                <Spinner size="sm" color="white ml-2" />
                                            )}
                                        </Button>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </TabPane>
            </TabContent>
        </>
    )
}

export default PointForm
