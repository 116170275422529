import React, { useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap'
import { TITLE } from 'config'
import BootstrapTable from 'react-bootstrap-table-next'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {
    getOrgTeams,
    getOrgMembers,
    removeOrgMember,
} from 'features/dashboard/Organization.actionTypes'
import ManageOrganizationModals from './ManageOrganizationModals'
import { selectors } from 'features/dashboard'
import dayjs from 'dayjs'
import { teamsColours, validateDate } from './OrgTeamHelpers'
import { deleteTeam } from 'features/dashboard/Team.actionTypes'
// import Filter from 'layout-components/Filter'

const OrganizationManage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const members = useSelector(selectors.getOrgMembers)
    const adminOrgs = useSelector(selectors.getAdminOrgs)
    const teams = useSelector(selectors.getOrganizationTeams)
    const [form, setForm] = useState(null)
    const [modal, setModal] = useState(false)
    const [dataOnClick, setDataOnClick] = useState({})
    const [activeTab, setActiveTab] = useState(localStorage.getItem('org-manage-tab') || 'members')
    const [btnText, setButtonText] = useState('Add member')
    const [lastClickedPageAdmins, setLastClickedPageAdmins] = useState(1)
    const [lastClickedPageMembers, setLastClickedPageMembers] = useState(1)
    const [lastClickedPageTeams, setLastClickedPageTeams] = useState(1)
    const [adminsSort, setAdminsSort] = useState('asc')
    const [membersSort, setMembersSort] = useState('asc')
    const [teamsSort, setTeamsSort] = useState('asc')
    // const [dropdownOpen, setDropdownOpen] = useState(false)
    // const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggle = () => setModal(!modal)

    useEffect(() => {
        dispatch(getOrgTeams(currentOrg.slug, true))
        dispatch(getOrgMembers(currentOrg.slug))
    }, [currentOrg.slug, dispatch])

    useEffect(() => {
        if (activeTab === 'members') {
            document.title = `${TITLE} - Members manage`
            setButtonText('Add member')
            setLastClickedPageTeams(1)
        }
        if (activeTab === 'teams') {
            document.title = `${TITLE} - Teams manage`
            setButtonText('Add team')
            setLastClickedPageAdmins(1)
            setLastClickedPageMembers(1)
        }
    }, [activeTab, TITLE]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modal) {
            setDataOnClick({})
            setForm(null)
        }
    }, [modal])

    const handleRemoveFromOrg = ({ uuid }) => {
        if (activeTab === 'members') {
            dispatch(removeOrgMember({ uuid })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgMembers(currentOrg.slug))
                    dispatch(getOrgTeams(currentOrg.slug, true))
                }
                toggle()
            })
        }

        if (activeTab === 'teams') {
            dispatch(deleteTeam({ uuid })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgTeams(currentOrg.slug, true))
                    dispatch(getOrgMembers(currentOrg.slug))
                }
                toggle()
            })
        }
    }

    const RenderOrgActions = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/map-editor/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )

    const adminsList = useMemo(() => {
        return members
            .filter(
                (member) => member.membership_type === 'owner' || member.membership_type === 'admin'
            )
            .map(({ user, membership_type, created_at, uuid }) => ({
                uuid: user.uuid,
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                type: <div className="team-div-cell">{membership_type}</div>,
                created_at: <div className="text-dark">{validateDate(dayjs(created_at))}</div>,
                teams: (
                    <div className="teams-grid">
                        {teams.filter((team) => team?.creator?.uuid === user.uuid).length > 0 ? (
                            teams
                                .filter((team) => team?.creator?.uuid === user.uuid)
                                .map((team, index) => {
                                    const colorIndex = index % teamsColours.length // Cycles through 0 to 3
                                    const { bg, text } = teamsColours[colorIndex]
                                    return (
                                        <div
                                            key={team.uuid}
                                            className="team-div-cell"
                                            style={{
                                                backgroundColor: bg,
                                                color: text,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/dashboard/organization/teams/${team.slug}/`,
                                                    state: { slug: team.slug, team: team },
                                                })
                                            }
                                        >
                                            {team.title}
                                        </div>
                                    )
                                })
                        ) : (
                            <div className="team-div-cell">No teams found</div> // or <div className="no-teams">/</div> for a slash
                        )}
                    </div>
                ),
                action: (
                    <RenderOrgActions
                        item={{ user, uuid, membership_type }}
                        form={{ edit: 'edit-member', remove: 'remove-member' }}
                    />
                ),
            }))
    }, [members, teams]) // eslint-disable-line react-hooks/exhaustive-deps

    const membersList = useMemo(() => {
        return members
            .filter(
                (member) => member.membership_type !== 'owner' && member.membership_type !== 'admin'
            )
            .map(({ user, membership_type, created_at, uuid }) => ({
                uuid: user.uuid,
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                type: <div className="team-div-cell">{membership_type}</div>,
                created_at: <div className="text-dark">{validateDate(dayjs(created_at))}</div>,
                teams: (
                    <div className="teams-grid">
                        {teams.filter((team) => team?.creator?.uuid === user.uuid).length > 0 ? (
                            teams
                                .filter((team) => team?.creator?.uuid === user.uuid)
                                .map((team, index) => {
                                    const colorIndex = index % teamsColours.length // Cycles through 0 to 3
                                    const { bg, text } = teamsColours[colorIndex]
                                    return (
                                        <div
                                            key={team.uuid}
                                            className="team-div-cell"
                                            style={{
                                                backgroundColor: bg,
                                                color: text,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/dashboard/organization/teams/${team.slug}/`,
                                                    state: { slug: team.slug, team: team },
                                                })
                                            }
                                        >
                                            {team.title}
                                        </div>
                                    )
                                })
                        ) : (
                            <div className="team-div-cell">No teams found</div> // or <div className="no-teams">/</div> for a slash
                        )}
                    </div>
                ),
                action: (
                    <RenderOrgActions
                        item={{ user, uuid, membership_type }}
                        form={{ edit: 'edit-member', remove: 'remove-member' }}
                    />
                ),
            }))
    }, [members, teams]) // eslint-disable-line react-hooks/exhaustive-deps

    const membersColumns = [
        {
            dataField: 'name',
            text: 'Name',
            headerClasses: 'custom-header pl-4',
            classes: 'custom-cell',
            style: { paddingLeft: '24px' },
            sort: true,
            onSort: (field, order) => {
                setAdminsSort(order)
                setMembersSort(order)
            },
            formatter: (cell, row) => {
                return (
                    <div className="d-flex flex-column">
                        <span>{cell}</span>
                        <span style={{ fontWeight: 'lighter' }}>{row.email}</span>
                    </div>
                )
            },
        },
        {
            dataField: 'type',
            text: 'Type',
            headerClasses: 'custom-header',
            style: { position: 'relative' },
        },
        {
            dataField: 'created_at',
            text: 'created at',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
        },
        {
            dataField: 'teams',
            text: 'Teams',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
        },
        {
            dataField: 'action',
            text: '',
            headerClasses: 'custom-header',
            headerStyle: { width: '128px' },
            style: { paddingRight: '24px' },
        },
    ]

    const teamsList = useMemo(() => {
        return teams.map((team) => ({
            uuid: team.uuid,
            team,
            title: team.title || '-',
            owners: team?.memberships
                ?.filter((member) => member.membership_type === 'owner')
                .map(
                    ({ user }) =>
                        `${user?.first_name || '-'} ${user?.last_name || ''}` || user?.email
                )
                .join(', '),
            team_members: (
                <div className="teams-grid">
                    {team?.memberships?.map(({ user }) => (
                        <div key={user.uuid} className="team-div-cell">
                            {user.first_name && user.last_name
                                ? `${user.first_name} ${user.last_name}`
                                : user.email}
                        </div>
                    ))}
                </div>
            ),
            action: (
                <RenderOrgActions item={team} form={{ edit: 'edit-team', remove: 'remove-team' }} />
            ),
        }))
    }, [teams])

    const teamsColumns = [
        {
            dataField: 'title',
            text: 'title',
            headerClasses: 'custom-header pl-4',
            classes: 'custom-cell',
            style: { paddingLeft: '24px' },
            sort: true,
            onSort: (field, order) => {
                setTeamsSort(order)
            },
            formatter: (cell, { team }) => {
                return (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push({
                                pathname: `/dashboard/organization/teams/${team.slug}/`,
                                state: { slug: team.slug, team: team },
                            })
                        }
                    >
                        {cell}
                    </div>
                )
            },
        },
        {
            dataField: 'owners',
            text: 'Owners',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
        },
        {
            dataField: 'team_members',
            text: 'Team members',
            headerClasses: 'custom-header',
            classes: 'custom-cell',
        },
        {
            dataField: 'action',
            text: '',
            headerClasses: 'custom-header',
            headerStyle: { width: '128px' },
            style: { paddingRight: '24px' },
        },
    ]

    return (
        <div className="big-org-container">
            <div className="buttons-container-org">
                <ButtonGroup className="org-button-group">
                    <Button
                        className={`${
                            activeTab === 'members' ? 'org-button-selected' : ''
                        } org-button`}
                        style={{ borderRadius: '6px' }}
                        onClick={() => {
                            setActiveTab('members')
                            localStorage.setItem('org-manage-tab', 'members')
                        }}
                    >
                        Organisation members
                    </Button>
                    <Button
                        className={`${
                            activeTab === 'teams' ? 'org-button-selected' : ''
                        } org-button`}
                        style={{ borderRadius: '6px' }}
                        onClick={() => {
                            setActiveTab('teams')
                            localStorage.setItem('org-manage-tab', 'teams')
                        }}
                    >
                        Teams
                    </Button>
                </ButtonGroup>
            </div>
            <div className="heading-container-org">
                <div>
                    <div className="mb-2 d-flex align-items-center">
                        <span className="header-text-org">
                            {activeTab === 'members' ? 'Organisation members' : 'Teams'}
                        </span>

                        <span
                            className="text-meili rounded px-2"
                            style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                        >
                            {adminOrgs.status === 'loaded' &&
                                (activeTab === 'members'
                                    ? `${adminOrgs?.results?.length} members`
                                    : `${adminOrgs?.teams?.length} teams`)}
                        </span>
                    </div>
                    <span className="small-text-org">
                        {activeTab === 'members'
                            ? ' Manage your organisation members, their teams, and their account permissions here.'
                            : 'Manage your teams and their members here. Click the edit icon to change your team’s individual details and settings.'}
                    </span>
                </div>
                <div className="d-flex">
                    {/* <Filter dropdownOpen={dropdownOpen} toggle={dropdownToggle} /> */}
                    <Button
                        className="new-buttons-desing-org-purple org-page-btns text-white"
                        onClick={() => {
                            setForm(activeTab === 'members' ? 'add-member' : 'add-team')
                            toggle()
                        }}
                        style={{ height: '40px' }}
                    >
                        <img src="/svgs/trigger-actions/add.svg" alt="plus" />
                        {btnText}
                    </Button>
                </div>
            </div>
            {adminOrgs.status === 'loading' ? (
                <LoadingSpinner />
            ) : activeTab === 'members' ? (
                <div>
                    <div className="d-flex pb-3 justify-content-between">
                        <div
                            className="d-flex flex-column"
                            style={{ width: '300px', marginRight: '24px', marginBottom: '8px' }}
                        >
                            <span
                                style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '0.1px',
                                }}
                            >
                                Admin users
                            </span>
                            <span className="">
                                Admins can add and remove users and manage organisation-level
                                settings.
                            </span>
                        </div>
                        <div className="rounded-table-container" style={{ width: '90%' }}>
                            {adminsList?.length > 0 ? (
                                <BootstrapTable
                                    bootstrap4={true}
                                    classes="my-custom-table"
                                    keyField="uuid"
                                    data={adminsList}
                                    columns={membersColumns}
                                    pagination={paginationFactory({
                                        page: lastClickedPageAdmins,
                                        sizePerPage: 5,
                                        sizePerPageList: [],
                                        onPageChange: (page) => setLastClickedPageAdmins(page),
                                    })}
                                    defaultSorted={[
                                        {
                                            dataField: 'name',
                                            order: adminsSort,
                                        },
                                    ]}
                                />
                            ) : (
                                <h5 className="p-4">There are currently no admins or owners.</h5>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex py-3 justify-content-between">
                        <div
                            className="d-flex flex-column"
                            style={{ width: '300px', marginRight: '24px', marginBottom: '8px' }}
                        >
                            <span
                                style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '0.1px',
                                }}
                            >
                                Account users
                            </span>
                            <span className="">
                                Account users can access and manage the Meili FMS dashboard, mission
                                control, maps, actions, and vehicles.
                            </span>
                        </div>
                        <div className="rounded-table-container" style={{ width: '90%' }}>
                            {membersList?.length > 0 ? (
                                <BootstrapTable
                                    bootstrap4={true}
                                    classes="my-custom-table"
                                    keyField="uuid"
                                    data={membersList}
                                    columns={membersColumns}
                                    pagination={paginationFactory({
                                        page: lastClickedPageMembers,
                                        sizePerPage: 5,
                                        sizePerPageList: [],
                                        onPageChange: (page) => setLastClickedPageMembers(page),
                                    })}
                                    defaultSorted={[
                                        {
                                            dataField: 'name',
                                            order: membersSort,
                                        },
                                    ]}
                                />
                            ) : (
                                <h5 className="p-4">There are currently no members.</h5>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="rounded-table-container">
                    {teamsList?.length > 0 ? (
                        <BootstrapTable
                            bootstrap4={true}
                            classes="my-custom-table"
                            keyField="uuid"
                            data={teamsList}
                            columns={teamsColumns}
                            pagination={paginationFactory({
                                page: lastClickedPageTeams,
                                sizePerPage: 7,
                                sizePerPageList: [],
                                onPageChange: (page) => setLastClickedPageTeams(page),
                            })}
                            defaultSorted={[
                                {
                                    dataField: 'title',
                                    order: teamsSort,
                                },
                            ]}
                        />
                    ) : (
                        <h5 className="p-4">There are currently no teams.</h5>
                    )}
                </div>
            )}

            <ManageOrganizationModals
                slug={currentOrg?.slug}
                form={form}
                modal={modal}
                toggle={toggle}
                setForm={setForm}
                dataOnClick={dataOnClick}
                submitDelete={handleRemoveFromOrg}
            />
        </div>
    )
}

export default OrganizationManage
