export const actionTypes = {
    GET_ALL_MEMBERS: 'GET_ALL_MEMBERS',
    GET_ALL_MEMBERS_SUCCESS: 'GET_ALL_MEMBERS_SUCCESS',
    GET_ALL_MEMBERS_FAIL: 'GET_ALL_MEMBERS_FAIL',

    REMOVE_MEMBER: 'REMOVE_MEMBER',
    REMOVE_MEMBER_SUCCESS: 'REMOVE_MEMBER_SUCCESS',
    REMOVE_MEMBER_FAIL: 'REMOVE_MEMBER_FAIL',
    UPDATE_MEMBER: 'UPDATE_MEMBER',
    UPDATE_MEMBER_SUCCESS: 'UPDATE_MEMBER_SUCCESS',
    UPDATE_MEMBER_FAIL: 'UPDATE_MEMBER_FAIL',
}

export function getMembers(slug) {
    return {
        type: actionTypes.GET_ALL_MEMBERS,
        payload: {
            request: {
                url: `api/teams/${slug}/memberships/`,
                method: 'GET',
            },
        },
    }
}

export function removeMember({ uuid }) {
    return {
        type: actionTypes.REMOVE_MEMBER,
        payload: {
            request: {
                url: `api/teams/memberships/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function updateMembership({ data, uuid }) {
    return {
        type: actionTypes.UPDATE_MEMBER,
        payload: {
            request: {
                url: `api/teams/memberships/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}
